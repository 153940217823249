const OnCampusJobs = (props) => {

  const description = "As a student you have access to various on and off-campus opportunities. It is the Career Center’s hope that a student job will be more than the path to a paycheck but rather a learning experience to help you become more organized, accept greater job responsibilities and develop transferable job skills that can apply to future career positions."

  const onCampus = "Learn more about the various types of on-campus student employment, how to locate and apply for positions, and rules and policies for working on campus."

  const moreInfo = "Welcome to our future On-Campus Jobs section! Each year, Towson University students work on campus in almost every department in a variety of roles. These student employees help the university run smoothly, gain skills that will help them in their future professional positions, finance their education and enhance their overall college experience."

  const bulletPoints = [
    {
      title: "Types Of Student Employment",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/types.html"
    },
    {
      title: "Eligibility Requirements",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/requirements.html"
    },
    {
      title: "Federal Work-Study FAQ",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/workstudyfaq.html"
    },
    {
      title: "Get a Job",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/getajob.html"
    },
    {
      title: "You're Hired",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/hired.html"
    },
    {
      title: "Guidelines",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/guidelines.html"
    },
    {
      title: "Professional Development & Training",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/training.html"
    }
  ]

  return (
    <div className="content-placeholder-container container-border">
      <img className="header-img background-img-contain" src="https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/oceanBanners%2FJobs?alt=media&token=911d96a2-b107-4884-acae-6d2d90cbe47d"/>
      <div className="content-placeholder-text-area">
          <h2 className='highlight'>On Campus Jobs</h2>
          <p>{moreInfo} Click <a href="https://www.towson.edu/careercenter/students/employment/oncampus/" target="_blank" rel="noopener noreferrer" >here</a> to learn more</p>
          <ul>
            {bulletPoints.map((point, index) => {
              return (
                <li key={index}>
                  <a href={point.link} target="_blank" rel="noreferrer">{point.title}</a>
                </li>
              )
            })}
          </ul>
      </div>
    </div>
  )
}

export default OnCampusJobs