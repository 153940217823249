import React from 'react'
import GraphiteCardbackground from '../../../assets/images/tu_graphite_card_background_169.png';
import GoldCardBackground from '../../../assets/images/tu_gold_card_background_169.png'

const Major = (props) => {

    const handleClick = () => {
        props.handleMajorClick(props.major.majorName);
    }

    return (
        <div id='card-container' onClick={handleClick} className='clickable border-radius-25'>
            <div id='club-org-cover-img-container-inset-shadow'></div>
            <div id='club-org-cover-img-container'>
                <img src={(props.index % 2 === 0) ? GraphiteCardbackground : GoldCardBackground}/>
            </div>
            <div id='card-text-field'>
                <p>{props.major.majorName}</p>
            </div>
        </div>
    )
}

export default Major