import { React, useState, useEffect, Fragment } from 'react';
import axios from "axios";
import { FoundBadToken } from "../../../../scripts/badToken";
import getGlobalURL from '../../../../assets/scripts/Global';
import FormCancelSubmitBtns from "../../../../Modals/forms/FormCancelSubmitBtns";
import InputWithObject from "../../../atoms/InputWithObject";
import ConvertToBase64Simple from "../../../helperComponents/ConvertToBase64Simple";
import {storage} from "../../../../firebase";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import FriendsChatDefault from '../../../../assets/images/friendsChatDefault.webp'

const CreateFriendGroupModal = (props) => {
    const url = getGlobalURL();
    const [friendGroupName, setFriendGroupName] = useState('')
    const [groupIconFile, setGroupIconFile] = useState(null)
    const [groupIconPreview, setGroupIconPreview] = useState(FriendsChatDefault)
    const [mutuals, setMutuals] = useState([])
    const [membersToAdd, setMembersToAdd] = useState([])

    useEffect(() => {
        axios.get(`${url}users/getMutualFollowers/${props.currentUser.userID}`, {
            headers: {
                Authorization: sessionStorage.getItem("token")
            }
        }).then((response) => {
            console.log(response.data)
            setMutuals(response.data)
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }, []);

    const createFriendGroup = async () => {
        const groupID = Date.now();
        let groupIcon = '';
        // if(groupIconFile){
        //     groupIcon = await uploadGroupIcon(groupID);
        // }
        if(membersToAdd.length === 0){
            alert('Please add at least one member to the group');
            return;
        }
        if(!friendGroupName.groupName){
            alert('Please enter a group name');
            return;
        }
        axios.post(`${url}friendGroups/createGroup`, {
            groupID: groupID,
            groupIcon: groupIcon,
            groupName: friendGroupName.groupName,
            groupOwner: props.currentUser.userID,
            pendingMembers: membersToAdd,
            members: [{
                userID: props.currentUser.userID,
                missedMessages: 0,
            }],
            messages: [{
                userID: props.currentUser.userID,
                date: Date.now(),
                messageContent: `created this group`,
                contentURL: '',
                messageType: 'system'
            }]
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then(async (response) => {
            props.currentUser.friendGroupChats.push(groupID);
            let group = {
                groupID: groupID,
                groupIcon: groupIcon,
                groupName: friendGroupName.groupName,
                groupOwner: props.currentUser.userID,
                pendingMembers: membersToAdd,
                members: [{
                    userID: props.currentUser.userID,
                    missedMessages: 0,
                }],
                messages: [{
                    userID: props.currentUser.userID,
                    date: Date.now(),
                    messageContent: `created this group`,
                    contentURL: '',
                    messageType: 'system'
                }]
            }
            await sendUserInvites(groupID, membersToAdd);
            props.addGroup(group);
            props.closeModal();
        }).catch((error) => {
            alert(error)
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    async function sendUserInvites(groupID, members){
        members.forEach((member) => {
            console.log('sending invite to: ', member)
            let channel = props.ably.channels.get(`user-updates-${member}`);
            channel.publish('friend-group-invite', {
                friendGroupID: groupID,
            })
        })
    }

    async function uploadGroupIcon(groupID){
        if(!groupIconFile){
            return;
        }
        else{
            const iconRef = ref(storage, `groupIcons/${groupID}`);
            return new Promise((resolve, reject) => {
                uploadBytes(iconRef, groupIconFile).then((response) => {
                    getDownloadURL(iconRef).then((url) => {
                        resolve(url);
                    })
                })
            })
        }
    }

    const handleCheck = (userID, isChecked) => {
        if(isChecked){
            let newMembersToAdd = membersToAdd;
            console.log('newMembersToAdd before push: ', newMembersToAdd)
            newMembersToAdd.push(userID);
            console.log('newMembersToAdd after push: ', newMembersToAdd)
            setMembersToAdd(newMembersToAdd);
        }
        else{
            let newMembersToAdd = membersToAdd
            console.log('newMembersToAdd before filter: ', newMembersToAdd)
            newMembersToAdd = newMembersToAdd.filter((member) => member !== userID)
            console.log('newMembersToAdd after filter: ', newMembersToAdd)
            setMembersToAdd(newMembersToAdd);
        }
        console.log('membersToAdd: ', membersToAdd)
    }

    return (
        <div className='modal-container' >
            <div className='sg-backdrop' onClick={() => {props.closeModal()}} />
            <div className='friend-group-modal-container border-radius-25 make-grid'>
                <div id='friend-group-modal-header' className='form-header'>Create A Group Chat</div>
                <div id='friend-group-modal-body' >
                    {/* <div id='create-account-form-lc' >
                        <div className='form-body-img-area border-radius-10' >
                            <img src={groupIconPreview} alt="Group Icon" />
                            <ConvertToBase64Simple textID="banner-1" setFile={setGroupIconFile} setImage={setGroupIconPreview} />
                        </div>
                    </div> */}
                    <div id='friend-group-modal-rc' >
                        <div>
                            <div className='form-body form-body-override' >
                                <InputWithObject handleFocus={() => {}} type='text' required={true} forText='groupName' labelText='Group Name' setData={setFriendGroupName} />
                                {/* <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupCategory' labelText='Group Category' helperText='Subject (e.g COSC)' date={groupCategory} setData={setGroupCategory} /> */}
                                {/* <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={createFriendGroup} submitText='Create Group' /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='' >
                    {mutuals.map((mutual, index) => {
                        return (
                            <Fragment>
                                <div className='mutual-listing' >
                                    <img src={mutual.profileImage} />
                                    <h3>{mutual.fName + " " + mutual.lName}</h3>
                                    <div className='mutual-listing-user-info' >
                                        {/* <p>@{mutual.username}</p> */}
                                        <input type='checkbox' onChange={((e) => { handleCheck(mutual.userID, e.target.checked) })} />
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
                <div className='friend-group-button-area' >
                    <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={createFriendGroup} submitText='Create Group' />
                </div>
            </div>
        </div>
    )
}

export default CreateFriendGroupModal;