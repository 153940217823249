import { React, Fragment, useState, useEffect, useContext } from "react";
import DefaultStudyGroupIcon from '../../../assets/images/DefaultStudyGroupIcon.png'
import { getElapsedTime } from "../../../assets/scripts/mchScripts";
import { AblyContext } from "../../../assets/custom-hooks/AblyContext";

const GroupListing = (props) => {
    const [numMessages, setNumMessages] = useState(0);

    const { reduceNumMessages, markAsRead } = useContext(AblyContext);

    const handleSetMessagesToZero = () => {
        props.group.members.forEach((member) => {
            if(member.userID === props.currentUser.userID){
                member.missedMessages = 0;
            }
        })
    }

    useEffect(() => {
        props.group.members.forEach((member) => {
            if(member.userID === props.currentUser.userID){
                setNumMessages(member.missedMessages);
            }
        })
    }, [props.group.messages.length]);

    const showTime = () => {
        return props.group.messages[props.group.messages.length - 1] ? (
            getElapsedTime(props.group.messages[props.group.messages.length - 1].date)
        ) : 'N/A'
    }

    const getLastMessage = () => {
        return props.group.messages[props.group.messages.length - 1].userID === props.currentUser.userID ? (
            props.group.messages[props.group.messages.length - 1].messageType === 'system' ? (
                'You ' + props.group.messages[props.group.messages.length - 1].messageContent
            ) : (
                <><strong>You: </strong> {props.group.messages[props.group.messages.length - 1].messageContent} </>
            )
        ) : (
            props.group.messages[props.group.messages.length - 1].messageType === 'system' ? (
                `${props.group.messages[props.group.messages.length - 1].userName} ` + props.group.messages[props.group.messages.length - 1].messageContent
            ) : (
                <><strong>{props.group.messages[props.group.messages.length - 1].userName}</strong>: {props.group.messages[props.group.messages.length - 1].messageContent}</>
            )
        )
        // return props.group.messages[props.group.messages.length - 1] ? (
        //     props.group.messages[props.group.messages.length - 1].userID === props.currentUser.userID ? (
        //         props.group.messages[props.group.messages.length - 1].messageType === 'system' ? (
        //             'You ' + props.group.messages[props.group.messages.length - 1].messageContent
        //         ) : (
        //             <><strong>You: </strong> {props.group.messages[props.group.messages.length - 1].messageContent} </>
        //         )
        //     ) : (
        //         props.group.messages[props.group.messages.length - 1].messageType === 'system' ? (
        //             `${props.group.messages[props.group.messages.length - 1].userName} ` + props.group.messages[props.group.messages.length - 1].messageContent
        //         ) : (
        //             <><strong>{props.group.messages[props.group.messages.length - 1].userName}</strong>: {props.group.messages[props.group.messages.length - 1].messageContent}</>
        //         )
        //     )
        // ) : 'Its quiet in here...'
    }

    return (
        <div className={numMessages > 0 ? 'message-area-unseen' : 'message-area' } >
            <div onClick={() => {
                if(numMessages > 0){
                    reduceNumMessages(numMessages)
                    markAsRead(props.currentUser.userID, props.group.studyGroupID, sessionStorage.getItem('token'), 'studyGroups');
                }
                props.handleSelectedGroupChat(props.index);
                setNumMessages(0);
                handleSetMessagesToZero();
            }} >
                <div className='message-container make-grid' >
                    <img src={props.group.studyGroupPicture ? props.group.studyGroupPicture : DefaultStudyGroupIcon} />
                    <div className='message-text-area make-grid' >
                        <h2>{props.group.studyGroupName}</h2>
                        <span id='message-content' >{getLastMessage()}</span>
                    </div>
                    <div className='message-details make-grid' >
                        <p>{showTime()}</p>
                        <div className='message-counter-container' >
                            { numMessages > 0 ? (
                                <div className='message-counter' >
                                    <p>{numMessages}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupListing;