import React, { useState, useEffect, useRef} from "react";
import getGlobalURL from "../../../assets/scripts/Global";
import ChatTextArea from "../../atoms/ChatTextArea";

const ChatbotChatField = (props) => {
    const url = getGlobalURL();
    const [message, setMessage] = useState("")
	const lastMessageRef = useRef(null);

	const scrollToBottom = () => {
		/*
        Called within useEffect when a new message is sent. Automatically scrolls the message-application-chat-field
        down to the last message without scrolling the entire window down.
        */
		let lastMessageDiv = lastMessageRef.current;
		let lastChild = lastMessageDiv.lastChild;
		lastMessageDiv.scrollTop = lastChild.offsetTop + lastChild.scrollHeight;
	};


    const newSendMessage = async () => {
        alert("This feature is coming soon!")
		
	};

	return (
		<div id="message-application-chat-field-container" className="make-grid">
			<div className="message-application-chat-field" ref={lastMessageRef}>
				<h2>This feature is coming soon!</h2>
			</div>
			<div className="message-app-input-field">
				<ChatTextArea
					handleSendMessage={newSendMessage}
					setMessage={setMessage}
					message={""}
				/>
			</div>
		</div>
	);
};

export default ChatbotChatField;
