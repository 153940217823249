import getGlobalURL from "../../../assets/scripts/Global";
import axios from "axios";
import { React, useState, useEffect, Fragment } from "react";
import ClubEventListing from "./ClubEventListing";
import ClubEventModal from "./ClubEventModal";
import { FoundBadToken } from "../../../scripts/badToken";

const ClubEvents = (props) => {
   const url = getGlobalURL();
   const [events, setEvents] = useState([]);

   const [eventMade, setEventMade] = useState(false);
   const [showEventModal, setShowEventModal] = useState(false);
   const [event, setEvent] = useState();

   useEffect(() => {
      axios.get(`${url}clubs/getClubEvents/${props.club.clubName}`, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if (response.status === 200) {
            setEvents(response.data);
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   }, [eventMade, props.eventMade]);

   function handleCloseEventModal() {
      setShowEventModal(false);
   }

   function handleSetEvent(event) {
      setEvent(event);
      setShowEventModal(true);
   }

   function handleEventMade() {
      setEventMade(!eventMade);
   }

   return (
      <Fragment>
         {showEventModal && (
            <ClubEventModal
               handleEventMade={handleEventMade}
               currentUser={props.currentUser}
               event={event}
               clubAdmins={props.club.admins}
               clubName={props.club.clubName}
               handleCloseEventModal={handleCloseEventModal}
            />
         )}
         <div id="child-oceans-container">
            <h2>Club Events</h2>
            {events.map((event) => {
               return (
                  <Fragment key={event.eventID}>
                     <ClubEventListing
                        handleSetEvent={handleSetEvent}
                        currentUser={props.currentUser}
                        event={event}
                     />
                     <div id="club-events-hr">
                        <hr />
                     </div>
                  </Fragment>
               );
            })}
         </div>
      </Fragment>
   );
};

export default ClubEvents;
