import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import AnnouncementModal from "./AnnouncementModal";
import ChannelMessage from "./ChannelMessage";
import ManageChannelMemberModal from "./ManageChannelMemberModal";
import MessageExpandModal from "../modals/MessageExpandModal";
import { storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FoundBadToken } from "../../../scripts/badToken";

// ! PROPS FROM ClassroomField.jsx: courseSelected, channelSelected, courseID, actionTaken, currentUser, channel, ably, course, handleSetActionTaken
const ClassRoom = (props) => {
   const [fetching, setFetching] = useState(true);
   const [message, setMessage] = useState("");
   const [showCreateAnnouncementModal, setShowCreateAnnouncementModal] = useState(false);
   const [channelMessages, setChannelMessages] = useState();
   const [showManageModal, setShowManageModal] = useState(false);
   const [action, setAction] = useState("");
   const [file, setFile] = useState(null);
   const [image, setImage] = useState(null);
   const [imagePreview, setImagePreview] = useState(null);
   const [typing, setTyping] = useState(false);
   const [typers, setTypers] = useState([]);
   const [showMessageModal, setShowMessageModal] = useState(false);
   const [modalMessage, setModalMessage] = useState();

   const url = getGlobalURL();

   useEffect(() => {
      axios.get(`${url}courses/getChannelMessages/${props.course.courseCode}/${props.channelSelected}`, {
         headers: {
               Authorization: sessionStorage.getItem('token')
         }
      }).then((response) => {
         if(response.status === 200){
               console.log(response.data)
               setChannelMessages(response.data)
               setFetching(false)
         }
      }).catch((error) => {
         alert(`${error}`);
      })
   }, [props.course, props.channelSelected]);

   useEffect(() => {
      if(props.channelSelected !== 'Announcements'){
         props.channel.current = props.ably.current.channels.get(`${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`);

         props.channel.current.subscribe('receive-message', (newMessage) => {
            setChannelMessages(newMessage.data);
         })

         props.channel.current.subscribe('receive-notification', (newMessage) => {
            setChannelMessages(newMessage.data);
         })

         props.channel.current.subscribe('remove-message', (newMessage) => {
            setChannelMessages(newMessage.data);
         })

         props.channel.current.subscribe('typing', (name) => {
            setTypers((prevTypers) => {
               return [...prevTypers, name.data];
            })
         })

         props.channel.current.subscribe('stopped-typing', (name) => {
            setTypers((prevTypers) => {
               return prevTypers.filter((typer) => {return typer !== name.data});
            })
         })
      }

      return () => {
         if(props.channel.current != null){
            props.channel.current.unsubscribe();
         }
      }
   }, [props.channel.current, props.channelSelected, props.courseSelected]);

   const handleShowAnnouncementModal = () => {
      setShowCreateAnnouncementModal(true);
   };

   const handleCloseAnnouncementModal = () => {
      setShowCreateAnnouncementModal(false);
   };

   const sendMessage = async () => {
      if (message === "") {
         alert("Message cannot be empty");
         return;
      } 
      else{
         let file_url = file ? await uploadFile() : "";
         let img_url = image ? await uploadImage() : "";
         let tempMessage = {
            message: message,
            senderName: props.currentUser.fName + " " + props.currentUser.lName,
            senderPicture: props.currentUser.profileImage,
            timestamp: Date.now(),
            messageID: Date.now(),
            senderID: props.currentUser.userID,
            file: file_url,
            fileName: file ? file.name : "",
            img: img_url,
            imgName: image ? image.name : "",
            isNotification: false,
            replies: [],
         };
         axios.post(`${url}courses/addMessage`, {
            message: tempMessage,
            courseCode: props.course.courseCode,
            channelName: props.channelSelected
         }, { 
            headers: {
               Authorization: sessionStorage.getItem('token')
            }
         }).then((response) => {
            if(response.status === 200){
               setMessage('');
               axios.post(`${url}channels/publishMessage`, {
                  message: tempMessage,
                  ablyChannel: `${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`,
                  courseCode: props.course.courseCode,
                  courseChannel: props.channelSelected
               }, {
                  headers: {
                     Authorization: sessionStorage.getItem('token')
                  }
               }).then((response) => {
                  if(response.status === 200){
                     
                  }
               }).catch((error) => {
                  alert(`${error}`);
               })
            }
         }).catch((error) => {
            alert(`${error.response.data.message}`);
         })
      }
      setMessage("");
      setFile(null);
      setImage(null);
      setImagePreview(null);
      document.getElementById("message-input").value = "";
   };

   async function uploadFile() {
      const fileRef = ref(
         storage,
         `courseFiles/${props.course.courseCode}/${props.channelSelected}/${file.name}`
      );
      return new Promise((resolve, reject) => {
         uploadBytes(fileRef, file).then((response) => {
            getDownloadURL(fileRef).then((fileUrl) => {
               resolve(fileUrl);
            });
         });
      });
   }

   async function uploadImage() {
      const imageRef = ref(
         storage,
         `courseImages/${props.course.courseCode}/${props.channelSelected}/${props.currentUser.userID}/${image.name}`
      );
      return new Promise((resolve, reject) => {
         uploadBytes(imageRef, image).then((response) => {
            getDownloadURL(imageRef).then((imageUrl) => {
               resolve(imageUrl);
            });
         });
      });
   }

   function showTypers() {
      let string = "";
      typers.forEach((typer) => {
         string += string.length == 0 ? typer : `, ${typer}`;
      });
      return string;
   }

   const handleSetMessage = (e) => {
      if (e.key === "Enter") {
         e.preventDefault();
         sendMessage();
      } else {
         if (!typing) {
            setTyping(true);
            props.channel.current.publish(
               "typing",
               props.currentUser.fName + " " + props.currentUser.lName,
               () => {
                  setTimeout(() => {
                     setTyping(false);
                     props.channel.current.publish(
                        "stopped-typing",
                        props.currentUser.fName + " " + props.currentUser.lName
                     );
                  }, 3000);
               }
            );
         }
         setMessage(e.target.value);
      }
   };

   const showManageMembersModal = () => {
      //TODO show the modal for managing members for this specific channel
      setShowManageModal(true);
   };

   const handleAddFile = (e) => {
      setFile(e.target.files[0]);
   };

   const handleAddImage = (e) => {
      setImage(e.target.files[0]);
      var reader = new FileReader();
      if (e.target.files[0]) {
         reader.readAsDataURL(e.target.files[0]);
         reader.onload = function () {
            setImagePreview(reader.result);
         };
      }
   };

   const handleRemoveFile = () => {
      setFile(null);
   };

   const handleClosePreviewClick = () => {
      setImage(null);
      setImagePreview(null);
      document.querySelector('input[name="img-selection"]').value = "";
   };

   const handleDeleteChannel = () => {
      axios.post(`${url}channels/deleteChannel`, {
         channelName: props.channelSelected,
         courseCode: props.course.courseCode
      }, {
         headers: {
            Authorization: sessionStorage.getItem('token')
         }
      }).then((response) => {
         if(response.status === 200){
            alert('Channel deleted');
         }
      }).catch((error) => {
         if(error.code === 'ERR_BAD_REQUEST'){
            FoundBadToken();
         }
         else{
            alert(`${error.response.data.message}`);
         }
      })
   };

   const handleShowMessageModal = (message) => {
      setShowMessageModal(true);
      console.log("message for modal");
      console.log(message);
      setModalMessage(message);
   };

   const handleHideMessageModal = () => {
      setShowMessageModal(false);
   };

   return (
      <Fragment>
         <div id='classroom-field'>
            {showCreateAnnouncementModal && <AnnouncementModal course={props.course} currentUser={props.currentUser} closeModal={handleCloseAnnouncementModal} />}
            {showManageModal && <ManageChannelMemberModal ably={props.ably} channel={props.channel} actionTaken={props.actionTaken} handleSetActionTaken={props.handleSetActionTaken} action={action} channelSelected={props.channelSelected} course={props.course} currentUser={props.currentUser} closeModal={() => setShowManageModal(false)} />}
            {showMessageModal && <MessageExpandModal channelSelected={props.channelSelected} message={modalMessage} currentUser={props.currentUser} course={props.course} ably={props.ably} handleHideMessageModal={handleHideMessageModal} /> }
            <h3>{props.course.courseSubject} {props.course.courseCode} - {props.course.courseName}</h3>
            <h4>{props.channelSelected}</h4>
            {fetching ? <p>Fetching...</p> : (
               <Fragment>
                  {channelMessages.messagesByDate.map((date, index) => {
                     if(date.date != '' && date.messages.length){
                           return (
                              <Fragment key={index} >
                                 <h3>{date.date}</h3>
                                 {date.messages.map((message) => {
                                       return (
                                          <ChannelMessage handleShowMessageModal={handleShowMessageModal} channelSelected={props.channelSelected} course={props.course} currentUser={props.currentUser} message={message} />
                                       )
                                 })}
                              </Fragment>
                           )
                     }
                  })}
               </Fragment>
            )}
            {props.course.channels.find((channel) => {return channel.name === props.channelSelected}).permissions === 'R' && props.currentUser.role === 'Professor' ? (
               <button onClick={handleShowAnnouncementModal} >Create Announcement</button>
            ) : (null)}
         </div>
         {props.course.channels.find((channel) => {return channel.name === props.channelSelected}).permissions === 'RW' ? (
            <div id='classroom-message-field' >
            {/* <div id="user-post-container" className="container-border" > */}
            {typers.length > 0 ? (<h5>{showTypers()} is typing...</h5>) : (null)}
               {/* <div id="input-field-container" className="make-grid" style={{"border" : "1px solid black", "background" : "white"}} > */}
               <div id="input-field-container" className="make-grid" style={{"border" : "1px solid black"}} >
                  <input type='textarea' id='message-input' placeholder={`Message ${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`} onKeyUp={handleSetMessage} />
                  <img className="user-post-image" style={{"border" : "1px solid black"}} src={props.currentUser.profileImage} />
               </div>
               <div>
                  {props.currentUser.userID === props.course.courseInstructor ? (
                     <Fragment>
                        <button className='ocean-header-btns btn-ease' onClick={() => {
                              setAction('add');
                              showManageMembersModal();
                        }} >Add Members</button>
                        <button className='ocean-header-btns btn-ease' onClick={() => {
                              setAction('remove');
                              showManageMembersModal();
                        }} >Remove Members</button>
                        <button className='ocean-header-btns btn-ease' onClick={handleDeleteChannel} >Delete {props.course.courseSubject}-{props.course.courseCode}-{props.channelSelected}</button>
                     </Fragment>
                  ) : (null)}
                  <Fragment>
                     <input type='file' accept='*' name='file-selection' onChange={handleAddFile} style={{ display: 'none' }}/>
                     <button className='ocean-header-btns btn-ease' onClick={() => {
                        document.querySelector('input[name="file-selection"]').click()
                     }} >Add File</button>
                     <input type='file' accept='image/*' name='img-selection' onChange={handleAddImage} style={{ display: 'none' }}/>
                     <button className='ocean-header-btns btn-ease' onClick={() => {
                        document.querySelector('input[name="img-selection"]').click()
                     }} >Add Image</button>
                     {file && <button className='ocean-header-btns btn-ease' onClick={handleRemoveFile} >Remove File</button>}
                  </Fragment>
                  {imagePreview && (
                     <div id='post-preview-container'>
                        <div onClick={handleClosePreviewClick} className='close-preview-btn btn-ease'>x</div>
                        <img src={imagePreview} />
                     </div>
                  )}
                  {file && <p>{file.name}</p>}
               </div>
               {/* </div> */}
            </div>
         ) : (null)}
      </Fragment>
   )
};

export default ClassRoom;
