import { React, Fragment } from 'react'
import ClubsField from '../campusModeComponents/ClubsField'
import AthleticsField from '../athletics/AthleticsField'
import GreekLifeField from '../campusModeComponents/greekLife/GreekLifeField'

/**
 * @description - A component that renders a specific StudentLife field based on the active button selected. 
 *  The fields are: AthleticsField, ClubsField, and GreekLifeField. 
 * 
 * @component
 * @param {String} activeButton - The active button selected
 * @param {Object} currentUser - The current user object
 * @returns {JSX.Element} - A specific StudentLife field based on the active button selected
 * 
 * @example
 * // Renders a specific StudentLife field based on the active button selected
 * <StudentLifeTab
 *  activeButton={activeButton}
 *  currentUser={currentUser}
 * />
 */

const StudentLifeTab = (props) => {
    return (
        <Fragment>
            {/*  */}
            {(props.activeButton === "Athletics") ? <AthleticsField />
                : (props.activeButton === "Clubs") ? <ClubsField currentUser={props.currentUser} />
                : (props.activeButton === "Greek Life") ? <GreekLifeField />
                : null
            }
        </Fragment>
    )
}

export default StudentLifeTab


// Old Navigation: Note that we'd need to pass the following props to the StudentLifeBtnList component
// import StudentLifeBtnList from '../campusModeComponents/StudentLifeBtnList'
// <StudentLifeBtnList currentUser={props.currentUser} campusButtonSelection={props.campusButtonSelection} activeButton={props.activeButton} handleTabClick={props.handleTabClick} tabSelected={props.tabSelected} source={props.source}/>