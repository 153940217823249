import getGlobalURL from "../../assets/scripts/Global";
import { React, Fragment, useState } from "react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../scripts/badToken";
import InputWithObject from "../../components/atoms/InputWithObject";
import FormCancelSubmitBtns from "./FormCancelSubmitBtns";

const ParentRequestModal = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [parentName, setParentName] = useState("");
   const [posting, setPosting] = useState(false); // Used for the spinner
   const [focussedField, setFocussedField] = useState(null);

   const requestParent = () => {
      axios.patch(`${url}oceans/parentOceanRequest`,{
         oceanID: props.ocean.oceanID,
         parentOceanName: parentName.fName,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         props.closeModal("parentRequest");
      }).catch((error) => {
         if (error.response.status === 401) {
            alert("Session expired. Please login again.")
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   const handleBackdropClick = () => {
      props.closeModal("parentRequest");
   };

   const handleFocus = (focussedOn) => {
      setFocussedField(focussedOn);
   }

   return (
      <div className="modal-container">
         <div className="modal-backdrop" onClick={handleBackdropClick} />
            <div className="new-form-container">
               <div className="new-form-header">Parent Request Form</div>
               <div className="new-form-body">
                  {posting ? (
                     <div style={{display: "flex", justifyContent: "center",alignItems: "center", height: "100%","margin-top": "15px"}}>
                        <PuffLoader color="#006994" />
                     </div>
                  ) : (
                     <Fragment>
                        <p className="form-instruction-text">Please enter the title of the hub/topic that you have created and that you wish to attach as a child or sub-topic of this parent hub.</p>
                        <hr className="hr-custom"/>
                        <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="fName" labelText="Hub Name:" data={parentName} setData={setParentName}/>
                        <FormCancelSubmitBtns handleCloseForm={handleBackdropClick} closeText="Cancel" handleSubmit={requestParent} submitText="Submit" />
                     </Fragment>
                  )}
               </div>
            </div>
      </div>
   );
};

export default ParentRequestModal;
