import getGlobalURL from "../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../scripts/badToken";
import FormCancelSubmitBtns from "../../Modals/forms/FormCancelSubmitBtns";
import ConvertToBase64Simple from '../../components/helperComponents/ConvertToBase64Simple'
import TextAreaWithObject from "../../components/atoms/TextAreaWithObject";

const EditOceanDataModal = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [bannerFile, setBannerFile] = useState(null);
   const [oceanBannerImage, setOceanBannerImage] = useState(props.ocean.oceanBanner);
   const [focussedField, setFocussedField] = useState(null);   // For future implementation

   const [oceanDescription, setOceanDescription] = useState(props.ocean.description);

   const [oceanData, setOceanData] = useState({
      oceanName: props.ocean.oceanName,
      description: props.ocean.description,
      oceanBanner: oceanBannerImage
   })

   const [posting, setPosting] = useState(false); // Used for the spinner

   const handleSubmitClick = async () => {
      let bannerImage = oceanBannerImage;

      if (bannerFile != null) {
         bannerImage = await uploadBannerImage(props.ocean.oceanName);
      }

      axios.patch(url + "oceans/updateOcean",{
         oceanName: oceanData.oceanName,
         description: oceanData.description,
         oceanBanner: bannerImage,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         props.ocean.description = oceanData.description;
         props.ocean.oceanBanner = bannerImage;
         props.updateOcean();
         props.closeModal("editOcean");
      }).catch((error) => {
         if(error.response.status === 401){
            alert('Session expired. Please log in again.')
            FoundBadToken();
         }
      });
   };

   async function uploadBannerImage(oceanName) {
      if (!bannerFile) {
         return;
      }

      const bannerImageRef = ref(storage, `oceanBanners/${oceanName}`);
      return new Promise((resolve, reject) => {
         uploadBytes(bannerImageRef, bannerFile).then((response) => {
            getDownloadURL(bannerImageRef).then((bannerUrl) => {
               setOceanBannerImage(bannerUrl);
               resolve(bannerUrl);
            });
         });
      });
   }

   const handleFocus = (focussedOn) => {
      // For future implementation for handling focus on input fields
      setFocussedField(focussedOn);
   }

   const handleBackdropClick = () => {
      props.closeModal("editOcean");
   };

   return (
      <div className='modal-container' >
         <div className='sg-backdrop' onClick={() =>  { handleBackdropClick() }}  />
         <div className='create-account-form-2c-container border-radius-25 make-grid'>
               <div id='create-account-form-2c-header' className='form-header'>{`Update ${props.ocean.oceanName}`}</div>
               <div id='create-account-form-main-body' >
                  <div id='create-account-form-lc' >
                     <div className='form-body-img-area border-radius-10' >
                           <img src={oceanBannerImage} alt="Group Icon" />
                           <ConvertToBase64Simple textID="banner-1" setFile={setBannerFile} setImage={setOceanBannerImage} />
                     </div>
                  </div>
                  <div id='create-account-form-rc' >
                     <div id='create-account-2c-form-container' >
                           <div className='form-body form-body-override' >                              
                              <TextAreaWithObject handleFocus={handleFocus} forText="description" labelText="Hub Description:" value={oceanData.description} data={oceanData} setData={setOceanData} />
                              <FormCancelSubmitBtns handleCloseForm={handleBackdropClick} closeText='Cancel' handleSubmit={handleSubmitClick} submitText='Update Group' />
                           </div>
                     </div>
                  </div>
               </div>
         </div>
      </div>
   );
};

export default EditOceanDataModal;
