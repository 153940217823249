import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import getGlobalURL from "../../assets/scripts/Global";
import PuffLoader from "react-spinners/PuffLoader";
import College from "./College";
import CollegeSlide from "./CollegeSlide";
import Department from "./Department";
import DepartmentSlide from "./DepartmentSlide";
import $ from "jquery";
import { FoundBadToken } from "../../scripts/badToken";
import SearchField from "../helperComponents/SearchField";
import DefaultBanner from "../../assets/images/directory_banner.jpg";

// ! PROPS COMING FROM AcademicsTab.jsx: currentUser
const DepartmentField = (props) => {
   const [fetching, setFetching] = useState(true);
   const [colleges, setColleges] = useState([]);
   const [departments, setDepartments] = useState([]);
   const [search, setSearch] = useState("");
   const [filter, setFilter] = useState("All");
   const [departmentClicked, setDepartmentClicked] = useState();

   const tempDescription = "Welcome to the Departments section! Here, you’ll find comprehensive information on each academic department within our institution. This section is designed to provide you with easy access to essential details about your department of interest, including a thorough overview of each department’s focus, key areas of study, and available resources. You can explore contact information for department offices, faculty members, and administrative staff, making it easier to reach out for guidance, support, or inquiries. Whether you’re looking to learn more about a particular department, find out about available courses, or connect with faculty, this section is your go-to resource for all departmental information."

   const url = getGlobalURL();

   useEffect(() => {
      axios.get(url + "departments/getAllDepartments", {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if (response.status === 200) {
            console.log(response.data);
            setDepartments(response.data);
            // setColleges(response.data);
            setFetching(false);
         } else {
            alert("Error getting departments");
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   }, []);

   useEffect(() => {
      $("#info-slide").slideToggle("fast", "swing");
   }, [departmentClicked]);

   const handleDepartmentClick = (departmentClicked) => {
      console.log(`Department clicked: ${departmentClicked}`);
      setDepartmentClicked(departmentClicked);
      $("#info-slide").slideToggle("fast", "swing");
   };

   function filterDepartments() {
      let array = departments.filter((department) => {
         return (
            department.departmentName.toLowerCase().startsWith(search.toLowerCase()) && (department.departmentCollege === filter || filter === "All")
         );
      });
      if (array.length === 0) {
         return <h1>No Departments Found</h1>;
      } else {
         return array.map((department, index) => {
            return (
               <div id="college-listing-plus-info-container">
                  <Fragment key={department.departmentID}>
                     <Department
                        handleDepartmentClick={handleDepartmentClick}
                        index={index}
                        departmentName={department.departmentName}
                     />
                     {departmentClicked === department.departmentName ? (
                        <DepartmentSlide
                           id="department-info-slide"
                           department={department}
                        />
                     ) : null}
                  </Fragment>
               </div>
            );
         });
      }
   }

   return (
      <Fragment>
         <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src={DefaultBanner}/>
            <div className="content-placeholder-text-area">
                  <h2 className='highlight'>Explore Departments</h2>
                  <p>{tempDescription}</p>
            </div>
            <div id="college-field-container">
               <div id="search-orgs-container">
                  <SearchField 
                     title="departments"
                     setCategory={setFilter} 
                     setSearch={setSearch} 
                     categories={['All', 'College of Business & Economics', 'College of Education', 'College of Fine Arts & Communication', 'College of Health Professions', 'College of Liberal Arts', 'Jess & Mildred Fisher College of Science & Mathematics', 'Honors College']}
                  />
               </div>
               {fetching ? (
                  <PuffLoader color="#006994" />
               ) : search === "" && filter === "All" ? (
                  departments.map((department, index) => {
                     return (
                        <div id="college-listing-plus-info-container">
                           <Fragment key={department.departmentID}>
                              <Department
                                 handleDepartmentClick={handleDepartmentClick}
                                 index={index}
                                 departmentName={department.departmentName}
                              />
                              {departmentClicked === department.departmentName ? (
                                 <DepartmentSlide
                                    id="info-slide"
                                    department={department}
                                 />
                              ) : null}
                           </Fragment>
                        </div>
                     );
                  })
               ) : (
                  filterDepartments()
               )}
            </div>
         </div>
      </Fragment>
   );
};

export default DepartmentField;
