import React from 'react'
// import GraphiteCardbackground from '../../assets/images/tu_graphite_card_background_169.png';
import GoldCardBackground from '../../assets/images/tu_gold_card_background_169.png'

const StaffBioCard = (props) => {

    const handleClick = () => {
        props.handleClick();
    }

    return (
        <div id='card-container' onClick={handleClick} className='border-radius-25'>
            <div id='club-org-cover-img-container-inset-shadow'></div>
            <div id='club-org-cover-img-container'>
                <img src={GoldCardBackground}/>
            </div>
            <div id='card-text-field'>
                <p>{"Meet The Tutors"}</p>
            </div>
        </div>
    )
}

export default StaffBioCard