import getGlobalURL from "../../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from "react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../scripts/badToken";

const ClubMemberListing = (props) => {
   const url = getGlobalURL();

   const [user, setUser] = useState();
   const [fetching, setFetching] = useState(true);

   useEffect(() => {
      axios.post(`${url}users/getSingleUser`,{
         userID: props.memberID,
      },{
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         setUser(response.data[0]);
         setFetching(false);
      }).catch((error) => {
         if(error.code === "ERR_BAD_REQUEST"){
            FoundBadToken();
         }
         else{
            alert(error.response.data.err);
         }
      });
   }, []);

   const handleRemoveMember = () => {
      axios.patch(`${url}clubs/removeClubMember`,{
         userID: props.memberID,
         clubName: props.club.clubName,
         adminOfficerID: props.currentUserID,
      },{
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if(response.status == 200){
            alert("Member has been removed");
         }
      }).catch((error) => {
         if(error.code === "ERR_BAD_REQUEST"){
            FoundBadToken();
         }
         else{
            alert(error);
         }
      });
   };

   const handleAddOfficer = () => {
      axios.post(`${url}clubs/addOfficer`,{
         clubName: props.club.clubName,
         userID: props.memberID,
         adminID: props.currentUserID,
      },{
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if(response.status == 200){
            alert("Officer has been added");
         }
      }).catch((error) => {
         if(error.code === "ERR_BAD_REQUEST"){
            FoundBadToken();
         }
         else{
            alert(error);
         }
      });
   };

   const handleRemoveOfficer = () => {
      axios.post(`${url}clubs/removeOfficer`,{
         clubName: props.club.clubName,
         userID: props.memberID,
         adminID: props.currentUserID,
      },{
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if(response.status == 200){
            alert("Officer has been removed");
         }
      }).catch((error) => {
         if(error.code === "ERR_BAD_REQUEST"){
            FoundBadToken();
         }
         else{
            alert(error);
         }
      });
   };

   const handleBanUser = () => {
      axios.patch(`${url}clubs/banUser`,{
         clubName: props.club.clubName,
         userID: props.memberID,
         adminID: props.currentUserID,
      },{
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if(response.status == 200){
            alert("User has been banned");
         }
      }).catch((error) => {
         if(error.code === "ERR_BAD_REQUEST"){
            FoundBadToken();
         }
         else{
            alert(error);
         }
      });
   };

   const addAdmin = () => {
      axios.patch(`${url}clubs/addAdmin`,{
         clubName: props.club.clubName,
         userID: props.memberID,
         adminID: props.currentUserID,
      },{
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if (response.status == 200) {
            alert("User has been made an admin");
         }
      }).catch((error) => {
         if(error.code === "ERR_BAD_REQUEST"){
            FoundBadToken();
         }
         else {
            alert(error);
         }
      });
   };

   function showAddAminButton() {
      /*
            Only and amdmin can make a user and admin
            this checks to see if the current user is and admin and if the member is not an admin
               - theres no need to show the 'make admin' button if the member is already an admin
        */
      if (props.club.admins.includes(props.currentUserID) && !props.club.admins.includes(props.memberID)){
         return (
            <button onClick={addAdmin} className="admin-btns btn-ease">Make Admin</button>
         );
      }
   }

   function showMakeOfficerButton() {
      /* 
            Only an admin can make a user an officer
            this checks to see if the current user is an admin and if the member is not an officer
        */
      if (props.club.admins.includes(props.currentUserID) && !props.club.officers.includes(props.memberID)){
         return (
            <button onClick={handleAddOfficer} className="admin-btns btn-ease">Make Officer</button>
         );
      }
   }

   function showRemoveOfficerButton() {
      /*
            Only an admin can remove an officer
            this checks to see if the current user is an admin and if the member is an officer
        */
      if (props.club.admins.includes(props.currentUserID)){
         if (props.club.officers.includes(props.memberID)){
            return (
               <button onClick={handleRemoveOfficer} className="admin-btns btn-ease">Remove Officer</button>
            );
         }
      }
   }

   function showRemoveMemberButton() {
      /*
            Only an admin or an officer can remove a member
            this checks to see if the current user is an admin or an officer and if the member is not an admin or officer
        */
      if (props.club.admins.includes(props.currentUserID) || props.club.officers.includes(props.currentUserID)){
         if (!props.club.admins.includes(props.memberID) && !props.club.officers.includes(props.memberID)){
            return (
               <button onClick={handleRemoveMember} className="admin-btns btn-ease">Remove Member</button>
            );
         }
      }
   }

   function showBanUserButton() {
      /*
            Only an admin can ban a user
            this checks to see if the current user is an admin and if the member is not an admin
            - theres no need to show the 'ban user' button if the member is already an admin
        */
      if (props.club.admins.includes(props.currentUserID) && !props.club.admins.includes(props.memberID)){
         return (
            <button onClick={handleBanUser} className="admin-btns btn-ease">Ban User</button>
         );
      }
   }

   return (
      <Fragment>
         {fetching ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "15px",}}>
               <PuffLoader color="#006994" />
            </div>
         ) : user ? (
            <div id="manage-members-listings-area" className="make-grid">
               <img src={user.profileImage} />
               <div id="manage-members-stats-title">
                  {props.club.admins.includes(props.memberID) ? (
                     <h3>
                        🛡️ {user.fName} {user.lName}
                     </h3>
                  ) : props.club.officers.includes(props.memberID) ? (
                     <h3>
                        👮 {user.fName} {user.lName}
                     </h3>
                  ) : (
                     <h3>
                        {user.fName} {user.lName}
                     </h3>
                  )}
                  <p>{user.username}</p>
               </div>
               <div id="manage-members-btns">
                  {showAddAminButton()}
                  {showMakeOfficerButton()}
                  {showRemoveOfficerButton()}
                  {showRemoveMemberButton()}
                  {showBanUserButton()}
               </div>
            </div>
         ) : null}
      </Fragment>
   );
};

export default ClubMemberListing;
