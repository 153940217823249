import getGlobalURL from "../../../assets/scripts/Global";
import {React, useState, useEffect, useRef, Fragment } from 'react'
import axios from "axios";
import { FoundBadToken } from "../../../scripts/badToken";
import ChatsUserList from "./ChatsUserList";
import ChatsChatField from "./ChatsChatField";

const PrivateChats = (props) => {
	const url = getGlobalURL();
	const [temp, setTemp] = useState(false);
	const [conversations, setConversations] = useState([]);
	const [chatSelected, setChatSelected] = useState(0);
	const convoRef = useRef(conversations);
	let conversationChannel = useRef(null);

	useEffect(() => {
		props.handleMessageChatOpen();
		axios.get(`${url}conversations/getUserConversations/${props.currentUser.userID}`, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			},
		}).then((response) => {
			setConversations(response.data.conversations);
			props.setFetching(false);
		}).catch((error) => {
			if (error.response.status === 401) {
				alert("Session Expired. Please login again.");
				FoundBadToken();
			} else {
				// alert(JSON.stringify(error))
			}
		});
		return () => {
			props.handleCloseMessage();
		};
	}, []);

	useEffect(() => {
		convoRef.current = conversations;
	}, [conversations]);

	// * ably useEffcet
	useEffect(() => {
		if (props.ably) {
			console.log('ably connection status: ', props.ably.connection.state)
			console.log('ably is defined in PrivateChats.jsx')
			conversationChannel.current = props.ably.channels.get(`conversation-${props.currentUser.userID}`);
			console.log('channel is ', conversationChannel.current)
			conversationChannel.current.subscribe("new-message", (message) => {
				console.log('new message!')
				let convoID = message.data.data.conversationID;
				let convo;
				let convoIndex;
				convoRef.current.forEach((conversation, index) => {
					if (conversation.conversationID === convoID) {
						convo = conversation;
						convoIndex = index;
					}
				});
				convo.messages.push(message.data.data.message);
				let user = convo.participants.find(
					(participant) => participant.userID === props.currentUser.userID
				);
				if (chatSelected !== convoIndex) {
					user.missedMessages += 1;
				}
				convoRef.current[convoIndex] = convo;
				if (chatSelected !== convoIndex) {
					convoRef.current[convoIndex].hasSeen = false;
				}
				setConversations([...convoRef.current]);
			});
		}

		return () => {
			if (props.ably) {
				conversationChannel.current.unsubscribe();
			}
		};
	}, [chatSelected]);

	// * useEffect to mark conversation as read
	useEffect(() => {
		if (conversations[chatSelected]) {
			props.markAsRead(props.currentUser.userID, conversations[chatSelected].conversationID, sessionStorage.getItem("token"), "conversations");
			// axios.post(`${url}conversations/markConvoAsRead`, {
			// 	conversationID: conversations[chatSelected].conversationID,
			// 	userID: props.currentUser.userID,
			// }, { 
			// 	headers: {
			// 		Authorization: sessionStorage.getItem("token"),
			// 	}
			// }).then((response) => {
			// 	// * nothing to do here since we're just marking the conversation as read in the db
			// }).catch((error) => {
			// 	if (error.response.status === 401) {
			// 		alert("Session Expired. Please login again.");
			// 		FoundBadToken();
			// 	} else {
			// 		alert(error);
			// 	}
			// });
		}
	}, [chatSelected]);

	const handleSelectedChat = (index) => {
		setChatSelected(index);
	};

	const addMessageToConvo = (message) => {
		let convo = conversations[chatSelected];
		convo.messages.push(message);
		setConversations([...conversations]);
	}

	return (
		<Fragment>
			<ChatsUserList temp={temp} currentUser={props.currentUser} conversations={conversations} chatSelected={chatSelected} handleSelectedChat={handleSelectedChat} />
			{(conversations.length > 0) && <ChatsChatField addMessageToConvo={addMessageToConvo} ably={props.ably} currentUser={props.currentUser} conversation={conversations[chatSelected]} />}
		</Fragment>
	)
}

export default PrivateChats