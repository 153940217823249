import React from 'react'

/**
 * @description - This component renders an inactive New Post component with the placeholder of "Click here to create post" 
 * 
 * @component
 * @param {string} userAvatar - A string with the user profile image url.
 * @param {string} hubSelected - A string with the currently selected hub.
 * @param {function} setCreatePost - A set function for the createPost state boolean variable .
 * @returns {JSX.Element} - A React element that renders an inactive New Post component with the placeholder of "Click here to create post"
 * 
 * @example
 * // Renders an inactive New Post component with the placeholder of "Click here to create post"
 * <CreatePostInactive userAvatar={profileImage} setCreatePost={setCreatePost} hubSelected={hubSelected} />
 */

const CreatePostInactive = (props) => {
    return (
        <div id="user-post-container" className="container-border">
            <div id="input-field-container" className="make-grid">
                <input onClick={() => props.setCreatePost(true)} type="text" id="post-text" autoComplete="off" placeholder="Click here to create a post" />
                <img className="user-post-image" src={props.userAvatar} />
            </div>
            <div className="post-btn-container">
                <button className="user-post-btn">{props.hubSelected}</button>
            </div>
    </div>
    )
}

export default CreatePostInactive