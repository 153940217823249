import React, { useState, useContext } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import { FoundBadToken } from "../../../scripts/badToken";
import { AblyContext } from "../../../assets/custom-hooks/AblyContext";

// ! PROPS FROM ClassRoom.jsx: course, currentUser
const AnnouncementModal = (props) => {
   const url = getGlobalURL();

   const { ably } = useContext(AblyContext);

   const [title, setTitle] = useState("");
   const [body, setBody] = useState("");

   const handlePostAnnouncement = () => {
      axios.post(`${url}courses/postAnnouncement`, {
         courseCode: props.course.courseCode,
         courseSubject: props.course.courseSubject,
         announcement: {
            message: body,
            title,
            senderName: props.currentUser.fName + " " + props.currentUser.lName,
            senderPicture: props.currentUser.profileImage,
            timestamp: Date.now()
         }
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         }
      }).then((response) => {
         if(response.status === 200){
            props.course.members.forEach((member) => {
               if(member !== props.course.courseInstructor){
                  userUpdate(`user-updates-${member}`, 'new-notification', '');
               }
            })
            props.closeModal();
         }
      }).catch((error) => {
         if(error.code === "ERR_BAD_REQUEST"){
            FoundBadToken();
         }
         else{
            alert("An error occurred while trying to post the announcement.")
         }
      })
   };

   function userUpdate(channel, publish, data){
      let ablyChannel = ably.channels.get(channel);
      ablyChannel.publish(publish, { data: data }, () => {
         console.log('user update sent')
      })
   }

   return (
      <div className='modal-container' >
         <div className='modal-backdrop'>
            <div className='create-event-modal-content' >
               <div className='form-container' >
                  <div className='form-header' >Create Announcement </div>
                  <div className='form-body' >
                     <input type="text" placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                     <textarea placeholder="Body" onChange={(e) => setBody(e.target.value)} />
                     <button className='btn-ease' onClick={handlePostAnnouncement}>Post Announcement</button>
                     <button className='btn-ease' onClick={props.closeModal}>Cancel</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
};

export default AnnouncementModal;
