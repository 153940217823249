import { React, useEffect, useState } from 'react'
import PostsField from '../PostsField';

const SubjectUpdatesSlide = (props) => {
    return (
        <div id="subject-updates-info-slide">
            <div id='tutoring-info-container'>
                <div id='business-info-area'>
                <div id='club-org-listing-plus-info-container'>
                        <div style={{overflowY: 'scroll', height: '350px', width: '100%'}}>
                            <PostsField subjectSelected={props.hubName} currentUser={props.currentUser} userID={props.currentUser.userID} source="TutoringField"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubjectUpdatesSlide;