import axios from "axios";
import { React, useState } from "react";
import getGlobalURL from "../../../assets/scripts/Global";
import { FoundBadToken } from "../../../scripts/badToken";

const ClubEventModal = (props) => {
   const url = getGlobalURL();

   const handleDeleteEvent = () => {
      axios
         .post(
            `${url}clubs/deleteCalendarEvent`,
            {
               clubName: props.clubName,
               eventID: props.event.eventID,
               userID: props.currentUser.userID,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         )
         .then((response) => {
            if (response.status === 200) {
               props.handleEventMade();
               props.handleCloseEventModal();
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
   };

   const handleAddToCalendar = () => {
      axios
         .post(
            `${url}users/appendToCalendar`,
            {
               userID: props.currentUser.userID,
               eventID: props.event.eventID,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         )
         .then((response) => {
            if (response.status === 200) {
               props.currentUser.calendar_events.push(props.event.eventID);
               props.handleCloseEventModal();
               alert("Event added to calendar");
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
   };

   return (
      <div className="modal-container">
         <div className='sg-backdrop' onClick={props.handleCloseEventModal}  />
         <div className="new-form-container">
            <div className="new-form-header">{props.event.title}</div>
            <div className="new-form-body">
               <p>{props.event.description}</p>
               {props.clubAdmins.includes(props.currentUser.userID) && (
                  <button className="general-btn" onClick={handleDeleteEvent}>Delete Event</button>
               )}
               {!props.currentUser.calendar_events.includes(props.event.eventID) ? (
                  <button className="general-btn" onClick={handleAddToCalendar}>Add to Calendar</button>
               ) : <p>This event exists in your calendar!</p>}
            </div>
         </div>
      </div>
   )
};

export default ClubEventModal;
