import { React, Fragment } from 'react'
import "../../assets/stylesheets/oceansStyles.css"

const Button = (props) => {
    function handleClick() {
        props.handleButtonClick(props.title)
    }

    return (
        <Fragment>
        <div id='oceans-list-container-button-area'>
            <button onClick={handleClick} className={`oceans-btn btn-ease ${props.activeButton === props.title && "active"}`}>{props.title}</button>
        </div>
        </Fragment>
    )
}

export default Button
