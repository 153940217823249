import { React, Fragment, useState, useEffect, useRef } from "react";
import ClassListing from "./classroomComponents/ClassListing";
import ClassRoom from "./classroomComponents/ClassRoom";
import getGlobalURL from "../../assets/scripts/Global";
import JoinClassModal from "./classroomComponents/JoinClassModal";
import axios from "axios";
import { Realtime } from "ably";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CreateEventModal from "./modals/CreateEventModal";
import EventModal from "./modals/EventModal";
import { FoundBadToken } from "../../scripts/badToken";

// ! PROPS FROM AcademicsTab.jsx: userID, course, currentUser
const ClassroomField = (props) => {
   const url = getGlobalURL();

   const localizer = momentLocalizer(moment);

   const [courseSelected, setCourseSelected] = useState();
   const [channelSelected, setChannelSelected] = useState();
   const [courseList, setCourseList] = useState([]);
   const [fetching, setFetching] = useState(true);

   const [activeCourse, setActiveCourse] = useState();

   const [showCodeModal, setShowCodeModal] = useState(false);

   const [actionTaken, setActionTaken] = useState(false);

   const channel = useRef(null);
   const classRoomFieldChannel = useRef(null);
   const ably = useRef(null);

   const [slotInfo, setSlotInfo] = useState(null);
   const [eventMade, setEventMade] = useState(false);
   const [events, setEvents] = useState([]);
   const [event, setEvent] = useState();
   const [showEventModal, setShowEventModal] = useState(false);
   const [showCreateEventModal, setCreateEventModal] = useState(false);

   // useEffect 1
   useEffect(() => {
      axios
         .get(`${url}courses/getCourses/${props.currentUser.userID}`, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         })
         .then((response) => {
            if (response.status === 200) {
               console.log("student courses: " + response.data);
               setCourseList(response.data);
               setFetching(false);
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error.response.data.message);
            }
         });
   }, [showCodeModal, actionTaken]);

   // useEffect 2
   useEffect(() => {
      if (ably.current === null) {
         ably.current = new Realtime(
            "_qz3Mg.yTUoGQ:GCDz6WRRVIXpbX5O0GiQ6PQOND6Sq9xdV-JInkuRNzM"
         );
         console.log("ably connection made");
      }

      return () => {
         if (
            ably.current.connection.state !== "connecting" &&
            ably.current.connection.state !== "initialized"
         ) {
            ably.current.close();
            console.log("ably closed");
         }
      };
   }, []);

   // useEffect 3
   useEffect(() => {
      if (ably.current) {
         classRoomFieldChannel.current =
            ably.current.channels.get("update-channels");

         classRoomFieldChannel.current.subscribe("new-channel", (channel) => {
            console.log("new channel", channel.data);
            let tempCourseList = [...courseList];
            tempCourseList.forEach((course) => {
               if (course.courseCode === channel.data.courseCode) {
                  course.channels.push(channel.data.channel);
               }
            });
            setCourseList(tempCourseList);
         });

         classRoomFieldChannel.current.subscribe(
            "delete-channel",
            (channel) => {
               let tempCourseList = [...courseList];
               console.log("delete channel", channel.data);
               let tempCourse = tempCourseList.find((course) => {
                  return course.courseCode === channel.data.courseCode;
               });
               tempCourse.channels = tempCourse.channels.filter((c) => {
                  return c.name !== channel.data.channelName;
               });
               console.log(tempCourse.channels);
               setCourseList(tempCourseList);
               setChannelSelected(tempCourse.channels[0].name);
            }
         );

         classRoomFieldChannel.current.subscribe(
            `add-channel-${props.currentUser.userID}`,
            (channel) => {
               let tempCourseList = [...courseList];
               tempCourseList.forEach((course) => {
                  if (course.courseCode === channel.data.courseCode) {
                     course.channels.push(channel.data.channel);
                  }
               });
               setCourseList(tempCourseList);
            }
         );

         classRoomFieldChannel.current.subscribe(
            `remove-channel-${props.currentUser.userID}`,
            (channel) => {
               let tempCourseList = [...courseList];
               let tempCourse = tempCourseList.find((course) => {
                  return course.courseCode === channel.data.courseCode;
               });
               tempCourse.channels = tempCourse.channels.filter((c) => {
                  return c.name !== channel.data.channel.name;
               });
               setCourseList(tempCourseList);
               if (channelSelected === channel.data.channel.name) {
                  setChannelSelected(tempCourse.channels[0].name);
               }
            }
         );
      }

      return () => {
         if (classRoomFieldChannel.current != null) {
            classRoomFieldChannel.current.unsubscribe();
         }
      };
   }, [classRoomFieldChannel.current, ably.current]);

   // useEffect 4
   useEffect(() => {
      if (activeCourse) {
         axios
            .get(`${url}courses/getCalendarEvents/${activeCourse.courseCode}`, {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            })
            .then((response) => {
               if (response.status === 200) {
                  let events = response.data.map((event) => {
                     return {
                        ...event,
                        start: new Date(event.start),
                        end: new Date(event.end),
                     };
                  });
                  setEvents(events);
               }
            })
            .catch((error) => {
               if (error.code === "ERR_BAD_REQUEST") {
                  FoundBadToken();
               } else {
                  alert(error);
               }
            });

         let userCourse = props.currentUser.enrolled_courses.find((course) => {
            return course.courseID === activeCourse.courseCode;
         });
         let userChannel = userCourse.channel.find((channel) => {
            return channel.channelName === channelSelected;
         });
         let courseChannel = activeCourse.channels.find((channel) => {
            return channel.name === channelSelected;
         });
         console.log("userChannel: ", userChannel);
         console.log("courseChannel: ", courseChannel);
         if (!userChannel || !courseChannel) {
            return;
         }
         if (userChannel.messageCount != courseChannel.messageCount) {
            axios
               .patch(
                  `${url}users/updateChannelMessages`,
                  {
                     courseCode: activeCourse.courseCode,
                     channelName: channelSelected,
                     userID: props.currentUser.userID,
                     messageCount: courseChannel.messageCount,
                  },
                  {
                     headers: {
                        Authorization: sessionStorage.getItem("token"),
                     },
                  }
               )
               .then((response) => {
                  if (response.status === 200) {
                     console.log("message count updated");
                     userChannel.messageCount = courseChannel.messageCount;
                  }
               })
               .catch((error) => {
                  if (error.code === "ERR_BAD_REQUEST") {
                     FoundBadToken();
                  } else {
                     alert(error.response.data.message);
                  }
               });
         }
      }
   }, [activeCourse, channelSelected, eventMade]);

   //params: course.courseCode, channel.name
   const handleCourseChannelClicked = (courseClicked, channelClicked) => {
      console.log(courseClicked, channelClicked);
      setCourseSelected(courseClicked);
      setChannelSelected(channelClicked);
      courseList.forEach((course) => {
         if (course.courseCode === courseClicked) {
            props.currentUser.enrolled_courses.forEach((c) => {
               if (c.name == channelClicked) {
               }
            });
            setActiveCourse(course);
         }
      });
   };

   const handleJoinClassClick = () => {
      setShowCodeModal(true);
   };

   const handleCreateClassClick = () => {
      //TODO show the modal for the professor to create a class
   };

   const handleSetActionTaken = () => {
      console.log("action taken from ClassroomField.jsx");
      setActionTaken(!actionTaken);
   };

   const eventStyleGetter = (event) => {
      const now = new Date();
      return event.end < now
         ? { style: { backgroundColor: "grey", color: "white" } }
         : {};
   };

   const handleSelectEvent = (event) => {
      setShowEventModal(true);
      setEvent(event);
   };

   const handleSelectSlot = (slotInfo) => {
      if (props.currentUser.userID === activeCourse.courseInstructor) {
         setSlotInfo(slotInfo);
         setCreateEventModal(true);
      }
   };

   const handleEventMade = () => {
      setEventMade(!eventMade);
   };

   return (
      <div
         id="classroom-container"
         className="make-grid container-border border-radius-25"
      >
         <div id="classroom-left-panel">
            <h1>My Hubs</h1>
            {showCodeModal && (<JoinClassModal userID={props.currentUser.userID} closeModal={() => setShowCodeModal(false)}/> )}
            {showCreateEventModal && (<CreateEventModal
                  handleEventMade={handleEventMade}
                  course={activeCourse}
                  currentUser={props.currentUser}
                  slotInfo={slotInfo}
                  eventMade={setEventMade}
                  closeModal={() => {
                     setCreateEventModal(false);
                  }}
               />
            )}
            {showEventModal && (<EventModal handleEventMade={handleEventMade} event={event} handleCloseEventModal={() => setShowEventModal(false)} course={activeCourse} currentUser={props.currentUser}/>)}
            {fetching ? (
               <p>Fetching...</p>
            ) : (
               <Fragment>
                  {courseList.map((course, index) => {
                     return (
                        <Fragment key={index}>
                           <ClassListing 
                              actionTaken={actionTaken} 
                              course={course}
                              handleCourseChannelClicked={handleCourseChannelClicked}
                              courseSelected={courseSelected}
                              channelSelected={channelSelected}
                              currentUser={props.currentUser}
                           />
                        </Fragment>
                     );
                  })}
                  <Fragment>
                     <button className="ocean-header-btns btn-ease" onClick={handleJoinClassClick}>Join Class</button>
                     {props.currentUser.role === "Professor" ? (
                        <button
                           className="ocean-header-btns btn-ease"
                           onClick={handleCreateClassClick}
                        >
                           Create Class
                        </button>
                     ) : null}
                  </Fragment>
               </Fragment>
            )}
         </div>
         {activeCourse ? (
            channelSelected !== "calendar" ? (
               <div id="classroom-main-panel" className="make-grid">
                  <ClassRoom
                     actionTaken={actionTaken}
                     handleSetActionTaken={handleSetActionTaken}
                     channel={channel}
                     ably={ably}
                     currentUser={props.currentUser}
                     course={activeCourse}
                     channelSelected={channelSelected}
                     courseSelected={courseSelected}
                  />
               </div>
            ) : (
               <div id="classroom-main-panel" className="make-grid">
                  <Calendar
                     localizer={localizer}
                     events={events}
                     startAccessor={"start"}
                     endAccessor={"end"}
                     style={{ height: 500 }}
                     onSelectEvent={handleSelectEvent}
                     onSelectSlot={handleSelectSlot}
                     selectable={true}
                     eventPropGetter={eventStyleGetter}
                  />
               </div>
            )
         ) : null}
      </div>
   );
};

export default ClassroomField;
