import getGlobalURL from "../../../assets/scripts/Global";
import { React, useEffect, useState } from "react";
import axios from "axios";
import BusinessCalendarModal from "../../../Modals/businessModals/BusinessCalendarModal";
import { FoundBadToken } from "../../../scripts/badToken";

const BusinessSlide = (props) => {
   const url = getGlobalURL();
   const [showCalendarModal, setShowCalendarModal] = useState(false);
   const [events, setEvents] = useState();

   useEffect(() => {
      axios.get(`${url}businesses/getBusinessEvents${props.source}/${props.business.businessName}`, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if (response.status === 200) {
            setEvents(response.data);
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   }, [props.eventUploadedFlag]);

   const handleCloseCalendarModal = () => {
      setShowCalendarModal(false);
   };

   const handleShowCalendarModal = () => {
      setShowCalendarModal(true);
   };

   return (
      <div id="business-info-slide">
         {showCalendarModal && (
            <BusinessCalendarModal
               events={events}
               handleCloseCalendarModal={handleCloseCalendarModal}
            />
         )}

         <div id="business-info-container" className="make-grid">
            <div id="business-info-buttons-area">
               <button className="border-radius-20" onClick={handleShowCalendarModal}>Calendar</button>
               <button className="border-radius-20" onClick={() => {window.open(props.business.businessUrl, "_blank")}}>Visit</button>
            </div>
            <div id="business-info-area">
               <p>{props.business.businessDescription}</p>
            </div>
         </div>
      </div>
   );
};

export default BusinessSlide;
