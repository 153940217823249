import getGlobalURL from "../../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import TestImage from '../../assets/images/alan_profile.jpg'
import { getElapsedTime } from '../../assets/scripts/mchScripts'
import { FoundBadToken } from "../../scripts/badToken";
import { useNavigate } from 'react-router-dom'

const NotificationCard = (props) => {
    const url = getGlobalURL();
    let navigate = useNavigate();

    const showText = () => {
        if(props.notification.notification.type === 'courseAnnouncement'){
            return props.notification.notification.notificationMessage.split(' ').map((word, index) => {
                return word === 'announcement' ? <h6 onClick={() => {props.handleShowModal(props.notification.notification)}} style={{cursor: 'pointer'}} ><u>{word}</u> </h6> : word + ' '
            })
        }
        else if(props.notification.notification.type === 'postNotification'){
            return props.notification.notification.notificationMessage.split(' ').map((word, index) => {
                return word === 'post' ? <h6 onClick={() => {props.handleShowModal(props.notification.notification)}} style={{cursor: 'pointer'}} ><u>{word}</u> </h6> : word + ' '
            })
        }
        else{
            return props.notification.notification.notificationMessage
        }
    }
    
    const handleAvatarClick = () => {
        axios.get(`${url}users/getUserByUsername/${props.notification.username}/${props.currentUser.userID}`, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            },
        }).then((response) => {
            let foreignUser = response.data;
            navigate("/DisplayUser", { state: { foreignUser: foreignUser, currentUser: props.currentUser } })
        }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
                FoundBadToken();
            } else {
                alert(error.response.data.err);
            }
        })
    }

    return (
        <Fragment>
            <div id='notification-area' className='make-grid'>
                <img onClick={() => { handleAvatarClick() }} src={props.notification.profileImage} style={{border: props.notification.notification.hasSeen ? '' : '2px solid gold', cursor: 'pointer'}} alt='user avatar' />
                <div id='notification-text-area' className="make-grid">
                    <h6><strong className='hover-underline' onClick={() => { handleAvatarClick() }} >{props.notification.name}</strong> {showText()}</h6>
                    <div id="notification-details">
                        <p>{getElapsedTime(props.notification.notification.timestamp)}</p>
                        {props.notification.notification.hasSeen ? null : <div id="notification-new-alert-marker"/>}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NotificationCard