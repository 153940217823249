import { React } from 'react'

/**
 * @description - This component renders a label and an input field. The label includes optional helper text that appears
 *  right-justified. The component receives a state data object with its setData function and loads the input data
 *  into the object. 
 * 
 * @component
 * @param {string} type - A string used to set the input type attribute.
 * @param {string} forText - A string used to set the htmlFor attribute.
 * @param {string} labelText - A string for the label text displayed to the user.
 * @param {string} helperText - A string for the helper area to give the user a hint about what to enter.
 * @param {string} value - An optional string to set the value attribute on the input field.
 * @param {boolean} required - A boolean to set the required attribute on the input field.
 * @param {object} data - The data object to populate.
 * @param {function} handleFocus - The function to handle the focus event.
 * @param {function} setData - The set function to populate the data object.
 * @returns {JSX.Element} - A React element that renders a label with an input field. NOTE: setData will be returned as an object {forText: value}
 * 
 * @example
 * // Renders a label and an input field. The label includes optional helper text that appears right-justified
 * <InputWithObject
 *  handleFocus={handleFocus}
 *  type="password" 
 *  forText="password" 
 *  labelText="Password:" 
 *  helperText="At least 6 characters" 
 *  value={someValue}
 *  data={userData} 
 *  setData={setUserData}
 * />
 */

const InputWithObject = (props) => {
    return (
        <div className='form-label-input-container'>
            <div className="form-body-label-area">
                <label htmlFor={props.forText}>{props.required && `* `}{props.labelText}</label>
                {props.helperText && <label className="helper">{props.helperText}</label>}
            </div>
            <input value={(props.value) ? props.value : null} onFocus={() => props.handleFocus(props.forText)} type={props.type} onChange={(e) => props.setData({...props.data, [props.forText]: e.target.value})} id={props.forText} />
        </div>
    )
}

export default InputWithObject