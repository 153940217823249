import React, { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import LeftAlignedMessage from "../LeftAlignedMessage";
import RightAlignedMessage from "../RightAlignedMessage";
import ChatTextArea from "../../atoms/ChatTextArea";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import EditStudyGroupModal from "./modals/EditStudyGroupModal";
import MembersPanel from "./modals/MembersPanel";
import { FoundBadToken } from "../../../scripts/badToken";
import InfiniteScroll from "react-infinite-scroll-component";
import PuffLoader from "react-spinners/PuffLoader";

const StudyGroupChatField = (props) => {
    const url = getGlobalURL();

    const [firstLoad, setFirstLoad] = useState(true);

    const [messages, setMessages] = useState({
        messagesByDate: [{
            date: '',
            messages: []
        }]
    });
    const [message, setMessage] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);

    const [hasMore, setHasMore] = useState(true);
    const [index, setIndex] = useState(1);

    const lastMessageRef = useRef(null);

    let totalMessages = 0;

    const scrollToBottom = () => {
		/*
			Called within useEffect when a new message is sent. Automatically scrolls the message-application-chat-field
			own to the last message without scrolling the entire window down.
		*/
		let lastMessageDiv = lastMessageRef.current;
		if (lastMessageDiv) {
			let lastChild = lastMessageDiv.lastChild;
			if (lastChild) {
				lastMessageDiv.scrollTop = lastChild.offsetTop + lastChild.scrollHeight;
			}
		}
	};

    // * scroll to bottom useEffect
    useEffect(() => {
        scrollToBottom();
    }, [props.group.messages.length, lastMessageRef, messages])

    // * getMessages useEffect
    useEffect(() => {
        let sortedMessages = [...props.group.messages].sort((a, b) => new Date(a.date) - new Date(b.date));
    
        let tempObj = {
            messagesByDate: []
        }
    
        sortedMessages.forEach((message) => {
            let date = new Date(message.date).toDateString();
            let obj = tempObj.messagesByDate.find((m) => {
                return m.date === date;
            })
            if(obj){
                obj.messages.push(message);
            }
            else{
                tempObj.messagesByDate.push({
                    date: date,
                    messages: [message]
                })
            }
        })
    
        setMessages(tempObj);
        totalMessages = messages.messagesByDate.reduce((total, messageByDate) => total + messageByDate.messages.length, 0);
    }, [props.group.studyGroupID, props.group.messages.length, props.group.messages])

    // * firstLoad useEffect
    useEffect(() => {
        if(firstLoad){
            setFirstLoad(false);
        }
    }, [])

    const handleSendMessage = async () => {
        if(!message){
            alert('Message cannot be empty');
            return;
        }
        let time = Date.now();
        await sendMessage(time);
        await sendRealTimeMessage(time);
        await sendNewMessageNotification();
    }

    async function sendMessage(time){
        axios.post(`${url}studyGroups/sendStudyGroupMessage`, {
            studyGroupID: props.group.studyGroupID,
            message: {
                userID: props.currentUser.userID,
                date: time,
                messageContent: message,
                contentURL: '',
                messageType: 'user'
            }
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            setMessage('');
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    async function sendRealTimeMessage(time){
        let channel = props.ably.channels.get(`study-group-${props.group.studyGroupID}`);
        channel.publish('new-message', {
            data: {
                studyGroupID: props.group.studyGroupID,
                message: {
                    userID: props.currentUser.userID,
                    profileImage: props.currentUser.profileImage,
                    userName: `${props.currentUser.fName} ${props.currentUser.lName}`,
                    messageContent: message,
                    date: time,
                    messageType: 'user'
                }
            }
        }, () => {
            console.log(`sent message to study-group-${props.group.studyGroupID}`)
        })
    }

    async function sendNewMessageNotification(){
        props.group.members.forEach((member) => {
            if(member.userID !== props.currentUser.userID){
                let channel = props.ably.channels.get(`user-updates-${member.userID}`);
                channel.publish('new-message', {})
            }
        })
    }

    function setImageFlag(dateIndex, index){
        if(messages.messagesByDate[dateIndex].messages[index + 1] !== undefined){
            if(messages.messagesByDate[dateIndex].messages[index + 1].userID !== messages.messagesByDate[dateIndex].messages[index].userID){
                return true;
            }
            else{
                return false;
            }
        }
        return true;
    }

    const handleEditGroup = () => {
        setShowEditModal(true);
    }

    const fetchMoreMessages = () => {
        alert('fetching more messages')
        axios.get(`${url}studyGroups/getStudyGroupMessages/${props.group.studyGroupID}/${index}`, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.addFetchedMessages(response.data);
            setIndex(index + 1);
            setHasMore(false);
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    return (
        <Fragment>
            {showEditModal && <EditStudyGroupModal updateGroup={props.updateGroup} group={props.group} closeModal={() => setShowEditModal(false)} currentUser={props.currentUser} />}
            <div id="message-application-study-field-container" className="make-grid" >
                <div className="message-application-study-field border-radius-20 scrollbar-mch-thin" ref={lastMessageRef}>
                    {messages && messages.messagesByDate && messages.messagesByDate.map((date, dateIndex) => {
                        if(date.date != '' && date.messages.length){
                            return (
                                <Fragment>
                                    <div className="date-separator" >{date.date}</div>
                                    {date.messages && date.messages.map((message, index) => {
                                        return message.userID === props.currentUser.userID ? (
                                            <RightAlignedMessage userImage={props.currentUser.profileImage} key={index} message={message} imageFlag={setImageFlag(dateIndex, index)} />
                                        ) : (
                                            <LeftAlignedMessage messageType={message.messageType} foreignUserImage={message.profileImage} foreignUserName={message.userName} key={index} message={message} imageFlag={setImageFlag(dateIndex, index)} />
                                        )
                                    })}
                                </Fragment>
                            )
                        }
                    })}
                </div>
                <div className="message-app-input-field">
                    <ChatTextArea handleSendMessage={handleSendMessage} setMessage={setMessage} message={message} />
                </div>
            </div>
            <MembersPanel leaveGroup={props.leaveGroup} handleEditGroup={handleEditGroup} updateGroup={props.updateGroup} ably={props.ably} currentUser={props.currentUser} group={props.group} />
        </Fragment>
    )
}

export default StudyGroupChatField;

{/* <Fragment>
    {showEditModal && <EditStudyGroupModal updateGroup={props.updateGroup} group={props.group} closeModal={() => setShowEditModal(false)} currentUser={props.currentUser} />}
    <div id="message-application-study-field-container" className="make-grid" >
        <div className="message-application-study-field border-radius-20 scrollbar-mch-thin" ref={lastMessageRef}>
            <div id='this-is-my-target' style={{overflow: 'auto', border: '1px solid red', height: '99%'}} >
                <InfiniteScroll
                    dataLength={totalMessages}
                    next={firstLoad ? () => {} : fetchMoreMessages}
                    // inverse={true}
                    hasMore={hasMore}
                    loader={<PuffLoader color={"#FF4B2B"} loading={true} size={150} css={"margin: 0 auto"} />}
                    endMessage={<p>Youve Reached The End!</p>}
                    scrollThreshold={'80%'}
                    scrollableTarget="this-is-my-target"
                >
                    {messages && messages.messagesByDate && messages.messagesByDate.map((date, dateIndex) => {
                        if(date.date != '' && date.messages.length){
                            return (
                                <Fragment>
                                    <div className="date-separator" >{date.date}</div>
                                    {date.messages && date.messages.map((message, index) => {
                                        return message.userID === props.currentUser.userID ? (
                                            <RightAlignedMessage userImage={props.currentUser.profileImage} key={index} message={message} imageFlag={setImageFlag(dateIndex, index)} />
                                        ) : (
                                            <LeftAlignedMessage messageType={message.messageType} foreignUserImage={message.profileImage} foreignUserName={message.userName} key={index} message={message} imageFlag={setImageFlag(dateIndex, index)} />
                                        )
                                    })}
                                </Fragment>
                            )
                        }
                    })}
                </InfiniteScroll>
            </div> 
        </div>
        <div className="message-app-input-field">
            <ChatTextArea handleSendMessage={handleSendMessage} setMessage={setMessage} message={message} />
        </div>
    </div>
    <MembersPanel leaveGroup={props.leaveGroup} handleEditGroup={handleEditGroup} updateGroup={props.updateGroup} ably={props.ably} currentUser={props.currentUser} group={props.group} />
    </Fragment> */}