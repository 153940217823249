import React, { useState, Fragment } from "react";
import "../../../assets/stylesheets/oceansStyles.css";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import { FoundBadToken } from "../../../scripts/badToken";
import FormCancelSubmitBtns from "../../../Modals/forms/FormCancelSubmitBtns";
import InputWithObject from "../../atoms/InputWithObject";

// ! PROPS COMING FROM ClassroomField.jsx: userID
const JoinClassModal = (props) => {
   const url = getGlobalURL();

   const [code, setCode] = useState("");
   const [focussedField, setFocussedField] = useState(null);


   const handleBackdropClick = () => {
      props.closeModal();
   };

   const handleJoinClass = () => {
      axios
         .post(
            `${url}courses/joinCourse`,
            {
               joinCode: code,
               userID: props.userID,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         )
         .then((response) => {
            if (response.status === 200) {
               alert("Course joined");
               props.closeModal();
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error.response.data.message);
               console.log(error.response.data.message);
            }
         });
   };

   const handleFocus = (focussedOn) => {
      setFocussedField(focussedOn);
}

   return (
      <div className="modal-container">
         <div className="modal-backdrop">
            <div className="new-form-container">
               <div className="new-form-header">Enter Course Code</div>
               <div className="new-form-body">
                  <p className="form-instruction-text">Please enter the course code provided by your professor.</p>
                  <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="fName" labelText="Course Code:" data={code} setData={setCode}/>
                  <FormCancelSubmitBtns handleCloseForm={handleBackdropClick} closeText="Cancel" handleSubmit={handleJoinClass} submitText="Join" />
               </div>
         </div>
         </div>
      </div>
   );
};

export default JoinClassModal;
