import { React, useEffect, useState } from 'react'
import BioListing from './BioListing';

const StaffBioSlide = (props) => {

    return (
        <div id="info-slide">
            <div id='tutoring-info-container' className='make-grid'>
                <div id='business-info-area'>
                    {props.staffBios.map((tutor) => {
                        return <BioListing tutor={tutor}/>
                    })}
                </div>
            </div>
        </div>
    )
}

export default StaffBioSlide;