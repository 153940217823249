import React, { useState, useEffect, Fragment, useRef } from 'react';
import axios from "axios";
import { FoundBadToken } from "../../../scripts/badToken";
import getGlobalURL from '../../../assets/scripts/Global';
import FriendsGroupList from './FriendsGroupList';
import CreateFriendGroupModal from './modals/CreateFriendGroupModal';
import FG_ChatField from './FG_ChatField';
import FG_InvitesModal from './modals/FG_InvitesModal';

const FriendGroupChats = (props) => {
    const url = getGlobalURL();
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [groupSelected, setGroupSelected] = useState(0)
    const [groups, setGroups] = useState([]);
    const groupRef = useRef(groups);

    const [showInvitesModal, setShowInvitesModal] = useState(false);

    // * grab all friend group chats the user is in
    useEffect(() => {
        axios.get(`${url}friendGroups/getUserFriendGroupChats/${props.currentUser.userID}`, 
            {
                headers: {
                    Authorization: sessionStorage.getItem('token')
                }
            }).then((response) => {
                setGroups(response.data);
            }).catch((error) => {
                if(error.response.status === 401){
                    alert('Session expired. Please log in again.')
                    FoundBadToken();
                }
            })
    }, [])

    // * update the groupRef when groups change
    useEffect(() => {
        groupRef.current = groups;
    }, [groups])

    // * ably useEffect
    useEffect(() => {
        const channels = {};
        if(groups.length >= 1 && props.ably){
            groups.forEach((group) => {
                const channel = props.ably.channels.get(`friend-group-${group.groupID}`);
                channels[group] = channel;
    
                channel.subscribe('new-message', (message) => {
                    updateMessages(message);
                })
    
                channel.subscribe('add-member', (message) => {
                    let groupID = message.data.groupID;
                    let group;
                    let groupIndex;
                    groupRef.current.forEach((g, index) => {
                        if(g.groupID === groupID){
                            group = g;
                            groupIndex = index;
                        }
                    })
                    group.members.push(message.data.member);
                    group.pendingMembers = group.pendingMembers.filter((m) => m !== message.data.member.userID);
                    groupRef.current[groupIndex] = group;
                    setGroups([...groupRef.current]);
                })
    
                channel.subscribe('delete-group', (message) => {
                    removeGroup(message.data.groupID);
                })
    
                channel.subscribe('remove-member', (message) => {
                    let groupID = message.data.groupID;
                    let userID = message.data.userID;
                    if(userID === props.currentUser.userID){
                        props.currentUser.friendGroupChats = props.currentUser.friendGroupChats.filter((group) => group !== groupID);
                        removeGroup(groupID);
                        return;
                    }
                    let group;
                    let groupIndex;
                    groupRef.current.forEach((g, index) => {
                        if(g.groupID === groupID){
                            group = g;
                            groupIndex = index;
                        }
                    })
                    group.messages.push(message.data.message);
                    group.members = group.members.filter((m) => m.userID !== message.data.userID);
                    groupRef.current[groupIndex] = group;
                    setGroups([...groupRef.current]);
                })
            })
        }
        else{
            if(!props.ably){
                console.log('ably not initialized')
            }
        }

        return () => {
            Object.values(channels).forEach((channel) => {
                channel.unsubscribe();
            })
        }
    }, [groups, groups.length])

    const updateMessages = (message) => {
        let groupID = message.data.groupID;
        let group;
        let groupIndex;
        groupRef.current.forEach((g, index) => {
            if(g.groupID === groupID){
                group = g;
                groupIndex = index;
            }
        })
        group.messages.push(message.data.message);
        groupRef.current[groupIndex] = group;
        setGroups([...groupRef.current])
    }

    const updateGroup = (group) => {
        let index = groups.findIndex((g) => {
            return g.groupID === group.groupID;
        })
        groups[index] = group;
        setGroups([...groups]);
    }

    const handleSelectedGroupChat = (index) => {
        setGroupSelected(index);
    }

    const handleShowInvitesModal = () => {
        setShowInvitesModal(true);
    }

    const addGroup = (group) => {
        let newGroups = groups;
        newGroups.push(group);
        setGroups(newGroups);
        setGroupSelected(newGroups.length - 1);
    }

    const removeGroup = (groupID) => {
        groupRef.current = groupRef.current.filter((g) => g.groupID !== groupID);
        props.currentUser.friendGroupChats = props.currentUser.friendGroupChats.filter((group) => group !== groupID);
        setGroupSelected(0);
        setGroups([...groupRef.current])
    }

    return (
        <Fragment>
            {showInvitesModal && <FG_InvitesModal ably={props.ably} addGroup={addGroup} currentUser={props.currentUser} closeModal={() => { setShowInvitesModal(false) }} />}
            {showCreateGroup && <CreateFriendGroupModal ably={props.ably} addGroup={addGroup} currentUser={props.currentUser} closeModal={() => { setShowCreateGroup(false) }} />}
            <FriendsGroupList handleShowInvitesModal={handleShowInvitesModal} handleSelectedGroupChat={handleSelectedGroupChat} currentUser={props.currentUser} groupSelected={groupSelected} groups={groups} handleShowGroup={() => { setShowCreateGroup(true) }} />
            {groups.length > 0 && <FG_ChatField removeGroup={removeGroup} updateGroup={updateGroup} ably={props.ably} currentUser={props.currentUser} group={groups[groupSelected]} />}
        </Fragment>
    )
}

export default FriendGroupChats;