import getGlobalURL from "../../../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from "react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../../scripts/badToken";
import IconWithLabel from "../../atoms/buttons/IconWithLabel";
import RemoveIcon from "../../icons/RemoveIcon";
import BanUser from "../../icons/BanUser";

// ! PROPS COMING FROM ManageChannelMemberModal.jsx: memberID, course, channelSelected, actionTaken, channel
const ChanneMemberListing = (props) => {
   const url = getGlobalURL();

   const [user, setUser] = useState();
   const [fetching, setFetching] = useState(true);

   useEffect(() => {
      axios.post(`${url}users/getSingleUser`, {
         userID: props.memberID,
      },{
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }).then((response) => {
         setUser(response.data[0]);
         setFetching(false);
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   }, []);

   const handleAddMember = () => {
      let channel = props.course.channels.find(
         (channel) => channel.name === props.channelSelected
      );
      console.log(user);
      axios.post(`${url}channels/addChannelMember`, {
         channelName: channel.name,
         memberID: props.memberID,
         courseCode: props.course.courseCode,
         message: {
            message: `${user.fName} ${user.lName} has been added to the channel`,
            senderName: "",
            senderPicture: "",
            timestamp: Date.now(),
            senderID: "",
            isNotification: true,
         },
         currentChannel: `${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if (response.status === 200) {
            alert("Member added to channel");
            channel.members.push(props.memberID);
            props.handleSetActionTaken();
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.message);
         }
      });
   };

   const handleRemoveMember = () => {
      let channel = props.course.channels.find(
         (channel) => channel.name === props.channelSelected
      );
      axios.post(`${url}channels/removeChannelMember`, {
         channelName: channel.name,
         memberID: props.memberID,
         courseCode: props.course.courseCode,
         message: {
            message: `${user.fName} ${user.lName} has been removed from the channel`,
            senderName: "",
            senderPicture: "",
            timestamp: Date.now(),
            senderID: "",
            isNotification: true,
         },
         currentChannel: `${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if (response.status === 200) {
            alert("Member removed from channel");
            channel.members = channel.members.filter((m) => {
               return m !== props.memberID;
            });
            props.handleSetActionTaken();
         }
      }).catch((err) => {
         if (err.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(err);
         }
      });
   };

   return (
      <Fragment>
         {fetching ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", "margin-top": "15px",}} >
               <PuffLoader color="#006994" />
            </div>
         ) : user != null ? (
            <div className="member-card-container">
               <div className="member-info">
               <img className="member-card-avatar" src={user.profileImage} />
                  <h3>{`${user.fName} ${user.lName}`}</h3>
                  <p>@{user.username}</p>
               </div>
               <div className="member-card-btn-area">
               {props.action === "add" ? (
                  <button onClick={handleAddMember} className="admin-btns btn-ease">{props.action.toUpperCase()}</button>
               ) : (
                  <button onClick={handleRemoveMember} className="admin-btns btn-ease">{props.action.toUpperCase()}</button>
               )}
                  {/* <IconWithLabel handleClick={handleRemoveMember} icon={<RemoveIcon width="25" height="25" color="var(--primary-color)" />} label="Remove" /> */}
                  {/* {displayModButton()} */}
               </div>
            </div>
            // <div id="manage-members-listings-area" className="make-grid">
            //    <img src={user.profileImage} />
            //    <div id="manage-members-stats-title">
            //       <h3>
            //          {user.fName} {user.lName}
            //       </h3>
            //       <p>{user.username}</p>
            //    </div>
            //    <div id="manage-members-btns">
            //       {props.action === "add" ? (
            //          <button
            //             onClick={handleAddMember}
            //             className="admin-btns btn-ease"
            //          >
            //             Add
            //          </button>
            //       ) : (
            //          <button
            //             onClick={handleRemoveMember}
            //             className="admin-btns btn-ease"
            //          >
            //             Remove
            //          </button>
            //       )}
            //    </div>
            // </div>
         ) : null}
      </Fragment>
   );
};

export default ChanneMemberListing;
