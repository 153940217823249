import React from 'react'
import "../../assets/stylesheets/developerWelcome.css"
import MyCampusHubsLogo from '../logo/MyCampusHubsLogo'
import ConstructionIcon from '../icons/ConstructionIcon'

const DeveloperMobileWelcome = () => {
    return (
        <div className='mobile-main-container'>
            <div className='welcome-mobile-container container-border'>
                <MyCampusHubsLogo />
                <br />
                <hr className="hr-custom"/>
                <br />
                <ConstructionIcon width="75px" height="75px" />
                <h2>Mobile Access Coming Soon!</h2>
                <hr className="hr-custom"/>
                <br />
                <div className='welcome-mobile-container-text-area'>
                    <p>Welcome! You’ll soon be able to access MyCampusHubs easily on your phone. We're also developing a dedicated mobile app for a better experience!</p>
                    <p>For now, please use a desktop browser to explore all features.</p>
                    <p>Thank you for your patience!</p>
                </div>
                <p>Best regards,</p>
                <p>The <span className='highlight'><strong>MyCampusHubs</strong></span> Team</p>
            </div>
        </div>
    )
}

export default DeveloperMobileWelcome