import { React, useState } from 'react'
import { useCollapse } from 'react-collapsed'
import UpArrow from '../icons/UpArrow';
import DownArrow from '../icons/DownArrow';
import AdminIcon from '../icons/AdminIcon';
import HubsAdminIcon from '../icons/HubsAdminIcon';

/**
 * @description - A collapsible section for the navigation drawer that displays a list of buttons
 * 
 * @component
 * @param {boolean} active - A boolean that determines if the section is active
 * @param {string} activeButton - The currently active button
 * @param {string} heading - The heading for the section
 * @param {array} list - A list of buttons to display
 * @param {array} adminList - A list hubs that the user is an admin of
 * @param {function} handleButtonSelection - A function that handles button selection
 * @param {JSX.Element} icon - The icon to display next to the heading 
 * @returns {JSX.Element} - A collapsible section for the navigation drawer
 * 
 * @example
 * // Renders a collapsible section for the navigation drawer
 * <NavDrawerSection 
 *  active={(activeTab === "My Hubs") ? true : false}
 *  activeButton={props.activeButton}
 *  adminList={userOceansAdminList}
 *  handleButtonSelection={handleButtonSelection}
 *  heading="My Hubs" 
 *  icon={<HubsIcon width="25px" height="20px" color={(activeTab === "My Hubs") ? "var(--towson-gold)" : "var(--nav-drawer-text)"}/>}
 *  list={props.currentUser.oceans}
 * />
 */

const NavDrawerSection = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse(props.active);

    return (
        <div className='nav-drawer-collapsible-container'>
            <button className={(props.active) ? 'active nav-drawer-btn make-grid' : 'nav-drawer-btn make-grid'} {...getToggleProps({onClick: () => {setIsExpanded((prevExpanded) => !prevExpanded)}})}>
                <div className='nav-drawer-btn-icon'>{props.icon}</div>
                <div className='nav-drawer-btn-title'>{props.heading}</div> 
                <div className='nav-drawer-btn-arrow'>{isExpanded ? <UpArrow width="13px" color="var(--nav-drawer-text)"/> : <DownArrow width="13px" color="var(--nav-drawer-text)"/>}</div>
            </button>
            <div className='collapsible-list' {...getCollapseProps()}>
                {props.list.sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                }).map((item, index) => {
                    return (
                        <div key={item} className='collapsible-list-btn'>
                            <button className={(item === props.activeButton) ? 'active channel-btn' : 'channel-btn'} onClick={() => props.handleButtonSelection(item, props.heading)}>{item}</button>
                            {(props.adminList !== null && props.adminList.includes(item)) ? <AdminIcon width="15px" height="15px" color="var(--nav-drawer-active)"/> : null}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default NavDrawerSection