import React from 'react'

const OffCampusIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        viewBox="0 0 32 32"
        {...props}
    >
        <title />
        <g data-name="Shop">
            <path
            d="M8.265 4.027h15.47v1.404H8.265zM12.893 10.371h.143l.013-.026.949-3.588h-2.21l-2.041 3.614h3.146zM10.293 6.757H8.096l-3.003 3.614h3.146l2.054-3.614zM11.801 6.731h-1.495l-.013.026h1.495l.013-.026zM23.761 10.345h3.146l-3.003-3.614h-2.197l2.054 3.614zM20.199 6.731h-2.197l.962 3.614h3.289l-2.054-3.614zM7.979 12.165v-.494H4.352v.494a1.813 1.813 0 0 0 3.627 0ZM9.279 12.165a1.807 1.807 0 1 0 3.614 0v-.494H9.279ZM20.914 13.959a1.812 1.812 0 0 0 1.807-1.807v-.507h-3.614v.507a1.82 1.82 0 0 0 1.807 1.807ZM15.35 6.731l-.962 3.614h3.224l-.949-3.614H15.35zM14.193 12.165a1.807 1.807 0 0 0 3.614-.013v-.507h-3.614ZM24.021 11.645v.507a1.813 1.813 0 0 0 3.627 0v-.507ZM18.678 27.973h4.277v-7.917h-4.277Zm.936-4.628a.671.671 0 0 1 .676.676.67.67 0 1 1-1.339 0 .669.669 0 0 1 .663-.676Z"
            style={{
                fill: props.color,
            }}
            />
            <path
            d="M23.371 14.05a3.101 3.101 0 0 1-4.914 0 3.101 3.101 0 0 1-4.914 0 3.091 3.091 0 0 1-4.914.013 3.138 3.138 0 0 1-2.106 1.196v12.714h10.855v-9.217h6.877v9.217h1.222V15.246a3.138 3.138 0 0 1-2.106-1.196Zm-7.839 12.402H7.849v-7.696h7.683Z"
            style={{
                fill: props.color,
            }}
            />
            <path
            d="m10.306 24.918-.234.234h4.16v-4.173l-2.08 2.08-.923-.91 2.093-2.093h-1.183l-1.417 1.417-.923-.923.494-.494H9.149v4.173l.234-.234.923.923z"
            style={{
                fill: props.color,
            }}
            />
        </g>
    </svg>
)

export default OffCampusIcon