import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import getGlobalURL from "../../assets/scripts/Global";
import { FoundBadToken } from "../../scripts/badToken";

const BioListing = (props) => {
   const [tutorData, setTutorData] = useState();
   const url = getGlobalURL();

   useEffect(() => {
      axios.get(`${url}users/getUserByUsername/${props.tutor.userName}/${props.currentUser.userID}`, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((res) => {
         if (res.status === 200) {
            setTutorData(res.data);
         }
      }).catch((error) => {
         if (error.response.status === 401) {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   }, []);

   return (
      <div>
         {tutorData ? (
            // go to post-title-container to adjust the hight of the dropdown... probably
            <div id="tutor-bio-container">
               <div>
                  <img id="tutor-profile-img" src={tutorData.profileImage} />
                  <h3 id="tutor-name">{`${tutorData.fName} ${tutorData.lName}`}</h3>
               </div>
               <div style={{ flexDirection: "column" }}>
                  <h4>Major</h4>
                  <p>{props.tutor.major}</p>
                  <h4>Acidemic intersets</h4>
                  <p>{props.tutor.acidemicInterests}</p>
                  <h4>Leadership and Involvment</h4>
                  <p>{props.tutor.leadershipAndInvolvment}</p>
                  <h4>Philosophy</h4>
                  <p>{props.tutor.philosophy}</p>
                  <h4>Fun Fact</h4>
                  <p>{props.tutor.funFact}</p>
                  <h4>Courses</h4>
                  {props.tutor.courses.map((course) => {
                     return <p>{course}</p>;
                  })}
                  <h4>Schedule</h4>
                  <p>{props.tutor.schedule}</p>
               </div>
            </div>
         ) : (
            <p>fetching data</p>
         )}
      </div>
   );
};

export default BioListing;
