import {React, useState, Fragment} from 'react'
import PersonalInfo from '../components/PersonalInfo'
import AccountSettings from '../components/AccountSettings'

const NewMyAccount = (props) => {
    const [settingSelected, setSettingSelected] = useState("PersonalInfo");
    
    return (
        <Fragment>
\            <div id='my-account-container' className='padding-top-80'>
                <div id='my-account-lc'>
                    <ul>
                        <li onClick={() => {setSettingSelected("PersonalInfo")}}><img className='my-account-icons' src={require("../assets/images/icons/personal-details.png")} alt='Personal details icon'></img>Personal Info</li>
                        <li onClick={() => {setSettingSelected("AccountSettings")}}><img className='my-account-icons' src={require("../assets/images/icons/settings.png")} alt='Account settings icon'></img>Account Settings</li>
                        <li onClick={() => {setSettingSelected("Preferences")}}><img className='my-account-icons' src={require("../assets/images/icons/preferences.png")} alt='Preferences icon'></img>Preferences</li>
                    </ul>
                </div>
                <div id='my-account-rc'>
                    <h2>Welcome {`${props.currentUser.fName}`}</h2>
                    <p>Your data and how to manage it.</p>
                    {(settingSelected === "PersonalInfo") ? <PersonalInfo currentUser={props.currentUser} /> 
                            : (settingSelected === "AccountSettings") ? <AccountSettings currentUser={props.currentUser} />
                            // : (settingSelected === "Preferences") ? <Preferences currentUser={currentUser} />
                            : ""
                        }
                </div>
            </div>
        </Fragment>
    )
}

export default NewMyAccount