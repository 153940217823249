import getGlobalURL from "../../../assets/scripts/Global";
import { React, useState } from "react";
import axios from "axios";
import { storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../../scripts/badToken";
import TextArea from "../../atoms/TextArea";
import ConvertToBase64 from "../../helperComponents/ConvertToBase64";
import NewPostFormHeader from "./NewPostFormHeader";

/**
 * @description - This component renders an active New Post component within a club 
 * 
 * @component
 * @param {object} currentUser - The current user object.
 * @param {object} club - The club object.
 * @param {string} clubSelected - The currently selected club.
 * @param {string} source - The source from which the component is being rendered.
 * @param {function} handlePostMade - A set function that calls the setPostMade state function located at Dashboard, Profile, DisplayUser, ClubHome.
 * @param {function} setCreatingPost - A set function for the state boolean variable used to switch between CreateClubPostActive and CreateClubPostInactive.
 * @returns {JSX.Element} - A React element that renders an active New Post component within a club and handles API call to send the new post
 * 
 * @example
 * // Renders an active New Post component within a club
 * <CreateClubPostActive 
 *  currentUser={props.currentUser} 
 *  club={props.club} 
 *  clubSelected={props.clubSelected} 
 *  source={props.source} 
 *  handlePostMade={props.handlePostMade} 
 *  setCreatingPost={setCreatingPost}
 * />
 */

const CreateClubPostActive = (props) => {
    const url = getGlobalURL();
    const [subject, setSubject] = useState(null);
    const [content, setContent] = useState(null);
    const [postImage, setPostImage] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(false);
    const [isCreatingPost, setCreatingPost] = useState(false);
    const [posting, setPosting] = useState(false);

    const createPost = () => {
        setPosting(true);
        if ((subject === "") | (content === "")) {
            setPosting(false);
            alert("Subject and Content are required fields!");
        } else {
            if (file) {
                postWithImage();
            } else {
                postWithoutImage();
            }
        }
    };
    
    const postWithImage = () => {
        let postID = Date.now();
        let uploadRef = ref(storage, `images/${postID}`);
        uploadBytes(uploadRef, file).then((response) => {
            getDownloadURL(uploadRef).then((contentUrl) => {
                axios.post(`${url}posts/createClubPost`, {
                    post: {
                        postSubject: subject,
                        postContent: content,
                        ocean: "TU Clubs", // This ensures that the club posts appear within the TU Clubs ocean/hub
                        club: props.source === "ClubHome" ? props.club.clubName : props.clubSelected,
                        postID: postID,
                        userPosterID: props.currentUser.userID,
                        timestamp: Date.now(),
                        postContentUrl: contentUrl,
                        contentIsVideo: false,
                    },
                    clubName: props.source === "ClubHome" ? props.club.clubName : props.clubSelected
                    }, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "Content-Type": "application/json",
                    },
                    }
                ).then((response) => {
                    if (response.status === 200) {
                    setPosting(false);
                    setCreatingPost(false);
                    setPosting(false);
                    setCreatingPost(false);
                    setSubject("");
                    setContent("");
                    setPostImage("");
                    setFile(null);
                    setSelectedImage(false);
                    setPosting(false);
                    props.handlePostMade();
                    props.setCreatingPost(false);
                    }
                }).catch((error) => {
                    if (error.code === "ERR_BAD_REQUEST") {
                    FoundBadToken();
                    } else {
                    alert(error);
                    }
                });
            });
        });
    };
    
    const postWithoutImage = () => {
        axios.post(`${url}posts/createClubPost`, {
                post: {
                    postSubject: subject,
                    postContent: content,
                    ocean: "TU Clubs",  // This ensures that the club posts appear within the TU Clubs ocean/hub
                    club: props.source === "ClubHome" ? props.club.clubName : props.clubSelected,
                    postID: Date.now(),
                    userPosterID: props.currentUser.userID,
                    timestamp: Date.now(),
                    postContentUrl: "",
                    contentIsVideo: false,
                },
                clubName: props.source === "ClubHome" ? props.club.clubName : props.clubSelected,
            }, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
            }
        ).then((response) => {
            if (response.status === 200) {
                setPosting(false);
                setCreatingPost(false);
                setSubject("");
                setContent("");
                setPostImage("");
                setFile(null);
                setSelectedImage(false);
                setPosting(false);
                props.handlePostMade();
                props.setCreatingPost(false);
            }
        }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
                FoundBadToken();
            } else {
                alert(error);
            }
        });
    };

    return (
        <div id="create-new-post-form-container" className="container-border make-grid">
            <div id="create-new-post-form-header">
                <NewPostFormHeader avatar={props.currentUser.profileImage} name={`${props.currentUser.fName} ${props.currentUser.lName}`} />
            </div>

            { posting ? (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", "margin-top": "15px"}}>
                    <PuffLoader color="#006994" />
                </div>
            ) : (
                <div id="create-new-post-form-inputs">
                    <input type="text" name="subject" onChange={(e) => {setSubject(e.target.value)}} placeholder="Enter a subject line" />
                    <TextArea setHandler={setContent} placeholderText={"Enter your content"}/>
                </div>
            )}
            <div id="create-new-post-btn-container">
                <ConvertToBase64
                    textID="post-image" 
                    setFile={setFile} 
                    setImage={setPostImage} 
                    image={postImage} 
                    type="image"
                    width="30px"
                    height="30px"
                    color="var(--secondary-text-color)"
                />
                <div id="create-new-post-btn-submit-cancel-area">
                    <button className="submit-btn clickable user-post-btn btn-ease" onClick={createPost}>Submit</button>
                    <button className="clickable user-post-btn btn-ease" onClick={() => props.setCreatingPost(false)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreateClubPostActive