const ArticleCard = (props) => {
  return (
    <div id='athletic-container' className='border-radius-10' >
      <img src={`https://towsontigers.com${props.article.story_image}`} />
      <div id='athletic-text-field' >
        <a href={`https://towsontigers.com${props.article.story_path}`} target="_blank" rel="noopener noreferrer" ><h3>{props.article.story_headline}</h3></a>
      </div>
      <div id='article-data-btns'>
        <div id="article-data">
          <p id="sport-title">{props.article.sport_title}</p>
          <p id="article-date">Created: {props.article.story_created.slice(0, 9)}</p>
        </div>
        <div id="article-btns">
          <button className="ocean-header-btns">Share</button>
        </div>
      </div>
      
    </div>
  )
}

export default ArticleCard