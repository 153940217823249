import { React, useEffect, useState } from 'react'
import getGlobalURL from '../../../assets/scripts/Global'
import axios from "axios";

const MajorSlide = (props) => {
    return (
        <div id="info-slide">
            <div id='business-info-container' className='make-grid'>
                <div id='business-info-area'>
                    <p>{props.major.majorDescription}</p>
                </div>
            </div>
        </div>
    )
}

export default MajorSlide;