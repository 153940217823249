import getGlobalURL from "../../../assets/scripts/Global";
import {React, useState, Fragment} from 'react'
import axios from "axios";
import { storage } from "../../../firebase";
import PuffLoader from "react-spinners/PuffLoader";
import { resizeImage } from "../../../scripts/ImageResizing";
import { FoundBadToken } from "../../../scripts/badToken";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ConvertToBase64 from '../../helperComponents/ConvertToBase64';
import TextArea from "../../atoms/TextArea";
import NewPostFormHeader from "./NewPostFormHeader";

/**
 * @description - This component renders an active New Post component 
 * 
 * @component
 * @param {object} userObject - The user's object with the following properties: userID, profileImage, fName, lName
 * @param {string} hubSelected - A string with the currently selected hub.
 * @param {function} handlePostMade - A set function that calls the setPostMade state function located at Dashboard, Profile, DisplayUser, HubHome/OceanHome
 * @param {function} setCreatePost - A set function for the state boolean variable used to switch between CreatePostActive and CreatePostInactive.
 * @returns {JSX.Element} - A React element that renders an active New Post component and handles API call to send the new post
 * 
 * @example
 * // Renders an active New Post component
 * <CreatePostActive 
 *  handlePostMade={props.handlePostMade} 
 *  hubSelected={props.oceanSelected} 
 *  setCreatePost={setCreatePost} 
 *  userObject={{
 *      userID: props.currentUser.userID, 
 *      profileImage: props.currentUser.profileImage, 
 *      fName: props.currentUser.fName, 
 *      lName: props.currentUser.lName, 
 *  }}
 * />
 */

const CreatePostActive = (props) => {
    const url = getGlobalURL();
    const [subject, setSubject] = useState(null);
    const [content, setContent] = useState(null);
    const [postImage, setPostImage] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(false);
    const [selectedImage, setSelectedImage] = useState(false);
    const [posting, setPosting] = useState(false); // Used for the spinner

    const createNewPost = () => {
        setPosting(true);
        if (props.userObject.userID === 0) {
            setPosting(false);
            alert("USER ID CANNOT BE 0");
        } else if (subject == "" || content == "") {
            setPosting(false);
            alert("Subject and Content are required fields!");
        } else {
            if (file != null) {
                let postID = Date.now();
                let uploadRef;
                if (selectedVideo) {
                    uploadRef = ref(storage, `postVideos/${postID}`);
                } else {
                    uploadRef = ref(storage, `images/${postID}`);
                }
                uploadBytes(uploadRef, file).then((response) => {
                    getDownloadURL(uploadRef).then((contentUrl) => {
                        axios.post(`${url}posts/createPost`, {
                                postSubject: subject,
                                postContent: content,
                                ocean: props.hubSelected,
                                postID: postID,
                                userPosterID: props.userObject.userID,
                                timestamp: Date.now(),
                                postContentUrl: contentUrl,
                                contentIsVideo: selectedVideo,
                            }, {
                                headers: {
                                Authorization: sessionStorage.getItem("token"),
                                "Content-Type": "application/json",
                                },
                            }
                        ).then((response) => {
                            props.handlePostMade();
                            setPosting(false);
                            setFile(null);
                            setPostImage("");
                            props.setCreatePost(false);
                        }).catch((error) => {
                            if (error.code === "ERR_BAD_REQUEST") {
                                FoundBadToken();
                            } else {
                                setPosting(false);
                                alert(error.response.data.err);
                                console.log("inside error with image upload");
                                // ? navigate to login page so they can reset their token?
                            }
                        });
                    });
                });
            } else {
                axios.post(`${url}posts/createPost`, {
                    postSubject: subject,
                    postContent: content,
                    ocean: props.hubSelected,
                    postID: Date.now(),
                    userPosterID: props.userObject.userID,
                    timestamp: Date.now(),
                }, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "Content-Type": "application/json",
                    },
                }).then((response) => {
                    props.handlePostMade();
                    setPosting(false);
                    setFile(null);
                    setPostImage("");
                    props.setCreatePost(false);
                }).catch((error) => {
                    if (error.code === "ERR_BAD_REQUEST") {
                        FoundBadToken();
                    } else {
                        setPosting(false);
                        alert(error.response.data.err);
                        // ? navigate to login page so they can reset their token?
                    }
                });
            }
        }
    };

    // const closePosting = () => {
    //     props.setCreatePost(false);
    // };

    return (
        <div id="create-new-post-form-container" className="container-border make-grid">
            <div id="create-new-post-form-header">
                <NewPostFormHeader avatar={props.userObject.profileImage} name={`${props.userObject.fName} ${props.userObject.lName}`} />
            </div>
            { posting ? (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", "margin-top": "15px"}}>
                    <PuffLoader color="#006994" />
                </div>
            ) : (
                <div id="create-new-post-form-inputs">
                    <input type="text" name="subject" onChange={(e) => {setSubject(e.target.value)}} placeholder="Enter a subject line" />
                    <TextArea setHandler={setContent} placeholderText={"Enter your content"}/>
                </div>
            )}
            <div id="create-new-post-btn-container">
                <ConvertToBase64 
                    textID="post-image" 
                    setFile={setFile} 
                    setImage={setPostImage} 
                    image={postImage} 
                    type="image"
                    width="30px"
                    height="30px"
                    color="var(--secondary-text-color)"
                />
                <div id="create-new-post-btn-submit-cancel-area">
                    <button className="clickable submit-btn user-post-btn btn-ease" onClick={createNewPost}>Submit</button>
                    <button className="clickable user-post-btn btn-ease" onClick={() => props.setCreatePost(false)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreatePostActive