import * as React from "react"
const FriendsChatsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={props.width || 40}
    height={props.height || 40}
    fill="#ccc"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="SVGRepo_iconCarrier">
      <defs>
        <style>
          {
            ".cls-1{fill:none;stroke:#ccc;stroke-miterlimit:10;stroke-width:1.91px}"
          }
        </style>
      </defs>
      <path
        d="M14.86 5.3H5.32A3.81 3.81 0 0 0 1.5 9.11v5.73a3.82 3.82 0 0 0 3.82 3.82h1.91l2.86 2.86L13 18.66h1.91a3.82 3.82 0 0 0 3.82-3.82V9.11a3.81 3.81 0 0 0-3.87-3.81Z"
        className="cls-1"
      />
      <path
        d="M18.68 14.84A3.82 3.82 0 0 0 22.5 11V5.3a3.82 3.82 0 0 0-3.82-3.82H9.14A3.82 3.82 0 0 0 5.32 5.3M5.32 11.98h1.91M9.14 11.98h1.91M12.95 11.98h1.91"
        className="cls-1"
      />
    </g>
  </svg>
)
export default FriendsChatsIcon
