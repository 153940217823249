import getGlobalURL from "../assets/scripts/Global";
import React, { useState, Fragment, useEffect, useRef, useContext } from "react";
import NavHeader from "../components/NavHeader";
import ClubNewPost from "../components/Clubs/ClubNewPost";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SuggestedFriends from "../components/suggested/SuggestedFriends";
import ClubHeader from "../components/Clubs/ClubHeader";
import Footer from "../components/Footer";
import ClubPostField from "../components/Clubs/ClubPostField";
import ClubAdminControls from "../components/Clubs/ClubControlls/ClubAdminControls";
import ClubEvents from "../components/Clubs/ClubCalendars/ClubEvents";
import { FoundBadToken } from "../scripts/badToken";
import NewMyAccount from "./NewMyAccount";
import NavDrawer from "../components/navigation/NavDrawer";
import MessageApplication from "../components/messages/MessageApplication";
import { AblyContext } from "../assets/custom-hooks/AblyContext";
import FeedbackForm from "../Modals/forms/FeedbackForm";

const ClubHome = () => {
   const { ably, currentUser } = useContext(AblyContext);
   let url = getGlobalURL();
   let location = useLocation();
   let clubName = location.state.clubSelected;

   let clubChannel = useRef();
   let userChannel = useRef();

   // const [club, setClub] = useState(location.state.club);
   const [club, setClub] = useState(null);
   const [clubSelected, setClubSelected] = useState(location.state.clubSelected);
   const [openNavDrawer, setOpenNavDrawer] = useState(true);
   const [openMyAccount, setOpenMyAccount] = useState(false);
   const [tabSelected, setTabSelected] = useState("My Clubs");
   const [activeButton, setActiveButton] = useState((clubName) ? clubName : currentUser.clubs[0]);
   const [numNotifications, setNumNotifications] = useState(currentUser.newNotifications);
   const [openNotifications, setOpenNotifications] = useState(false);
   const [numMessages, setNumMessages] = useState(0);
   const [openMessagesApp, setOpenMessagesApp] = useState(location.state.openMessagesApp !== null ? location.state.openMessagesApp : false);
   const [openFeedback, setOpenFeedback] = useState(false);
   const [messageAppCurrentlyOpen, setMessageAppCurrentlyOpen] = useState(false);


   useEffect(() => {
      console.log(`In ClubHome: clubSelected: ${clubSelected}`)
      axios.get(`${url}clubs/getClubByName`, {
         params: {
            clubName: clubSelected
         },
         headers: {
            "Authorization": sessionStorage.getItem("token")
         }
      }).then((response) => {
         if(response.status === 200){
            setClub(response.data);
         }
      }).catch((error) => {
         alert(error);
      })
   }, [clubSelected])

   const [postMade, setPostMade] = useState(false);
   const [userJoined, setUserJoined] = useState(false);
   const [eventMade, setEventMade] = useState(false);
   const [clubEdit, setClubEdit] = useState(false);

   const handlePostMade = () => {
      setPostMade(!postMade);
   };

   // * ocean channel useEffect
   useEffect(() => {
      if(ably){
         clubChannel.current = ably.channels.get(`club-updates-${clubName}`);
         
         clubChannel.current.subscribe('club-update', (message) => {
            setClub(message.data.data);
         })

         clubChannel.current.subscribe('banned-user', (message) => {
            setClub(message.data.data);
         })

         clubChannel.current.subscribe('officer-update', (message) => {
            setClub(message.data.data);
         })

         clubChannel.current.subscribe('admin-update', (message) => {
            setClub(message.data.data);
         })

         clubChannel.current.subscribe('member-list-update', (message) => {
            setClub(message.data.data);
         })

         clubChannel.current.subscribe('ban-appeal', (message) => {
            setClub(message.data.data)
         })
      }

      return () => {
         if(clubChannel.current){
            clubChannel.current.detach()
         }
      }
   }, [ably, club]);

   useEffect(() => {
      if(ably){
         userChannel.current = ably.channels.get(`user-clubs-updates-${currentUser.userID}`);
         
         // * Really only called if the user is banned/removed/unbanned from the club
         userChannel.current.subscribe('user-update', (message) => {
            currentUser.clubs = message.data.data;
         })
      }

      return () => {
         if(userChannel.current){
            userChannel.current.detach()
         }
      }
   }, [ably, club])

   const handleJoinClub = () => {
      axios.patch(`${url}clubs/addClubMember`, {
         userID: currentUser.userID,
         clubName: club.clubName
      }, {
         headers: {
            "Authorization": sessionStorage.getItem("token")
         }
      }).then((response) => {
         if(response.status === 200){
            club.members.push(currentUser.userID);
            currentUser.clubs.push(club.clubName);
            currentUser.clubs = currentUser.clubs.sort();
            setUserJoined(!userJoined);
         }
      }).catch((error) => {
         alert(error);
      })
   }

   const handleLeaveClub = () => {
      axios.patch(`${url}clubs/leaveClub`, {
         userID: currentUser.userID,
         clubName: club.clubName
      }, {
         headers: {
            "Authorization": sessionStorage.getItem("token")
         }
      }).then((response) => {
         if(response.status === 200){
            club.members = club.members.filter((member) => member !== currentUser.userID);
            currentUser.clubs = currentUser.clubs.filter((c) => c !== club.clubName);
            currentUser.clubs = currentUser.clubs.sort();
            setUserJoined(!userJoined);
         }
      }).catch((error) => {
         alert(error);
      })
   }

   function handleEventMade() {
      setEventMade(!eventMade);
   }

   function handleClubEdit() {
      setClubEdit(!clubEdit);
   }

   const handleOpenNavDrawer = () => {
		setOpenNavDrawer(!openNavDrawer);
	};

   const buttonSelection = (buttonSelected, tabSelected) => {
      console.log(`In ClubHome: buttonSelected: ${buttonSelected}, tabSelected: ${tabSelected}`)
      setClubSelected(buttonSelected);
      setTabSelected(tabSelected);
      setActiveButton(buttonSelected);
   }

   const handleOpenNotifications = () => {
		if(openNotifications === false){
			setOpenNotifications(true);
			markSeenNotifications();
		}
		else{
			setNumNotifications(0);
			currentUser.newNotifications = 0;
			setOpenNotifications(false);
		}
		// setOpenNotifications(!openNotifications);
	}

   const markSeenNotifications = async () => {
		await axios.post(`${url}users/markSeenNotifications`, {
			userID: currentUser.userID
		}, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			}
		}).then((response) => {
			// nothing to do here since we are just marking them as seen in the db
		}).catch((error) => {
			alert(error);
		})
	}

   const handleOpenMessagesApp = () => {
		if(openMessagesApp === false || openMessagesApp === undefined) {
			setOpenNavDrawer(false);
		} else {
			setOpenNavDrawer(true);
		}
		setOpenMessagesApp(!openMessagesApp);
	};

	const handleMessageChatOpen = () => {

		setMessageAppCurrentlyOpen(true);
	};

	const handleCloseMessage = () => {
		setMessageAppCurrentlyOpen(false);
	};

	const reduceNumMessages = (num) => {
		setNumMessages(Math.abs(numMessages - num));
	};

   return (
      <Fragment>
         <NavHeader 
            currentUser={currentUser}
            source="ClubHome" 
            handleOpenNavDrawer={handleOpenNavDrawer}
            numNotifications={numNotifications}
            openNotifications={openNotifications}
            handleOpenNotifications={handleOpenNotifications}
            setOpenNotifications={setOpenNotifications}
            numMessages={numMessages}
            messageAppCurrentlyOpen={messageAppCurrentlyOpen}
            handleOpenMessagesApp={handleOpenMessagesApp}
         />
         {openNavDrawer && <NavDrawer 
                              currentUser={currentUser} 
                              handleOpenNavDrawer={handleOpenNavDrawer} 
                              handleButtonSelection={buttonSelection} 
                              activeButton={activeButton} 
                              activeTab={tabSelected}
                              setOpenMyAccount={setOpenMyAccount}
                              setOpenFeedback={setOpenFeedback}
                              source="ClubHome"
                           />
         }
         { openMyAccount ? (
            <NewMyAccount currentUser={currentUser} />
         ) : openMessagesApp ? (
            <MessageApplication
               reduceNumMessages={reduceNumMessages}
               handleCloseMessage={handleCloseMessage}
               handleMessageChatOpen={handleMessageChatOpen}
               ably={ably.current}
               currentUser={currentUser}
               handleOpenMessagesApp={handleOpenMessagesApp}
            />
         ) : (
            <Fragment>
               <div id='main-panel' onClick={() => setOpenNotifications(false)} className='make-grid'>
                  <div id='left-panel' className='padding-top-80'>
                     { !openNavDrawer && (
                        <Fragment>
                           <SuggestedFriends currentUser={currentUser} />
                        </Fragment>
                     )}
                  </div>
                  <div id='center-panel' className="padding-top-80"> 
                     {openFeedback ? (
								<FeedbackForm userID={currentUser.userID} setOpenFeedback={setOpenFeedback}/>
							) : (club && club.clubName === clubName) && (
                        <Fragment>
                           <ClubHeader clubEdit={clubEdit} userJoined={userJoined} handleJoinClub={handleJoinClub} handleLeaveClub={handleLeaveClub} currentUser={currentUser} club={club} />
                           {club.banList.includes(currentUser.userID) ? (
                                 <h1>You are banned from this club. Please fill out a ban appeal form</h1>
                           ) : (
                                 <Fragment>
                                    {(club.admins.includes(currentUser.userID) || club.officers.includes(currentUser.userID)) && <ClubAdminControls ably={ably.current} handleClubEdit={handleClubEdit} handleEventMade={handleEventMade} club={club} currentUser={currentUser} />}
                                    {club.members.includes(currentUser.userID) && <ClubNewPost handlePostMade={handlePostMade} club={club} currentUser={currentUser} postMade={postMade} source="ClubHome" />}
                                    <ClubPostField club={club} userID={currentUser.userID} currentUser={currentUser} postMade={postMade} source="clubsHome" />
                                 </Fragment>
                           )}
                        </Fragment>
                     )}
                  </div>
                  <div id='right-panel' className='padding-top-80'>
                     { openNavDrawer && (club && club.clubName === clubName) && (
                        <Fragment> 
                           <ClubEvents eventMade={eventMade} currentUser={currentUser} club={club} />
                           <SuggestedFriends currentUser={currentUser} />
                        </Fragment>
                     )}
                  </div>
               </div>
            </Fragment>
         )}
         <Footer currentUser={currentUser}/>
      </Fragment>
      )
};

export default ClubHome;
