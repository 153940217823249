import React from 'react'

/**
 * @description - This component adds a count to an icon displaying the count passed in as props. NOTE: This is called by the IconWithCounter.jsx component. 
 * 
 * @component
 * @param {number} count - A count to display. pass in null if counter is not used
 * @returns {JSX.Element} - A react element that renders a counter
 * 
 * @example
 * // Render an alert icon for notifications with a counter
 * <IconCounter 
 *  count={someCount} 
 * />
 */

const IconCount = (props) => {
    return (
        <div className="main-nav-alerts-counter">
            <p>{props.count}</p>
        </div>
    )
}

export default IconCount