import React from 'react'
import IconCount from './IconCount'

/**
 * @description - This component adds an nav-alert-icon with an optional counter. 
 * 
 * @component
 * @param {function} handleClick - A function that triggers when the icon is clicked
 * @param {number} count - A count to display. pass in null if counter is not used
 * @param {string} iconName - The name of the icon. Used to fetch the icon image
 * @returns {JSX.Element} - A react element that renders an icon with an optional counter
 * 
 * @example
 * // Render an alert icon for a calendar without a counter
 * <IconWithCounter handleClick={handleOpenCalendar} count={null} iconName="calendar"/>
 * 
 * @example
 * // Render an alert icon for notifications with a counter
 * <IconWithCounter 
 *  handleClick={handleOpenNotifications} 
 *  count={props.numNotifications} 
 *  iconName="notifications"
 * />
 */

const IconWithCounter = (props) => {
    return (
        <li onClick={props.handleClick} className="main-nav-alerts-icon">
            {(props.count !== null) && (props.count > 0) ? <IconCount count={props.count} /> : null}
            <div className="main-nav-alerts-container">
                <img className="btn-ease" src={require(`../../assets/images/icons/${props.iconName}.png`)} alt={`${props.iconName} icon`} />
            </div>
        </li>
    )
}

export default IconWithCounter