import React from 'react'
import "../../assets/stylesheets/developerWelcome.css"
import MyCampusHubsEmblem from '../logo/MyCampusHubsEmblem'
import ConnectSocially from "./images/connect_socially.jpg"
import CampusResources from "./images/campus_resources.jpg"
import LocalEngagement from "./images/local_engagement.jpg"

const DeveloperWelcome = (props) => {
    return (
        <div className="welcome-container container-border">
            <br />
            <h1>Hello, {props.currentUser.fName}.</h1>
            <h2>Welcome to <span>MyCampusHubs!</span></h2>
            <p>We’re excited to have you as one of our first users of our platform, a comprehensive tool designed to connect you with your peers, faculty, and the local community. Our goal is to enhance your campus life by keeping you informed about academic opportunities, social events, and local activities—all in one place.</p>

            <br />
            <hr className="hr-custom"/>

            <h3>What You Can Do Here:</h3>
            <div className='options-cards-area'>
                <div className='option-card-container make-grid container-border'>
                    <img className='background-img-contain' src={ConnectSocially}/>
                    <h2>Connect Socially</h2>
                    <p>Meet and interact with fellow students for study, events, and socializing.</p>
                </div>
                <div className='option-card-container make-grid container-border'>
                    <img className='background-img-contain' src={CampusResources}/>
                    <h2>Find Resources</h2>
                    <p>Find helpful tools, study groups, and other academic resources.</p>
                </div>
                <div className='option-card-container make-grid container-border'>
                    <img className='background-img-contain' src={LocalEngagement}/>
                    <h2>Local Engagement</h2>
                    <p>Discover events and places in the surrounding area.</p>
                </div>
            </div>
            <br />
            <br />
            <hr className="hr-custom"/>

            <div className="important-note">
                <p><strong>Important Note:</strong></p>
                <p>You are accessing our <strong>browser version</strong> of the platform, which is still under active development. We’re continuously improving and adding new features, and your feedback is crucial to making this platform the best it can be. The <strong>mobile version</strong> is on its way, so stay tuned!</p>
            </div>

            <div className="feedback">
                <p><strong>We need your feedback!</strong></p>
                <p>Please explore, use, and enjoy the platform. If you encounter any issues or have suggestions, let us know! Your input will help shape the future of <strong>MyCampusHubs</strong>.</p>
            </div>
            <hr className="hr-custom"/>
            <br />

            <div className="regards">
                <p>Thank you for being part of our journey!</p>
                <br />
                <p>Best regards,</p>
                <p>The <span className='highlight'><strong>MyCampusHubs</strong></span> Development Team</p>
            </div>
                <div className="logo-area">
                    <MyCampusHubsEmblem className="logo" width="75px" height="75px" color="#fb0"/>
                </div>
        </div>
    )
}

export default DeveloperWelcome