import DirectoryBanner from '../../assets/images/directory_banner.jpg';
import GeneralButton from '../atoms/buttons/GeneralButton.jsx';

const DirectoryField = () => {
  const buttons = [
    {
      title: "Offices & Services",
      link: "https://www.towson.edu/directories/offices.html"
    },
    {
      title: "Faculty/Staff",
      link: "https://inside.towson.edu/phonebook/PublicEmployeeSearch.cfm"
    },
    {
      title: "Student",
      link: "https://inside.towson.edu/generalcampus/Studentphonebook/recsearch.cfm"
    },
    {
      title: "Social Media",
      link: "https://www.towson.edu/news/social-media/directory.html"
    }
  ]

  const moreInfo = "Search the campus directories to find offices and services, and to contact Towson University faculty, staff and students. See our social media directory for links to social media channels around campus."

  return (
    <div className="content-placeholder-container container-border">
      <img className="header-img background-img-contain" src={DirectoryBanner}/>
      <div className="content-placeholder-text-area">
          <h2 className='highlight'>TU Directory</h2>
          <p>{moreInfo}</p>
          <div id='directory-buttons-container' >
            {buttons.map((button, index) => {
              return (
                <GeneralButton key={index} title={button.title} link={button.link} />
              )
            })}
          </div>
      </div>
    </div>
  )
}

export default DirectoryField;