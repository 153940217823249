import React from 'react'

/**
 * @description - The header of the drawer navigation component that displays the user's profile image, banner image, and shortBio
 * 
 * @component
 * @param {object} currentUser - The current user object
 * @param {function} handleAvatarClick - A function that handles the click event of the user's profile image
 * @returns {JSX.Element} - The header of the drawer navigation component that displays the user's profile image, banner image, and shortBio
 * 
 * @example
 * // Renders the header of the drawer navigation component
 * <NavDrawerHeader 
 *  handleAvatarClick={handleAvatarClick} 
 *  currentUser={props.currentUser}
 * />
 */

const NavDrawerHeader = (props) => {
    return (
        <div id='nav-drawer-header'>
            <div id='nav-drawer-header-img-area'>
                <img className='background-img-contain' src={props.currentUser.bannerImage} />
                <img className='clickable-po background-img-cotain nav-drawer-avatar' src={props.currentUser.profileImage} onClick={props.handleAvatarClick}/>
            </div>
            <div id='nav-drawer-header-text-area'>
                <h1>{props.currentUser.fName} {props.currentUser.lName}</h1>
                <div id='nav-drawer-header-stats-bar'>
                    
                </div>
            </div>
        </div>
    )
}

export default NavDrawerHeader