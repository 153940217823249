import PostExpandModal from "../../../Modals/postModals/PostExpandModal"
import getGlobalURL from "../../../assets/scripts/Global"
import axios from "axios"
import Post from '../../Post'

const ExpandNotificationModal = (props) => {

    const announcementNotification = () => {
        return (
            <div className='modal-container'>
                <div className='modal-backdrop' onClick={() => {props.closeModal()}} />
                <div className='create-event-modal-content' >
                    <div className='form-container' >
                        <div className='form-header'>Announcement</div>
                        {/* <div className='form-header'>{props.notification.message.title}</div> */}
                        <div style={{'background' : 'white'}} id='manage-members-container' >
                            <div className='form-body' >
                                <h3><u><i>{props.notification.message.title}</i></u></h3>
                                <h4>{props.notification.message.body}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const postNotification = () => {
        return (
            <div className='modal-container' >
                <div className='modal-backdrop' onClick={() => { props.closeModal() }} />
                <div className='modal-thread-container container-border' >
                    <PostExpandModal closeModal={props.closeModal} currentUser={props.currentUser} post={props.post} source={props.source} />
                    {/* <Post currentUser={props.currentUser} post={props.post} source={props.source} /> */}
                </div>
            </div>
        )
    }

    return (
        props.notification.type === 'courseAnnouncement' ? announcementNotification() :
        props.notification.type === 'postNotification' ? postNotification() : null
    )
}

export default ExpandNotificationModal