import axios from 'axios';
import React, { useState, useEffect, Fragment } from 'react';
import AthleticButton from './AthleticButton';
import DefaultCard from './DefaultCard';
import NewsArticle from './NewsArticle';
import $ from 'jquery';

const AthleticsField = (props) => {

  const [buttonClicked, setButtonClicked] = useState();

  const buttons = [
    {
      title: "News",
      link: "https://towsontigers.com/"
    },
    {
      title: "Tickets",
      link: "https://towsontigers.com/sports/2023/4/14/ticket-central.aspx"
    },
    {
      title: "Merch",
      link: "https://towsontigersmerch.com/"
    }
  ]

  useEffect(() => {
    $('#athletics-info-slide').slideToggle("fast", "swing");
  }, [buttonClicked]);

  function handleSetButtonClicked(button){
    if(button.title === 'News'){
      setButtonClicked(button.title);
      $('#athletics-info-slide').slideToggle("fast", "swing");
    }
    else{
      window.open(button.link, '_blank');
    }
  }

  return (
    <div id='athletic-listing-plus-info-container'>
      <Fragment>
        <DefaultCard buttons={buttons} handleSetButtonClicked={handleSetButtonClicked} />
        {buttonClicked === 'News' && <NewsArticle id='#athletics-info-slide' />}
      </Fragment>
    </div>
  );
}

export default AthleticsField;