import GeneralButton from "../atoms/buttons/GeneralButton";
import AthleticButton from "./AthleticButton"

const DefaultCard = (props) => {
  return (
    <div className="content-placeholder-container container-border">
      <img className="header-img background-img-contain" src="https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/oceanBanners%2FTU%20Basketball?alt=media&token=805ad0a9-9553-47aa-b47b-b4a5c0523dc1"/>
      <div className="content-placeholder-text-area">
          <h2 className='highlight'>TU Athletics</h2>
          <p>Welcome to the Towson Athletics Page – your ultimate destination for all things sports at Towson University! Dive into the pulse of the Tigers' athletic scene with the latest news, updates, and highlights. From nail-biting games to triumphant victories, we cover it all. Stay tuned for game schedules, player spotlights, behind-the-scenes insights, and exclusive interviews. Whether you bleed black and gold or just love the thrill of the game, Towson Athletics has something for everyone. Join us as we cheer on our Tigers to success!</p>
          <div id='athletic-buttons-container' >
            {props.buttons.map((button, index) => {
              return (
                <AthleticButton key={index} handleSetButtonClicked={props.handleSetButtonClicked} title={button.title} link={button.link} />
              )
            })}
          </div>
      </div>
    </div>
  )
}

export default DefaultCard;