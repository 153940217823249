import getGlobalURL from "../../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import Business from "./Business";
import PuffLoader from "react-spinners/PuffLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import BusinessFilter from "../BusinessFilter";
import BusinessSlide from "./BusinessSlide";
import $ from "jquery";
import { FoundBadToken } from "../../../scripts/badToken";
import DefaultBanner from "../../../assets/images/around_town_banner.jpg";

const BusinessField = () => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [index, setIndex] = useState(1);
   const [dataSource, setDataSource] = useState([]);
   const [fetchingBusinesses, setFetchingBusinesses] = useState(true);
   const [hasMore, setHasMore] = useState(true);

   const [category, setCategory] = useState("All");
   const [businessClicked, setBusinessClicked] = useState("");

   const tempDescription = "Welcome to the Around Town section! Here, you can explore a curated selection of local businesses categorized by nightlife, entertainment, and food. Whether you're in the mood for a night out, looking for fun activities, or searching for delicious dining options, this section helps you find the best spots around town. Each business profile includes a brief description, location, and contact details, making it easy to plan your next adventure. Dive into the vibrant local scene and discover all the exciting places to visit and enjoy in our community!"

   function handleSetCategory(category) {
      setCategory(category);
   }

   const fetchMoreData = async () => {
      try {
         const res = await axios.get(
            `${url}businesses/getMoreBusinesses/${index}`,
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         );

         if (res.status === 200) {
            if (res.data.length === 0) {
               // No more elements to fetch
               setHasMore(false);
            } else {
               setDataSource([...dataSource, ...res.data]);
               setFetchingBusinesses(false);
               setIndex(index + 1);
            }
         }
      } catch (err) {
         if (err.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(err.response.data.err);
         }
      }
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const res = await axios.get(
               `${url}businesses/getMoreBusinesses/${index}`,
               {
                  headers: {
                     Authorization: sessionStorage.getItem("token"),
                  },
               }
            );
            if (res.status === 200) {
               setDataSource(res.data);
               setFetchingBusinesses(false);
               setIndex(index + 1);
            }
         } catch (err) {
            if (err.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(err.response.data.err);
            }
         }
      };

      fetchData();
   }, []);

   useEffect(() => {
      $("#business-info-slide").slideToggle("fast", "swing");
   }, [businessClicked]);

   const handleBusinessClick = (businessClicked) => {
      console.log(`Business clicked: ${businessClicked}`);
      setBusinessClicked(businessClicked);
      $("#business-info-slide").slideToggle("fast", "swing");
   };

   return (
      <div className="content-placeholder-container container-border">
         <img className="header-img background-img-contain" src={DefaultBanner}/>
         <div className="content-placeholder-text-area">
               <h2 className='highlight'>Around Town</h2>
               <p>{tempDescription}</p>
         </div>
         <div id="clubs-orgs-field-container">
            <div id="category-container">
               <BusinessFilter handleSetCategory={handleSetCategory} />
            </div>

            <InfiniteScroll
               dataLength={dataSource.length}
               next={fetchMoreData}
               hasMore={hasMore}
               loader={<PuffLoader color="#006994" />}
               scrollThreshold={1.0}
            >
               {fetchingBusinesses ? (
                  <PuffLoader color="#006994" />
               ) : (
                  dataSource.filter((business) => {
                        return (
                           business.category.includes(category) ||
                           category === "All"
                        );
                     }).map((business, index) => {
                        return (
                           <div key={index} id="club-org-listing-plus-info-container">
                              <Fragment key={business.businessName}>
                                 <Business
                                    handleBusinessClick={handleBusinessClick}
                                    business={business}
                                    businessClicked={businessClicked}
                                 />
                                 {businessClicked == business.businessName ? (
                                    <BusinessSlide
                                       id="business-info-slide"
                                       business={business}
                                       eventUploadedFlag=""
                                       source="Student"
                                    />
                                 ) : null}
                              </Fragment>
                           </div>
                        );
                     })
               )}
            </InfiniteScroll>
         </div>
      </div>
   );
};

export default BusinessField;
