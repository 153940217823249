const { GlobalURL_FLAG } = require('./GlobalFlagValue')

function getGlobalURL() {
  // ############################ backend URL start #############################################
  let url = ""

  if (GlobalURL_FLAG === 1) { // ! DON'T CHANGE THIS. SHOULD READ 1
    url = "http://localhost:3001/api/";
  } else {
    url = "https://syncedup-backend.vercel.app/api/";
  }

  return url;
}

export default getGlobalURL;