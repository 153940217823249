import getGlobalURL from "../../../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from "react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../../scripts/badToken";
import BanUser from "../../icons/BanUser";
import IconWithLabel from "../../atoms/buttons/IconWithLabel";
import RemoveIcon from "../../icons/RemoveIcon";
import ModTrue from "../../icons/ModTrue";
import ModFalse from "../../icons/ModFalse";

const MemberListing = (props) => {
   // Incoming props from ChildRequestModal: 'currentUser', 'ocean'

   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [user, setUser] = useState();
   const [fetching, setFetching] = useState(true);
   const [isMod, setIsMod] = useState(props.ocean.mods.includes(props.memberID));

   useEffect(() => {
      axios.post(`${url}users/getSingleUser`, {
            userID: props.memberID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         setUser(response.data[0]);
         setFetching(false);
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   }, []);

   const handleRemoveOceanMember = () => {
      axios.patch(`${url}oceans/removeOceanMember`, {
            oceanName: props.ocean.oceanName,
            userID: props.memberID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         let newOceansList = [...user.oceans];
         newOceansList.splice(newOceansList.indexOf(props.ocean.oceanName), 1);
         axios.patch(`${url}users/updateUserOceans`, {
               userID: props.memberID,
               oceans: newOceansList,
            }, {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         ).then((response) => {
            // alert("User removed from ocean and the ocean has been removed from the user's ocean list.");
            props.handleRemoveMember(props.memberID);
         }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error.response.data.err);
            }
         });
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   const handleAddMod = () => {
      axios.patch(`${url}oceans/addModerator`, {
            oceanName: props.ocean.oceanName,
            userID: props.currentUser.userID,
            userToMakeModID: props.memberID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         props.ocean.mods.push(props.memberID);
         setIsMod(props.ocean.mods.includes(props.memberID))
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   const handleBanUser = () => {
      axios.patch(`${url}oceans/banUserFromOcean`, {
            oceanName: props.ocean.oceanName,
            userID: props.currentUser.userID,
            userToBanID: props.memberID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         handleRemoveOceanMember();
         alert(
            "User has been BANNED from the" +
               props.ocean.oceanName +
               " ocean!"
         );
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
         }
      });
   };

   const handleRemoveMod = () => {
      axios.patch(`${url}oceans/removeModerator`, {
            oceanName: props.ocean.oceanName,
            userID: props.currentUser.userID,
            userToRemoveModID: props.memberID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         props.ocean.mods.splice(props.ocean.mods.indexOf(props.memberID), 1);
         setIsMod(props.ocean.mods.includes(props.memberID))
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.message);
         }
      });
   };

   function displayModButton() {
      if (props.memberID === props.ocean.owner) {
         return null;
      } else if (props.currentUser.userID === props.ocean.owner) {
         if (isMod) {
            return (
               <IconWithLabel handleClick={handleRemoveMod} icon={<ModTrue width="25" height="25" color="var(--primary-color)" />} label="-Mod" />
            );
         } else {
            return (
               <IconWithLabel handleClick={handleAddMod} icon={<ModFalse width="25" height="25" color="var(--primary-color)" />} label="+Mod" />
            );
         }
      } else {
         return null;
      }
   }

   return (
      <Fragment>
         {fetching ? (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "15px"}}>
               <PuffLoader color="#006994" />
            </div>
         ) : user != null ? (
            <div className="member-card-container">
               <div className="member-info">
               <img className="member-card-avatar" src={user.profileImage} />
                  <h3>{`${user.fName} ${user.lName}`}</h3>
                  <p>@{user.username}</p>
               </div>
               <div className="member-card-btn-area">
                  <IconWithLabel handleClick={handleRemoveOceanMember} icon={<RemoveIcon width="25" height="25" color="var(--primary-color)" />} label="Remove" />
                  {displayModButton()}
                  <IconWithLabel handleClick={handleBanUser} icon={<BanUser width="25" height="25" color="var(--primary-color)" />} label="Ban" />
               </div>
            </div>
         ) : null}
      </Fragment>
   );
};

export default MemberListing;


// Old listings
// <button onClick={handleRemoveMod} className="admin-btns btn-ease">Remove Mod</button>
// <button onClick={handleAddMod} className="admin-btns btn-ease">Add Mod</button>


// <div id="manage-members-listings-area" className="make-grid">
//    <img src={user.profileImage} />
//    <div id="manage-members-stats-title">
//       <h3>{user.fName} {user.lName}</h3>
//       <p>{user.username}</p>
//    </div>
//    <div id="manage-members-btns">
//       <button onClick={handleRemoveOceanMember} className="admin-btns btn-ease">Remove</button>
//       {displayModButton()}
//       <button onClick={handleBanUser} className="admin-btns btn-ease">Ban User</button>
//    </div>
// </div>
