import { React, useState, useEffect } from 'react'

/**
 * @description - This component renders a password policy field that displays the password policy to the user in realtime.
 * 
 * @component
 * @param {string} password - A string used to compare and update the password policy milestones.
 * @param {function} setValidPassword - A function used to update the password policy status.
 * @returns {JSX.Element} - A React element that renders a password policy field.
 * 
 * @example
 * // Renders a password policy field that displays the password policy to the user in realtime
 * <PasswordPolicy password={password} />
 */

const PasswordPolicy = (props) => {
    const [passwordLength, setPasswordLength] = useState(false);
    const [passwordAlphaNum, setPasswordAlphaNum] = useState(false);
    const [passwordSpecialChar, setPasswordSpecialChar] = useState(false);

    useEffect(() => {
        if (props.password.length >= 8) {
            setPasswordLength(true);
        } else {
            setPasswordLength(false);
        }
        
        if (/[a-zA-Z]/.test(props.password) && /[0-9]/.test(props.password)) {
            setPasswordAlphaNum(true);
        } else {
            setPasswordAlphaNum(false);
        }
        
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(format.test(props.password)) {
            setPasswordSpecialChar(true);
        } else {
            setPasswordSpecialChar(false);
        }

        if(passwordLength && passwordAlphaNum && passwordSpecialChar) {
            props.setValidPassword(true);
        }
    }, [props.password]);
    
    return (
        <div id='password-policies-container'>
            <p>PASSWORD POLICY:</p>
            <ul>
                <li className='pass-policy' style={{color: passwordLength ? 'green' : 'red'}}>{passwordLength ? "✔" : "X"} Must be at least 8 characters long</li>
                <li className='pass-policy' style={{color: passwordAlphaNum ? 'green' : 'red'}}>{passwordAlphaNum ? "✔" : "X"} Must include letters and numbers</li>
                <li className='pass-policy' style={{color: passwordSpecialChar ? 'green' : 'red'}}>{passwordSpecialChar ? "✔" : "X"} Must include at least 1 special character ${`!@#$%^&*()}`}</li>
            </ul>
        </div>
    
    )
}

export default PasswordPolicy