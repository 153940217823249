import React, { useState } from 'react';

const AthleticArticleFilter = (props) => {

  const filters = ['All', 'Baseball', 'Basketball', 'Football', 'Golf', 'Lacrosse', 'Swimming & Diving', 'Cross Country', 'Field Hockey', 'Gymnastics', 'Soccer', 'Softball', 'Tennis', 'Track & Field'];

  function handleFilterChoice(event){
    props.handleSetFilter(event.target.value);
  }

  return (
    <div id='athletics-search-container' >
      <div className='athletics-search-field border-radius-15'>
        <select id='club-category' onChange={handleFilterChoice} >
        {filters.map((filter, index) => {
          return(
            <option key={index} value={filter}>{filter}</option>
          )
        })}
        </select>
      </div>
    </div>
  )
}

export default AthleticArticleFilter;