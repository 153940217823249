import { React, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import "../assets/stylesheets/oceansStyles.css"
import MyCampusHubsEmblem from "./logo/MyCampusHubsEmblem.jsx";

const NavHeaderBusiness = (props) => {

  // When the logout button is clicked, the page will refresh to the Dashboard
  let navigate = useNavigate()

  const handleLogoutClick = () => {
    // When the user clicks the 'Logout' link, route to the Login page with the user object cleared
    sessionStorage.clear();
    navigate("/", {state: {currentUser: {}}})
  }
  
  return (
    <Fragment>
      <div id="business-nav-container" className="container-border">
        <div id="business-nav" className="make-grid">
          <MyCampusHubsEmblem />
          <ul>
            <li>Hi, {props.currentBusiness.fName}</li>
            <li onClick={handleLogoutClick}>Logout</li>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

export default NavHeaderBusiness