import { React } from 'react'
import AddImageIcon from '../../components/messages/toolbar/icons/AddImageIcon';

/**
 * @description - This component renders an AddFileIcon component on the lower right corner of a container that is intended
 * to be used as an image preview. This component DOES NOT render a preview image. 
 * 
 * @component
 * @param {string} textID - A string used as the input id so that the querySelector knows which element triggered the call
 * @param {function} setFile - A set function for the state variable that stores the targeted file that will uploaded to firebase.
 * @param {function} setImage - A set function for the state variable that stores the image to preview.
 * @returns {JSX.Element} - A react element that renders an AddFileIcon component on the lower right corner of a container and handles
 *     fetching an image selected by the user from their file system.
 * 
 * @example
 * // Render an AddImageIcon on the lower right corner of an image parent-container.
 * <ConvertToBase64Simple
 *  textID="banner-1"
 *  setFile={setFile}
 *  setImage={setBannerImage} 
 * />
 */

const ConvertToBase64Simple = (props) => {
    const convertToBase64 = (e) => {
        let targetFiles = e.target.files[0];
        props.setFile(targetFiles);
        var reader = new FileReader();
        if(targetFiles) {
            reader.readAsDataURL(targetFiles);
            reader.onload = function () {
                props.setImage(reader.result);
            };
        }
    }

    return (
            <div className='c2b64simple-input-btn-area'>
                {/* <input type="file" name="video-preview" onChange={convertToBase64} style={{ display: 'none'}} /> */}
                
                <input type='file' id={props.textID} accept='image/*' onChange={convertToBase64} style={{ display: 'none' }}/>
                <div className={`c2b64simple-add-img-btn btn-ease`} onClick={() => {document.querySelector(`input[type="file"][id="${props.textID}"]`).click()}}>
                    <AddImageIcon width="20px" height="20px" color="var(--primary-text-color)"/>
                </div>
            </div>
    )
}

export default ConvertToBase64Simple