import React from 'react'

const AcademicsIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            d="M16 27s3-1 6-1 7 1 7 1V8s0-1-.983-1.224C26.647 6.463 24.081 6 22 6c-3 0-6 1-6 1s-3-1-6-1c-2.081 0-4.647.463-6.017.776C3 7 3 8 3 8v19s4-1 7-1 6 1 6 1zm4-14h5a1 1 0 1 1 0 2h-5a1 1 0 1 1 0-2zm0 4h5a1 1 0 1 1 0 2h-5a1 1 0 1 1 0-2zM7 13h5a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2zm-1 5a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm25-9.001v20a1 1 0 0 1-1 1c-.028 0-11.004-.913-11.004-.913a.999.999 0 0 1-.996.913h-4a.999.999 0 0 1-.996-.913l-10.921.91A1.002 1.002 0 0 1 1 28.999v-20a1 1 0 0 1 1-1v19.648c0 .321.295.56.608.488C4.056 27.801 7.755 27 10 27c2.794 0 5.656.939 5.684.949l.315.105.316-.105C16.344 27.939 19.206 27 22 27c2.245 0 5.944.801 7.392 1.136a.497.497 0 0 0 .608-.488V8a.999.999 0 0 1 1 .999z"
            style={{
            fill:props.color,
            }}
        />
    </svg>
)

export default AcademicsIcon