import React from 'react'

const ThumbsUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <g fill={props.color}>
            <path d="M12.366.888A1.525 1.525 0 0 1 13.75 0c1.242 0 2.222.359 2.74 1.292.226.407.314.843.354 1.225.039.37.039.764.039 1.111V7h3.133c1.838 0 3.225 1.648 2.95 3.455l-1.374 9A2.988 2.988 0 0 1 18.643 22H8V9h.377L12.366.888ZM6 9H3.983A2.992 2.992 0 0 0 1 12v7c0 1.649 1.328 3 2.983 3H6V9Z" />
        </g>
    </svg>
)

export default ThumbsUp;