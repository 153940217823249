import getGlobalURL from "../assets/scripts/Global";
import React, { useState, Fragment, useEffect, useRef, useContext } from "react";
import NavHeader from "../components/NavHeader";
import NewPost from "../components/NewPost";
import OceansHeader from "../components/OceansHeader";
import PostsField from "../components/PostsField";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SuggestedOceans from "../components/suggested/SuggestedOceans";
import SuggestedFriends from "../components/suggested/SuggestedFriends";
import ChildOceansList from "../components/oceans/ChildOceansList";
import AdminControls from "../components/oceanControls/AdminControls";
import ModsControls from "../components/oceanControls/ModsControls";
import Footer from "../components/Footer";
import { FoundBadToken } from "../scripts/badToken";
import NavDrawer from "../components/navigation/NavDrawer";
import NewMyAccount from "./NewMyAccount";
import MessageApplication from "../components/messages/MessageApplication";
import { AblyContext } from "../assets/custom-hooks/AblyContext";
import FeedbackForm from "../Modals/forms/FeedbackForm";

const OceansHome = () => {
   let url = getGlobalURL();
   let location = useLocation();
   // let currentUser = location.state.currentUser;
   let searchedOcean = location.state.oceanSelected;
   // let visitingOcean = location.state.ocean;

   const { ably, currentUser } = useContext(AblyContext);
   let oceanChannel = useRef();

   const [oceanSelected, setOceanSelected] = useState(searchedOcean);
   const [tabSelected, setTabSelected] = useState("My Hubs");
   const [oceanEdited, setOceanEdited] = useState(false);
   const [postMade, setPostMade] = useState(false);
   const [ocean, setOcean] = useState();
   const [oceanCreated, setOceanCreated] = useState(false);
   const [adminCheck, setAdminCheck] = useState(null);
   const [modCheck, setModCheck] = useState(null);
   const [openNavDrawer, setOpenNavDrawer] = useState(true);
   const [openMyAccount, setOpenMyAccount] = useState(false);
   const [activeButton, setActiveButton] = useState(searchedOcean ? searchedOcean : currentUser.oceans[0]);
   const [numNotifications, setNumNotifications] = useState(currentUser.newNotifications);
   const [openNotifications, setOpenNotifications] = useState(false);
   const [numMessages, setNumMessages] = useState(0);
   const [openMessagesApp, setOpenMessagesApp] = useState(location.state.openMessagesApp !== null ? location.state.openMessagesApp : false);
   const [openFeedback, setOpenFeedback] = useState(false);
   const [messageAppCurrentlyOpen, setMessageAppCurrentlyOpen] = useState(false);

   useEffect(() => {
      axios.get(`${url}oceans/getOceanByName/`, {
         params: {
            oceanName: oceanSelected,
            userID: currentUser.userID,
         },
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         setOcean(response.data);
         setAdminCheck(response.data.isAdmin);
         setModCheck(response.data.isMod);
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            // alert(error.response.data.err);
         }
      });
   }, [oceanSelected]);

   useEffect(() => {}, [postMade, ocean]);

   // * ably useEffect
   useEffect(() => {
      if (ably) {
         oceanChannel.current = ably.channels.get(`ocean-updates-${oceanSelected}`);

         oceanChannel.current.subscribe("ocean-update", (message) => {
            setAdminCheck(message.data.data.admins.includes(currentUser.userID));
            setModCheck(message.data.data.mods.includes(currentUser.userID));
            setOcean(message.data.data);
         });
      }
      else{
         console.log("ably is not defined in OceansHome.jsx");
      }

      return () => {
         if(oceanChannel.current){
            oceanChannel.current.unsubscribe();
         }
      };
   }, [ably, oceanChannel.current]);

   useEffect(() => {}, [ocean])

   // Fixed our oceanSelected state to be the ocean we searched for
   // itll work for now atleast
   if (searchedOcean !== oceanSelected) {
      setOceanSelected(searchedOcean);
   }

   const handlePostMade = () => {
      // This function runs when.....
      setPostMade(!postMade);
   };

   const updateOcean = () => {
      setOceanEdited(!oceanEdited);
   };

   const handleOceanCreated = () => {
      setOceanCreated(!oceanCreated);
   };

   const handleOpenNavDrawer = () => {
		setOpenNavDrawer(!openNavDrawer);
	};

   const buttonSelection = (buttonSelected, tabSelected) => {
      setOceanSelected(buttonSelected);
      setTabSelected(tabSelected);
      setActiveButton(buttonSelected);
      // The above does not seem to be updating fast enough to rerender the OceansHome, so having to navigate each tiem 
      //   buttonSelected is changed. This is not ideal, but it works for now.
      //console.log(`In OceansHome: buttonSelected: ${buttonSelected}, tabSelected: ${tabSelected}`)
   }

   const handleOpenNotifications = () => {
		if(openNotifications === false){
			setOpenNotifications(true);
			markSeenNotifications();
		}
		else{
			setNumNotifications(0);
			currentUser.newNotifications = 0;
			setOpenNotifications(false);
		}
		// setOpenNotifications(!openNotifications);
	}

   const markSeenNotifications = async () => {
		await axios.post(`${url}users/markSeenNotifications`, {
			userID: currentUser.userID
		}, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			}
		}).then((response) => {
			// nothing to do here since we are just marking them as seen in the db
		}).catch((error) => {
			// alert(error);
		})
	}

   const handleOpenMessagesApp = () => {
		if(openMessagesApp === false || openMessagesApp === undefined) {
			setOpenNavDrawer(false);
		} else {
			setOpenNavDrawer(true);
		}
		setOpenMessagesApp(!openMessagesApp);
	};

	const handleMessageChatOpen = () => {

		setMessageAppCurrentlyOpen(true);
	};

	const handleCloseMessage = () => {
		setMessageAppCurrentlyOpen(false);
	};

   const addSubHub = (subHub, hubID) => {
      let newOcean = {
         ...ocean,
         children: [...ocean.children, subHub],
         request_parent: ocean.request_parent.filter((parent) => parent !== hubID),
      };
      setOcean(newOcean);
   }

   return (
      <Fragment>
         <NavHeader
            currentUser={currentUser} 
            source="HubHome" 
            handleOpenNavDrawer={handleOpenNavDrawer}
            openNotifications={openNotifications}
            handleOpenNotifications={handleOpenNotifications}
            setOpenNotifications={setOpenNotifications}
            numMessages={numMessages}
            messageAppCurrentlyOpen={messageAppCurrentlyOpen}
            handleOpenMessagesApp={handleOpenMessagesApp}
         />
         {openNavDrawer && <NavDrawer 
                              currentUser={currentUser} 
                              handleOpenNavDrawer={handleOpenNavDrawer} 
                              handleButtonSelection={buttonSelection} 
                              activeButton={activeButton} 
                              activeTab={tabSelected}
                              setOpenMyAccount={setOpenMyAccount}
                              setOpenFeedback={setOpenFeedback}
                              source="OceansHome"
                           />
         }
         { openMyAccount ? (
            <NewMyAccount currentUser={currentUser} />
         ) : openMessagesApp ? (
            <MessageApplication
               handleCloseMessage={handleCloseMessage}
               handleMessageChatOpen={handleMessageChatOpen}
               currentUser={currentUser}
               handleOpenMessagesApp={handleOpenMessagesApp}
            />
         ) : (
            <Fragment>
               <div id="main-panel" onClick={() => setOpenNotifications(false)} className="make-grid">
                  <div id="left-panel" className="padding-top-80">
                     { !openNavDrawer && (
                        <Fragment>
                           <SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated} />
                           <SuggestedFriends currentUser={currentUser} />
                        </Fragment>
                     )}
                  </div>
                  <div id="center-panel" className="padding-top-80">
                     {openFeedback ? (
								<FeedbackForm userID={currentUser.userID} setOpenFeedback={setOpenFeedback}/>
							) : (
                        <Fragment>
                           {ocean && <OceansHeader
                              currentUser={currentUser}
                              ocean={ocean}
                              oceanEdited={oceanEdited}
                              adminCheck={adminCheck}
                              modCheck={modCheck}
                           />}
                           {adminCheck && (
                              <AdminControls
                                 addSubHub={addSubHub}
                                 currentUser={currentUser}
                                 ocean={ocean}
                                 updateOcean={updateOcean}
                                 ably={ably}
                              />
                           )}
                           {modCheck && (
                              <ModsControls currentUser={currentUser} ocean={ocean} />
                           )}
                           <NewPost
                              currentUser={currentUser}
                              oceanSelected={oceanSelected}
                              handlePostMade={handlePostMade}
                           />
                           <PostsField
                              oceanSelected={oceanSelected}
                              userID={currentUser.userID}
                              currentUser={currentUser}
                              postMade={postMade}
                              source="oceansHome"
                           />
                        </Fragment>
                     )}
                  </div>
                  <div id="right-panel" className="padding-top-80">
                     { openNavDrawer && (
                        <Fragment>                  
                           { ocean && <ChildOceansList currentUser={currentUser} parentOcean={oceanSelected} parentOceanChildren={ocean.children} /> }
                           <SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated} />
                           <SuggestedFriends currentUser={currentUser} />
                        </Fragment>
                     )}
                  </div>
               </div>
            </Fragment>
         )}
         <Footer currentUser={currentUser} />
      </Fragment>
   );
};

export default OceansHome;
