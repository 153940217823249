import React from 'react'
import { formatDateToMDY } from '../../assets/scripts/mchScripts'

/**
 * @description - A component that displays a date in the format 'mm/yyyy with a label over it. Imports and utilizes the 
 *  formatDateToMDY function from mchScripts.js to format the date from the current format to 'mm/yyyy'.
 * 
 * @component
 * @param {string} label - The label for the stat
 * @param {string} date - The date to display
 * @returns {JSX.Element} - A component that displays a stat with a label over it
 * 
 * @example
 * // Renders a component that displays a stat with a label over it
 * <MemberSinceContainer 
 *  label="Member Since" 
 *  date={joinDate} 
 * />
 */

const MemberSinceContainer = (props) => {
    return (
        <div className="header-stat-container">
            <p>{props.label}</p>
            <h1>{formatDateToMDY(props.date)}</h1>
        </div>
    )
}

export default MemberSinceContainer