import axios from "axios";
import { Fragment, React, useState, useEffect } from "react";
import getGlobalURL from "../../../assets/scripts/Global";
import { FoundBadToken } from "../../../scripts/badToken";

const BanAppeal = (props) => {
   const url = getGlobalURL();

   const [text, setText] = useState("");

   const handleBackdropClick = () => {
      props.closeModal("ban");
   };

   const handleSubmitBanAppeal = () => {
      axios.post(`${url}clubs/banAppeal`, {
         bannedUserID: props.currentUser.userID,
         appealReason: text,
         clubName: props.clubName
      }, {
         headers: {
            "Authorization": sessionStorage.getItem("token")
         }
      }).then((response) => {
         alert('Ban appeal submitted')
         props.closeModal('ban')
      }).catch((error) => {
         alert(error)
      })
   }

   return (
      <div className="modal-container">
         <div className="modal-backdrop" onClick={handleBackdropClick} />
         <div className="create-post-modal-content">
            <div id="create-post-form-container">
               <div id="modal-title" className="make-grid">
                  <h1>Ban Appeal</h1>
                  <h4>This form is to appeal your ban from the club.</h4>
               </div>
               <div id="create-post-form-inputs">
                  <textarea
                     className="scroll"
                     type="text"
                     name="content"
                     onChange={(e) => {
                        setText(e.target.value);
                     }}
                     placeholder="Enter your appeal"
                  ></textarea>
               </div>
               <div id="create-post-btn-container">
                  <button
                     className="user-post-btn btn-ease"
                     onClick={handleSubmitBanAppeal}
                  >
                     Submit
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};

export default BanAppeal;
