import getGlobalURL from "../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import TutoringSubjectCard from "./TutoringSubjectCard";
import Button from "../campusModeComponents/Button";
import SubjectScheduleCard from "./SubjectScheduleCard";
import ScheduleSlide from "./ScheduleSlide";
import StaffBioCard from "./StaffBioCard";
import StaffBioSlide from "./StaffBioSlide";
import SubjectUpdatesCard from "./SubjectUpdatesCard";
import SubjectUpdatesSlide from "./SubjectUpdatesSlide";
import { FoundBadToken } from "../../scripts/badToken";
import SearchField from "../helperComponents/SearchField";
import DefaultBanner from "../../assets/images/tutoring-banner.jpg";

const TutoringFiled = (props) => {
   const [subjects, setSubjects] = useState([]);
   const [subjectClicked, setSubjectClicked] = useState();
   const [searchedSubject, setSearchedSubject] = useState("");
   const [fetchingSubjects, setFetchingSubjects] = useState(true);
   const [category, setCategory] = useState("All");
   const [subjectSchedule, setSubjectSchedule] = useState(false);
   const [subjectUpdates, setSubjectUpdates] = useState(false);
   const [staffBio, setStaffBio] = useState(false);

   const url = getGlobalURL();

   const tempDescription = "Welcome to our future Tutoring Services resource! We're in the process of building a comprehensive section to connect you with the academic support you need. In the meantime, explore the basic features we've implemented, including introductory information on available tutoring services, contact details for tutors, and a list of subjects currently offered. Stay tuned for updates as we expand and enhance this resource to better support your academic success."

   useEffect(() => {
      const fetchData = async () => {
         try {
            const res = await axios.get(`${url}tutoring/getSubjects`, {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            });
            if (res.status === 200) {
               setSubjects(res.data);
               setFetchingSubjects(false);
               console.log(res.data);
            }
         } catch (err) {
            if (err.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(err.response.data.err);
            }
         }
      };

      fetchData();
   }, []);

   useEffect(() => {
      $("#info-slide").slideToggle("fast", "swing");
   }, [subjectSchedule, staffBio]);

   useEffect(() => {
      $("#subject-updates-info-slide").slideToggle("fast", "swing");
   }, [subjectUpdates]);

   const handleSubjectClick = (subjectClicked) => {
      console.log(`subject clicked: ${subjectClicked}`);
      setSubjectClicked(subjectClicked);
      // alert(JSON.stringify(subjectClicked.staffBios))
   };

   const handleBackButtonClick = () => {
      console.log("back button clicked");
      setSubjectClicked(null);
      setSubjectSchedule(false);
      setStaffBio(false);
      setSubjectUpdates(false);
   };

   const handleSubjectScheduleCardClick = () => {
      setSubjectSchedule(!subjectSchedule);
      setStaffBio(false);
      setSubjectUpdates(false);
   };

   const handleStaffBioCardClick = () => {
      setStaffBio(!staffBio);
      setSubjectSchedule(false);
      setSubjectUpdates(false);
   };

   const handleSubjectUpdatesCardClick = () => {
      setSubjectUpdates(!subjectUpdates);
      setStaffBio(false);
      setSubjectSchedule(false);
   };

   if (!subjectClicked) {
      return (
         <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src={DefaultBanner}/>
            <div className="content-placeholder-text-area">
               <h2 className='highlight'>Tutoring Services</h2>
               <p>{tempDescription}</p>
               <br />
               <div className="resource-body-container">
                  <div id="search-orgs-container">
                     <SearchField 
                        categories={['All', 'Business', 'Communication', 'Education', 'Fine Arts', 'Humanities', 'STEM']}
                        setSearch={setSearchedSubject}
                        setCategory={setCategory}
                        title="tutoring subjects"
                     />
                  </div>
                  {fetchingSubjects ? (
                     <PuffLoader color="#006994" />
                  ) : searchedSubject === "" ? (
                     subjects.filter((subject) => {
                        // console.log(`${major.majorCollege} === ${category}: ${major.majorCollege === category}`)
                        return (
                           subject.category === category || category === "All"
                        );
                     }).map((subject, index) => {
                        return (
                           <div id="club-org-listing-plus-info-container">
                              <Fragment key={subject.subject}>
                                 <TutoringSubjectCard
                                    tutoring={subject}
                                    handleSubjectClick={handleSubjectClick}
                                    subjectClicked={subjectClicked}
                                    index={index}
                                 />
                                 {/* {(subjectClicked == major.majorName) ? <MajorSlide id="info-slide" major={major} /> : null} */}
                              </Fragment>
                           </div>
                        );
                     })
                  ) : (
                     subjects.filter((subject) => {
                        return subject.subject
                           .toLowerCase()
                           .startsWith(searchedSubject.toLowerCase());
                     }).filter((Subject) => {
                        // return major.majorCollege.toLowerCase() === category.toLowerCase() || category === "All";
                        return true;
                     }).map((subject, index) => {
                        return (
                           <div id="club-org-listing-plus-info-container">
                              <Fragment key={subject.subject}>
                                 <TutoringSubjectCard
                                    tutoring={subject}
                                    handleSubjectClick={handleSubjectClick}
                                    subjectClicked={subjectClicked}
                                    index={index}
                                 />
                                 {/* {(majorClicked == major.majorName) ? <MajorSlide id="info-slide" major={major} /> : null} */}
                              </Fragment>
                           </div>
                        );
                     })
                  )}
               </div>
            </div>
      </div>
      );
   } else {
      return (
         
         <Fragment>
            <div id="tutoring-field-container" className="container-border">
               <div id="tutoring-back-button">
                  <Button
                     title={"Back To Subjects"}
                     handleButtonClick={handleBackButtonClick}
                  />
               </div>
               <h1 id="tutoring-subject-title">
                  {subjectClicked.subject + " Tutoring Center"}
               </h1>
               {/* "semester schedule" card. should act as a card on the major field with a slider that displays the schedule. */}
               <div id="club-org-listing-plus-info-container">
                  <SubjectScheduleCard
                     handleClick={handleSubjectScheduleCardClick}
                  />
                  {subjectSchedule ? (
                     <ScheduleSlide
                        id="info-slide"
                        schedulePDF={subjectClicked.schedulePDF}
                        scheduleImage={subjectClicked.scheduleImage}
                     />
                  ) : null}
               </div>
               {/* post field. should display posts from this tutoring subjects Hub. should be a small scrolable field. */}
               <div id="club-org-listing-plus-info-container">
                  <SubjectUpdatesCard
                     handleClick={handleSubjectUpdatesCardClick}
                     subject={subjectClicked.subject}
                  />
                  {subjectUpdates ? (
                     <SubjectUpdatesSlide
                        id="info-slide"
                        hubName={subjectClicked.hubName}
                        currentUser={props.currentUser}
                        userID={props.currentUser.userID}
                     />
                  ) : null}
               </div>
               {/* "meet the tutors" card. should act as a card on the major field with a slider that displays all the info pertaining to this tutoring subjects StaffBio. */}
               <div id="club-org-listing-plus-info-container">
                  <StaffBioCard handleClick={handleStaffBioCardClick} />
                  {staffBio ? (
                     <StaffBioSlide
                        id="info-slide"
                        staffBios={subjectClicked.staffBios}
                     />
                  ) : null}
               </div>
            </div>
         </Fragment>
      );
   }
};

export default TutoringFiled;
