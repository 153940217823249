import { React, Fragment } from 'react'
import { adjustTextAreaHeight } from '../../assets/scripts/mchScripts';
import SendIcon from '../messages/toolbar/icons/SendIcon'
import AddFileIcon from '../messages/toolbar/icons/AddFileIcon';

/**
 * @description - This component renders a textarea whose height adjusts. Includes a button to add a single file and a 
 * single image.
 * 
 * @component
 * @param {function} handleSendMessage - A function that triggers when the send icon is clicked.
 * @param {function} handleSetMessage - A function that triggers as the user types that updates a state variable.
 * @returns {JSX.Element} - A react element that renders a textarea whose height adjusts.
 * 
 * @example
 * // Render a textarea whose height adjusts.
 * <ChatTextArea 
 *  handleSendMessage={newSendMessage} 
 *  setMessage={setMessage} 
 *  message={message} 
 * />
 */

const ChatTextArea = (props) => {
    // Need: handleSendMessage, setMessage, message

    const handleSendMessage = () => {
        props.handleSendMessage();
        props.setMessage('')
    }

    return (
        <div id='message-app-input-container' className='make-grid'>
            <div className='message-app-input-icons'>
                <ul>
                    <li className='btn-ease'><AddFileIcon color="#333" /></li>
                </ul>
            </div>
            <textarea onChange={(e) => {props.setMessage(e.target.value)}} onKeyDown={adjustTextAreaHeight} onKeyUp={adjustTextAreaHeight} rows={1} placeholder='Type your message here' />          
            <div id='message-app-send-btn-container' className='message-app-input-icons'>
                <ul>
                    <li className='btn-ease'> <SendIcon onClick={handleSendMessage} width="30px" height="30px" strokeColor="var(--primary-color)"/></li>
                </ul>
            </div>
        </div>
    )
}

export default ChatTextArea