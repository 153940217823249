import { React, useState } from "react";
import axios from "axios";
import getGlobalURL from "../assets/scripts/Global";
import { useNavigate } from "react-router-dom";
import { FoundBadToken } from "../scripts/badToken";

const AccountSettings = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [deleteText, setDeleteText] = useState("");

   let navigate = useNavigate();

   const deleteAccount = (passedID) => {
      console.log("DELETE ACCOUNT METHOD: " + passedID);
      if (passedID === 0) {
         alert("USER CANT BE 0 FOR DELETE ACCOUNT");
      } else if (deleteText == "DELETE") {
         axios.patch(`${url}users/delete`, {
               userID: passedID,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         ).then((response) => {
            if (response.status === 200) {
               alert("ACCOUNT DELETED");
               localStorage.removeItem("token");
               navigate("/");
            } else {
               alert("ACCOUNT NOT DELETED");
            }
         }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error.response.data.err);
            }
         });
      } else {
         alert("You must enter 'DELETE' to confirm account delete request.");
      }
   };

   return (
      <div id="account-settings-container">
         <h3>
            WARNING: This button will delete your account, and there will be no
            way to bring it back!
         </h3>
         <label htmlFor="DeleteEntry">Type DELETE to confirm deletion</label>
         <input
            onChange={(e) => {
               setDeleteText(e.target.value);
            }}
            type="text"
            placeholder="There's no turning back!"
         />
         <button
            onClick={() => {
               deleteAccount(props.currentUser.userID);
            }}
         >
            DELETE ACCOUNT
         </button>
      </div>
   );
};

export default AccountSettings;
