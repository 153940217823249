import { React, Fragment } from 'react'
import "../assets/stylesheets/oceansStyles.css"
import AdminIcon from './icons/AdminIcon'

const OceansButton = (props) => {
  function handleClick() {
    props.handleButtonClick(props.title)
  }

  return (
    <Fragment>
      <div id='oceans-list-container-button-area'>
        <button onClick={handleClick} className={`oceans-btn btn-ease ${props.activeButton === props.title && "active"}`}>{props.title}</button>
        {(props.userOceansAdminList.includes(props.title)) && <AdminIcon className="admin-icon" width="15px" height="15px" color="var(--nav-drawer-active)" />}
      </div>
    </Fragment>
  )
}

export default OceansButton
