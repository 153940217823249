import React, { useState, Fragment } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getGlobalURL from "../assets/scripts/Global";
import ResetPasswordModal from "../Modals/accountModals/ResetPasswordModal";
import PuffLoader from "react-spinners/PuffLoader";
import MyCampusHubsLogo from "../components/logo/MyCampusHubsLogo";
import Footer from "../components/Footer";
import CreateBusinessAccountForm from "../Modals/forms/CreateBusinessAccountForm";

const BusinessLogin = () => {

  // ##################### URL change for Vercel##############################
    const url = getGlobalURL();

    if(url === "https://syncedup-backend.vercel.app/") {
        axios.defaults.withCredentials = true;
    }
    // ##################### END OF URL change for Vercel#######################

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    // When business user clicks 'Create Account' button, this state variable will be used to call the modal
    const [openForm, setOpenForm] = useState(false);
    const [setPasswordModalStatus, setResetPasswordModalStatus] = useState(false);

    //This hook is used once the user logs in. They will be directed to the Dashboard upon successful login
    let navigate = useNavigate();

    // The BusinessLogin function is called when the business user clicks 'Log In' button
    const businessLogin = () => {
        axios.post(`${url}businesses/businessLogin`, {
        username,
        password,
        }).then((response) => {
        if(response.status === 200){
            if(response.data.business.username !== undefined){
                alert("Welcome Back " + response.data.business.username + "!");
                let currentBusiness = response.data.business;
                localStorage.setItem('token', response.data.token);
                navigate("/BusinessPortal", {state: {currentBusiness}});
            }
            else{
            alert("Invalid username or password");
            }
        }
        else{
            alert('Error logging in')
        }
        }).catch((error) => {
        // alert('error logging in')
        alert(`error logging in! ${error.response.data.err}`);
        })
    }

    const handleCreateBusinessClick = () => {
        // Setting modalStatus to true will prevent the short circuiting in the return and allows CreateAccountModal component to render
        setOpenForm(!openForm);
    }

    const handleForgotPasswordClick = () => {
        setResetPasswordModalStatus(true);
    }

    const handleClosePasswordModal = () => {
        setResetPasswordModalStatus(false);
    }

    // This function is called from within CreateAccountModal component
    const closeForm = () => {
        setOpenForm(false);
    }

    return (
        <Fragment>
            {openForm ? <CreateBusinessAccountForm closeForm={closeForm}/> : (
                <Fragment>
                    {setPasswordModalStatus && <ResetPasswordModal closeModal={handleClosePasswordModal}/>}
                    <div id="create-business-account-area">
                        <div id="business-login-container">
                            <div id="business-login-section">
                            <div id="login-container" className="container-border">
                                <div id="login-area">
                                    <MyCampusHubsLogo />
                                    <h3>Business Portal Login</h3>
                                    <input id="username" type="text" required="required" placeholder="Username" onChange={(event) => {
                                        setUsername(event.target.value)
                                    }} />
                                    <input id="password" type="password" required="required" placeholder="Password" onChange={(event) => {
                                        setPassword(event.target.value)
                                    }} onKeyDown={
                                        (event) => {
                                            if (event.key === "Enter") {
                                                businessLogin();
                                            }
                                        }
                                    }/>
                                    <div className="buttonCenter">
                                        <button className="signInButtons" onClick={() => { businessLogin(); }}>Log In</button>
                                    </div>
                                    <a id="forgotPassword" onClick={handleForgotPasswordClick} >Forgot Password?</a>
                                </div>
                                <hr id="line" />
                                <div className="buttonCenter">
                                    <button className="signInButtons" onClick={handleCreateBusinessClick}>{(openForm) ? "Close Form" : "Create Business Account"}</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
            
            <Footer businessUser={null}/>
        </Fragment>
    );

};

export default BusinessLogin;