import React, { useState, Fragment } from 'react'
import NavHeader from '../components/NavHeader'
import OceansList from '../components/OceansList'
import NewPost from '../components/NewPost'
import PostsField from '../components/PostsField'
import ProfileHeader from '../components/ProfileHeader'
import { useLocation } from 'react-router-dom'
import SuggestedOceans from '../components/suggested/SuggestedOceans'
import SuggestedFriends from '../components/suggested/SuggestedFriends'
import Footer from '../components/Footer'
import NavDrawer from '../components/navigation/NavDrawer'
import NewMyAccount from './NewMyAccount'
import MessageApplication from '../components/messages/MessageApplication'
import FeedbackForm from '../Modals/forms/FeedbackForm'

const Profile = () => {
  let location = useLocation();
  let currentUser = location.state.currentUser;

  const [oceanSelected, setOceanSelected] = useState(currentUser.oceans[0]);
  const [postMade, setPostMade] = useState(false);
  const [activeButton, setActiveButton] = useState(currentUser.oceans[0]);
  const [oceanCreated, setOceanCreated] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openNavDrawer, setOpenNavDrawer] = useState(true);
  const [openMyAccount, setOpenMyAccount] = useState(false);
  const [openMessagesApp, setOpenMessagesApp] = useState(location.state.openMessagesApp !== null ? location.state.openMessagesApp : false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [messageAppCurrentlyOpen, setMessageAppCurrentlyOpen] = useState(false);
  const [tabSelected, setTabSelected] = useState("Profile");


  const onSearchedOcean = (ocean) => {
    console.log(ocean);
  }

  const buttonSelection = (buttonSelected) => {
    setOceanSelected(buttonSelected);
    setActiveButton(buttonSelected);
  }

  const handlePostMade = () => {
    setPostMade(!postMade);
  }

  const handleOceanCreated = () => {
    setOceanCreated(!oceanCreated);
  }

  const handleOpenNavDrawer = () => {
		setOpenNavDrawer(!openNavDrawer);
	};

  const handleOpenNotifications = () => {
		setOpenNotifications(!openNotifications);
	};

  const handleOpenMessagesApp = () => {
		if(openMessagesApp === false || openMessagesApp === undefined) {
			setOpenNavDrawer(false);
		} else {
			setOpenNavDrawer(true);
		}
		setOpenMessagesApp(!openMessagesApp);
	};

  const handleCloseMessage = () => {
		setMessageAppCurrentlyOpen(false);
	};

  const handleMessageChatOpen = () => {

		setMessageAppCurrentlyOpen(true);
	};

  return (
    <Fragment>
      <NavHeader 
        currentUser={currentUser} 
        source="Profile" 
        handleOpenNavDrawer={handleOpenNavDrawer}
        openNotifications={openNotifications}
        handleOpenNotifications={handleOpenNotifications}
        setOpenNotifications={setOpenNotifications}
        messageAppCurrentlyOpen={messageAppCurrentlyOpen}
        handleOpenMessagesApp={handleOpenMessagesApp}
      />
      {openNavDrawer && <NavDrawer 
                          currentUser={currentUser} 
                          handleOpenNavDrawer={handleOpenNavDrawer} 
                          handleButtonSelection={buttonSelection} 
                          activeButton={activeButton} 
                          activeTab={tabSelected}
                          setOpenMyAccount={setOpenMyAccount}
                          setOpenFeedback={setOpenFeedback}
                          source="Profile"
                        />
      }

      { openMyAccount ? (<NewMyAccount currentUser={currentUser} />) : 
        openMessagesApp ? (
          <MessageApplication
              handleCloseMessage={handleCloseMessage}
              handleMessageChatOpen={handleMessageChatOpen}
              currentUser={currentUser}
              handleOpenMessagesApp={handleOpenMessagesApp}
          />
        ) : 
      (
        <Fragment>
          <div id='main-panel' className='make-grid' onClick={() => setOpenNotifications(false)}>
            <div id='left-panel' className='padding-top-80'>
              { !openNavDrawer && (
								<Fragment>
									<SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated} />
									<SuggestedFriends currentUser={currentUser} />
								</Fragment>
							)}
            </div>
            <div id="center-panel" className='padding-top-80'>
              {
                openFeedback ? (
                <FeedbackForm currentUser={currentUser} setOpenFeedback={setOpenFeedback} />
              ) : (
                  <Fragment>
                    <ProfileHeader currentUser={currentUser} />
                    <NewPost currentUser={currentUser} oceanSelected={oceanSelected} handlePostMade={handlePostMade} />
                    <PostsField oceanSelected={oceanSelected} userID={currentUser.userID} currentUser={currentUser} postMade={postMade} source="profile" />
                  </Fragment>
              )
              }
            </div>
            <div id='right-panel' className='padding-top-80'>
              { openNavDrawer && (
								<Fragment>
									<SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated} />
									<SuggestedFriends currentUser={currentUser} />
								</Fragment>
							)}
            </div>
          </div>
          <Footer currentUser={currentUser}/>
        </Fragment>
      )}
    </Fragment>
  )
}

export default Profile