import "../../assets/stylesheets/oceansStyles.css"
import axios from "axios";
import getGlobalURL from "../../assets/scripts/Global";
import MoonLoader from "react-spinners/MoonLoader";
import { useState, Fragment } from "react";

const ResetPasswordModal = (props) => {
    const url = getGlobalURL();
    if (url === "https://oceans-server-prod-v1.vercel.app") { axios.defaults.withCredentials = true; }

    // * first we get the username and then send an email with a code
    const [username, setUsername] = useState("");
    const [sentEmail, setSentEmail] = useState(false);

    const [calling, setCalling] = useState(false);
    
    // * then we check the code to see if its correct
    const [code, setCode] = useState();
    const [checkingCode, setCheckingCode] = useState(false);
    const [hasCheckedCode, setHasCheckedCode] = useState(false);

    // * then we set the new password
    const [password, setPassword] = useState("");
    const [settingPassword, setSettingPassword] = useState(false);
    const [hasSetPassword, setHasSetPassword] = useState(false);

    const handleRequestCode = async() =>{
        // TODO: make axios call to users/resetPassword
        setCalling(true);
        axios.patch(`${url}users/resetPassword`, {
            username: username
        }).then((response) => {
            if(response.status === 200){
                alert('email sent');
                setSentEmail(true);
                setCalling(false);
            }
        }).catch((error) => {
            alert(error.response.data.err)
            setCalling(false);
        })
    }

    const handleCheckCode = async() =>{
        // TODO: make axios call to users/checkCode
        setCalling(true);
        axios.post(`${url}users/checkCode`, {
            username: username,
            code: code
        }).then((response) => {
            if(response.status === 200){
                setCheckingCode(true);
                setCalling(false);
            }
        }).catch((error) => {
            alert(error.response.data.err)
            setCalling(false);
        })
    }

    const handleSetPassword = async() =>{
        // TODO: make axios call to users/updatePassword
        setCalling(true);
        axios.patch(`${url}users/updatePassword`, {
            username: username,
            password: password
        }).then((response) => {
            if(response.status === 200){
                alert('password updated');
                setSettingPassword(true);
                setCalling(false);
                props.closeModal();
            }
        }).catch((error) => {
            alert(error.response.data.err)
            setCalling(false);
        })
    }

    return (
        <div className='modal-container' >
            <div className='modal-backdrop' onClick={props.closeModal} />
            <div className='create-user-modal-content'>
                <div id='create-user-form-container'>
                    <div id='modal-title' className='make-grid'>
                        <h1>Reset Your Password</h1>
                        <h4>I feel like theres a play on words we can make here</h4>
                    </div>
                    {calling ? (
                        <MoonLoader color="#006994" />
                    ) : (
                        <div>
                            <div id='password-inputs'>
                        {!sentEmail ? (<input className='create-user-input' type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />) : null}
                        {sentEmail && !checkingCode ? (<input className='create-user-input' type="number" placeholder="Code" onChange={(e) => setCode(e.target.value)} />) : null}
                        {sentEmail && checkingCode && !settingPassword ? (<input className='create-user-input' type="password" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} />) : null}
                    </div>

                    {/*HOW SHOULD WE ALLOW THEM TO SELECT OCEANS? MINI SEARCH BAR? OUTPUT ALL OCEANS WITH CHECKBOXES?*/}
            
                        <div id='form-radios' className='make-grid'>
                            {!sentEmail ? (<h4>Enter the username associated with your account</h4>) : null}
                            {sentEmail && !checkingCode ? (<h4>Enter the code sent to your email</h4>) : null}
                            {sentEmail && checkingCode && !settingPassword ? (<h4>Enter your new password</h4>) : null}
                            <div id='radios-area' className='make-grid'>
                            </div>
                        </div>
                        </div>
                    )}
                    {!sentEmail ? (<button className='create-user-btn btn-ease' type="button" onClick={handleRequestCode}>Submit Username</button>) : null}
                    {sentEmail && !checkingCode ? (<button className='create-user-btn btn-ease' type="button" onClick={handleCheckCode}>Check Code</button>) : null}
                    {sentEmail && checkingCode && !settingPassword ? (<button className='create-user-btn btn-ease' type="button" onClick={handleSetPassword}>Reset Password</button>) : null}
                </div>
            </div>
        </div>  
        )
}

export default ResetPasswordModal;