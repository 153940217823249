import React from 'react'

const MyAccountIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        viewBox="0 0 24 24"
        {...props}
    >
        <title>{"User"}</title>
        <g
            fill="none"
            fillRule="evenodd"
            stroke={props.color}
            strokeDasharray="0,0"
            strokeLinecap="round"
            strokeWidth={2}
        >
            <path d="M12 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM6 20a6 6 0 1 1 12 0h0" />
        </g>
    </svg>
)

export default MyAccountIcon