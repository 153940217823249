import getGlobalURL from '../../assets/scripts/Global'
import {React, useState} from 'react'
import axios from 'axios'
import FormCancelSubmitBtns from './FormCancelSubmitBtns'
import TextAreaWithObject from '../../components/atoms/TextAreaWithObject'
import RadialWithObject from '../../components/atoms/RadialWithObject'

const FeedbackForm = (props) => {
    const url = getGlobalURL();
    const [feedbackData, setFeedbackData] = useState({feedback: ""})
    const [selectedOption, setSelectedOption] = useState("General Feedback");
    const [focussedField, setFocussedField] = useState(null);

    const instructionText = "We value your input! Use this form to share your thoughts, report any bugs, or suggest improvements. Your feedback helps us improve and provide a better experience for everyone. Select one of the options below to guide your feedback."

    const sendFeedback = () => {
        if (feedbackData.feedback !== "") {
            axios.post(`${url}feedback/addFeedback`, {
                userID: props.userID,
                feedbackType: selectedOption,
                feedback: feedbackData.feedback
            }, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                }
            }).then((res) => {
                if (res.status === 200) {
                    alert(`Feedback sent! response: ${JSON.stringify(res.data)}`)
                    props.setOpenFeedback((prevState) => !prevState)
                }
            }).catch((err) => {
                console.log(err)
            })
        } else {
            alert("Please enter feedback before submitting.")
        }
    }

    const closeForm = () => {
        props.setOpenFeedback((prevState) => !prevState)
    }

    const handleFocus = (focussedOn) => {
        setFocussedField(focussedOn);
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className="new-form-container container-border">
            <div className="new-form-header">New Feedback Form</div>
            <div className="new-form-body">
                <p className="form-instruction-text">{instructionText}</p>
                <hr className="hr-custom"/>

                <div className='new-form-radial-btns-area'>
                    <h3>Please select the type of feedback:</h3>
                    <div className='radial-btn-and-label'>
                        <input type="radio" id="generalFeedback" name="feedbackType" value="General Feedback" checked={selectedOption === 'General Feedback'} onChange={(e) => handleOptionChange(e)} />
                        <label htmlFor="generalFeedback">General Feedback</label>
                    </div>

                    <div className='radial-btn-and-label'>
                        <input type="radio" id="reportBug" name="feedbackType" value="Report Bug" checked={selectedOption === 'Report Bug'} onChange={(e) => handleOptionChange(e)} />
                        <label htmlFor="reportBug">Report Bug</label>
                    </div>

                    <div className='radial-btn-and-label'>
                        <input type="radio" id="improvement" name="feedbackType" value="Improvement" checked={selectedOption === 'Improvement'} onChange={(e) => handleOptionChange(e)} />
                        <label htmlFor="improvement">Improvement</label>
                    </div>
                </div>

                <TextAreaWithObject handleFocus={handleFocus} forText="feedback" labelText="Feedback:" helperText="Please be as specific as possible" data={feedbackData} setData={setFeedbackData}/>
                <FormCancelSubmitBtns handleCloseForm={closeForm} closeText="Cancel" handleSubmit={sendFeedback} submitText="Send"/>
            </div>
        </div>
    )
}

export default FeedbackForm