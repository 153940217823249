import getGlobalURL from "../../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import GreekOrg from "./GreekOrg";
import GreekOrgSlide from "./GreekOrgSlide";
import $ from "jquery"
import SearchField from "../../helperComponents/SearchField";
import DefaultBanner from "../../../assets/images/directory_banner.jpg";

const GreekLifeField = () => {
    const url = getGlobalURL();
    const [searchedOrg, setSearchedOrg] = useState("");
    const [category, setCategory] = useState("All");
    const [orgClicked, setOrgClicked] = useState("");
    const tempDescription = "Welcome to the Greek Life section! Here, you can explore the dynamic and supportive community of fraternities and sororities at our university. Whether you're seeking lifelong friendships, leadership opportunities, or ways to get involved on campus, Greek Life offers a rich and rewarding experience. Browse through comprehensive profiles of each fraternity and sorority, including their missions, values, and key activities. You’ll find essential contact information, social media links, and details on how to join or get more involved. Get to know the diverse organizations that make up our Greek community and discover the unique opportunities each one offers. Connect with a chapter that aligns with your values and interests and start building connections that last a lifetime!"


    const handleOrgClick = (orgClicked) => {
        setOrgClicked(orgClicked);
        $('#info-slide').slideToggle("fast", "swing");
    }

    const [orgs, setOrgs] = useState([]);

    useEffect(() => {
        axios.get(url + "greekorg/getGreekOrgs").then((response) => {
            if(response.status === 200) {
                setOrgs(response.data);
            }
        }).catch((error) => {
            alert(error);
        })
    }, [])

    useEffect(() => {
        $('#info-slide').slideToggle("fast", "swing");
    }, [orgClicked])

    return (
        <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src={DefaultBanner}/>
            <div className="content-placeholder-text-area">
                <h2 className='highlight'>Explore Greek Life</h2>
                <p>{tempDescription}</p>
            </div>
            <div id="cards-field-container">
                <div id="search-orgs-container">
                    <SearchField 
                        categories={['All', 'Fraternity', 'Sorority', 'Interfraternity Council (IFC)', 'National Pan-Hellenic Council (NPHC)', 'Panhellenic Association (PHA)', 'Multicultural Greek Council (MGC)', 'Independent Greek Council (IGC)', 'Suspended']}
                        setCategory={setCategory}
                        setSearch={setSearchedOrg}
                        title="greek organizations"
                    />                
                </div>
                {orgs.filter((org) => {
                    switch(category){
                        case "All":
                            return true;
                        
                        case "Fraternity":
                            return org.fraternity === true;
                        
                        case "Sorority":
                            return org.fraternity === false;
                        
                        case "Suspended":
                            return org.suspension === true;
                        
                        default:
                            return org.governingCouncil === category;
                    }
                }).filter((org) => {
                    // reding proporties of undefined on org.organization.toLowerCase()
                    // return org.chapter.toLowerCase().startsWith(searchedOrg.toLowerCase()) || org.organization.toLowerCase().startsWith(searchedOrg.toLowerCase());
                    return org.chapter.toLowerCase().startsWith(searchedOrg.toLowerCase());
                }).map((org, index) => {
                    return (
                        <div key={index} id='club-org-listing-plus-info-container'>
                            <GreekOrg greekOrg={org} handleOrgClicked={handleOrgClick} index={index}/>
                            {(orgClicked == org.chapter) ? <GreekOrgSlide id="info-slide" org={org}/> : null}
                        </div>
                    );
                })}
            </div>
        </div>
    )
};

export default GreekLifeField;
