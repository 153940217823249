import { React, useState, useEffect } from "react";
import { FoundBadToken } from "../../../../scripts/badToken";
import axios from "axios";
import getGlobalURL from "../../../../assets/scripts/Global";
import FormCancelSubmitBtns from "../../../../Modals/forms/FormCancelSubmitBtns";
import InputWithObject from "../../../atoms/InputWithObject";
import ConvertToBase64Simple from "../../../helperComponents/ConvertToBase64Simple";
import { storage } from "../../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const FG_EditModal = (props) => {
    const url = getGlobalURL();
    const [groupName, setGroupName] = useState(props.group.groupName);
    const [groupIconFile, setGroupIconFile] = useState(null);
    const [groupIconPreview, setGroupIconPreview] = useState(props.group.groupIcon);

    const updateGroup = async () => {
        axios.patch(`${url}friendGroups/updateGroup`, {
            groupID: props.group.groupID,
            groupName: groupName,
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.group.groupName = groupName;
            props.updateGroup(props.group);
            props.closeModal();
        }).catch((error) => {
            FoundBadToken(error);
        })
    }

    return (
        <div className='modal-container' >
            <div className='sg-backdrop' onClick={() => {props.closeModal()}} />
            <div className='friend-group-modal-container border-radius-25 make-grid'>
                <div id='friend-group-modal-header' className='form-header'>Create A Group Chat</div>
                <div id='friend-group-modal-body' >
                    {/* <div id='create-account-form-lc' >
                        <div className='form-body-img-area border-radius-10' >
                            <img src={groupIconPreview} alt="Group Icon" />
                            <ConvertToBase64Simple textID="banner-1" setFile={setGroupIconFile} setImage={setGroupIconPreview} />
                        </div>
                    </div> */}
                    <div id='friend-group-modal-rc' >
                        <div>
                            <div className='form-body form-body-override' >
                                <label htmlFor='studyGroupName'>* Group Name</label>
                                <input type='text' id='studyGroupName' value={groupName} onChange={(e) => {setGroupName(e.target.value)}} />
                                <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={updateGroup} submitText='Update Group' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='' >
                    {/* {mutuals.map((mutual, index) => {
                        return (
                            <Fragment>
                                <div className='mutual-listing' >
                                    <img src={mutual.profileImage} />
                                    <h3>{mutual.fName + " " + mutual.lName}</h3>
                                    <div className='mutual-listing-user-info' >
                                        <input type='checkbox' onChange={((e) => { handleCheck(mutual.userID, e.target.checked) })} />
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })} */}
                </div>
                <div className='friend-group-button-area' >
                    {/* <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={createFriendGroup} submitText='Create Group' /> */}
                </div>
            </div>
        </div>
    )
}

export default FG_EditModal;

// {mutuals.map((mutual, index) => {
//     return (
//         <Fragment>
//             <div className='mutual-container' >
//                 <div className='mutual-listing' >
//                     <img src={mutual.profileImage} />
//                     <h3>{mutual.fName + " " + mutual.lName}</h3>
//                     <div className='mutual-listing-user-info' >
//                         {/* <p>@{mutual.username}</p> */}
//                         <input type='checkbox' onChange={((e) => { handleCheck(mutual.userID, e.target.value) })} />
//                     </div>
//                 </div>
//             </div>
//         </Fragment>
//     )
// })}