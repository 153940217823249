import { React, useState, useEffect } from 'react';
import { FoundBadToken } from '../../scripts/badToken';
import axios from 'axios';
import getGlobalURL from '../../assets/scripts/Global';
import FormCancelSubmitBtns from '../../Modals/forms/FormCancelSubmitBtns';
import InputWithObject from '../atoms/InputWithObject';
import ConvertToBase64Simple from '../helperComponents/ConvertToBase64Simple';
import {storage} from "../../firebase";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import GraphiteBackground from '../../assets/images/tu_graphite_card_background_banner.png'

const CreateClubModal = (props) => {
    const url = getGlobalURL();
    const [clubName, setClubName] = useState('')
    const [clubDescription, setClubDescription] = useState('')
    const [groupIconImage, setGroupIconImage] = useState(null)
    const [groupIconPreview, setGroupIconPreview] = useState(GraphiteBackground)

    const createClub = async () => {
        if(!clubName){
            alert('Please fill out all required fields');
            return;
        }
        let clubID = Date.now();
        let clubBanner = await uploadClubBanner(clubID)
        let club = {
            president: props.currentUser.userID,
            members: [props.currentUser.userID],
            admins: [props.currentUser.userID],
            officers: [],
            posts: [],
            description: clubDescription,
            clubName: clubName.clubName,
            clubBanner: clubBanner,
            clubID: clubID,
            createdDate: clubID,
            events: [],
            tags: [],
        }
        axios.post(`${url}clubs/createClub`, {
            userID: props.currentUser.userID,
            clubName: clubName.clubName,
            clubID: clubID,
            club: club
        }, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            }
        }).then((reponse) => {
            if(reponse.status === 200){
                props.addClub(club);
                props.currentUser.clubs.push(clubName.clubName)
                alert('Club Created');
                props.closeModal();
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                FoundBadToken();
            } else {
                alert(error.response.data.err);
            }
        })
    }

    async function uploadClubBanner(clubID){
        if(!groupIconImage){
            return '';
        }
        else{
            const iconRef = ref(storage, `clubBanners/${clubID}`);
            return new Promise((resolve, reject) => {
                uploadBytes(iconRef, groupIconImage).then((response) => {
                    getDownloadURL(iconRef).then((url) => {
                        resolve(url);
                    })
                })
            })
        }
    }

    return (
        <div className='modal-container' >
            <div className='sg-backdrop' onClick={() => {props.closeModal()}} />
            <div className='create-account-form-2c-container border-radius-25 make-grid'>
                <div id='create-account-form-2c-header' className='form-header'>Create a Study Group</div>
                <div id='create-account-form-main-body' >
                    <div id='create-account-form-lc' >
                        <div className='form-body-img-area border-radius-10' >
                            <img src={groupIconPreview} alt="Group Icon" />
                            <ConvertToBase64Simple textID="banner-1" setFile={setGroupIconImage} setImage={setGroupIconPreview} />
                        </div>
                    </div>
                    <div id='create-account-form-rc' >
                        <div id='create-account-2c-form-container' >
                            <div className='form-body form-body-override' >
                                <InputWithObject handleFocus={() => {}} type='text' required={true} forText='clubName' labelText='Club Name' date={clubName} setData={setClubName} />
                                {/* <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupCategory' labelText='Group Category' helperText='Subject (e.g COSC)' date={groupCategory} setData={setGroupCategory} /> */}
                                <label htmlFor='clubDescription'>Club Description</label>
                                <textarea id='clubDescription' value={clubDescription} onChange={(e) => {setClubDescription(e.target.value)}} />
                                <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={createClub} submitText='Create Club' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateClubModal;