import React from 'react'
import CategoryFilter from './CategoryFilter';

/**
 * @description - A container that displays a search field and a filter-by-category menu.
 * 
 * @component
 * @param {array} categories - An array of categories
 * @param {function} setCategory - A function that sets the category state
 * @param {function} setSearch - A function that sets the search state
 * @param {string} title - The title of the source (e.g. "departments", "majors", etc.)
 * @returns {JSX.Element} - A container that displays a search field and a filter-by-category menu
 * 
 * @example
 * // Renders a container that displays a search field and a filter-by-category menu
 * <SearchField
 *  categories={["All", "General Knowledge & Research"]} 
 *  setCategory={setCategory} 
 *  setSearch={setSearch}
 *  title="helpful tools"
 * />                
 */

const SearchField = (props) => {
    return (
        <div id='orgs-search-container' className='container-border' >
            <div className='orgs-search-field border-radius-15' >
            <input type='text' placeholder={`🔍 search ${props.title}`} className="clubs-search-bar" onChange={(e) => {
                props.setSearch(e.target.value);
            }} />
            </div>
            <CategoryFilter categories={props.categories} setCategory={props.setCategory} />
        </div>
    )
}

export default SearchField