import { React, useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import getGlobalURL from '../../../../assets/scripts/Global'
import AcceptIcon from '../../../icons/AcceptIcon'
import RejectIcon from '../../../icons/RejectIcon'
import { FoundBadToken } from '../../../../scripts/badToken'

const FG_InvitesModal = (props) => {

    const [acceptHover, setAcceptHover] = useState(false)
    const [rejectHover, setRejectHover] = useState(false)

    const url = getGlobalURL();
    const [groupInvites, setGroupInvites] = useState([]);

    useEffect(() => {
        axios.get(`${url}friendGroups/getFriendGroupRequests/${props.currentUser.userID}`, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            setGroupInvites(response.data)
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }, [])

    const handleJoinGroup = (group) => {
        axios.patch(`${url}friendGroups/acceptFriendGroup`, {
            groupID: group.groupID,
            userID: props.currentUser.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.currentUser.friendGroupChats.push(group.groupID);
            props.currentUser.friendGroupRequests = props.currentUser.friendGroupRequests.filter((groupID) => groupID !== group.groupID);
            sendRealTimeMessage(group.groupID);
            sendAddMember(group.groupID);
            props.addGroup(response.data.group);
            props.closeModal();
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    const handleRejectGroup = (group) => {
        axios.patch(`${url}friendGroups/rejectFriendGroup`, {
            groupID: group.groupID,
            userID: props.currentUser.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.currentUser.friendGroupRequests = props.currentUser.friendGroupRequests.filter((groupID) => groupID !== group.groupID);
            let newGroupInvites = groupInvites;
            newGroupInvites = newGroupInvites.filter((invite) => invite.groupID !== group.groupID);
            setGroupInvites(newGroupInvites);
            // props.closeModal();
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    function sendRealTimeMessage(groupID){
        let channel = props.ably.channels.get(`friend-group-${groupID}`);
        channel.publish('new-message', {
            groupID: groupID,
            message: {
                userID: props.currentUser.userID,
                profileImage: props.currentUser.profileImage,
                userName: props.currentUser.fName + ' ' + props.currentUser.lName,
                messageContent: 'joined the group',
                date: Date.now(),
                messageType: 'system'
            }
        })
    }

    function sendAddMember(groupID){
        let channel = props.ably.channels.get(`friend-group-${groupID}`);
        channel.publish('add-member', {
            groupID: groupID,
            member: {
                userID: props.currentUser.userID,
                profileImage: props.currentUser.profileImage,
                userName: props.currentUser.fName + ' ' + props.currentUser.lName
            }
        })
    }

    return (
        <div className='modal-container' >
            <div className='sg-backdrop' onClick={() => { props.closeModal() }} />
            <div className='create-account-form-2c-container border-radius-25 make-grid'>
                <div id='create-account-form-2c-header' className='form-header'>Your Invites</div>
                <div id='create-account-form-main-body' >
                    {groupInvites && groupInvites.map((group, index) => {
                        return (
                            <div className='group-listing-container' >
                                <p style={{fontSize:'1.2em'}} >Matt Dibbern invited you to: {group.groupName}</p>
                                <div className='action-button-container' >
                                    <AcceptIcon width='25' height='25' fill={acceptHover ? 'limegreen' : 'black'} onClick={() => { handleJoinGroup(group) }} onMouseEnter={() => { setAcceptHover(true) }} onMouseLeave={() => { setAcceptHover(false) }} />
                                    <RejectIcon width='21' height='21' fill={rejectHover ? 'red' : 'black'} onClick={() => { handleRejectGroup(group) }} onMouseEnter={() => { setRejectHover(true) }} onMouseLeave={() => { setRejectHover(false) }} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FG_InvitesModal;