import React, { useState, useEffect, useContext } from 'react'
import { getElapsedTime } from '../../../assets/scripts/mchScripts'
import FG_Icon from '../../icons/FG_Icon'
import { AblyContext } from '../../../assets/custom-hooks/AblyContext'

const DisplayMessage = (props) => {
    const [numMessages, setNumMessages] = useState()

    const { reduceNumMessages, markAsRead } = useContext(AblyContext)

    const handleSetMessagesToZero = () => {
        props.group.members.forEach((member) => {
            if(member.userID === props.currentUser.userID){
                member.missedMessages = 0;
            }
        })
    }

    useEffect(() => {
        props.group.members.forEach((member) => {
            if(member.userID === props.currentUser.userID){
                setNumMessages(member.missedMessages);
            }
        })
    }, [props.group.messages.length])

    const showTime = () => {
        return props.group.messages[props.group.messages.length - 1] ? (
            getElapsedTime(props.group.messages[props.group.messages.length - 1].date)
        ) : 'N/A'
    }

    const getLastMessage = () => {
        return props.group.messages[props.group.messages.length - 1].userID === props.currentUser.userID ? (
            props.group.messages[props.group.messages.length - 1].messageType === 'system' ? (
                'You ' + props.group.messages[props.group.messages.length - 1].messageContent
            ) : (
                <><strong>You: </strong> {props.group.messages[props.group.messages.length - 1].messageContent} </>
            )
        ) : (
            props.group.messages[props.group.messages.length - 1].messageType === 'system' ? (
                `${props.group.messages[props.group.messages.length - 1].userName} ` + props.group.messages[props.group.messages.length - 1].messageContent
            ) : (
                <><strong>{props.group.messages[props.group.messages.length - 1].userName}</strong>: {props.group.messages[props.group.messages.length - 1].messageContent}</>
            )
        )
    }

    return (
        <div className={numMessages > 0 ? 'message-area-unseen' : 'message-area' } >
            <div onClick={() => {
                if(numMessages > 0){
                    reduceNumMessages(numMessages);
                    markAsRead(props.currentUser.userID, props.group.groupID, sessionStorage.getItem('token'), 'friendGroups');
                    setNumMessages(0);
                    handleSetMessagesToZero();
                }
                props.handleSelectedGroupChat(props.index);
            }} >
                <div className='message-container make-grid' >
                    <div className='message-container-fg-icon' >
                        <FG_Icon height='35' width='35' />
                    </div>
                    <div className='message-text-area make-grid' >
                        <h2>{props.group.groupName}</h2>
                        <span id='message-content' >{getLastMessage()}</span>
                    </div>
                    <div className='message-details make-grid' >
                        <p>{showTime()}</p>
                        <div className='message-counter-container' >
                            { numMessages > 0 ? (
                                <div className='message-counter' >
                                    <p>{numMessages}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisplayMessage;