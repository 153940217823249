import { React, Fragment, useState } from 'react'
import ManageMembersModal from './controlsModals/ManageMembersModal';



const ModsControls = (props) => {
    const [membersListModalStatus, setMembersListModalStatus] = useState(false);
    const handleModalRequestClick = (source) => {
        if (source === "manageMembers") {
            setMembersListModalStatus(true);
        }
    }
    
    const closeModal = (source) => {
        if (source === "manageMembers") {
            setMembersListModalStatus(false);
        }
    }

    return (
        <Fragment>
            {membersListModalStatus && <ManageMembersModal ocean={props.ocean} currentUser={props.currentUser} closeModal={closeModal} />}
            <div id="oceans-list-banner-container">
                <div id='oceans-list-banner-title-container'>
                    <div className="oceans-list-banner-title-tab tab-active">Moderator</div>
                </div>
                <div id="oceans-list-banner-links-container" className="container-border">
                    <div id='controls-container'>
                        <button onClick={() => { handleModalRequestClick("manageMembers") }} className='oceans-btn btn-ease'>Manage Members</button>
                        <button onClick={() => { handleModalRequestClick("deletePosts") }} className='oceans-btn btn-ease'>Delete Posts</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ModsControls