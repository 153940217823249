import getGlobalURL from "../assets/scripts/Global";
import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/stylesheets/oceansStyles.css";
import axios from "axios";
import MyCampusHubsEmblem from "./logo/MyCampusHubsEmblem.jsx";
import PersonalCalendar from "../Modals/calendarModals/PersonalCalendar";
import { isMobile } from "react-device-detect";
import MessagesDropDownMenu from "./messages/MessagesDropDownMenu.jsx";
import { FoundBadToken } from "../scripts/badToken.js";
import NotificationsField from "./notifications/NotificationsField.jsx";
import IconCounter from "./atoms/IconCount.jsx";
import IconWithCounter from "./atoms/IconWithCounter.jsx";
import MenuIcon from "./icons/MenuIcon.jsx";
import { AblyContext } from "../assets/custom-hooks/AblyContext.js";

const NavHeader = (props) => {
	// This component populates the top navigation neader section within all pages (excluding Login)
	// Incoming props from the Dashboard page: 'User object', and a pointer to 'onSearchedOcean', which
	// returns the ocean that the user selected within the search bar.

	// ##################### URL change for Vercel##############################
	const url = getGlobalURL();
	const { ably, numMessages, numNotifications, setNotificationsRead } = useContext(AblyContext);
	let userChannel = useRef()
	// ##################### END OF URL change for Vercel#######################

	// When the home button is clicked, the page will refresh to the Dashboard
	let navigate = useNavigate();

	// State variable to keep track of what the user is typing
	const [searchedOcean, setSearchedOcean] = useState("");

	// State variable stores the array of available oceans returned by the route call within useEffect
	const [oceans, setOceans] = useState([]);

	// state variable stores the array of users returned by the route call within useEffect
	const [users, setUsers] = useState([]);

	//const [modePreference, setModePreference] = useState(props.currentUser.modePreference);

	const [calendarClicked, setCalendarClicked] = useState(false);

	const [numberOfMessages, setNumMessages] = useState(0);
	const [numberOfNotifications, setNumNotifications] = useState(0);

	// * gets number of missed messages
	useEffect(() => {
		setNumMessages(numMessages)
	}, [numMessages]);

	// * gets number of missed notifications
	useEffect(() => {
		setNumNotifications(numNotifications)
	}, [numNotifications])

	// * this triggers when a new ocean is created`
	useEffect(() => {
		// GET the full array of oceans and users available in the Database, which will be used in the search bar.
		axios.get(`${url}oceans`, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			},
		}).then((response) => {
			setOceans(response.data);
		}).catch((error) => {
			if (error.code === "ERR_BAD_REQUEST") {
				FoundBadToken();
			} else {
				alert(error.response.data.err);
			}
		});
	}, [props.oceanCreated]);

	// * this only runs once since new the user cannot create a new user
	useEffect(() => {
		axios.get(`${url}users/users`, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			},
		}).then((response) => {
			setUsers(response.data);
		}).catch((error) => {
			if (error.code === "ERR_BAD_REQUEST") {
				FoundBadToken();
			} else {
				alert(error.response.data.err);
			}
		});
	}, []);

	const handleHomeClick = () => {
		// When the user clicks the 'home icon', route to the Dashboard page with the user object
		navigate("/Dashboard", { state: { currentUser: props.currentUser, activeTab: "Dashboard" } });
	};

	const handleOpenMessagesAppClick = () => {
		props.setOpenNotifications(false);
		// setMessagesOpen(false);
		props.handleOpenMessagesApp();
		
		// if (props.source === "Dashboard") {
		// 	props.handleOpenMessagesApp();
		// } else {
		// 	navigate("/Dashboard", {
		// 		state: { currentUser: props.currentUser, openMessagesApp: true },
		// 	});
		// }
	};

	const handleLDMode = () => {
		// When the user clicks the 'light/dark mode icon', toggle the LD mode.
		document.body.classList.toggle("dark-theme");
		document.querySelector(".container-border").classList.toggle("dark-theme");
	};

	const onSearchUsername = (username) => {
		axios.get(`${url}users/getUserByUsername/${username}/${props.currentUser.userID}`, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			},
		}).then((response) => {
			navigate("/DisplayUser", {
				state: { currentUser: props.currentUser, foreignUser: response.data },
			});
		}).catch((error) => {
			if (error.code === "ERR_BAD_REQUEST") {
				FoundBadToken();
			} else {
				alert(error.response.data.err);
			}
		})
	};

	const handleOpenCalendar = () => {
		props.setOpenNotifications(false);
		setCalendarClicked(true);
	};

	const handleCloseCalendar = () => {
		setCalendarClicked(false);
	};

	function onSearchedOcean(oceanClicked) {
		// Receives the ocean that the user searched for within the search bar. Route to the ocean's page
		let oceanToGoTo = oceans.find((ocean) => ocean.oceanName === oceanClicked);
		navigate("/OceansHome", { state: {currentUser: props.currentUser, oceanSelected: oceanClicked, ocean: oceanToGoTo} });
	}

	if (isMobile) {
		return (
			<Fragment>
				{calendarClicked && (<PersonalCalendar closeModal={handleCloseCalendar} currentUser={props.currentUser} />)}
				<div id="main-nav-container" className="container-border make-grid">
					<div id="nav" className="make-grid">
						<MyCampusHubsEmblem width="25px"/>
						<ul>
							<li>
								<img onClick={handleHomeClick} src={require("../assets/images/icons/home.png")} className="btn-ease" alt="home icon" />
							</li>
							<li>
								<img onClick={handleLDMode} className="btn-toggle btn-ease" src={require("../assets/images/icons/light_dark.png")} alt="light-dark mode button icon" />
							</li>
						</ul>
					</div>
					<div id="main-nav-search-bar-area">
						<div id="main-nav-search-bar-container">
							<div className="search-dropdown">
								<input
									type="text"
									placeholder="🔍 Search Hubs"
									className="search-bar"
									onChange={(event) => {
										setSearchedOcean(event.target.value);
									}}
								/>
								<div id="dropdown-content" className="container-border">
									{searchedOcean === "" ? null 
										: searchedOcean.charAt(0) === "@" ? users.filter((user) => {
												let username = "@" + user.username;
												return username.toLowerCase().includes(searchedOcean.toLowerCase().substring());
											}).slice(0, 5).map((user) => {
												return (
													<div key={user.userID}>
														<button onClick={() => {onSearchUsername(user.username)}}>{user.username}</button>
													</div>
												);
											})
											: oceans.filter((ocean) => ocean.oceanName.toLowerCase().includes(searchedOcean.toLowerCase())).slice(0, 5).map((ocean) => {
												return (
													<div key={ocean.oceanID}>
														<button onClick={() => {onSearchedOcean(ocean.oceanName)}}>{ocean.oceanName}</button>
													</div>
												);
											})
									}
								</div>
							</div>
						</div>
					</div>

					<div id="main-nav-user-alerts-container" className="make-grid">
						<ul>
							<li>
								<img onClick={props.handleOpenNotifications} className="btn-ease" src={require("../assets/images/icons/notifications.png")} alt="notifications icon" />
							</li>
							<li>
								<img className="btn-ease" src={require("../assets/images/icons/messages.png")} alt="messages icon" />
							</li>
							<li>
								<img className="btn-ease" onClick={handleOpenCalendar} src={require("../assets/images/icons/calendar.png")} alt="calendar icon" />
							</li>
						</ul>
						<div id="main-nav-user-alerts-name">
							<h4>{props.currentUser.fName}</h4>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	return (
		<Fragment>
			{calendarClicked && <PersonalCalendar closeModal={handleCloseCalendar} currentUser={props.currentUser} />}
			{props.openNotifications && <NotificationsField currentUser={props.currentUser} userID={props.currentUser.userID} openNotifications={props.openNotifications} handleOpenNotifications={props.handleOpenNotifications}/>}
			<div id="main-nav-container" className="container-border make-grid">
				<div id="nav" className="make-grid">
					<MenuIcon className="nav-icon" width="35px" height="35px" color="#ccc" onClick={props.handleOpenNavDrawer} />
					<MyCampusHubsEmblem width="35px" height="35px" color="#ccc"/>
					<ul>
						<li>
							<img onClick={handleHomeClick} src={require("../assets/images/icons/home.png")} className="btn-ease" alt="home icon" />
						</li>
						<li>
							<img onClick={handleLDMode} className="btn-toggle btn-ease" src={require("../assets/images/icons/light_dark.png")} alt="light-dark mode button icon" />
						</li>
					</ul>
				</div>
				<div id="main-nav-search-bar-area">
					<div id="main-nav-search-bar-container">
						<div className="search-dropdown">
							<input type="text" placeholder="🔍 Search Hubs" className="search-bar" onChange={(event) => {setSearchedOcean(event.target.value)}} />
							<div id="dropdown-content" className="container-border">
								{searchedOcean === "" ? null : searchedOcean.charAt(0) === "@"
									? users.filter((user) => {
											let username = "@" + user.username;
											return username.toLowerCase().includes(searchedOcean.toLowerCase().substring());
										}).slice(0, 5).map((user) => {
											return (
												<div key={user.userID}>
													<button onClick={() => { onSearchUsername(user.username) }}>{user.username}</button>
												</div>
											);
										})
									: oceans.filter((ocean) => ocean.oceanName.toLowerCase().includes(searchedOcean.toLowerCase())).slice(0, 5).map((ocean) => {
										return (
											<div key={ocean.oceanID}>
												<button onClick={() => { onSearchedOcean(ocean.oceanName) }}>{ocean.oceanName}</button>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</div>

				<div id="main-nav-user-alerts-container" className="make-grid">
					<ul>
						<IconWithCounter handleClick={handleOpenCalendar} count={null} iconName="calendar"/>
						<IconWithCounter handleClick={handleOpenMessagesAppClick} count={numberOfMessages} iconName="messages"/>
						<IconWithCounter handleClick={() => {props.handleOpenNotifications(); setNotificationsRead() }} count={numberOfNotifications} iconName="notifications"/>
					</ul>
					<div id="main-nav-user-alerts-name">
						<h4>{props.currentUser.fName}</h4>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default NavHeader;
