import React from 'react'

/**
 * @description - This component renders a message field that contains relevant form information such as 
 * the reason for an error
 * 
 * @component
 * @param {string} formMessage - A string used to display the form message.
 * @returns {JSX.Element} - A React element that renders a form message field.
 * 
 * @example
 * // Renders a form message field that contains relevant form information such as the reason for an error
 * <FormMessageField formMessage={formMessage} />
 */

const FormMessageField = (props) => {
    return (
        <div className='form-message-container'> 
            <p className='form-message'>{props.formMessage}</p>
        </div>
    )
}

export default FormMessageField