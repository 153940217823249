import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        viewBox="0 0 1200 1200"
        {...props}
        >
        <path d="M600 0C268.629 0 0 268.629 0 600s268.629 600 600 600 600-268.629 600-600S931.371 0 600 0zm-1.216 333.625c55.343.728 101.183 45.781 116.413 103.191 5.807 23.424 6.462 47.188.608 71.998-8.827 34.929-26.498 69.048-59.423 90.008l47.986 22.796 114.021 55.205c11.199 4.8 16.793 14.399 16.793 28.8v110.372c0 22.763 1.808 42.393-26.406 50.418H388.792c-27.134-.391-28.258-27.874-27.622-50.418V705.623c0-14.4 6.009-24.415 18.009-30.016l117.591-53.989L542.401 600c-20.8-13.6-37.202-32.383-49.202-56.383-14.41-31.684-20.123-72.814-9.612-110.411 13.288-50.962 54.904-96.748 115.197-99.581zm-195.593 50.38c17.601 0 33.587 5.215 47.986 15.615-3.993 11.198-7.375 23.009-10.183 35.41-2.799 12.398-4.217 25.38-4.217 38.981 0 20.001 2.796 39.199 8.396 57.6 5.599 18.399 13.61 35.217 24.013 50.418-4.801 6.399-11.187 11.993-19.188 16.793l-88.83 40.805c-12 6.4-21.599 15.376-28.799 26.977-7.2 11.6-10.79 24.619-10.79 39.02v110.372h-87.576c-12.705-.198-21.286-13.002-21.619-26.368V685.221c0-12 4.384-20.013 13.184-24.013L358.777 600c-34.417-21.156-51.021-59.395-52.773-101.976.606-52.462 34.992-109.661 97.187-114.019zm393.58 0c55.291.874 95.229 55.691 97.227 114.02-.304 38.595-15.369 75.863-50.418 100.798l130.813 62.386c8.8 4.8 13.184 12.812 13.184 24.013v104.407c-.132 12.392-6.82 25.103-21.58 26.367h-90.008V705.623c0-14.4-3.59-27.419-10.79-39.02s-16.8-20.576-28.8-26.976c-37.304-17.339-80.146-29.784-108.017-58.814 20.8-32 31.193-67.601 31.193-106.802 0-24.8-4.384-49.214-13.184-73.214 14.452-9.541 31.558-16.524 50.38-16.792z" />
    </svg>
)
export default SvgComponent