import { BrowserRouter, Routes, Route } from "react-router-dom";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import DisplayUser from "./pages/DisplayUser";
import MyAccount from "./pages/MyAccount";
import OceansHome from "./pages/OceansHome";
import ClubHome from "./pages/ClubHome";
import About from "./pages/footer_links/About";
import BusinessLogin from "./pages/BusinessLogin"
import BusinessPortal from "./pages/businessesPortal/BusinessPortal";
import { AblyProvider } from "./assets/custom-hooks/AblyContext";

function App() {
  return (
    <AblyProvider>
      <div>
        {/*Allows use of the useNavigate hook from within the pages to switch around*/}
        <BrowserRouter>
          {" "}
          <Routes>
            <Route exact path="/" element={<Login />} />{" "}
            <Route exact path="/About" element={<About />} /> {" "}
            <Route exact path="/BusinessLogin" element={<BusinessLogin />} /> {" "}
            <Route exact path="/BusinessPortal" element={<BusinessPortal />} /> {" "}
            <Route exact path="/Profile" element={<Profile />} />{" "}
            <Route exact path="/DisplayUser" element={<DisplayUser />} />{" "}
            <Route exact path="/OceansHome" element={<OceansHome />} />{" "}
            <Route exact path="/ClubHome" element={<ClubHome />} />{" "}
            {/*Routed to if Authentication successful or Home button is clicked from within Dashboard and Profile pages*/}
            <Route exact path="/Dashboard" element={<Dashboard />} />{" "}
          </Routes>
        </BrowserRouter>
      </div>
    </AblyProvider>
  );
}

export default App;
