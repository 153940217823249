import { React, useState, useContext } from "react";
import MessageApplicationToolbar from "./MessageApplicationToolbar";
import PrivateChats from "./user_chats/PrivateChats";
import ChatBot from "./uni-chatbot/ChatBot";
import Classes from "./classes/Classes";
import StudyGroups from "./study_groups/StudyGroups";
import FriendGroupChats from "./friend_groups/FriendGroupChats";
import { AblyContext } from "../../assets/custom-hooks/AblyContext";

const MessageApplication = (props) => {
	const [roomSelected, setRoomSelected] = useState("Private Chats");
	const [fetching, setFetching] = useState(false);

	const { ably, markAsRead } = useContext(AblyContext);

	const handleRoomSelected = (room) => {
		setRoomSelected(room);
	}

	return (
		<div id="message-application-dashboard-container">
			<div id="message-application-container" className="container-border make-grid">
				<MessageApplicationToolbar handleOpenMessagesApp={props.handleOpenMessagesApp} roomSelected={roomSelected} handleRoomSelected={handleRoomSelected} handleCloseMessage={props.handleCloseMessage} />
				{!fetching && (
					<div id="message-application-chat-area-container">
						{(roomSelected === "Private Chats") ? (
							<PrivateChats ably={ably} markAsRead={markAsRead} currentUser={props.currentUser} handleCloseMessage={props.handleCloseMessage} handleMessageChatOpen={props.handleMessageChatOpen} setFetching={setFetching}/>
						) : (roomSelected === "Group Chats") ? (
							<FriendGroupChats ably={ably} currentUser={props.currentUser} />
						): (roomSelected === "Archived") ? (
							<h3>Archived</h3>
						): (roomSelected === "Classrooms") ? (
							<Classes ably={ably} currentUser={props.currentUser}/>
						): (roomSelected === 'Study Groups') ? (
							<StudyGroups markAsRead={markAsRead} ably={ably} currentUser={props.currentUser} />
						): (roomSelected === "Chatbot") ? (
							<ChatBot currentUser={props.currentUser} fetching={setFetching}/>
						): (roomSelected === "Settings") ? (
							<h3>Settings</h3>
						): null}
					</div>
				)}
			</div>
		</div>
	);
};

export default MessageApplication;
