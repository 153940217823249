import * as React from "react"
const AiIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={props.width}
        fill={props.color}
        stroke={props.color}
        viewBox="0 0 512 512"
        {...props}
    >
        <path d="M272.746 0v134.554l33.68-14.369V4.771C295.657 2.372 284.42.775 272.746 0zM348.852 233.465c-8.089 0-14.669 6.58-14.669 14.668s6.58 14.668 14.669 14.668 14.669-6.58 14.669-14.668c0-8.087-6.581-14.668-14.669-14.668z" />
        <path d="M410.021 70.002a181.542 181.542 0 0 0-3.641-4.608v87.5h-33.495V33.897c-10.2-7.284-21.206-13.449-32.966-18.455V142.31l-67.174 28.657v211.145h33.88v33.494h-33.88v96.393c22.394-2.675 43.931-10.707 63.982-23.701l20.993-146.841 85.611-17.622c8.476-31.739 13.006-65.023 13.006-97.856 0-62.31-16.016-116.245-46.316-155.977zm-61.169 226.293c-26.557 0-48.163-21.606-48.163-48.162 0-26.556 21.606-48.162 48.163-48.162 26.557 0 48.163 21.606 48.163 48.162 0 26.556-21.606 48.162-48.163 48.162z" />
        <path d="m387.545 369.515-12.237 85.59c9.811-10.805 19.041-23.033 27.574-36.632 11.177-17.813 20.774-37.369 28.636-58.009l-43.973 9.051zM205.573 4.771v115.415l33.68 14.369V0c-11.674.775-22.911 2.372-33.68 4.771zM163.147 233.465c-8.089 0-14.669 6.58-14.669 14.668s6.58 14.668 14.669 14.668 14.669-6.58 14.669-14.668c0-8.087-6.58-14.668-14.669-14.668z" />
        <path d="M239.254 382.114V170.969l-67.174-28.657V15.443c-11.76 5.005-22.767 11.171-32.966 18.455v118.996H105.62V65.395h-.001a184.304 184.304 0 0 0-3.641 4.608c-30.3 39.732-46.315 93.667-46.315 155.977 0 32.833 4.529 66.118 13.006 97.856l85.611 17.622 20.993 146.841c20.05 12.996 41.588 21.026 63.982 23.701v-96.393h-33.88v-33.494h33.879zm-76.107-85.819c-26.557 0-48.163-21.605-48.163-48.162s21.606-48.162 48.163-48.162c26.557 0 48.163 21.606 48.163 48.162 0 26.556-21.606 48.162-48.163 48.162z" />
        <path d="m124.454 369.515-43.973-9.051c7.862 20.64 17.459 40.196 28.636 58.009 8.533 13.6 17.763 25.827 27.574 36.632l-12.237-85.59z" />
    </svg>
)
export default AiIcon
