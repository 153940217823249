import React from 'react'

/**
 * @description - This component renders a New Post Form Header
 * 
 * @component
 * @param {string} avatar - The user's profileImage url
 * @param {string} name - The user's full name
 * @returns {JSX.Element} - A React element that renders a New Post Form Header
 * 
 * @example
 * // Renders a New Post Form Header
 * <NewPostFormHeader 
 *  avatar={props.userObject.profileImage} 
 *  name={`${props.userObject.fName} ${props.userObject.lName}`} 
 * />
 */

const NewPostFormHeader = (props) => {
    return (
        <div className="title-container make-grid">
            <img className="title-avatar" src={props.avatar} />
            <h3>{`${props.name}`}</h3>
            <p>Public</p>
        </div>
    )
}

export default NewPostFormHeader