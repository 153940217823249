import { React, Fragment } from 'react'
import BusinessField from '../campusModeComponents/businesses/BusinessField'
import OffCampusJobs from '../jobs/OffCampusJobs'
import OffCampusHousing from '../housing/off_campus/OffCampusHousing'

/**
 * @description - A component that renders a specific OffCampus field based on the active button selected. 
 *  The fields are: BusinessField, Calendar, Housing, and OffCampusJobs. 
 * 
 * @component
 * @param {String} activeButton - The active button selected
 * @param {Object} currentUser - The current user object (CURRENTLY UNUSED)
 * @returns {JSX.Element} - A specific OffCampus field based on the active button selected
 * 
 * @example
 * // Renders a specific OffCampus field based on the active button selected
 * <OffCampusTab
 *  activeButton={activeButton}
 *  currentUser={currentUser}
 * />
 */

const OffCampusTab = (props) => {
    return (
        <Fragment>
            {(props.activeButton === "Around Town") ? <BusinessField />
                : (props.activeButton === "Calendar") ? <p>CALENDAR COMPONENT HERE. REPLACE THIS P TAG WITH COMPONENT</p>
                : (props.activeButton === "Housing") ? <OffCampusHousing />
                : (props.activeButton === "Off-Campus Jobs") ? <OffCampusJobs />
                : (props.activeButton === "") ? <p>____ COMPONENT HERE. REPLACE THIS P TAG WITH COMPONENT</p>
                : (props.activeButton === "") ? <p>____ COMPONENT HERE. REPLACE THIS P TAG WITH COMPONENT</p>
                : null
            }
        </Fragment>
    )
}

export default OffCampusTab


// Old Navigation: Note that we'd need to pass the following props to the OffCampusBtnList component
// import OffCampusBtnList from '../campusModeComponents/OffCampusBtnList'
// <OffCampusBtnList currentUser={props.currentUser} campusButtonSelection={props.campusButtonSelection} activeButton={props.activeButton} handleTabClick={props.handleTabClick} tabSelected={props.tabSelected} source={props.source}/>