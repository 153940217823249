import getGlobalURL from "../../assets/scripts/Global";
import {Fragment, React, useState} from 'react'
import axios from 'axios';
import PuffLoader from "react-spinners/PuffLoader";
import ChildRequestListing from "./ChildRequestListing";

const ChildRequestsModal = (props) => {
    // ##################### URL change for Vercel##############################
    const url = getGlobalURL();
    if (url === "https://oceans-server-prod-v1.vercel.app") { axios.defaults.withCredentials = true; }
    // ##################### END OF URL change for Vercel#######################

    const [childList, setChildList] = useState(props.ocean.request_parent);
    const [posting, setPosting] = useState(false); // Used for the spinner

    return (
        <div className='modal-container'>
            <div className='modal-backdrop' onClick={() => props.closeModal("childRequest")} />
            <div className="new-form-container">
                <div className="new-form-header">Manage Child Hub Requests</div>
                <div className="new-form-body">
                    <p className="form-instruction-text">In this form, you as an admin, may accept/reject child hub requests for your '{props.ocean.oceanName}' hub to be added as a parent</p>
                    <hr className="hr-custom"/>
                    {posting ? (
                        <div style={{display: "flex", justifyContent: "center",alignItems: "center", height: "100%","margin-top": "15px"}}>
                            <PuffLoader color="#006994" />
                        </div>
                    ) : (
                        <div id="child-request-container">
                            {props.ocean.request_parent.map((childID) => {
                                return (
                                    <Fragment key={childID}>
                                        <ChildRequestListing addSubHub={props.addSubHub} currentUser={props.currentUser} parentOcean={props.ocean} childOceanID={childID} />
                                    </Fragment>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChildRequestsModal
