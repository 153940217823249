import {React, Fragment} from 'react'
import { useLocation } from 'react-router-dom'
import NavHeader from '../../components/NavHeader'
import creatorsData from '../../assets/data/ourInfoObject'
import CreatorCard from './components/aboutComponents/CreatorCard'
import Footer from '../../components/Footer'

const About = () => {
  let location = useLocation();
  let currentUser = location.state.currentUser

  return (
    <Fragment>
      <h1 id='about-title' className={(currentUser !== null) ? 'padding-top-80' : 'padding-top-20'}>The High Council</h1>
      {(currentUser !== null) && <NavHeader currentUser={currentUser}/>}
      <div id='creator-listing-container'>
        {creatorsData.map((creator) => {
          return (
            <CreatorCard currentUser={currentUser} creatorData={creator} key={creator.id}/>
          )
        })}
      </div>
      <Footer currentUser={currentUser}/>
    </Fragment>
  )
}

export default About