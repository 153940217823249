import { React, Fragment, useState } from 'react'
import EditOceanDataModal from '../../Modals/oceanModals/EditOceanDataModal';
import ParentRequestModal from '../../Modals/forms/ParentRequestModal';
import ChildRequestsModal from '../../Modals/forms/ChildRequestsModal';
import ManageMembersModal from '../../components/oceanControls/controlsModals/ManageMembersModal';

const AdminControls = (props) => {
    const [editOceanDataModalStatus, setEditOceanDataModalStatus] = useState(false);
    const [parentReqModalStatus, setParentReqModalStatus] = useState(false);
    const [childReqModalStatus, setChildReqModalStatus] = useState(false);
    const [membersListModalStatus, setMembersListModalStatus] = useState(false);

    const handleModalRequestClick = (source) => {
        (source === "editOcean")
            ? setEditOceanDataModalStatus(true)
            : (source === "parentRequest")
                ? setParentReqModalStatus(true)
                : (source === "childRequest")
                    ? setChildReqModalStatus(true)
                    : setMembersListModalStatus(true)

    }

    const closeModal = (source) => {
        (source === "editOcean")
            ? setEditOceanDataModalStatus(false)
            : (source === "parentRequest")
                ? setParentReqModalStatus(false)
                : (source === "childRequest")
                    ? setChildReqModalStatus(false)
                    : setMembersListModalStatus(false)

    }

    return (
        <Fragment>
            {editOceanDataModalStatus && <EditOceanDataModal ocean={props.ocean} closeModal={closeModal} updateOcean={props.updateOcean} />}
            {parentReqModalStatus && <ParentRequestModal ocean={props.ocean} currentUser={props.currentUser} closeModal={closeModal} />}
            {childReqModalStatus && <ChildRequestsModal addSubHub={props.addSubHub} ocean={props.ocean} currentUser={props.currentUser} closeModal={closeModal} />}
            {membersListModalStatus && <ManageMembersModal ably={props.ably} ocean={props.ocean} currentUser={props.currentUser} closeModal={closeModal} />}
            <div id="oceans-list-banner-container">
                <div id='oceans-list-banner-title-container'>
                    <div className="oceans-list-banner-title-tab tab-active">Admin</div>
                </div>
                <div id="oceans-list-banner-links-container" className="container-border">
                    <div id='controls-container'>
                        <button onClick={() => { handleModalRequestClick("parentRequest") }} className='oceans-btn btn-ease'>Request Parent</button>
                        <button onClick={() => { handleModalRequestClick("childRequest") }} className='oceans-btn btn-ease'>Child Requests</button>
                        <button onClick={() => { handleModalRequestClick("manageMembers") }} className='oceans-btn btn-ease'>Manage Members</button>
                        <button onClick={() => { handleModalRequestClick("editOcean") }} className='oceans-btn btn-ease'>Edit Hub</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AdminControls