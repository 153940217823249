import React from 'react'
import { useNavigate } from 'react-router-dom'
import "../assets/stylesheets/oceansStyles.css"

const Footer = (props) => {
    let navigate = useNavigate()

    const handleLinkClick = (page) => {
        navigate(`/${page}`, {state: {currentUser: props.currentUser}})
    }

    

    return (
        <footer>
            <ul>
                {/* <li onClick={() => {handleLinkClick("About")}}>About</li> */}
                <li><a href="http://www.surgepointsoftware.com" target='_blank'>About</a></li>
                <li>Careers</li>
                <li>Help</li>
                <li>Advertising</li>
                <li>Terms</li>
                <li>Privacy Policy</li>
                <li onClick={() => {handleLinkClick("BusinessLogin")}}>Business Portal</li>
            </ul>
            <p>SurgePoint Software © 2023</p>
            {/* <p onClick={handleSurgePointClick}>© 2023 SurgePoint Software LLC</p> */}
        </footer>
    )
}

export default Footer