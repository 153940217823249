import * as React from "react"

const MyCampusHubsEmblem = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        viewBox="0 0 55.249 51.189"
        {...props}
    >
        <defs>
        <linearGradient
            id="a"
            x1={214.37}
            x2={421.48}
            y1={147.08}
            y2={145.81}
            gradientTransform="matrix(.62111 0 0 .62111 -132.75 -26.815)"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset={0} stopColor="#59f" />
            <stop offset={1} stopColor="#003380" />
        </linearGradient>
        </defs>
        <path
        fill="#59f"
        d="M18.419 7.175c-4.728 0-9.458 1.795-13.042 5.38-7.169 7.172-7.17 18.92-.002 26.093 7.168 7.172 18.913 7.176 26.082.004a18.26 18.26 0 0 0 4.564-7.615 5.5 5.5 0 0 0 .82.062 5.5 5.5 0 0 0 5.5-5.499 5.5 5.5 0 0 0-5.498-5.499 5.5 5.5 0 0 0-.838.064 18.33 18.33 0 0 0-1.444-3.466 9.179 9.179 0 0 0-4.209 2.42 9.252 9.252 0 0 0-2.077 3.153c1.237 3.622.4 7.766-2.515 10.681a10.323 10.323 0 0 1-14.69-.002 10.337 10.337 0 0 1 .002-14.698c2.475-2.476 5.836-3.452 9.01-2.93a19.5 19.5 0 0 1 2.85-3.628 19.488 19.488 0 0 1 3.435-2.732 18.535 18.535 0 0 0-7.949-1.787Zm18.407 16.397a2.03 2.03 0 0 1 .016 0 2.03 2.03 0 0 1 2.03 2.028 2.03 2.03 0 0 1-2.03 2.03 2.03 2.03 0 0 1-2.028-2.03 2.03 2.03 0 0 1 2.012-2.028z"
        style={{
            fontVariantLigatures: "normal",
            fontVariantPosition: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantAlternates: "normal",
            fontVariantEastAsian: "normal",
            fontFeatureSettings: "normal",
            fontVariationSettings: "normal",
            textIndent: 0,
            textDecoration: "none",
            textDecorationLine: "none",
            textDecorationStyle: "solid",
            textDecorationColor: "#000",
            textTransform: "none",
            textOrientation: "mixed",
            whiteSpace: "normal",
            shapePadding: 0,
            shapeMargin: 0,
            inlineSize: 0,
            isolation: "auto",
            mixBlendMode: "normal",
            fill: "#fb0",
            fillOpacity: 1,
            fillRule: "evenodd",
            paintOrder: "normal",
        }}
        />
        <path
        d="M36.834 7.177c-4.727 0-9.454 1.793-13.039 5.379a18.265 18.265 0 0 0-4.561 7.603 5.5 5.5 0 1 0 .01 10.878c.36 1.18.826 2.333 1.426 3.434a9.181 9.181 0 0 0 4.127-2.398 9.245 9.245 0 0 0 2.13-3.294c-1.162-3.585-.31-7.653 2.563-10.527a10.323 10.323 0 0 1 14.69.002 10.337 10.337 0 0 1 0 14.698c-2.502 2.503-5.907 3.473-9.112 2.914a19.517 19.517 0 0 1-2.853 3.632 19.478 19.478 0 0 1-3.4 2.71c6.864 3.296 15.39 2.113 21.057-3.556 7.169-7.172 7.17-18.92.002-26.094-3.583-3.586-8.311-5.38-13.039-5.38ZM18.412 23.57h.018a2.03 2.03 0 1 1-.018 0zM27.662 0A25.594 25.594 0 0 0 8.617 8.559a19.729 19.729 0 0 1 9.803-2.603h1.217v-.001c2.488.153 4.959.818 7.253 1.908l.755.358a19.73 19.73 0 0 1 9.19-2.265c3.53 0 7.064.947 10.178 2.827A25.594 25.594 0 0 0 27.663 0zm19.315 42.448c-5.686 3.414-12.766 3.702-18.691.857l-.671-.323C21.69 46.1 14.475 45.99 8.64 42.657a25.594 25.594 0 0 0 38.337-.209z"
        style={{
            fontVariantLigatures: "normal",
            fontVariantPosition: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantAlternates: "normal",
            fontVariantEastAsian: "normal",
            fontFeatureSettings: "normal",
            fontVariationSettings: "normal",
            textIndent: 0,
            textDecoration: "none",
            textDecorationLine: "none",
            textDecorationStyle: "solid",
            textDecorationColor: "#000",
            textTransform: "none",
            textOrientation: "mixed",
            whiteSpace: "normal",
            shapePadding: 0,
            shapeMargin: 0,
            inlineSize: 0,
            opacity: 1,
            isolation: "auto",
            mixBlendMode: "normal",
            fill: props.color,
            fillOpacity: 1,
            fillRule: "evenodd",
            paintOrder: "normal",
        }}
        />
    </svg>
)

export default MyCampusHubsEmblem