import { React, useEffect } from 'react'

const BusinessFilter = (props) => {
  var select;

  useEffect(() => {
    select = document.getElementById('business-category');
    select.addEventListener('change', () => {
      props.handleSetCategory(select.value);
    });
  }, [])

  return (
    <div id='orgs-search-container' className='container-border'>
      <div className='orgs-search-field border-radius-15'>
        <select id='business-category' >
          <option value="All">All</option>
          <option value="Food">Food</option>
          <option value="Entertainment">Entertainment</option>
          <option value="Night Life">Night Life</option>
        </select>
      </div>
    </div>
  )

}

export default BusinessFilter