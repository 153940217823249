import React from 'react'

const GeneralButton = (props) => {
    const handleClick = () => {
        window.open(props.link, "_blank");
    }

    return (
        <div id='general-button-area-container' >
            <button onClick={handleClick} className='general-btn btn-ease'>{props.title}</button>
        </div>
    )
}

export default GeneralButton