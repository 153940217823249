import React from 'react'

// ! PROPS COMING FROM DepartmentField.jsx: title, link
const UniversityStoreButton = (props) => {

  console.log(props);

  const click = () => {
    props.handleButtonClick(props.link);
  }

  return (
    <a id="University-Store-Anchor" href={props.link} target="_blank" rel="noopener noreferrer">
      <div id='store-button-container' style={{background : '#FFBB00'}} className='border-radius-25' >
        <div id='club-org-cover-img-container' >
          {/* <img src={} /> */}
        </div>
        <div>
          <h1>{props.title}</h1>
        </div>
      </div>
    </a>
  )
}

export default UniversityStoreButton