import { React, useState, Fragment} from 'react'
import DownArrow from '../../icons/DownArrow';
import UpArrow from '../../icons/UpArrow';

const Business = (props) => {
    const [businessClicked, setBusinessClicked] = useState(false);
    const street = props.business.businessAddress.replace(' ', '+');
    const city = '+' + props.business.businessCity;
    const state = '+MD';

    const handleClick = () => {
        props.handleBusinessClick(props.business.businessName);
        setBusinessClicked(!businessClicked);

    }

    return (
        <div id='business-container' onClick={handleClick} className='border-radius-25'>
            <div id='club-org-cover-img-container-inset-shadow'></div>
            <div id='club-org-cover-img-container'>
                <img src={props.business.businessBanner}/>
            </div>
            <div id='org-text-field'>
                <h1>{props.business.businessName}</h1>
                <a href={`https://www.google.com/maps/search/?api=1&query=${street},${city},${state}`} target="_blank" rel="noopener noreferrer" >
                    <p>{props.business.businessAddress}, {props.business.businessCity}, MD {props.business.businessZip}</p>
                </a>
                <a href={`https://${props.business.businessUrl}`} target="_blank" rel="noopener noreferrer" ><p>{props.business.businessUrl.slice(8)}</p></a>
            </div>
            <div id='business-slide-expand'>{(businessClicked) ? <UpArrow width="30px" color="#fff"/> : <DownArrow width="30px" color="#fff"/>}</div>
        </div>
    )
}

export default Business