import React, { useState, Fragment } from "react";
import "../../../assets/stylesheets/oceansStyles.css";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import { FoundBadToken } from "../../../scripts/badToken";
import FormCancelSubmitBtns from "../../../Modals/forms/FormCancelSubmitBtns";
import InputWithObject from "../../atoms/InputWithObject";

// ! PROPS COMING FROM ClassListing.jsx: course, closeModal, currentUser
const CreateChannelModal = (props) => {
   const url = getGlobalURL();

   const [isPrivate, setIsPrivate] = useState(false);
   const [readOnly, setReadOnly] = useState(false);
   const [channelName, setChannelName] = useState("");
   const [selectedOption, setSelectedOption] = useState("Public");
   const [permissionType, setPermissionType] = useState("R");

   const handleCreateChannel = () => {
      axios.post(`${url}channels/createChannel`, {
         channelName: channelName.channelName,
         courseSubjet: props.course.courseSubject,
         courseCode: props.course.courseCode,
         channel: {
            visibility: selectedOption === 'Public' ? "public" : "private",
            permissions: permissionType === 'R' ? "R" : "RW",
            name: channelName.channelName,
            messageCount: 0,
            messages: [],
            members: [props.currentUser.userID],
         }
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         if (response.status === 200) {
            alert("Channel created");
            props.closeModal();
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error)
         }
      });
   };

   const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
   }

   const handlePermissionChange = (event) => {
      setPermissionType(event.target.value);
   }

   const handleFocus = (focussedOn) => {
      // setFocussedField(focussedOn);
   }


   return (
      <div className='modal-container' >
         <div className='modal-backdrop' >
         <div className="new-form-container container-border">
            <div className="new-form-header">Create a new channel!</div>
               <div className="new-form-body">
                     <p className="form-instruction-text">This form allows you to create a new channel for students</p>
                     <hr className="hr-custom"/>

                     <InputWithObject handleFocus={() => {}} type='text' required={true} forText='channelName' labelText='Channel Name' date={channelName} setData={setChannelName} />

                     <div className='new-form-radial-btns-area'>
                        <h3>Please select the type of channel:</h3>

                        <div className='radial-btn-and-label'>
                           <input type="radio" id="readOnlyChannel" name="channelType" value="Public" checked={selectedOption === 'Public'} onChange={(e) => handleOptionChange(e)} />
                           <label htmlFor="readOnlyChannel">Public Channel</label>
                        </div>

                        <div className='radial-btn-and-label'>
                           <input type="radio" id="privateChannel" name="channelType" value="Private" checked={selectedOption === 'Private'} onChange={(e) => handleOptionChange(e)} />
                           <label htmlFor="privateChannel">Private Channel</label>
                        </div>

                        <h3 style={{'margin-top': '20px'}} >Please select the permission level for the students</h3>

                        <div className='radial-btn-and-label'>
                           <input type="radio" id="readOnly" name="permissionType" value="R" checked={permissionType === 'R'} onChange={(e) => handlePermissionChange(e)} />
                           <label htmlFor="readOnly">{`Read Only (only you can post in this channel)`}</label>
                        </div>

                        <div className='radial-btn-and-label'>
                           <input type="radio" id="readAndWrite" name="permissionType" value="RW" checked={permissionType === 'RW'} onChange={(e) => handlePermissionChange(e)} />
                           <label htmlFor="readAndWrite">{`Read and Write (both you and the students can make posts in this channel)`}</label>
                        </div>

                     </div>

                     {/* <TextAreaWithObject handleFocus={handleFocus} forText="feedback" labelText="Feedback:" helperText="Please be as specific as possible" data={feedbackData} setData={setFeedbackData}/> */}
                     <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText="Cancel" handleSubmit={handleCreateChannel} submitText="Create Channel"/>
               </div>
            </div>
         </div>
      </div>
   )
   // return (
   //    <div className="modal-container">
   //       <div className="modal-backdrop">
   //          <div className="create-user-modal-content">
   //             <div id="modal-title" className="make-grid">
   //                <h1>Enter the name of the new channel name!</h1>
   //             </div>
   //             <div id="form-inputs" style={{ background: "white" }}>
   //                <input type="text" placeholder="Channel Name" onChange={(e) => setChannelName(e.target.value)} />
   //                <button onClick={handleCreateChannel}>Submit</button>
   //                <button onClick={props.closeModal}>Cancel</button>
   //                <input type="checkbox" id="isPrivate" name="isPrivate" value="isPrivate" onChange={(e) => setIsPrivate(e.target.checked)} />
   //                <label htmlFor="isPrivate">Private</label>
   //                <input type="checkbox" id="readOnly" name="readOnly" value="readOnly" onChange={(e) => setReadOnly(e.target.checked)} />
   //                <label htmlFor="readOnly">Read Only</label>
   //             </div>
   //          </div>
   //       </div>
   //    </div>
   // );
};

export default CreateChannelModal;
