import getGlobalURL from '../../assets/scripts/Global';
import { React, useState, useEffect, Fragment, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FoundBadToken } from '../../scripts/badToken';
import GroupUsersIcon from '../icons/GroupUsersIcon';
import NavDrawerSection from './NavDrawerSection';
import AdministrationIcon from '../icons/AdministrationIcon';
import AcademicsIcon from '../icons/AcademicsIcon';
import StudentLifeIcon from '../icons/StudentLifeIcon';
import OffCampusIcon from '../icons/OffCampusIcon';
import LogoutIcon from '../icons/LogoutIcon';
import HubsIcon from '../icons/HubsIcon';
import MyAccountIcon from '../icons/MyAccountIcon';
import NavDrawerHeader from './NavDrawerHeader';
import { formatNumber } from '../../assets/scripts/mchScripts';
import { AblyContext } from '../../assets/custom-hooks/AblyContext';
import FeedbackIcon from '../icons/FeedbackIcon';

/**
 * @description - A drawer navigation component that displays a list of sections with collapsible buttons
 * 
 * @component
 * @param {object} currentUser - The current user object
 * @param {function} handleOpenNavDrawer - A function that toggles the navigation drawer
 * @param {function} handleButtonSelection - A function that handles the selection of a button
 * @param {function} setOpenMyAccount - A function that toggles the MyAccount component
 * @param {function} setOpenFeedback - A function that toggles the Feedback component
 * @param {string} activeButton - The active button that is selected
 * @param {string} activeTab - The active tab that is selected
 * @param {string} source - The source of the navigation drawer
 * @returns {JSX.Element} - A drawer navigation component that displays a list of sections with collapsible buttons
 * 
 * @example
 * // Renders a drawer navigation component
 * <NavDrawer 
 *  activeButton={activeButton}
 *  activeTab={activeTab}
 *  currentUser={currentUser} 
 *  handleButtonSelection={buttonSelection}
 *  handleOpenNavDrawer={handleOpenNavDrawer}  
 *  setOpenMyAccount={setOpenMyAccount}
 *  setOpenFeedback={setOpenFeedback}
 *  source="OceansHome"
 * />
 */

const NavDrawer = (props) => {
    const url = getGlobalURL();
    let navigate = useNavigate();
    const [userOceansAdminList, setUserOceansAdminList] = useState([]);
    const [activeTab, setActiveTab] = useState((props.activeTab) ? props.activeTab : "My Hubs")
    const [activeButton, setActiveButton] = useState(props.activeButton)

    const { closeAbly } = useContext(AblyContext);

    useEffect(() => {
        setActiveButton(props.activeButton);
    }, [props.activeButton])

    useEffect(() => {
        axios.get(`${url}oceans/getUserOceansAdminList/${props.currentUser.userID}`, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            }
        }).then((response) => {
            setUserOceansAdminList(response.data);
        }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
                FoundBadToken();
            } else {
                alert(error.response.data.err + " error in getUserOceansAdminList");
            }
        });
    }, [props.oceanCreated]);

    const handleButtonSelection = (button, tab) => {
        // Only navigate if the button or tab is different from the current active button or tab
        if (props.activeButton !== button || props.activeTab !== tab) {
            setActiveTab(tab);
            props.handleButtonSelection(button, tab);
            
            switch(tab) {
                case "My Hubs":
                    // if(props.source !== "OceansHome") {
                        //alert('Navigating to OceansHome')
                        navigate("/OceansHome", { state: { currentUser: props.currentUser, oceanSelected: button} })
                    // }
                    break;
                case "My Clubs":
                    // if(props.source !== "ClubHome") {
                        //alert('Navigating to ClubHome')
                        navigate("/ClubHome", { state: { currentUser: props.currentUser, clubSelected: button} })
                    // }
                    break;
                default:
                    if(props.source !== "Dashboard") {
                        //alert('Navigating to Dashboard')
                        navigate("/Dashboard", { state: { currentUser: props.currentUser, buttonSelected: button, activeTab: tab } })
                    }
            }
        }
    }

    const handleLogoutClick = () => {
        closeAbly();
        sessionStorage.clear();
		navigate("/", { state: { currentUser: {} } });
    }

    const handleAvatarClick = () => {
        navigate("/Profile", { state: { currentUser: props.currentUser } })
    }

    return (
        <div id='nav-drawer-main-container'>
            <NavDrawerHeader handleAvatarClick={handleAvatarClick} currentUser={props.currentUser}/>
            <div id='nav-drawer-stats-slide'>
                <p><GroupUsersIcon width="20px" height="20px" color="var(--secondary-text-color)" /> {formatNumber(props.currentUser.numFollowing)} Following</p>
                <p><GroupUsersIcon width="20px" height="20px" color="var(--secondary-text-color)" /> {formatNumber(props.currentUser.numFollowers)} Followers</p>
            </div>
            <div id='nav-drawer-container' className='scrollbar-mch-thick'>
                <div id='nav-drawer'>
                    <div id='nav-drawer-top'>              
                        <NavDrawerSection 
                            active={(activeTab === "My Hubs") ? true : false}
                            activeButton={activeButton}
                            adminList={userOceansAdminList}
                            handleButtonSelection={handleButtonSelection}
                            heading="My Hubs" 
                            icon={<HubsIcon width="25px" height="20px" color={(activeTab === "My Hubs") ? "var(--towson-gold)" : "var(--nav-drawer-text)"}/>}
                            list={props.currentUser.oceans}
                        />
                        <hr/>
                        <NavDrawerSection 
                            active={(activeTab === "My Clubs") ? true : false}
                            activeButton={activeButton}
                            adminList={null}
                            handleButtonSelection={handleButtonSelection} 
                            heading="My Clubs" 
                            icon={<GroupUsersIcon width="25px" height="25px" color={(activeTab === "My Clubs") ? "var(--towson-gold)" : "var(--nav-drawer-text)"}/>}
                            list={props.currentUser.clubs}
                        />
                        <hr/>
                        <NavDrawerSection 
                            active={(activeTab === "Academics") ? true : false}
                            activeButton={props.activeButton}
                            adminList={null}
                            handleButtonSelection={handleButtonSelection} 
                            heading="Academics"
                            icon={<AcademicsIcon width="25px" height="20px" color={(activeTab === "Academics") ? "var(--towson-gold)" : "var(--nav-drawer-text)"}/>}
                            list={["Departments", "Helpful Tools", "Library", "Majors", "Study Groups"]}
                        />                       
                        <hr/>
                        <NavDrawerSection 
                            active={(activeTab === "Student Life") ? true : false}
                            activeButton={props.activeButton}
                            adminList={null}
                            handleButtonSelection={handleButtonSelection} 
                            heading="Student Life"
                            icon={<StudentLifeIcon width="25px" height="20px" color={(activeTab === "Student Life") ? "var(--towson-gold)" : "var(--nav-drawer-text)"}/>}
                            list={["Athletics", "Clubs", "Greek Life"]}
                        />
                        <hr/>
                        <NavDrawerSection 
                            active={(activeTab === "Administration") ? true : false}
                            activeButton={props.activeButton}
                            adminList={null}
                            handleButtonSelection={handleButtonSelection} 
                            heading="Administration"
                            icon={<AdministrationIcon width="25px" height="20px" color={(activeTab === "Administration") ? "var(--towson-gold)" : "var(--nav-drawer-text)"}/>}
                            list={["Directory", "On-Campus Jobs"]}
                        />
                        <hr/>
                        <NavDrawerSection 
                            active={(activeTab === "Off Campus") ? true : false}
                            activeButton={props.activeButton}
                            adminList={null}
                            handleButtonSelection={handleButtonSelection} 
                            heading="Off Campus"
                            icon={<OffCampusIcon width="25px" height="25px" color={(activeTab === "Off Campus") ? "var(--towson-gold)" : "var(--nav-drawer-text)"}/>}
                            list={["Around Town", "Housing", "Off-Campus Jobs"]}
                        />
                    </div>
                    <div id='nav-drawer-bottom'>
                        <div id='nav-drawer-bottom-btn-area'>
                            <ul className='ul-no-padding'>
                                <li onClick={() => props.setOpenFeedback((prevState) => !prevState )}><FeedbackIcon width="20px" height="20px" color="var(--nav-drawer-text)"/>Send Feedback</li>
                                <li onClick={() => props.setOpenMyAccount((prevState) => !prevState )}><MyAccountIcon width="20px" height="20px" color="var(--nav-drawer-text)"/>My Account</li>
                                <li onClick={handleLogoutClick}><LogoutIcon width="20px" height="20px" color="var(--nav-drawer-text)"/>Logout</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavDrawer