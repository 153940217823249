import getGlobalURL from '../../../assets/scripts/Global';
import { Fragment, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChannelMessage from '../../classrooms/classroomComponents/ChannelMessage';
import { FoundBadToken } from '../../../scripts/badToken';
import NewPostFormHeader from '../../organisms/posts/NewPostFormHeader';
import ConvertToBase64 from '../../helperComponents/ConvertToBase64';
import SendIcon from '../toolbar/icons/SendIcon';
import CloseIcon from '../../icons/CloseIcon';

const ExpandedClassroomMessage = (props) => {
    const url = getGlobalURL();
    const [replies, setReplies] = useState([]);
    const [reply, setReply] = useState("");
    const [sentFlag, setSentFlag] = useState(false);

    const channel = useRef(null);

    useEffect(() => {
        axios.post(`${url}channels/getMessageReplies`, {
            courseCode: props.course.courseCode,
            channelName: props.channelSelected,
            messageID: props.message.messageID,
        }, {
            headers: {
            Authorization: sessionStorage.getItem("token"),
            },
        }).then((response) => {
            if (response.status === 200) {
                console.log(response.data.replies);
                setReplies(response.data.replies.reverse());
            }
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        });
    }, [props.message, sentFlag]);

    useEffect(() => {
        channel.current = props.ably.channels.get(
            `${props.course.courseCode}-${props.channelSelected}-${props.message.messageID}`
        );
        channel.current.subscribe("receive-reply", (reply) => {
            setReplies([...replies, reply.data]);
        });

        return () => {
            channel.current.unsubscribe();
            channel.current = null;
        };
    }, []);

    const sendReply = () => {
        if (reply === "") {
            alert("Please enter a reply");
            return;
        } else {
            let newReply = {
                reply: reply,
                senderName: props.currentUser.fName + " " + props.currentUser.lName,
                senderPicture: props.currentUser.profileImage,
                timestamp: Date.now(),
                replyID: Date.now(),
                senderID: props.currentUser.userID,
            };
            
            axios.post(`${url}channels/makeReply`, {
                courseCode: props.course.courseCode,
                channelName: props.channelSelected,
                messageID: props.message.messageID,
                reply: newReply,
                }, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                }
            ).then((response) => {
                if (response.status === 200) {
                    setReply("");
                    setSentFlag(!sentFlag);
                }
            }).catch((error) => {
                if(error.response.status === 401){
                    alert('Session expired. Please log in again.')
                    FoundBadToken();
                }
            });
        }
    };
    return (
        <div id='message-app-classroom-expanded-message' className='border-radius-20'>
            <div className='close-window'>
                <CloseIcon className="clickable" onClick={() => props.closeMessageSlide()} width="30px" height="30px" strokeColor="var(--primary-color)"/>
            </div>
            <ChannelMessage
                channelSelected={props.channelSelected}
                course={props.course}
                currentUser={props.currentUser}
                message={props.message}
            />
            <div id="create-comment-container" className="comment-content">
                <div id="comment-input-field-container" className="make-grid">
                    <NewPostFormHeader avatar={props.currentUser.profileImage} name={`${props.currentUser.fName} ${props.currentUser.lName}`}/>
                    <textarea className="make-grid" placeholder="Comment here" onChange={(event) => setReply(event.target.value)}></textarea>
                    <hr className="hr-custom"/>
                    <div className="comment-icons-container">
                        {/* <ConvertToBase64 type="image" textID="comment-1" setFile={setFile} width="25px" height="25px" color="var(--secondary-text-color)" /> */}
                        <div></div>
                        <SendIcon onClick={sendReply} className="clickable" width="30px" height="30px" strokeColor="var(--secondary-text-color)"/>
                    </div>
                </div>
            </div>

            {replies && replies.map((reply) => {
                return (
                    <div className="comment-container">
                        <div id="comment-title-container" className="make-grid">
                            <img className="comment-avatar" src={reply.senderPicture} />
                            <h3>{reply.senderName}</h3>
                        </div>
                        <div className="comment-content">
                            <p>{reply.reply}</p>
                            {reply.image && (
                                <img loading="lazy" className="post-thumbnail" src={reply.image} />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default ExpandedClassroomMessage