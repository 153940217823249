import React from 'react'
import { useNavigate } from 'react-router-dom'

// ! implement passing the club ID so we can fetch it on the ClubHome page
const Club = (props) => {

    console.log('clubName: ', props.club.clubName)

    let navigate = useNavigate();
    const handleNavClick = () => {
        navigate('/ClubHome', {state: {
            currentUser: props.currentUser,
            clubSelected: props.club.clubName
        }});
    }

    return (
        <div onClick={handleNavClick} id='club-org-container' className='clickable border-radius-25'>
            <div id='club-org-cover-img-container-inset-shadow'></div>
            <div id='club-org-cover-img-container'>
                <img src={props.clubBanner}/>
            </div>
            <div id='club-text-field'>
                <h1>{props.title}</h1>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default Club
