import { React, useEffect, useState } from 'react'
import GroupListing from './GroupListing'

const GroupUserList = (props) => {
    return (
        <div id='message-application-left-panel-container' className='make-grid' >
            <div id='message-application-user-search-field' >
                <input type='text' placeholder='🔍 Search Groups'/>
            </div>

            <div id='message-application-user-field' className='scrollbar-mch-thin' >
                {props.groups.map((group, index) => {
                    if(group.studyGroupID !== 1709259029266){
                        return (
                            <div className={props.groupSelected === index ? 'activeChat border-radius-15' : ''} >
                                <GroupListing handleSelectedGroupChat={props.handleSelectedGroupChat} index={index} key={index * 2} currentUser={props.currentUser} group={group} />
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default GroupUserList