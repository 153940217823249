import getGlobalURL from "../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { FoundBadToken } from "../scripts/badToken";
import DefaultBackground from "../assets/images/tu_gold_card_background_169.png";
import StatContainer from "./atoms/StatContainer";
import MemberSinceContainer from "./atoms/MemberSinceContainer";
import AdminIcon from "./icons/AdminIcon";

const OceansHeader = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [oceansList, setOceansList] = useState(props.currentUser.oceans);
   const [numberOfMembers, setNumberOfMembers] = useState(props.ocean.numMembers);

   useEffect(() => {
      setNumberOfMembers(props.ocean.numMembers);
   }, [props.ocean])

   let createdDate = new Date(props.ocean.createdDate).toString().split(" ").splice(1, 3).join(" ");

   // ! need to handle this logic in the axios response rather than before it
   const handleJoinOceanClick = () => {
      let newOceansList = [...oceansList];
      newOceansList.push(props.ocean.oceanName);
      setOceansList(newOceansList);
      updateUserOceans(newOceansList);
      addOceanMember();
   };

   // ! need to handle this logic in the axios response rather than before it
   const handleRemoveOceanClick = () => {
      let newOceansList = [...oceansList];
      newOceansList.splice(newOceansList.indexOf(props.ocean.oceanName), 1);
      setOceansList(newOceansList);
      updateUserOceans(newOceansList);
      removeOceanMember();
   };

   const updateUserOceans = (newOceansList) => {
      axios.patch(`${url}users/updateUserOceans`,
         {
            userID: props.currentUser.userID,
            oceans: newOceansList,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         props.currentUser.oceans = newOceansList;
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   const addOceanMember = () => {
      axios.patch(`${url}oceans/addOceanMember`,{
            userID: props.currentUser.userID,
            oceanName: props.ocean.oceanName,
         },
         {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         console.log(JSON.stringify(response.data));
         props.ocean.numMembers++;
         setNumberOfMembers(props.ocean.numMembers);
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   const removeOceanMember = () => {
      axios.patch(`${url}oceans/removeOceanMember`, {
            userID: props.currentUser.userID,
            oceanName: props.ocean.oceanName,
         },
         {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         props.ocean.numMembers--;
         setNumberOfMembers(props.ocean.numMembers);
         console.log(JSON.stringify(response.data));
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
         }
      });
   };

   return (
      <Fragment>
         <div id='header-container' className='margin-top-80 container-border'>
               <div id='header-top-container'>
                  <div className="background-img-contain" id="header-cover-img" style={{ backgroundImage: `url(${(props.ocean.oceanBanner) ? props.ocean.oceanBanner : DefaultBackground})` }}></div>
               </div>
               <div id='header-middle-container'>
                  <div id='header-title'>
                     <h1>{props.ocean.oceanName}</h1>
                     {props.ocean.isAdmin && <AdminIcon width="25px" height="25px" color="var(--secondary-text-color"/>}
                  </div>
                  <div id='header-stats-btns-container'>
                     <div id='header-stats'>
                           <StatContainer label="Members" stat={numberOfMembers}/>
                           <StatContainer label="Posts" stat={props.ocean.numPosts}/>
                           <MemberSinceContainer label="Created" date={createdDate}/>
                     </div>
                     <div id='header-btns'>
                        { oceansList.includes(props.ocean.oceanName) ? (
                           <button onClick={handleRemoveOceanClick} className="ocean-header-btns btn-ease">Leave Hub</button>
                        ) : (
                           <button onClick={handleJoinOceanClick} className="ocean-header-btns btn-ease">Join Hub</button>
                        )}
                     </div>
                  </div>
               </div>
               <div id='header-bottom-container'>
                  <p>{props.ocean.description}</p>
               </div>
         </div>
      </Fragment>
   );
};

export default OceansHeader;
