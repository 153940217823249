import * as React from "react"
const SendIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
        stroke={props.strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M20.764 12H10.056M3 8h2.5M4 12h1.5m-1 4h1m4.462-3.51-.892 2.959c-.337 1.12-.506 1.68-.363 2.014a1 1 0 0 0 .687.578c.354.085.877-.177 1.924-.7l7.82-3.91c.921-.46 1.382-.69 1.53-1.002a1 1 0 0 0 0-.857c-.148-.312-.609-.542-1.53-1.003l-7.831-3.916c-1.044-.521-1.565-.782-1.92-.698a1 1 0 0 0-.686.577c-.144.334.023.893.357 2.01l.905 3.028a2.1 2.1 0 0 1 .086.344.994.994 0 0 1 0 .232c-.01.087-.036.173-.087.344Z"
        />
    </svg>
)
export default SendIcon