import React, { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import LeftAlignedMessage from "../LeftAlignedMessage";
import RightAlignedMessage from "../RightAlignedMessage";
import ChatTextArea from "../../atoms/ChatTextArea";
import FG_Members from "./FG_Members";
import FG_EditModal from "./modals/FG_EditModal";
import { FoundBadToken } from "../../../scripts/badToken";

const FG_ChatField = (props) => {
    const url = getGlobalURL();

    const [messages, setMessages] = useState();
    const [message, setMessage] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);

    const lastMessageRef = useRef(null);

    const ScrollToBottom = () => {
        let lastMessageDiv = lastMessageRef.current;
        if(lastMessageDiv){
            let lastChild = lastMessageDiv.lastChild;
            if(lastChild){
                lastMessageDiv.scrollTop = lastChild.offsetTop + lastChild.scrollHeight;
            }
        }
    }

    // * scroll to bottom useEffect
    useEffect(() => {
        ScrollToBottom();
    }, [props.group.messages.length, lastMessageRef, messages])

    // * set the messages to be displays by the day they were posted
    useEffect(() => {
        let tempObj = {
            messagesByDate: [{
                date: '',
                messages: []
            }]
        }
        props.group.messages.forEach((message) => {
            let date = new Date(message.date).toString().split(" ").splice(0, 4).join(" ");
            let obj = tempObj.messagesByDate.find((m) => {
                return m.date === date;
            })
            if(obj){
                obj.messages.push(message);
            }
            else{
                tempObj.messagesByDate.push({
                    date: date,
                    messages: [message]
                })
            }
        })
        setMessages(tempObj);
    }, [props.group.groupID, props.group.messages.length, props.group.messages])

    const handleSendMessage = async () => {
        if(!message){
            alert('Cannot send an empty message');
            return;
        }
        let time = Date.now();
        await sendMessage(time);
        await sendRealTimeMessage(time);
        await sendNewMessageNotification();
    }

    async function sendMessage(time){
        axios.post(`${url}friendGroups/sendFriendGroupMessage`, {
            groupID: props.group.groupID,
            message: {
                userID: props.currentUser.userID,
                date: time,
                messageContent: message,
                contentURL: '',
                messageType: 'user'
            }
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            setMessage('');
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    async function sendRealTimeMessage(time){
        let channel = props.ably.channels.get(`friend-group-${props.group.groupID}`);
        channel.publish('new-message', {
            groupID: props.group.groupID,
            message: {
                userID: props.currentUser.userID,
                profileImage: props.currentUser.profileImage,
                userName: props.currentUser.fName + ' ' + props.currentUser.lName,
                messageContent: message,
                date: time,
                messageType: 'user'
            }
        }, () => {
            console.log(`sent to friend-group-${props.group.groupID}`);
        })
    }

    async function sendNewMessageNotification(){
        props.group.members.forEach((member) => {
            if(member.userID !== props.currentUser.userID){
                let channel = props.ably.channels.get(`user-updates-${member.userID}`);
                channel.publish('new-message', {})
            }
        })
    }

    function setImageFlag(dateIndex, index){
        if(messages.messagesByDate[dateIndex].messages[index + 1] !== undefined){
            if(messages.messagesByDate[dateIndex].messages[index + 1].userID !== messages.messagesByDate[dateIndex].messages[index].userID){
                return true;
            }
            else{
                return false;
            }
        }
        return true;
    }

    const handleLeaveGroup = () => {

    }

    const handleShowModal = () => {
        setShowEditModal(true);
    }

    return (
        <Fragment>
            { showEditModal && <FG_EditModal updateGroup={props.updateGroup} group={props.group} closeModal={() => { setShowEditModal(false) }} currentUser={props.currentUser} /> }
            {/* {showEditModal && <EditStudyGroupModal updateGroup={props.updateGroup} group={props.group} closeModal={() => setShowEditModal(false)} currentUser={props.currentUser} />} */}
            <div id="message-application-study-field-container" className="make-grid" >
                <div className="message-application-study-field border-radius-20 scrollbar-mch-thin" ref={lastMessageRef} >
                    {messages && messages.messagesByDate && messages.messagesByDate.map((date, dateIndex) => {
                        if(date.date != '' && date.messages.length){
                            return (
                                <Fragment>
                                    <div className="date-separator" >{date.date}</div>
                                    {date.messages && date.messages.map((message, index) => {
                                        return message.userID === props.currentUser.userID ? (
                                            <RightAlignedMessage userImage={props.currentUser.profileImage} key={index} message={message} imageFlag={setImageFlag(dateIndex, index)} />
                                        ) : (
                                            <LeftAlignedMessage messageType={message.messageType} foreignUserImage={message.profileImage} foreignUserName={message.userName} key={index} message={message} imageFlag={setImageFlag(dateIndex, index)} />
                                        )
                                    })}
                                </Fragment>
                            )
                        }
                    })}
                </div>
                <div className="message-app-input-field">
                    <ChatTextArea handleSendMessage={handleSendMessage} setMessage={setMessage} message={message} />
                </div>
            </div>
            <FG_Members ably={props.ably} removeGroup={props.removeGroup} handleShowModal={handleShowModal} updateGroup={props.updateGroup} group={props.group} currentUser={props.currentUser} />
        </Fragment>
    )
}

export default FG_ChatField;