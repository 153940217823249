import { React, useState, Fragment } from "react";
import getGlobalURL from "../../assets/scripts/Global";
import axios from "axios";
import { Realtime } from "ably";
import { FoundBadToken } from "../../scripts/badToken";

// ! PROPS: foreignUserID, currentUserID
const MessageModal = (props) => {
   const url = getGlobalURL();

   const [message, setMessage] = useState("");

   const sendMessage = () => {
      if (!message) {
         alert("Message cannot be empty");
         return;
      }
      let conversationID = Date.now();
      axios
         .post(
            `${url}conversations/createConversation`,
            {
               sender: props.currentUser,
               receiver: props.foreignUser,
               message,
               contentUrl: "",
               conversationID,
               senderImage: props.currentUser.profileImage,
               senderName:
                  props.currentUser.fName + " " + props.currentUser.lName,
               receiverImage: props.foreignUser.profileImage,
               receiverName:
                  props.foreignUser.fName + " " + props.foreignUser.lName,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         )
         .then((response) => {
            if (response.status === 200) {
               console.log(JSON.stringify(response));
               if (response.data.createConvo) {
                  props.currentUser.messages.push(conversationID);
               }
               let ably = new Realtime({
                  key: "_qz3Mg.yTUoGQ:GCDz6WRRVIXpbX5O0GiQ6PQOND6Sq9xdV-JInkuRNzM",
               });
               let channel = ably.channels.get(
                  `new-messages-alert-${props.foreignUser.userID}`
               );
               channel.publish("new-message", {}, () => {
                  console.log("new message alert sent");
               });
               props.handleCloseMessageModal();
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
   };

   return (
      <div className="modal-container">
         <div
            className="modal-backdrop"
            onClick={props.handleCloseMessageModal}
         />
         <div className="create-event-modal-content">
            <div className="form-container">
               <div className="form-header">Send Message</div>
               <div
                  style={{ background: "white" }}
                  id="manage-members-container"
               >
                  <div className="form-body">
                     <label htmlFor="message">Message:</label>
                     <textarea
                        id="message"
                        onChange={(e) => setMessage(e.target.value)}
                     />
                     <button onClick={sendMessage}>Send Message</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default MessageModal;
