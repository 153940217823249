import getGlobalURL from "../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import SuggestedFriendsListing from "./SuggestedFriendsListing";
import { FoundBadToken } from "../../scripts/badToken";

const SuggestedFriends = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [suggestedFriendsList, setSuggestedFriendsList] = useState([]);

   // Get 5 suggested oceans that the user is not already a member of. Store in state variable

   useEffect(() => {
      axios.get(`${url}users/getSuggestedFriends/${props.currentUser.userID}`, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         setSuggestedFriendsList(response.data);
      }).catch((error) => {
         if(error.response.status === 401){
            FoundBadToken();
         }
         else{
            alert(error)
         }
      });
   }, []);

   return (
      <Fragment>
         <div id="suggested-oceans-container">
            <h2>Suggested Friends</h2>
            {/* map through suggested oceans list here */}
            {suggestedFriendsList.sort().map((friend) => {
               return (
                  <Fragment key={friend.userID}>
                     <SuggestedFriendsListing currentUser={props.currentUser} friend={friend} />
                  </Fragment>
               );
            })}
            <hr />
         </div>
      </Fragment>
   );
};

export default SuggestedFriends;
