import React from 'react'
import GraphiteCardbackground from '../../assets/images/tu_graphite_card_background_169.png';
// import GoldCardBackground from '../../assets/images/tu_gold_card_background_169.png'

const SubjectUpdatesCard = (props) => {

    const handleClick = () => {
        props.handleClick();
    }

    return (
        <div id='card-container' onClick={handleClick} className='clickable border-radius-25'>
            <div id='club-org-cover-img-container-inset-shadow'></div>
            <div id='club-org-cover-img-container'>
                <img src={GraphiteCardbackground}/>
            </div>
            <div id='card-text-field'>
                <p>{`${props.subject} Tutoring Center Updates`}</p>
            </div>
        </div>
    )
}

export default SubjectUpdatesCard