import React from 'react'
import "../../../assets/stylesheets/offCampusHousing.css"

const OffCampusHousing = () => {
    return (
        <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src="https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/oceanBanners%2FHousing?alt=media&token=58b0a1d5-766f-472f-805b-4c042db8999d"/>
            <div className="content-placeholder-text-area">
                <h2 className='highlight'>Discover Nearby Housing Options</h2>
                <p>COMING SOON! Welcome to our upcoming Off-Campus Housing section! We’re excited to inform you that we’re developing a resource to help you find nearby housing options tailored for students. This section will provide you with listings and contact information for various rental properties.</p>

                <h4>What’s Ahead:</h4>
                <ul>
                    <li><strong>Browse Nearby Listings:</strong> You’ll soon be able to explore a variety of housing options close to campus.</li>
                    <li><strong>View Details:</strong> Access key information such as location, price range, and amenities for each listing.</li>
                    <li><strong>Contact Information:</strong> Get the contact details for landlords or property managers directly from the listings.</li>
                    <li><strong>Stay Updated:</strong> Keep track of the latest housing options that meet your needs.</li>
                </ul>

                <p>We’re dedicated to bringing you this helpful resource and will provide updates as development progresses. Stay tuned!</p>

                <p>Thank you for your patience and support!</p>
            </div>
        </div>
    )
}

export default OffCampusHousing

// import DefaultBanner from '../../../assets/images/tu_gold_card_background_169.png'
// <img className="background-img-contain" src={DefaultBanner}/>