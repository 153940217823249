import getGlobalURL from '../../assets/scripts/Global'
import { React, useState, useEffect } from 'react'
import axios from "axios";
import {storage} from "../../firebase";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import FormCancelSubmitBtns from './FormCancelSubmitBtns';
import InputWithObject from '../../components/atoms/InputWithObject';
import { DefaultAvatar } from '../../assets/scripts/mchScripts';
import DefaultBanner from "../../assets/images/tu_graphite_card_background_banner.png"
import ConvertToBase64Simple from '../../components/helperComponents/ConvertToBase64Simple';
import SignUpButton from '../../components/atoms/SignUpButton';
import FormMessageField from './FormMessageField';
import PasswordPolicy from './PasswordPolicy';
import TextAreaWithObject from '../../components/atoms/TextAreaWithObject';

const CreateUserAccount = (props) => {
    const url = getGlobalURL();
    const [profileImageFile, setProfileImageFile] = useState(null);
    const [bannerImageFile, setBannerImageFile] = useState(null);
    const [validPassword, setValidPassword] = useState(false);
    const [formMessage, setFormMessage] = useState("Hello there! We're excited to have you join us! Please fill out the form to create your account.");
    const [focussedField, setFocussedField] = useState(null);
    const [profileImagePreview, setProfileImagePreview] = useState(DefaultAvatar);
    const [bannerImagePreview, setBannerImagePreview] = useState(DefaultBanner);
    const [verifyPassword, setVerifyPassword] = useState("");
    const [userData, setUserData] = useState({
        fName: "",
        lName: "",
        email: "",
        username: "",
        password: "",
        bio: "",
        city: "",
        state: "",
        profileImage: profileImagePreview,
        bannerImage: bannerImagePreview,
        hubs: []
    });

    const oceansArray = ["Towson University"];
    // Temporary
    const enrolled_courses = {
        courseID: 484,
        channel: [{
            channelName: 'Announcements',
            messageCount: 7,
        }]
    }

    const createUser = async () => {
        // const pattern = /@(students\.)?towson\.edu$/;
        
        // if (!pattern.test(userData.email)) {
        //     setFormMessage("You must use a valid Towson email address!");
        // } else 
        
        if (userData.password !== verifyPassword) {
            setFormMessage("Passwords do not match!");
        } else if (validPassword === false) {
            setFormMessage("Password does not meet our password policy!");
        } else {
            let userID = Date.now();
            let profileImgUrl = "";
            let bannerImgUrl = "";
    
            if (profileImageFile != null) {
                profileImgUrl = await uploadProfileImage(userID);
            }
            if (bannerImageFile != null) {
                bannerImgUrl = await uploadBannerImage(userID);
            }

            if(profileImageFile === null || bannerImageFile === null){
            // This test aint right.... Could be improved.
                axios.post(url + "users/createUser", {
                    fName: userData.fName,
                    lName: userData.lName,
                    username: userData.username,
                    email: userData.email,
                    password: userData.password,
                    oceans: ["Towson University", "TU Clubs"],
                    bio: userData.bio,
                    userID: userID,
                    joinDate: Date.now(),
                    state: userData.state,
                    city: "",
                    profileImage: userData.profileImage,
                    bannerImage: userData.bannerImage,
                    role: "Student",
                    enrolled_courses: enrolled_courses
                })
                .then((response) => {
                    alert("Welcome! You will be redirected to the login page.");
                    oceansArray.length = 0;
                    props.closeModal();
                })
                .catch((error) => {
                    alert("there was an error creating the account");
                    console.log(error);
                });
            } else{
                axios.post(url + "users/createUser", {
                    fName: userData.fName,
                    lName: userData.lName,
                    username: userData.username,
                    email: userData.email,
                    password: userData.password,
                    oceans: oceansArray,
                    bio: userData.bio,
                    userID: userID,
                    joinDate: Date.now(),
                    state: userData.state,
                    city: userData.city,
                    profileImage: profileImgUrl,
                    bannerImage: bannerImgUrl,
                    role: "Student",
                    enrolled_courses: enrolled_courses
                    }).then((response) => {
                        alert("Welcome!");
                        console.log(response);
                        oceansArray.length = 0;
                        props.closeModal();
                    }).catch((error) => {
                        alert("there was an error creating the account");
                        console.log(error);
                    });
            }
        }
    };

    async function uploadProfileImage(userID) {
        if (!profileImageFile) {
            console.log("RETURNING FROM PROFILE IMAGE");
            return;
        }
        const profileImageRef = ref(storage, `profileImages/${userID}`);
        return new Promise((resolve, reject) => {
            uploadBytes(profileImageRef, profileImageFile).then((response) => {
                getDownloadURL(profileImageRef).then((profileUrl) => {
                    setUserData({...userData, profileImage: profileUrl});
                    resolve(profileUrl);
                });
            });
        });
    }

    async function uploadBannerImage(userID) {
        if (!bannerImageFile) {
            console.log("RETURNING FROM BANNER IMAGE");
            return;
        }
        const bannerImageRef = ref(storage, `profileBannerImages/${userID}`);
        return new Promise((resolve, reject) => {
            uploadBytes(bannerImageRef, bannerImageFile).then((response) => {
                getDownloadURL(bannerImageRef).then((bannerUrl) => {
                    setUserData({...userData, bannerImage: bannerUrl});
                    resolve(bannerUrl);
                });
            });
        });
    }

    const handleFocus = (focussedOn) => {
        setFocussedField(focussedOn);
    }

    const handleClearImages = () => {
        setBannerImagePreview(DefaultBanner);
        setProfileImagePreview(DefaultAvatar)
    }

    return (
        // <div className='create-user-form-content container-border'>
        <div className='create-account-form-2c-container border-radius-25 make-grid'>   
            <div id='create-account-form-2c-header' className='form-header'>Create Your Account</div>
            <div id='create-account-form-main-body'>
                <div id='create-account-form-lc'>
                    <div className="form-body-img-area border-radius-10">
                        <img src={bannerImagePreview} alt="Banner Image Container"/>
                        <ConvertToBase64Simple textID="banner-1" setFile={setBannerImageFile} setImage={setBannerImagePreview} />
                        <div className='form-body-profile-img-area'>
                            <div className='form-body-profile-img-btn-container'>
                                <img src={profileImagePreview} alt="Profile Image Container"/>
                                <ConvertToBase64Simple textID="profile" setFile={setProfileImageFile} setImage={setProfileImagePreview} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <button className='action-btn-container action-btn clickable' onClick={handleClearImages}>Clear Images</button>
                    <FormMessageField formMessage={formMessage} focussedField={focussedField}/>
                </div>

                <div id='create-account-form-rc' className='scrollbar-mch-thin'>
                    <div id='create-account-2c-form-container'>
                        <div className='form-body form-body-override'>                            
                            <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="fName" labelText="First Name:" data={userData} setData={setUserData}/>
                            <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="lName" labelText="Last Name:" data={userData} setData={setUserData}/>
                            <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="email" labelText="Email:" data={userData} setData={setUserData}/>
                            <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="username" labelText="Username:" data={userData} setData={setUserData}/>
                            <InputWithObject handleFocus={handleFocus} required={true} type="password" forText="password" labelText="Password:" data={userData} setData={setUserData} />
                            {(focussedField === "password") && <PasswordPolicy password={userData.password} setValidPassword={setValidPassword}/>}

                            <label className='vpass-label' htmlFor="vpassword">* Verify Password:</label>
                            <input onFocus={() => handleFocus("vpassword")} required type="password" onChange={(e) => setVerifyPassword(e.target.value)} id="vpassword" />

                            <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="city" labelText="City:" data={userData} setData={setUserData}/>
                            <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="state" labelText="State:" helperText="Abbreviated (e.g. MD)" data={userData} setData={setUserData}/>

                            <TextAreaWithObject handleFocus={handleFocus} forText="bio" labelText="Bio:" data={userData} setData={setUserData} />
                            <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText="Cancel" handleSubmit={createUser} submitText="Create Account" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUserAccount