import {React, useState, useEffect} from 'react'
import axios from 'axios'
import getGlobalURL from '../../../assets/scripts/Global'
import { FoundBadToken } from '../../../scripts/badToken'
import PuffLoader from "react-spinners/PuffLoader";
import HelpfulTool from './HelpfulTool'
import SearchField from '../../helperComponents/SearchField';
import ExpandedToolCard from './ExpandedToolCard';
import DefaultBanner from '../../../assets/images/helpful-tools-banner.jpg'

/**
 * @description - A container that displays a list of helpful tools, a search field and a filter-by-category menu.
 * 
 * @component
 * @param {object} userData - An object that contains the userID and profileUrl
 * @returns {JSX.Element} - A container that displays a list of helpful tools
 * 
 * @example
 * // Renders a container that displays a list of helpful tools
 * <HelpfulToolsField />
 */

const HelpfulToolsField = (props) => {
    const url = getGlobalURL();
    const [fetching, setFetching] = useState(true);
    const [helpfulToolsList, setHelpfulToolsList] = useState([]);
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("All");
    const [recommendationChange, setRecommendationChange] = useState(false);
    const [expandedCard, setExpandedCard] = useState(false);
    const [tool, setTool] = useState({}); // holds the tool object
    const [index, setIndex] = useState(); // holds the selected tool's index

    const tempDescription = "Welcome to the Helpful Tools section! Here, you'll find a diverse set of resources designed to support and enhance your academic journey. Whether you're researching for a paper, brushing up on a subject, or looking for ways to improve your study habits, this section has you covered. From specialized databases and educational platforms to organizational apps and writing aids, these tools cater to a variety of learning needs and preferences. Explore these resources to make your studies more efficient, productive, and enriching. Our goal is to provide you with the tools that will help you succeed academically and beyond."

    useEffect(() => {
        axios.get(`${url}helpfulTools/getHelpfulTools`, {
            headers: { 
                Authorization: sessionStorage.getItem("token") 
            } 
        }).then((response) => {
            setHelpfulToolsList(response.data.sort());
            setFetching(false);
        }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
                FoundBadToken();
            } else {
                alert(error.response.data.err);
            }
        });
    }, [recommendationChange])

    const filterCategories = () => {
        let array = helpfulToolsList.filter((tool) => {
            return (
                tool.title.toLowerCase().startsWith(search.toLowerCase()) && (tool.category === category || category === "All")
            );
        });
        if (array.length === 0) {
            return <h1>No Tools Found!</h1>
        } else {
            return array.map((tool, index) => {
                return (
                    <HelpfulTool
                        handleCardClick={handleCardClick}
                        userData={props.userData}
                        tool={tool}
                        category={category}
                    />
                )
            });
        }
    }

    const handleAddRecommendation = (selectedTool) => {
        axios.patch(`${url}helpfulTools/addRecommendation`, {
            title: selectedTool,
            userData: props.userData
        }, {
            headers: { 
                Authorization: sessionStorage.getItem("token")
            } 
        }).then((response) => {
            setRecommendationChange(!recommendationChange);
        }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
                FoundBadToken();
            } else {
                alert(error.response.data.err);
            }
        });
    }

    const handleRemoveRecommendation = (selectedTool) => {
        axios.patch(`${url}helpfulTools/removeRecommendation`, {
            title: selectedTool,
            userData: props.userData
        }, {
            headers: { 
                Authorization: sessionStorage.getItem("token")
            } 
        }).then((response) => {
            setRecommendationChange(!recommendationChange);
        }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
                FoundBadToken();
            } else {
                alert(error.response.data.err);
            }
        });
    }

    const handleCardClick = (selectedCard) => {        
        const index = helpfulToolsList.findIndex(tool => tool.title === selectedCard);
        if (index !== -1) { // Ensure the card is found in the list
            setIndex(index);
            setTool(helpfulToolsList[index]);
            setExpandedCard(true);
        } else {
            // Handle the case where the selected card is not found
            console.error("Selected card not found in the list");
        }
    }

    const closeModal = () => {
        setExpandedCard(false);
    }

    return (
        <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src={DefaultBanner}/>
            <div className="content-placeholder-text-area">
                <h2 className='highlight'>Helpful Tools</h2>
                <p>{tempDescription}</p>
            </div>
            <div id='helpful-tools-field'>
                {expandedCard && <ExpandedToolCard 
                        handleAddRecommendation={handleAddRecommendation} 
                        handleRemoveRecommendation={handleRemoveRecommendation} 
                        tool={helpfulToolsList[index]} 
                        closeModal={closeModal} 
                        userData={props.userData}
                    />
                }
                <div id="search-orgs-container">
                    <SearchField 
                        title="helpful tools"
                        categories={["All", "General Knowledge & Research", "Online Courses & Tutorials", "Study Tools & Organization"]}
                        setCategory={setCategory} 
                        setSearch={setSearch}
                    />
                </div>
                <div id='helpful-tools-list'>
                    { fetching ? (
                        <PuffLoader color={"#FFA500"} />
                    ) : search === "" && category === "All" ? (
                            helpfulToolsList.map((tool, index) => {
                                return (
                                    <HelpfulTool
                                        key={index}
                                        handleCardClick={handleCardClick}
                                        userData={props.userData}
                                        tool={tool}
                                        category={category}
                                    />
                                )
                            })
                    ) : (
                        filterCategories()
                    )}
                </div>
            </div>
        </div>
    )
}

export default HelpfulToolsField