import ArticleCard from "./ArticleCard";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import getGlobalURL from "../../assets/scripts/Global";
import PuffLoader from "react-spinners/PuffLoader";
import AthleticArticleFilter from "./AthleticArticleFilter";
import { FoundBadToken } from "../../scripts/badToken";

const NewsArticle = (props) => {
   const url = getGlobalURL();

   const [articles, setArticles] = useState([]);
   const [fetching, setFetching] = useState(true);
   const [filter, setFilter] = useState("All");

   useEffect(() => {
      axios
         .get(url + "athletics/getArticles", {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         })
         .then((response) => {
            if (response.status === 200) {
               setArticles(response.data.data);
               setFetching(false);
            } else {
               alert("Error getting articles");
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
   }, []);

   function handleSetFilter(filter) {
      setFilter(filter);
   }

   return (
      <div id="athletics-info-slide">
         {fetching ? (
            <PuffLoader color={"#FFBB00"} />
         ) : (
            <Fragment>
               <AthleticArticleFilter handleSetFilter={handleSetFilter} />
               {filter === "All"
                  ? articles.map((article, index) => {
                       return <ArticleCard key={index} article={article} />;
                    })
                  : articles
                       .filter((article) => {
                          return article.sport_title.includes(filter);
                       })
                       .map((article, index) => {
                          return <ArticleCard key={index} article={article} />;
                       })}
            </Fragment>
         )}
      </div>
   );
};

export default NewsArticle;
