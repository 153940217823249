import getGlobalURL from "../../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import { FoundBadToken } from "../../scripts/badToken.js";
import NotificationCard from './NotificationCard'
import ExpandNotificationModal from "./modals/ExpandNotificationModal.jsx";

const NotificationsField = (props) => {
    const url = getGlobalURL();
    const [notifications, setNotifications] = useState([]);
    const [post, setPost] = useState();
    const [showExpandModal, setShowExpandModal] = useState(false);
    const [notification, setNotification] = useState();

    useEffect(() => {
        axios.get(`${url}users/getNotifications/${props.userID}`, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            },
        }).then((response) => {
            setNotifications(response.data);
        }).catch((error) => {
            if (error.response.status === 401) {
                FoundBadToken();
            } else {
                alert(error.response.data.err);
            }
        })
    }, [])

    const handleShowModal = async (notification) => {
        if(notification.type === 'postNotification') {
            await axios.get(`${url}posts/post/${notification.postID}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            }).then((response) => {
                setPost(response.data);
            }).catch((error) => {
                if (error.code === "ERR_BAD_REQUEST") {
                    // FoundBadToken();
                } else {
                    alert(error.response.data.err);
                }
            })
            setNotification(notification)
            setShowExpandModal(true)
        }
        else{
            setNotification(notification)
            setShowExpandModal(true)
        }
    }

    return (
        <Fragment>
            {showExpandModal && <ExpandNotificationModal currentUser={props.currentUser} post={post} source='expandNotificationModal' notification={notification} closeModal={() => {setShowExpandModal(false)}}/>}
            <div className='notifications-container container-border-heavier border-radius-15 scrollbar-mch-thin'>
                {notifications.length > 0 && [...notifications].reverse().map((notification, index) => {
                    return(
                        <NotificationCard currentUser={props.currentUser} handleShowModal={handleShowModal} key={index} notification={notification}/>   
                    )
                })}
            </div>
        </Fragment>
    )
}

export default NotificationsField