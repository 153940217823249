import getGlobalURL from "../assets/scripts/Global";
import React, {useEffect, useState, Fragment} from "react";
import axios from "axios";
import Post from "./Post";
import PostExpandModal from "../Modals/postModals/PostExpandModal";
import InfiniteScroll from "react-infinite-scroll-component";
import PuffLoader from "react-spinners/PuffLoader";

const PostsField = (props) => {
  // Incoming props from Dashboard page: 'oceanSelected', 'userID', 'currentUser', 'postMade', 'source = "dashboard"'
  // Incoming props from Profile page: 'oceanSelected', 'userID', 'currentUser', 'postMade', 'source = "profile"'
  // Incoming props from Display User page: 'oceanSelected', 'foreignUser as current user', 'userID as foreign user', 'currentUser as foreign user', 'source = "displayUser"'

  // ##################### URL change for Vercel##############################
  const url = getGlobalURL();
  if (url === "https://oceans-server-prod-v1.vercel.app") { axios.defaults.withCredentials = true; }
  // ##################### END OF URL change for Vercel#######################

  // QUESTION: Can we just use prop below instead of creating a new variable 'ocean'?
  let ocean = (props.source == "TutoringField")? props.subjectSelected: props.oceanSelected;

  // State variable set after data returns from the route call. Holds arrray of posts by ocean selected
  const [listOfPosts, setListOfPosts] = useState([]);

  // State variable changed when the user clicks 'like' within the <Post /> component. Triggers useEffect
  const [likeFlag, setLikeFlag] = useState();

  // State variable....
  const [postDelete, setPostDelete] = useState(false);

  // When user clicks a 'post title area', these state variables will be used for the modal
  // modalStatus - when true, the short circuiting is prevented allowing the modal to render
  // modalPostID - stores the postID of the post where the click originated from
  const [modalStatus, setModalStatus] = useState(false);
  const [modalPostID, setModalPostID] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(1);

  useEffect(() => {
    // GET the array of posts by the ocean that was selected. Listening to changes in 
    // the incoming ocean selected through props, 'like' changes, and postDelete clicks 
    setListOfPosts([]);
    setIndex(1);
    if(ocean){
      let initialIndex = 1;
      axios.get(`${url}posts/oceanPosts/${ocean}/${initialIndex}/${props.currentUser.userID}`, {
        headers: {
          "Authorization": sessionStorage.getItem("token")
        }
      }).then((response) => {
        if(response.status == 200){
          console.log(`response getting ocean posts: ${JSON.stringify(response.data)}`)
          if(response.data.length == 0){
            setHasMore(false);
          }
          else{
            setListOfPosts(response.data);
            setIndex(index + 1);
            setHasMore(true);
          }
        }
        else{
          console.log('ERROR RIGHT HERE!!!!!!!')
        }
      }).catch((error) => {
        // alert(error.response.data.err);
      })
    }
    else{
      // alert('no ocean')
    }
  }, [ocean, postDelete, props.postMade]);


  // Once the listofPosts array is returned from the axios call, grab the post where the call was made and send it through to
  // the <PostExpandModal /> component. The logic prevents checking array with undefined modalPostID, which is the initial case
  let modalPost = (modalPostID === undefined) ? "" : listOfPosts.find((element) => element.postID === modalPostID);
  

  const handleLikeFlag = (countChange, postID, likeFlag) => {
    // This function runs when the 'like' button is pressed. The like flag is set with the new number of likes
    // only to ensure that useEffect sees a change. The modalPostID state variable is also updated in order to
    // pull out the post where the like change originated from the new array of posts returned by the axios call
    
    setListOfPosts(prevPosts => prevPosts.map((post) => {
      if(post.postID === postID){
        const updatedPost = {
          ...post,
          hasLiked: likeFlag ? true : false,
          likes: likeFlag ? post.likes + 1 : post.likes - 1,
        };
        console.log(updatedPost); // log the updated post
        return updatedPost;
      }
      else{
        return post;
      }
    }));
  }

  function handlePostTitleClick(postID) {
    // This function runs when the user clicks on the 'post title field' from any of the posts. The modalStatus
    // state variable is changed to prevent the short circuiting in the return below, and the postID is grabbed
    // from the post where the click originated from to be used in pulling out the post from the new array
    setModalStatus(true);
    setModalPostID(postID);
  }

  function handlePostDelete() {
    // This function runs when.....
    setPostDelete(!postDelete);
    setModalStatus(false);
  }

  const closeModal = () => {
    // This function is called from within <PostExpandModal /> component when the user clicks on the modal backdrop.
    // By setting the modalStatus to false, short circuiting takes place in the logic within the return, which prevents
    // the <PostExpandModal /> component from being rendered.
    setModalStatus(false);
  }

  const fetchMoreData = async () => {
    try {
      await axios.get(`${url}posts/oceanPosts/${ocean}/${index}/${props.currentUser.userID}`, {
        headers: {
          "Authorization": sessionStorage.getItem("token")
        }
      }).then((response) => {
        if(response.status == 200){
          if(response.data.length == 0){
            setIndex(1);
            setHasMore(false);
          }
          else{
            setListOfPosts([...listOfPosts, ...response.data]);
            setIndex(index + 1);
            setHasMore(true);
          }
        }
        else{
          console.log('ERROR RIGHT HERE!!!!!!!')
        }
      })
    } catch (err) {
      // alert(err.response.data.err);
      setHasMore(false);
    }
  };

  return (
    <Fragment>
      {/* if modalStatus == true then modal pops up, else short circuiting takes place*/}
      {modalStatus && <PostExpandModal foreignUser={props.foreignUser} currentUser={props.currentUser} post={modalPost} handleLikeFlag={handleLikeFlag} handlePostTitleClick={handlePostTitleClick} closeModal={closeModal} modalStatus={modalStatus} userID={props.userID} setLikeFlag={likeFlag} handlePostDelete={handlePostDelete} source={props.source} ocean={ocean}/>}
      {props.tabSelected === "My Clubs" ? (
        <InfiniteScroll
        dataLength={listOfPosts.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<PuffLoader color="#006994" />}
        scrollThreshold={1.0}
      >
        {listOfPosts.filter((post) => props.currentUser.following.includes(post.userPosterID)).map((post) => {
          const userLiked = post.usersLiked.includes(props.currentUser.userID);
          return (
            <Fragment key={post.postID}>
              {/* passing in foreign user when its used in DisplayUser. DisplayUser needs to check if the foreignUser.userID === post.userPosterID */}
                <Post currentUser={props.currentUser} post={post} handleLikeFlag={handleLikeFlag} userLiked={userLiked} closeModal={closeModal} handlePostTitleClick={handlePostTitleClick} modalStatus={modalStatus} userID={props.userID} handlePostDelete={handlePostDelete} source={props.source} ocean={ocean} foreignUser={props.foreignUser} />
            </Fragment>
          )
        })}
      </InfiniteScroll>
      ) : (
        <InfiniteScroll
        dataLength={listOfPosts.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<PuffLoader color="#006994" />}
        scrollThreshold={1.0}
      >
        {listOfPosts.map((post) => {
          return (
            <Fragment key={post.postID}>
              {/* passing in foreign user when its used in DisplayUser. DisplayUser needs to check if the foreignUser.userID === post.userPosterID */}
                <Post currentUser={props.currentUser} post={post} handleLikeFlag={handleLikeFlag} closeModal={closeModal} handlePostTitleClick={handlePostTitleClick} modalStatus={modalStatus} userID={props.userID} handlePostDelete={handlePostDelete} source={props.source} ocean={ocean} foreignUser={props.foreignUser} />
            </Fragment>
          )
        })}
      </InfiniteScroll>
      )}
    </Fragment>
  )
}

export default PostsField