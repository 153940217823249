import { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import getGlobalURL from "../../assets/scripts/Global";
import { Realtime } from "ably";
import PuffLoader from "react-spinners/PuffLoader";
import StudyGroupListing from "./StudyGroupListing";
import StudyGroup from "./StudyGroup";
import { FoundBadToken } from "../../scripts/badToken";
import DefaultStudyGroupIcon from '../../assets/images/DefaultStudyGroupIcon.png'
import CreateStudyGroupModal from "./CreateStudyGroupModal";
import SearchField from "../helperComponents/SearchField";
import GroupUsersIcon from "../icons/GroupUsersIcon";
import DefaultBanner from '../../assets/images/study-groups-banner.jpg'

/**
 * @description - A component that displays a search field and a filter-by-category menu and a list of study groups.
 * 
 * @component
 * @param {object} ably - The ably object
 * @param {object} currentUser - The current user object
 * @returns {JSX.Element} - A component that displays a search field and a filter-by-category menu and a list of study groups
 * 
 * @example
 * // Renders a component that displays a search field and a filter-by-category menu and a list of study groups
 * <StudyGroupField 
 *    ably={props.ably} 
 *    currentUser={props.currentUser} 
 * />
 */

// ! PROPS COMING FROM AcademicsTab.jsx: currentUser
const StudyGroupField = (props) => {
   const url = getGlobalURL();

   const [studyGroupSelected, setStudyGroupSelected] = useState();
   const [channelSelected, setChannelSelected] = useState();
   const [studyGroupList, setStudyGroupList] = useState([]);
   const [fetching, setFetching] = useState(true);

   const [studyGroupSearched, setStudyGroupSearched] = useState();
   const [studyGroupCategory, setStudyGroupCategory] = useState("All");

   const [showModal, setShowModal] = useState(false);

   const [activeStudyGroup, setActiveStudyGroup] = useState();

   const tempDescription = "Welcome to the Study Groups section! Here, you can join forces with fellow students to enhance your learning experience through collaborative study. Whether you’re looking for support in a specific subject or just want to share insights and resources, our study groups offer a flexible and dynamic environment to suit your needs. You can join existing public groups to connect with a wider community or opt for private groups for a more focused, intimate study experience. Additionally, you have the option to create your own group, tailoring it to your specific academic goals and interests. Dive in, connect with peers, and take your study sessions to the next level!";

   useEffect(() => {
      axios.get(`${url}studyGroups/getAllStudyGroups/${props.currentUser.userID}`,{
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
            if (response.status === 200) {
               console.log(response.data);
               setStudyGroupList(response.data);
               setFetching(false);
            }
      }).catch((error) => {
            if (error.response.status === 401) {
               alert('Session expired. Please log in again.')
               FoundBadToken();
            }
            else {

            }
      });
   }, []);

   const handleGroupChannelClicked = (groupID, channelClickedName) => {
      console.log("groupID: ", groupID);
      console.log("channelClickedName: ", channelClickedName);
      setStudyGroupSelected(groupID);
      setChannelSelected(channelClickedName);
      studyGroupList.forEach((group) => {
         if (group.studyGroupID === groupID) {
            console.log("found active group: ", group);
            setActiveStudyGroup(group);
         }
      });
   };

   const showStudyGroupModal = () => {
      setShowModal(true);
   }

   const handleStudyGroupMade = (newGroup) => {
      setStudyGroupList([...studyGroupList, newGroup]);
   }

   const handleStudyGroupDeleted = (groupID) => {
      console.log("groupID: ", groupID);
      const newGroups = studyGroupList.filter((group) => {
         return group.studyGroupID !== groupID;
      })
      setStudyGroupList(newGroups);
   }

   const handleFilterGroups = () => {
      if (studyGroupCategory === "All"){
         if(studyGroupSearched){
            const filteredGroups = studyGroupList.filter((group) => {
               return group.studyGroupName.toLowerCase().startsWith(studyGroupSearched.toLowerCase());
            })
            return (
               filteredGroups.map((group, index) => {
                  if(group.studyGroupID !== 1709259029266){
                     return (
                        <StudyGroupListing ably={props.ably} handleStudyGroupDeleted={handleStudyGroupDeleted} key={index} currentUser={props.currentUser} group={group} />
                     )
                  }
               })
            )
         }
         else{
            return (
               studyGroupList.map((group, index) => {
                  if(group.studyGroupID !== 1709259029266){
                     return (
                        <StudyGroupListing ably={props.ably} handleStudyGroupDeleted={handleStudyGroupDeleted} key={index} currentUser={props.currentUser} group={group} />
                     )
                  }
               })
            )
         }
      }
      else{
         if(studyGroupSearched){
            const filteredGroups = studyGroupList.filter((group) => {
               return (
                  group.studyGroupName.toLowerCase().startsWith(studyGroupSearched.toLowerCase()) &&
                  group.subject === studyGroupCategory
               )
            })
            return (
               filteredGroups.map((group, index) => {
                  if(group.studyGroupID !== 1709259029266){
                     return (
                        <StudyGroupListing ably={props.ably} handleStudyGroupDeleted={handleStudyGroupDeleted} key={index} currentUser={props.currentUser} group={group} />
                     )
                  }
               })
            )
         }
         else{
            const filteredGroups = studyGroupList.filter((group) => {
               return group.subject === studyGroupCategory;
            })
            return (
               filteredGroups.map((group, index) => {
                  if(group.studyGroupID !== 1709259029266){
                     return (
                        <StudyGroupListing ably={props.ably} handleStudyGroupDeleted={handleStudyGroupDeleted} key={index} currentUser={props.currentUser} group={group} />
                     )
                  }
               })
            )
         }
      }
   }
   
   return (
      <Fragment>
         {showModal && <CreateStudyGroupModal handleStudyGroupMade={handleStudyGroupMade} currentUser={props.currentUser} closeModal={() => {setShowModal(false)}} />}
         <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src={DefaultBanner}/>
            <div className="content-placeholder-text-area">
                  <h2 className='highlight'>Study Groups</h2>
                  <p>{tempDescription}</p>
            </div>
            <div id='study-groups-field'>
               <div id='search-orgs-container' >
                  <SearchField 
                     categories={["All", "ASTR", "COSC", "ENGL", "MATH"]} 
                     setCategory={setStudyGroupCategory} 
                     setSearch={setStudyGroupSearched}
                     title="study groups"
                  />
               </div>
               <div onClick={showStudyGroupModal} className="action-btn-container btn-ease clickable">
                  <GroupUsersIcon width="30px" height="30px" color="var(--towson-gold)" />
                  <span className="action-btn">Create Study Group</span>
               </div>
               <div id='helpful-tools-list'>
                  {handleFilterGroups()}
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default StudyGroupField;
