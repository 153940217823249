import { React, useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import getGlobalURL from "../../assets/scripts/Global";
import Footer from "../../components/Footer";
import Business from "../../components/campusModeComponents/businesses/Business";
import $ from "jquery";
import BusinessSlide from "../../components/campusModeComponents/businesses/BusinessSlide";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NavHeaderBusiness from "../../components/NavHeaderBusiness";
import { FoundBadToken } from "../../scripts/badToken";

const BusinessPortal = (props) => {
   let location = useLocation();
   let currentBusiness = location.state.currentBusiness;
   const url = getGlobalURL();

   // Event State
   const [eventData, setEventData] = useState({
      title: "",
      start: "",
      end: "",
      description: "",
      priceRange: "",
      eventID: Date.now(),
      academic: false,
      assignment: false,
      personal: false,
      business: true,
   });

   // Business Info State Variables
   const [businessData, setBusinessData] = useState({
      fName: currentBusiness.fName,
      lName: currentBusiness.lName,
      businessPhone: currentBusiness.businessPhone,
      businessAddress: currentBusiness.businessAddress,
      businessCity: currentBusiness.businessCity,
      businessState: currentBusiness.businessState,
      businessZip: currentBusiness.businessZip,
      businessDescription: currentBusiness.businessDescription,
      businessBanner: currentBusiness.businessBanner,
      businessUrl: currentBusiness.businessUrl,
   });

   const [bannerImage, setBannerImage] = useState(
      currentBusiness.businessBanner
   );
   const [file, setFile] = useState(null);

   // Shared State Variables
   // const [file, setFile] = useState(false);
   // const [loading, setLoading] = useState(false);
   const [businessClicked, setBusinessClicked] = useState(false);
   const [businessUpdatedFlag, setBusinessUpdatedFlag] = useState(false);
   const [eventUploadedFlag, setEventUploadedFlag] = useState(false);

   const addEvent = () => {
      if (eventData.title !== "" && eventData.start !== "" && eventData.description !== ""){
         axios.patch(`${url}businesses/addBusinessEvent`,{
            businessName: currentBusiness.businessName,
            eventData: eventData,
         },{
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }).then((response) => {
            if (response.status === 200) {
               setEventData({
                  title: "",
                  start: "",
                  end: "",
                  description: "",
                  priceRange: "",
               });
               setEventUploadedFlag(!eventUploadedFlag);
               alert("Event uploaded!");
            }
         }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
      } else {
         alert("Please ensure that the form is completely filled out.");
      }
   };

   useEffect(() => {}, [eventUploadedFlag, businessUpdatedFlag]);

   useEffect(() => {
      $("#business-info-slide").slideToggle("fast", "swing");
   }, [businessClicked]);

   const handleBusinessClick = () => {
      setBusinessClicked(!businessClicked);
      $("#business-info-slide").slideToggle("fast", "swing");
   };

   const handleSubmit = () => {
      const imageRef = ref(storage, `businessBanners/${currentBusiness.businessID}`);
      if (file === null) {
         axios.patch(`${url}businesses/updateBusiness`,{
            businessName: currentBusiness.businessName,
            businessBanner: businessData.businessBanner,
            fName: businessData.fName,
            lName: businessData.lName,
            businessPhone: businessData.businessPhone,
            businessAddress: businessData.businessAddress,
            businessCity: businessData.businessCity,
            businessState: businessData.businessState,
            businessZip: businessData.businessZip,
            businessDescription: businessData.businessDescription,
            businessUrl: businessData.businessUrl,
         },{
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }).then((response) => {
               if (response.status === 200) {
                  currentBusiness.fName = businessData.fName;
                  currentBusiness.lName = businessData.lName;
                  currentBusiness.businessPhone = businessData.businessPhone;
                  currentBusiness.businessAddress = businessData.businessAddress;
                  currentBusiness.businessCity = businessData.businessCity;
                  currentBusiness.businessState = businessData.businessState;
                  currentBusiness.businessZip = businessData.businessZip;
                  currentBusiness.businessDescription = businessData.businessDescription;
                  currentBusiness.businessBanner = businessData.businessBanner;
                  currentBusiness.businessUrl = businessData.businessUrl;
                  setBusinessUpdatedFlag(!businessUpdatedFlag);
                  alert("Business data updated!");
               }
         }).catch((error) => {
               if (error.code === "ERR_BAD_REQUEST") {
                  FoundBadToken();
               } else {
                  alert(error);
               }
            });
      } else {
         uploadBytes(imageRef, file).then((response) => {
            getDownloadURL(imageRef).then((imageUrl) => {
               axios.patch(`${url}businesses/updateBusiness`, {
                  businessName: currentBusiness.businessName,
                  fName: businessData.fName,
                  lName: businessData.lName,
                  businessPhone: businessData.businessPhone,
                  businessAddress: businessData.businessAddress,
                  businessCity: businessData.businessCity,
                  businessState: businessData.businessState,
                  businessZip: businessData.businessZip,
                  businessDescription: businessData.businessDescription,
                  businessUrl: businessData.businessUrl,
                  businessBanner: imageUrl,
               },{
                  headers: {
                     Authorization: sessionStorage.getItem("token"),
                  },
               }).then((response) => {
                  if (response.status === 200) {
                     currentBusiness.fName = businessData.fName;
                     currentBusiness.lName = businessData.lName;
                     currentBusiness.businessPhone = businessData.businessPhone;
                     currentBusiness.businessAddress = businessData.businessAddress;
                     currentBusiness.businessCity = businessData.businessCity;
                     currentBusiness.businessState = businessData.businessState;
                     currentBusiness.businessZip = businessData.businessZip;
                     currentBusiness.businessDescription = businessData.businessDescription;
                     currentBusiness.businessBanner = imageUrl;
                     currentBusiness.businessUrl = businessData.businessUrl;
                     alert("Business data updated!");
                  }
               }).catch((error) => {
                     if (error.code === "ERR_BAD_REQUEST") {
                        FoundBadToken();
                     } else {
                        alert(error);
                     }
                  });
            });
         });
      }
   };

   const convertToBase64 = (e) => {
      setFile(e.target.files[0]);
      var reader = new FileReader();
      if (e.target.files[0]) {
         reader.readAsDataURL(e.target.files[0]);
         reader.onload = function () {
            setBannerImage(reader.result);
            console.log("Business Banner: " + bannerImage);
         };
      }
   };

   const handleClosePreviewClick = () => {
      // SetPostImage does not seem to be resetting the state variable back to "" in time.
      setBannerImage(currentBusiness.businessBanner);
   };

   return (
      <Fragment>
         <NavHeaderBusiness currentBusiness={currentBusiness} />
         <div id="current-business-main-container">
            <div id="business-portal-text-area" className="container-border">
               <div className="form-header">INSTRUCTIONS</div>
               <div className="form-instructions">
                  <h3>Welcome to your business portal!</h3>
                  <p>
                     Here you'll be able to create an event and load the details
                     into your calendar as well as edit your business details.
                     The business component below shows you what the students
                     see when they visit the 'Around Town' section of
                     MyCampusHubs. Click on the image to expand the slide for
                     more information.
                  </p>
               </div>
            </div>
            <div id="business-preview-container">
               <Fragment key={currentBusiness.businessName}>
                  <Business
                     handleBusinessClick={handleBusinessClick}
                     business={currentBusiness}
                     businessClicked={businessClicked}
                  />
                  {businessClicked ? (
                     <BusinessSlide
                        id="business-info-slide"
                        business={currentBusiness}
                        eventUploadedFlag={eventUploadedFlag}
                        source="Business"
                     />
                  ) : null}
               </Fragment>
            </div>
            <div id="business-forms-area">
               <div className="form-container container-border">
                  <div className="form-header">CREATE AN EVENT</div>
                  <div className="form-body">
                     <label htmlFor="eventName">Event Name:</label>
                     <input
                        type="text"
                        value={eventData.title}
                        onChange={(e) =>
                           setEventData({ ...eventData, title: e.target.value })
                        }
                        id="eventName"
                     />

                     <label htmlFor="start">Start Date:</label>
                     <input
                        type="datetime-local"
                        value={eventData.start}
                        onChange={(e) =>
                           setEventData({ ...eventData, start: e.target.value })
                        }
                        id="startDate"
                     />

                     <label htmlFor="end">End Date</label>
                     <input
                        type="datetime-local"
                        value={eventData.end}
                        onChange={(e) =>
                           setEventData({ ...eventData, end: e.target.value })
                        }
                        id="endDate"
                     />

                     <label htmlFor="priceRange">
                        Price Range {`(enter as $xx - $xx)}`}:
                     </label>
                     <input
                        type="text"
                        value={eventData.priceRange}
                        onChange={(e) =>
                           setEventData({
                              ...eventData,
                              priceRange: e.target.value,
                           })
                        }
                        id="priceRange"
                     />

                     <label htmlFor="eventDescription">
                        Event Description:
                     </label>
                     <textarea
                        value={eventData.description}
                        onChange={(e) =>
                           setEventData({
                              ...eventData,
                              description: e.target.value,
                           })
                        }
                     />

                     {/* BANNER IMAGE LOAD HERE */}

                     <button className="btn-ease" onClick={addEvent}>
                        Submit
                     </button>
                  </div>
               </div>
               <div className="form-container container-border">
                  <div className="form-header">EDIT YOUR BUSINESS DATA</div>
                  <div className="form-body">
                     <label>First Name:</label>
                     <input
                        type="text"
                        value={businessData.fName}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              fName: e.target.value,
                           })
                        }
                     />

                     <label>Last Name:</label>
                     <input
                        type="text"
                        value={businessData.lName}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              LName: e.target.value,
                           })
                        }
                     />

                     <label>Phone Number:</label>
                     <input
                        type="text"
                        value={businessData.businessPhone}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              businessPhone: e.target.value,
                           })
                        }
                     />

                     <label>Address:</label>
                     <input
                        type="text"
                        value={businessData.businessAddress}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              businessAddress: e.target.value,
                           })
                        }
                     />

                     <label>City:</label>
                     <input
                        type="text"
                        value={businessData.businessCity}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              businessCity: e.target.value,
                           })
                        }
                     />

                     <label>State:</label>
                     <input
                        type="text"
                        value={businessData.businessState}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              businessState: e.target.value,
                           })
                        }
                     />

                     <label>Zip:</label>
                     <input
                        type="text"
                        value={businessData.businessZip}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              businessZip: e.target.value,
                           })
                        }
                     />

                     <label>Url:</label>
                     <input
                        type="text"
                        value={businessData.businessUrl}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              businessUrl: e.target.value,
                           })
                        }
                     />

                     <label>Business Description:</label>
                     <textarea
                        value={businessData.businessDescription}
                        onChange={(e) =>
                           setBusinessData({
                              ...businessData,
                              businessDescription: e.target.value,
                           })
                        }
                     />

                     {bannerImage != null ? (
                        <div id="post-preview-container">
                           <div
                              onClick={handleClosePreviewClick}
                              className="close-preview-btn btn-ease"
                           >
                              x
                           </div>
                           <img src={bannerImage} />
                        </div>
                     ) : (
                        ""
                     )}
                     <input
                        type="file"
                        accept="image/*"
                        onChange={convertToBase64}
                        style={{ display: "none" }}
                     />
                     <button
                        className="btn-ease"
                        onClick={() => {
                           document.querySelector('input[type="file"]').click();
                        }}
                     >
                        Add Image
                     </button>
                     <button className="btn-ease" onClick={handleSubmit}>
                        Submit
                     </button>
                  </div>
               </div>
            </div>
            <Footer />
         </div>
      </Fragment>
   );
};

export default BusinessPortal;
