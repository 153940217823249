import getGlobalURL from "../../../../assets/scripts/Global";
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import profileIcon from "../../../../assets/data/images/icons/profile_icon.png";
import emailIcon from "../../../../assets/data/images/icons/email_icon.png";
import resumeIcon from "../../../../assets/data/images/icons/resume_icon.png";
import gitHubIcon from "../../../../assets/data/images/icons/github_icon.png";
import linkedinIcon from "../../../../assets/data/images/icons/linkedin_icon.png";
import { FoundBadToken } from "../../../../scripts/badToken";

const CreatorCard = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   let navigate = useNavigate();

   const handleProfileIconClick = () => {
      if (props.currentUser === null) {
         alert("You must be logged in to visit this user's page!");
      } else {
         props.creatorData.username === props.currentUser.username
            ? navigate("/Profile", {state: { currentUser: props.currentUser }, })
            : axios.get(`${url}users/getUserByUsername/${props.creatorData.username}/${props.currentUser.userID}`, {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }).then((response) => {
               navigate("/DisplayUser", {
                  state: { currentUser: props.currentUser, foreignUser: response.data, },
               });
            }).catch((error) => {
               if (error.response.status === 401) {
                  FoundBadToken();
               } else {
                  alert(error.response.data.err);
               }
            });
      }
   };

   return (
      <div id="creator-card" className="container-border">
         <img
            onClick={handleProfileIconClick}
            id="our-team-profile-image"
            src={require(`../../../../assets/data/images/ourProfileImages/${props.creatorData.imgName}`)}
         />
         <h3>
            {props.creatorData.fName} {props.creatorData.lName}
         </h3>
         <ul>
            <li>
               <img
                  onClick={handleProfileIconClick}
                  src={profileIcon}
                  className="icon"
               />
            </li>
            <li>
               <a href={props.creatorData.gitHub} target="_blank">
                  <img src={gitHubIcon} className="icon" />
               </a>
            </li>
            <li>
               <a href={props.creatorData.linkedIn} target="_blank">
                  <img src={linkedinIcon} className="icon" />
               </a>
            </li>
            <li>
               <a
                  href={`mailto:${props.creatorData.email}?Subject=We%20want%20to%20hire%20you`}
               >
                  <img src={emailIcon} className="icon" />
               </a>
            </li>
            <li>
               <a
                  href={require(`../../../../assets/data/ourResumes/${props.creatorData.resume}`)}
                  target="_blank"
               >
                  <img src={resumeIcon} className="icon" />
               </a>
            </li>
         </ul>
      </div>
   );
};

export default CreatorCard;
