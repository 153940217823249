import { Fragment } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import { storage } from "../../../firebase";
import { ref, getDownloadURL, deleteObject } from "firebase/storage";
import { FoundBadToken } from "../../../scripts/badToken";

// ! PROPS COMING FROM ClassRoom.jsx: message, currentUser, channelSelected, courseCode, course
const ChannelMessage = (props) => {
   const url = getGlobalURL();

   let newDate = new Date(props.message.timestamp).toString().split(" ").splice(0, 4).join(" ");

   const handleDeleteMessage = () => {
      axios.post(`${url}channels/deleteChannelMessage`,{
            courseCode: props.course.courseCode,
            channelName: props.channelSelected,
            messageID: props.message.messageID,
            currentChannel: `${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         if (response.status === 200) {
            if (props.message.file) {
               let deleteRef = ref(
                  storage,
                  `courseFiles/${props.course.courseCode}/${props.channelSelected}/${props.message.fileName}`
               );
               deleteObject(deleteRef)
                  .then((response) => {
                     alert("Message and file deleted");
                  })
                  .catch((error) => {
                     alert(error);
                  });
            }
            if (props.message.img) {
               let deleteRef = ref(
                  storage,
                  `channelImages/${props.course.courseCode}/${props.channelSelected}/${props.message.senderID}/${props.message.imgName}`
               );
               deleteObject(deleteRef)
                  .then((response) => {
                     alert("Message and image deleted");
                  })
                  .catch((error) => {
                     alert(error);
                  });
            } else {
               alert("Message deleted");
            }
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   };

   const handleDownloadFile = () => {
      const fileRef = ref(storage, `courseFiles/${props.course.courseCode}/${props.channelSelected}/${props.message.fileName}`);
      getDownloadURL(fileRef).then((url) => {
         axios.get(url, { responseType: "arrayBuffer" }).then((response) => {
            const blob = new Blob([response.data], {
               type: response.headers["content-type"],
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = props.message.fileName;
            link.style.display = "none";
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
         });
      });
   };

   return (
      <Fragment>
         <div id="channel-message-container" className="make-grid scroll-hide">
            {props.message.isNotification ? (
               <Fragment>
                  <div id="channel-message">
                     <h4>{props.message.message}</h4>
                  </div>
               </Fragment>
            ) : (
               <Fragment>
                  <div id="channel-message-title-container" className="make-grid">
                     <img className="channel-user-avatar" src={props.message.senderPicture ? props.message.senderPicture : require("../../../assets/images/irani_profile.jpg")}/>
                     <div id="channel-message-title-heading" className="make-grid">
                        <h3>{props.message.senderName}</h3>
                        <p>{newDate}</p>
                     </div>
                  </div>
                  <div id="channel-message">
                     {props.message.title && <h4>{props.message.title}</h4>}
                     <p>{props.message.message}</p>
                     {props.message.img && (
                        <Fragment>
                           <img style={{ height: "60%", width: "80%" }} src={props.message.img}/>
                           {" "}
                           <br></br>
                        </Fragment>
                     )}

                     {props.currentUser.userID === props.message.senderID ||
                     props.currentUser.userID === props.course.courseInstructor ? (
                        <button onClick={handleDeleteMessage} className="ocean-header-btns btn-ease">Delete</button>
                     ) : null}
                     {props.message.file && (
                        <button onClick={handleDownloadFile} className="ocean-header-btns btn-ease">Download {props.message.fileName}</button>
                     )}
                     <button onClick={() => {props.handleShowMessageModal(props.message)}} className="ocean-header-btns btn-ease">Reply</button>
                  </div>
               </Fragment>
            )}
         </div>
      </Fragment>
   );
};

export default ChannelMessage;
