import getGlobalURL from "../../../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import { storage } from "../../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../../../scripts/badToken";
import FormCancelSubmitBtns from "../../../../Modals/forms/FormCancelSubmitBtns";
import ConvertToBase64Simple from '../../../helperComponents/ConvertToBase64Simple'
import TextAreaWithObject from "../../../atoms/TextAreaWithObject";
import InputWithObject from "../../../atoms/InputWithObject";

const EditClubModal = (props) => {
   const url = getGlobalURL();

   const [bannerFile, setBannerFile] = useState(null);
   const [clubBannerImage, setClubBannerImage] = useState(props.club.clubBanner);
   const [focussedField, setFocussedField] = useState(null);   // For future implementation
   const [clubData, setClubData] = useState({
      clubName: props.club.clubName,
      description: props.club.description,
      clubBanner: clubBannerImage
   })

   const [updating, setUpdating] = useState(false);

   const handleSubmitClick = async() => {
      setUpdating(true)

      let banner = clubData.clubBanner

      if(bannerFile){
         banner = await uploadBannerImage(props.club.clubName)
      }
      axios.patch(`${url}clubs/updateClub`, {
         clubID: props.club.clubID,
         clubName: clubData.clubName,
         description: clubData.description,
         clubBanner: banner,
         userID: props.currentUser.userID
      }, {
         headers: {
            "Authorization": sessionStorage.getItem("token")
         }
      }).then((response) => {
         props.club.clubName = clubData.clubName
         props.club.description = clubData.description
         props.club.clubBanner = banner
         setUpdating(false)
         props.handleClubEdit()
         props.closeModal("editClub")
      }).catch((error) => {
         if(error.response.status === 401){
            FoundBadToken();
         }
      })
   }

   async function uploadBannerImage(clubName) {
      if (!bannerFile) {
         return;
      }
      const bannerImageRef = ref(storage, `clubBanners/${clubName}`);
      return new Promise((resolve, reject) => {
         uploadBytes(bannerImageRef, bannerFile).then((response) => {
            getDownloadURL(bannerImageRef).then((bannerURL) => {
               setClubBannerImage(bannerURL);
               resolve(bannerURL);
            });
         });
      });
   }

   const handleFocus = (focussedOn) => {
      // For future implementation for handling focus on input fields
      setFocussedField(focussedOn);
   }

   const handleBackdropClick = () => {
      props.closeModal("editClub");
   };

   return (
      <div className='modal-container' >
         <div className='sg-backdrop' onClick={() =>  { handleBackdropClick() }}  />
         <div className='create-account-form-2c-container border-radius-25 make-grid'>
            <div id='create-account-form-2c-header' className='form-header'>{`Edit ${props.club.clubName}`}</div>
            <div id='create-account-form-main-body' >
               <div id='create-account-form-lc' >
                  <div className='form-body-img-area border-radius-10' >
                     <img src={clubBannerImage} alt="Group Icon" />
                     <ConvertToBase64Simple textID="banner-1" setFile={setBannerFile} setImage={setClubBannerImage} />
                  </div>
               </div>
               <div id='create-account-form-rc' >
                  <div id='create-account-2c-form-container' >
                     <div className='form-body form-body-override' >

                        {/* WARNING: updateClub controller currently does not go through to update the name within each user's club list. Do not allow editing of Club Name until that is implemented */}
                        {/* <InputWithObject handleFocus={handleFocus} type="text" forText="clubName" labelText="Club Name:" data={clubData} setData={setClubData} value={clubData.clubName}/> */}

                        <TextAreaWithObject handleFocus={handleFocus} forText="description" labelText="Club Description:" value={clubData.description} data={clubData} setData={setClubData} />
                        <FormCancelSubmitBtns handleCloseForm={handleBackdropClick} closeText='Cancel' handleSubmit={handleSubmitClick} submitText='Update Group' />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
};

export default EditClubModal;

// #FF613A
