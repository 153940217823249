import { React, Fragment } from 'react'

const AthleticButton = (props) => {
  function handleClick() {
    props.handleSetButtonClicked({title: props.title, link: props.link});
  }

  return (
    <Fragment>
      <div id='oceans-list-container-button-area'>
        <button onClick={handleClick} className={`${props.title === 'News' ? 'general-btn' : 'general-btn'} btn-ease ${props.activeButton === props.title && "active"}`}>{props.title}</button>
      </div>
    </Fragment>
  )
}

export default AthleticButton