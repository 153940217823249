export const DefaultAvatar = "https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/defaultImages%2FDefaultProfileImage.png?alt=media&token=8ddf50f7-53ca-49da-9754-8286a766c048";

/**
 * @description - This function adjusted the height of the targeted textarea field.
 * @function
 * @param {event} event
 * @example
 * // Render a textarea whose height adjusts.
 * <textarea onChange={(e) => {setMessage(e.target.value)}} onKeyDown={adjustTextAreaHeight} onKeyUp={adjustTextAreaHeight} rows={1} />          
 */
export const adjustTextAreaHeight = (event) => {
    const textArea = event.target;
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`
}

/**
 * @description - This function returns the difference between Date.now() and a provided time.
 * @function
 * @param {number} timestamp - A value of when the original content was generated
 * @returns - The difference between the current date and time and the provided time in minutes, hours, or days.
 * @example
 * // Render a textarea whose height adjusts.
 * <p>{getElapsedTime(notification.timestamp)}</p>          
 */
export const getElapsedTime = (timestamp) => {
    const now = Date.now();
    const elapsed = now - timestamp;
    const minutes = Math.floor(elapsed / 60000);
    const hours = Math.floor(elapsed / 3600000)
    const days = Math.floor(elapsed / 86400000)

    if (minutes < 60) {
        return `${minutes}m`
    } else if (hours < 24) {
        return `${hours}h`
    } else {
        return `${days}d`
    }
};

export const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

export const formatDateToMDY = (dateStr) => {
    // Parse the input date string into a Date object
    const date = new Date(dateStr);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
    }
    
    // Extract month, day, and year from the date object
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure month is 2 digits
    const year = (date.getFullYear() % 100).toString().padStart(2, '0'); // Ensure year is 2 digits
    
    // Construct the formatted date string
    const formattedDate = `${month}/${year}`;
    
    return formattedDate;
}