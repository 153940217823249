import {Fragment, React, useState, useEffect} from 'react'
import PuffLoader from "react-spinners/PuffLoader";
import ChanneMemberListing from "./ChannelMemberListing";

// ! PROPS COMING FROM ClassRoom.jsx: channelSelected, course, currentUser, closeModal, action, handleSetActionTaken, actionTaken, channel, ably
const ManageChannelMemberModal = (props) => {

    // needs to be a state variable because we will want to re-render the modal to reflect the action
    const [membersList, setMembersList] = useState([]);

    useEffect(() => {
        let channel_list = props.course.channels.find((channel) => {
            return channel.name === props.channelSelected;
        })

        console.log('in manage channel member modal useEffect')

        console.log(channel_list);

        setMembersList(props.action === 'add' ? 
        (props.course.members.filter((member) => {
            return !(channel_list.members.includes(member));
        })) : 
        (props.course.members.filter((member) => {
            return (channel_list.members.includes(member));
        })))
    }, [props.actionTaken, props.course.channels])

    return (
        <div className='modal-container'>
            <div className='modal-backdrop' onClick={props.closeModal} />
            <div className='new-form-container' >
                <div className='new-form-header' >Manage Members</div>
                <div className='new-form-body' >
                    <p className='form-instruction-text' >In this form, you can manage the members in the given channel</p>
                    <hr className='hr-custom' />
                    <div className='member-listing-container scrollbar-mch-thin' >
                        {membersList.map((memberID) => {
                            return (
                                <Fragment key={memberID}>
                                    <ChanneMemberListing channel={props.channel} handleSetActionTaken={props.handleSetActionTaken} action={props.action} memberID={memberID} course={props.course} channelSelected={props.channelSelected} />
                                </Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* <div className='create-post-modal-content'>
                <div id='create-post-form-container'>
                    <div id='modal-title' className='make-grid'>
                        <h1>Manage Members</h1>
                        {props.action === 'add' ? (
                            <h4>{`Add members to ${props.channelSelected}`}</h4>
                        ) : (
                            <h4>{`Remove members from ${props.channelSelected}`}</h4>
                        )}
                    </div>
                    <div id="manage-members-container">
                        {membersList.map((memberID) => {
                            return (
                                <Fragment key={memberID}>
                                    <ChanneMemberListing channel={props.channel} handleSetActionTaken={props.handleSetActionTaken} action={props.action} memberID={memberID} course={props.course} channelSelected={props.channelSelected} />
                                </Fragment>
                            )
                        })}
                    </div>
                </div>
            </div> */}
        </div>
)
}

export default ManageChannelMemberModal