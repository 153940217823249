import { Calendar, momentLocalizer } from "react-big-calendar";
import { React, useEffect, useState } from "react";
import moment from "moment";
import PersonalEventModal from "./PersonalEventModal";
import CreatePersonalEventModal from "./CreatePersonalEventModal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import getGlobalURL from "../../assets/scripts/Global";
import axios from "axios";
import { FoundBadToken } from "../../scripts/badToken";

// ! PROPS COMING FROM NavHeader.jsx: closeModal, currentUser
const PersonalCalendar = (props) => {
   const url = getGlobalURL();

   const [showEventModal, setShowEventModal] = useState(false);
   const [showCreateEventModal, setCreateEventModal] = useState(false);
   const [slotInfo, setSlotInfo] = useState();
   const [eventMade, setEventMade] = useState(false);
   const [events, setEvents] = useState([]);
   const [event, setEvent] = useState();
   const classColors = ["#87CEFA", "#FFC107", "#4CAF50", "#2196F3", "#FF5722"];

   useEffect(() => {
      axios
         .get(`${url}users/getCalendarEvents/${props.currentUser.userID}`, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         })
         .then((response) => {
            if (response.status === 200) {
               let events = response.data.map((event) => {
                  return {
                     ...event,
                     start: new Date(event.start),
                     end: new Date(event.end),
                  };
               });
               setEvents(events);
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
   }, [eventMade]);

   const handleSelectSlot = (slotInfo) => {
      setSlotInfo(slotInfo);
      setCreateEventModal(true);
   };

   const handleCloseEventModal = () => {
      setShowEventModal(false);
      setEvent(null);
   };

   const handleEventMade = () => {
      setEventMade(!eventMade);
   };

   const handleSelectEvent = (event) => {
      setShowEventModal(true);
      setEvent(event);
   };

   const eventStyleGetter = (event) => {
      const now = new Date();
      if (event.end < now) {
         return { style: { backgroundColor: "grey", color: "white" } };
      }
      if (event.personal) {
         return event.priority == 1
            ? { style: { backgroundColor: "red" } }
            : event.priority == 2
            ? { style: { backgroundColor: "yellow", color: "black" } }
            : event.priority == 3
            ? { style: { backgroundColor: "green" } }
            : {};
      }
      if (event.academic && event.assignment) {
         const index = props.currentUser.enrolled_courses.findIndex(
            (course) => {
               return course.courseID === event.courseCode;
            }
         );
         console.log("index", index);
         console.log("color", classColors[index]);
         return {
            style: { backgroundColor: `${classColors[index]}`, color: "black" },
         };
      }
   };

   return (
      <div className="modal-container">
         {showEventModal && (
            <PersonalEventModal
               event={event}
               handleCloseEventModal={handleCloseEventModal}
               currentUser={props.currentUser}
               handleEventMade={handleEventMade}
            />
         )}
         {showCreateEventModal && (
            <CreatePersonalEventModal
               currentUser={props.currentUser}
               slotInfo={slotInfo}
               eventMade={handleEventMade}
               closeModal={() => {
                  setCreateEventModal(false);
               }}
            />
         )}
         <div className="modal-backdrop"></div>
         <div className="modal-backdrop">
            <Calendar
               localizer={momentLocalizer(moment)}
               events={events}
               startAccessor="start"
               endAccessor="end"
               style={{ height: 500, background: "white" }}
               onSelectEvent={handleSelectEvent}
               onSelectSlot={handleSelectSlot}
               selectable={true}
               eventPropGetter={eventStyleGetter}
            />
            <button
               onClick={props.closeModal}
               style={{ border: "3px solid red", color: "white" }}
            >
               Close
            </button>
         </div>
      </div>
   );
};

export default PersonalCalendar;
