import {React, useEffect, useState } from 'react'
import Message from './Message'

const MessageApplicationUserList = (props) => {
  // receiving entire user object list

  useEffect(() => {

  }, props.temp)

  return (
    <div id='message-application-left-panel-container' className='make-grid'>
      <div id='message-application-user-search-field'>
        <input type='text' placeholder='🔍 Search User'/>
      </div>
      
      <div id='message-application-user-field' className='scrollbar-mch-thin'>
        {props.conversations.map((conversation, index) => {
          return (
            <div className={(props.chatSelected === index) ? "activeChat border-radius-15 scroll-hide" : ""} >
              <Message reduceNumMessages={props.reduceNumMessages} handleSelectedChat={props.handleSelectedChat} index={index} key={index * 2} currentUser={props.currentUser} conversation={conversation} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MessageApplicationUserList