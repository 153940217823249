import { React, useState, useEffect, Fragment } from 'react'
import { useCollapse } from 'react-collapsed'
import CreateChannelModal from "../modals/CreateChannelModal";
import CalendarModal from '../modals/CalendarModal';

// ! PROPS FROM ClassroomField.jsx: course, courseSelected, channelSelected, handleCourseChannelClicked, currentUser, actionTaken, userChannel
const ClassListing = (props) => {
    const { getCollapseProps, getToggleProps } = useCollapse()
    const [isActive, setIsActive] = useState(false);
    const [activeChannel, setActiveChannel] = useState(false);

    const [showCreateChannelModal, setShowCreateChannelModal] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);

    const [channelMade, setChannelMade] = useState(false);

    useEffect(() => {
        
    },[activeChannel, isActive, props.actionTaken, channelMade])

    const handleChannelClick = (channel) => {
        props.handleCourseChannelClicked(props.course.courseCode, channel);
        setActiveChannel(true);
    }

    const showChannelModal = () => {
        setShowCreateChannelModal(true);
    }

    // * a cheap way to trigger a re-render for when a channel is made so we dont have to switch tabs
    const handleChannelMade = () => {
        setChannelMade(!channelMade);
    }

    const handleShowCalendar = () => {
        console.log('showing calendar')
        setShowCalendar(true);
    }

    const handleCloseCalendar = () => {
        setShowCalendar(false);
    }

    const displayCourse = (currentChannel) => {
        try{
            if(currentChannel.name === 'calendar'){
                return (currentChannel.name)
            }
            let arr = props.currentUser.enrolled_courses;
            let user_temp_course = arr.find((c) => c.courseID === props.course.courseCode);
            if(!user_temp_course){
                return (currentChannel.name)
            }
            let userChannel = user_temp_course.channel.find((channel) => {
                return channel.channelName === currentChannel.name
            })
            if(!userChannel){
                return (currentChannel.name)
            }
            return (currentChannel?.messageCount > userChannel?.messageCount ? `${currentChannel?.name} (${currentChannel?.messageCount - userChannel?.messageCount})` : currentChannel.name)
        }
        catch(error){
            alert(error);
        }
    }

    return (
        <Fragment>
            {showCalendar && <CalendarModal course={props.course} currentUser={props.currentUser} closeModal={handleCloseCalendar} />}
            <div id='course-code-collapsible-container'>
            {showCreateChannelModal && <CreateChannelModal currentUser={props.currentUser} course={props.course} closeModal={() => setShowCreateChannelModal(false)} handleChannelMade={handleChannelMade} />}
                <button id='course-code-btn' className={ (props.courseSelected === props.course.courseCode) ? 'active-course course-title' : 'course-title'} onClick={() => {setIsActive(!isActive)}} {...getToggleProps()}>{props.course.courseSubject} {props.course.courseCode}</button>
                <div id='collapsible-channels-list' {...getCollapseProps()}>
                    {props.course.channels.map((channel) => {
                        return (
                            channel.members.includes(props.currentUser.userID) || channel.visibility === 'public' ? (
                                <button key={channel.name} onClick={() => {handleChannelClick(channel.name)}} className={(props.courseSelected === props.course.courseCode && props.channelSelected === channel) ? 'active-channel channel-btn' : 'channel-btn'}>{displayCourse(channel)}</button>
                            ) : (null)
                        );
                    })}
                    <button className='channel-btn' onClick={() => handleChannelClick('calendar')} >Calendar</button>
                    {props.currentUser.userID === props.course.courseInstructor && (<button className='channel-btn' onClick={showChannelModal} >+ Create Channel</button>)}
                </div>
                <hr />      
        </div>
        </Fragment>
    )
}

export default ClassListing