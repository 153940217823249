import { React, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import AnnouncementModal from "../../classrooms/classroomComponents/AnnouncementModal";
import ClassMessage from "./ChannelMessageApp";
import ManageChannelMemberModal from "../../classrooms/classroomComponents/ManageChannelMemberModal";
import MessageExpandModal from "../../classrooms/modals/MessageExpandModal";
import { storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FoundBadToken } from "../../../scripts/badToken";
import ChannelMessageApp from "./ChannelMessageApp";
import ClassroomChatTextArea from "../../atoms/ClassroomChatTextArea";

// ! PROPS FROM ClassroomField.jsx: courseSelected, channelSelected, courseID, actionTaken, currentUser, channel, ably, course, handleSetActionTaken
const ClassChatField = (props) => {
  const [fetching, setFetching] = useState(true);
  const [message, setMessage] = useState("");
  const [showCreateAnnouncementModal, setShowCreateAnnouncementModal] = useState(false);
  const [channelMessages, setChannelMessages] = useState();
  const [showManageModal, setShowManageModal] = useState(false);
  const [action, setAction] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [typing, setTyping] = useState(false);
  const [typers, setTypers] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();

  const url = getGlobalURL();

  const lastMessageRef = useRef(null);
  const scrollToBottom = () => {
    // 	/*
    // 	Called within useEffect when a new message is sent. Automatically scrolls the message-application-chat-field
    // 	own to the last message without scrolling the entire window down.
    // 	*/
    let lastMessageDiv = lastMessageRef.current;
    if (lastMessageDiv) {
      let lastChild = lastMessageDiv.lastChild;
      if (lastChild) {
        lastMessageDiv.scrollTop = lastChild.offsetTop + lastChild.scrollHeight;
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [lastMessageRef, channelMessages])

  useEffect(() => {
    const encodedChannelName = encodeURIComponent(props.channelSelected);
    axios.get(`${url}courses/getChannelMessages/${props.course.courseCode}/${encodedChannelName}`, {
      headers: {
        Authorization: sessionStorage.getItem('token')
      }
    }).then((response) => {
      if(response.status === 200){
        console.log(response.data)
        setChannelMessages(response.data)
        setFetching(false)
      }
    }).catch((error) => {
      if(error.response.status === 401){
        alert('Session expired. Please log in again.')
        FoundBadToken();
      }
    })
  }, [props.course, props.channelSelected]);

  useEffect(() => {
    if(props.channelSelected !== 'Announcements'){
      props.channel.current = props.ably.channels.get(`${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`);

      props.channel.current.subscribe('receive-message', (newMessage) => {
        setChannelMessages(newMessage.data);
      })

      props.channel.current.subscribe('receive-notification', (newMessage) => {
        setChannelMessages(newMessage.data);
      })

      props.channel.current.subscribe('remove-message', (newMessage) => {
        setChannelMessages(newMessage.data);
      })

      props.channel.current.subscribe('typing', (name) => {
        setTypers((prevTypers) => {
          return [...prevTypers, name.data];
        })
      })

      props.channel.current.subscribe('stopped-typing', (name) => {
        setTypers((prevTypers) => {
          return prevTypers.filter((typer) => {return typer !== name.data});
        })
      })
    }

    return () => {
      if(props.channel.current != null){
        props.channel.current.unsubscribe();
      }
    }
  }, [props.channel.current, props.channelSelected, props.courseSelected]);

  const handleShowAnnouncementModal = () => {
    setShowCreateAnnouncementModal(true);
  };

  const handleCloseAnnouncementModal = () => {
    setShowCreateAnnouncementModal(false);
  };

  const sendMessage = async () => {
    if (message === "") {
      alert("Message cannot be empty");
      return;
    } else {
      let file_url = file ? await uploadFile() : "";
      let img_url = image ? await uploadImage() : "";
      let tempMessage = {
        message: message,
        senderName: props.currentUser.fName + " " + props.currentUser.lName,
        senderPicture: props.currentUser.profileImage,
        timestamp: Date.now(),
        messageID: Date.now(),
        senderID: props.currentUser.userID,
        file: file_url,
        fileName: file ? file.name : "",
        img: img_url,
        imgName: image ? image.name : "",
        isNotification: false,
        replies: [],
      };
      axios.post(`${url}courses/addMessage`, {
        message: tempMessage,
        courseCode: props.course.courseCode,
        channelName: props.channelSelected
      }, { 
          headers: {
            Authorization: sessionStorage.getItem('token')
          }
      }).then((response) => {
        if(response.status === 200){
          setMessage('');
          axios.post(`${url}channels/publishMessage`, {
            message: tempMessage,
            ablyChannel: `${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`,
            courseCode: props.course.courseCode,
            courseChannel: props.channelSelected
          }, {
            headers: {
              Authorization: sessionStorage.getItem('token')
            }
          }).then((response) => {
            if(response.status === 200){
              
            }
          }).catch((error) => {
            if(error.response.status === 401){
              alert('Session expired. Please log in again.')
              FoundBadToken();
            }
          })
        }
      }).catch((error) => {
        if(error.response.status === 401){
          alert('Session expired. Please log in again.')
          FoundBadToken();
        }
      })
    }
    setMessage("");
    setFile(null);
    setImage(null);
    document.getElementById("message-input").value = "";
  };

  async function uploadFile() {
    const fileRef = ref(storage, `courseFiles/${props.course.courseCode}/${props.channelSelected}/${file.name}`);
    return new Promise((resolve, reject) => {
      uploadBytes(fileRef, file).then((response) => {
        getDownloadURL(fileRef).then((fileUrl) => {
          resolve(fileUrl);
        });
      });
    });
  }

  async function uploadImage() {
    const imageRef = ref(storage, `courseImages/${props.course.courseCode}/${props.channelSelected}/${props.currentUser.userID}/${image.name}`);
    return new Promise((resolve, reject) => {
      uploadBytes(imageRef, image).then((response) => {
        getDownloadURL(imageRef).then((imageUrl) => {
          resolve(imageUrl);
        });
      });
    });
  }

  function showTypers() {
    let string = "";
    typers.forEach((typer) => {
      string += string.length == 0 ? typer : `, ${typer}`;
    });
    return string;
  }

  const handleSetMessage = (e) => {
    // This function is now being called from within the ClassroomChatTextArea component. The spacebar key is now being checked for in the handleMonitorTyping function.
    if (!typing) {
      setTyping(true);
        props.channel.current.publish("typing", props.currentUser.fName + " " + props.currentUser.lName, () => {
          setTimeout(() => {
            setTyping(false);
            props.channel.current.publish("stopped-typing", props.currentUser.fName + " " + props.currentUser.lName);
          }, 3000);
        });
      }
    setMessage(e.target.value);
  };

  const showManageMembersModal = () => {
    //TODO show the modal for managing members for this specific channel
    setShowManageModal(true);
  };

  const handleDeleteChannel = () => {
    axios.post(`${url}channels/deleteChannel`, {
      channelName: props.channelSelected,
      courseCode: props.course.courseCode,
    }, {
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.status === 200) {
        alert("Channel deleted");
      }
    }).catch((error) => {
      if (error.code === "ERR_BAD_REQUEST") {
        FoundBadToken();
      } else {
        alert(`${error.response.data.message}`);
      }
    });
  };

  const handleShowMessageModal = (message) => {
    props.openMessageSlide(message);
    // setShowMessageModal(true);
    // console.log("message for modal");
    // console.log(message);
    // setModalMessage(message);
  };

  const handleHideMessageModal = () => {
    setShowMessageModal(false);
  };

  const displayActionButtons = () => {
    if(props.currentUser.userID === props.course.courseInstructor){
      if(props.course.channels.find((channel) => {return channel.name === props.channelSelected}).visibility === 'public'){
        return (
          <Fragment>
            <button className='ocean-header-btns btn-ease' onClick={() => handleDeleteChannel()}>Delete Channel</button>
          </Fragment>
        )
      }
      else{
        return (
          <Fragment>
            <button className='ocean-header-btns btn-ease' onClick={() => {
              setAction('add');
              showManageMembersModal();
            }} >Add Members</button>
            <button className='ocean-header-btns btn-ease' onClick={() => {
              setAction('remove');
              showManageMembersModal();
            }} >Remove Members</button>
            <button className='ocean-header-btns btn-ease' onClick={handleDeleteChannel} >Delete {props.course.courseSubject}-{props.course.courseCode}-{props.channelSelected}</button>
          </Fragment>
        )
      }
    }
  }

  const displayCreateAnnouncementButton = () => {
    if(props.currentUser.role === 'Professor' && props.channelSelected === 'Announcements'){
      return <button className='ocean-header-btns btn-ease' onClick={handleShowAnnouncementModal}>Create Announcement</button>
    }
  }

  return (
    <div id="message-app-classroom-content" className="make-grid">
      <div id='message-app-classroom-field' ref={lastMessageRef}>
        {showCreateAnnouncementModal && <AnnouncementModal course={props.course} currentUser={props.currentUser} closeModal={handleCloseAnnouncementModal} />}
        {showManageModal && <ManageChannelMemberModal ably={props.ably} channel={props.channel} actionTaken={props.actionTaken} handleSetActionTaken={props.handleSetActionTaken} action={action} channelSelected={props.channelSelected} course={props.course} currentUser={props.currentUser} closeModal={() => setShowManageModal(false)} />}
        <h3 className="course-name-separator">{props.course.courseSubject} {props.course.courseCode} - {props.course.courseName}</h3>
        <h4 className="channel-name-separator">{props.channelSelected}</h4>
        {fetching ? <p>Fetching...</p> : (
          <Fragment>
            {channelMessages.messagesByDate.map((date, index) => {
              if(date.date != '' && date.messages.length){
                return (
                  <Fragment key={index} >
                    <h3 className="date-separator date-separator-classroom">{date.date}</h3>
                    {date.messages.map((message) => {
                      return (
                        <ChannelMessageApp handleShowMessageModal={handleShowMessageModal} channelSelected={props.channelSelected} course={props.course} currentUser={props.currentUser} message={message} />
                      )
                    })}
                  </Fragment>
                )
              }
            })}
          </Fragment>
        )}
        {displayCreateAnnouncementButton()}
      </div>
      {props.course.channels.find((channel) => {return channel.name === props.channelSelected}).permissions === 'RW' || props.currentUser.userID === props.course.courseInstructor && props.channelSelected !== 'Announcements' ? (
        <Fragment>
          <div id='message-app-classroom-message-field'>
            {typers.length > 0 ? (<h5>{showTypers()} is typing...</h5>) : (null)}
            <ClassroomChatTextArea message={message} handleSetMessage={handleSetMessage} sendMessage={sendMessage} placeholder={`Message ${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`} file={file} setFile={setFile} setImage={setImage}/>
            {displayActionButtons()}
          </div>
        </Fragment>
      ) : (null)}
    </div>
  )
};

export default ClassChatField;
