import { React, Fragment, useState } from 'react'
import AddImageIcon from '../messages/toolbar/icons/AddImageIcon';

/**
 * @description - This component renders a preview image that is selected from the file system. 
 * 
 * @component
 * @param {string} type - A string value used to determine if the input is for an image or video file.
 * @param {string} textID - A string used as the input id so that the querySelector knows which element triggered the call
 * @param {string} userImage - An optional prop used when the preview image holds a required profile or banner image
 * @param {string} width - An optional prop used to set the width of the icon
 * @param {string} height - An optional prop used to set the height of the icon
 * @param {string} color - An optional prop used to set the color of the icon
 * @param {function} setFile - A set function for the state variable that stores the targeted file that will uploaded to firebase.
 * @returns {JSX.Element} - A React element that renders an Add Image button and an image preview to be used prior to uploading to firebase
 * 
 * @example
 * // Renders an Add Image button and an image preview field above the button.
 * <ConvertToBase64
 *  type="image"
 *  textID="profile-1"
 *  userImage={props.currentUser.profileImage} 
 *  setFile={setProfileImageFile} 
 *  width="30px"
 *  height="30px"
 *  color="#ddd"
 * />
 */

const ConvertToBase64 = (props) => {
    // currently userImage is only passed by EditInfoModal.jsx. Ensures that original photo stays in place if user cancels upload
    const [imagePreview, setImagePreview] = useState((props.userImage) ? props.userImage : null);
    
    // Defaults in case width, height, or color are not passed in as props
    let width = (props.width) ? props.width : "30px";
    let height = (props.height) ? props.height : "30px";
    let color = (props.color) ? props.color : "#ccc";

    const convertToBase64 = (e) => {
        let targetFiles = e.target.files[0];
        props.setFile(targetFiles);
        var reader = new FileReader();
        if(targetFiles) {
            reader.readAsDataURL(targetFiles);
            reader.onload = function () {
                setImagePreview(reader.result);
            };
        }
    }
    
    const handleClosePreviewClick = () => {
        setImagePreview((props.userImage) ? props.userImage : null);
    }

    return (
        <div className='c2b64-container'>
            {(imagePreview != null) ? (
                <div className='c2b64-image-preview-container border-radius-10'>
                    <div onClick={handleClosePreviewClick} className='close-preview-btn btn-ease'>x</div>
                    <img className='border-radius-10' src={imagePreview} />
                </div>
            ) : null}
            <div className='c2b64-input-btn clickable'>
                {/* for multiple files: add 'multiple' attribute to <input> */}
                {/* <input className="disp-none" type="file" name="video-preview" onChange={handleFileSelection} /> */}

                { (props.type === "image") ? (
                    <Fragment>
                        <input type='file' id={props.textID} accept='image/*' onChange={convertToBase64} style={{ display: 'none' }}/>
                        <AddImageIcon width={width} height={height} color={color} onClick={() => {document.querySelector(`input[type="file"][id="${props.textID}"]`).click()}}/>
                    </Fragment>
                ) : (props.type === "video") ? (
                    <Fragment>
                        {/* <input className="disp-none" type="file" name="video-preview" onChange={handleFileSelection} />
                        <AddVideoIcon width="30px" color="#ccc" onClick={() => {document.querySelector(`input[type="file"][id="${props.textID}"]`).click()}}/> */}
                    </Fragment>
                ) : null}

                {/* <button className={`${props.style} btn-ease`} onClick={() => {document.querySelector(`input[type="file"][id="${props.textID}"]`).click()}}>{props.text}</button> */}
            </div>
        </div>
    )
}

export default ConvertToBase64;