import { React, useState, useEffect } from 'react'
import Recommendation from './Recommendation'
import GroupUsersIcon from '../../icons/GroupUsersIcon'

/**
 * @description - A helpful tool component that displays an image, title, and a summary of the tool
 * 
 * @component
 * @param {object} userData- An object that contains the userID and profileUrl
 * @param {object} tool - The tool object
 * @param {string} category - The category of the tool
 * @param {function} handleCardClick - A function that handles the card click event
 * @returns {JSX.Element} - A helpful tool component 
 * 
 * @example
 * // Renders a helpful tool component
 * <HelpfulTool
 *  userData={props.userData}
 *  tool={tool}
 *  category={category}
 *  handleCardClick={handleCardClick}
 * />
 */

const HelpfulTool = (props) => {
    const [userRecommends, setUserRecommends] = useState(props.tool.recommendations.some((recommendation) => Number(recommendation.userID) === props.userData.userID));

    useEffect(() => {
        setUserRecommends(props.tool.recommendations.some((recommendation) => Number(recommendation.userID) === props.userData.userID));
    }, [props.tool.recommendations.length])

    return (
        <div onClick={() => props.handleCardClick(props.tool.title)} className='helpful-tool-container'>
            <img src={props.tool.logo} alt={props.tool.title} />
            <div className='helpful-tool-text-area'>
                <h3>{props.tool.title}</h3>
                <p>{props.tool.summary}</p>
                <div className='helpful-tool-recommendations-list'>
                    <GroupUsersIcon width="30px" height="30px" color="var(--secondary-text-color)"/>
                    {(userRecommends && props.tool.recommendations.length > 0)  ? `You and ${props.tool.recommendations.length - 1}` : `${props.tool.recommendations.length}`} others recommend this tool.
                </div>
            </div>
        </div>
    )
}

export default HelpfulTool;