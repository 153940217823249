import React, { Fragment, useEffect, useState } from 'react'
import BanAppeal from './clubModals/BanAppealRequest'
import StatContainer from '../atoms/StatContainer'
import MemberSinceContainer from '../atoms/MemberSinceContainer'
import AdminIcon from '../icons/AdminIcon'
import GraphiteBackground from '../../assets/images/tu_graphite_card_background_169.png'

const ClubHeader = (props) => {

    const [banAppealModal, setBanAppealModal] = useState(false)

    useEffect(() => {

    }, [props.userJoined, props.clubEdit])

    function displayButton(){
        if(props.club.banList.includes(props.currentUser.userID)){
            return <button className='ocean-header-btns btn-ease' onClick={() => {setBanAppealModal(true)}}>Ban Appeal</button>
        }
        if(props.club.members.includes(props.currentUser.userID)){
            return<button className='ocean-header-btns btn-ease' onClick={props.handleLeaveClub} >Leave Club</button>
        } else {
            return <button className='ocean-header-btns btn-ease' onClick={props.handleJoinClub} >Join Club</button>
        }
    }

    function closeModal(modal){
        if(modal === 'ban'){
            setBanAppealModal(false);
        }
    }

    return (
        <Fragment>
            {banAppealModal && <BanAppeal clubName={props.club.clubName} closeModal={closeModal} currentUser={props.currentUser} />}
            <div id='header-container' className='margin-top-80 container-border'>
                <div id='header-top-container'>
                    <div className="background-img-contain" id="header-cover-img" style={{ backgroundImage: props.club.clubBanner ? `url(${props.club.clubBanner})` : `url(${GraphiteBackground})` }}></div>
                </div>
                <div id='header-middle-container'>
                    <div id='header-title'>
                        <h1>{props.club.clubName}</h1>
                        {(props.club.admins.includes(props.currentUser.userID)) && <AdminIcon width="25px" height="25px" color="var(--secondary-text-color)"/>}
                    </div>
                    <div id='header-stats-btns-container'>
                        <div id='header-stats'>
                            <StatContainer label="Members" stat={props.club.members.length}/>
                            <StatContainer label="Posts" stat={props.club.posts.length}/>
                            <MemberSinceContainer label="Created" date="Apr 14 2024"/>
                        </div>
                        <div id='header-btns'>{displayButton()}</div>
                    </div>
                </div>
                <div id='header-bottom-container'>
                    <p>{props.club.description}</p>
                </div>
            </div>
        </Fragment>
    )
}

export default ClubHeader;