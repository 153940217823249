import { React, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import getGlobalURL from "../../../assets/scripts/Global";
import EventModal from "./EventModal";
import CreateEventModal from "./CreateEventModal";
import axios from "axios";
import { FoundBadToken } from "../../../scripts/badToken";

// ! PROPS COMING FROM ClassListing.jsx: course, currentUser, closeModal
const CalendarModal = (props) => {
   const localizer = momentLocalizer(moment);

   const url = getGlobalURL();

   const [showEventModal, setShowEventModal] = useState(false);
   const [showCreateEventModal, setCreateEventModal] = useState(false);
   const [slotInfo, setSlotInfo] = useState(null);
   const [eventMade, setEventMade] = useState(false);
   const [events, setEvents] = useState([]);
   const [event, setEvent] = useState();

   useEffect(() => {
      axios
         .get(`${url}courses/getCalendarEvents/${props.course.courseCode}`, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         })
         .then((response) => {
            if (response.status === 200) {
               let events = response.data.map((event) => {
                  return {
                     ...event,
                     // start: new Date(event.start),
                     // end: new Date(event.end)
                  };
               });
               setEvents(events);
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error.response.data.message);
            }
         });
   }, [eventMade]);

   //change the color of the event to red if the event has already passed
   const eventStyleGetter = (event) => {
      const now = new Date();
      return event.end < now ? { style: { backgroundColor: "red" } } : {};
   };

   const handleSelectEvent = (event) => {
      setShowEventModal(true);
      setEvent(event);
   };

   const handleSelectSlot = (slotInfo) => {
      if (props.currentUser.userID === props.course.courseInstructor) {
         setSlotInfo(slotInfo);
         setCreateEventModal(true);
      }
   };

   const handleCloseEventModal = () => {
      setShowEventModal(false);
      setEvent(null);
   };

   const handleEventMade = () => {
      setEventMade(!eventMade);
   };

   return (
      <div className="model-container">
         {showEventModal && (
            <EventModal
               event={event}
               handleCloseEventModal={handleCloseEventModal}
               course={props.course}
               currentUser={props.currentUser}
               handleEventMade={handleEventMade}
            />
         )}
         {showCreateEventModal && (
            <CreateEventModal
               course={props.course}
               currentUser={props.currentUser}
               slotInfo={slotInfo}
               eventMade={handleEventMade}
               closeModal={() => {
                  setCreateEventModal(false);
               }}
            />
         )}
         <div className="modal-backdrop">
            <Calendar
               localizer={localizer}
               events={events}
               startAccessor={"start"}
               endAccessor={"end"}
               style={{ height: 500, background: "white" }}
               onSelectEvent={handleSelectEvent}
               onSelectSlot={handleSelectSlot}
               selectable={true}
               eventPropGetter={eventStyleGetter}
            />
            <button
               onClick={props.closeModal}
               style={{ border: "1px solid red" }}
            >
               Close
            </button>
         </div>
      </div>
   );
};

export default CalendarModal;
