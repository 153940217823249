import { React, useState, useEffect } from 'react';
import { FoundBadToken } from '../../scripts/badToken';
import axios from 'axios';
import getGlobalURL from '../../assets/scripts/Global';
import FormCancelSubmitBtns from '../../Modals/forms/FormCancelSubmitBtns';
import InputWithObject from '../atoms/InputWithObject';
import ConvertToBase64Simple from '../helperComponents/ConvertToBase64Simple';
import {storage} from "../../firebase";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import DefaultStudyGroupIcon from '../../assets/images/DefaultStudyGroupIcon.png'

const CreateStudyGroupModal = (props) => {
    const url = getGlobalURL();
    const [studyGroupName, setStudyGroupName] = useState('')
    const [studyGroupDescription, setStudyGroupDescription] = useState('')
    const [studyGroupRules, setStudyGroupRules] = useState('')
    const [checked, setChecked] = useState(false)
    const [groupIconImage, setGroupIconImage] = useState(null)
    const [groupIconPreview, setGroupIconPreview] = useState(DefaultStudyGroupIcon)
    const [groupSubject, setGroupSubject] = useState('')

    const createStudyGroup = async () => {
        if(!studyGroupName || groupSubject === 'Choose A Subject'){
            alert('Please fill out all required fields');
            return;
        }
        let studyGroupID = Date.now();
        let studyGroupPicture = '';
        if(groupIconImage){
            studyGroupPicture = await uploadStudyGroupIcon(studyGroupID);
        }
        let studyGroup = {
            studyGroupName: studyGroupName.studyGroupName,
            studyGroupDescription: studyGroupDescription,
            studyGroupID,
            studyGroupOwner: props.currentUser.userID,
            studyGroupPicture: studyGroupPicture,
            members: [{
                userID: props.currentUser.userID,
                userImage: props.currentUser.profileImage,
                name: props.currentUser.fName + props.currentUser.lName,
                missedMessages: 0
            }],
            messages: [{
                userID: props.currentUser.userID,
                date: Date.now(),
                messageContent: `created this study group`,
                contentURL: '',
                messageType: 'system'
            }],
            channels: [],
            pendingMembers: [],
            studyGroupRules: studyGroupRules,
            visibility: checked ? 'private' : 'public',
            subject: groupSubject
        }
        axios.post(`${url}studyGroups/createStudyGroup`, {
            studyGroup
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.currentUser.groups.push(studyGroupID);
            props.handleStudyGroupMade(studyGroup);
            props.closeModal();
        }).catch((error) => {
            if(error.code === 'ERR_BAD_REQUEST'){
                FoundBadToken();
            }
            else{
                alert(error);
            }
        })
    }

    async function uploadStudyGroupIcon(studyGroupID){
        if(!groupIconImage){
            return;
        }
        else{
            const iconRef = ref(storage, `studyGroupIcons/${studyGroupID}`);
            return new Promise((resolve, reject) => {
                uploadBytes(iconRef, groupIconImage).then((response) => {
                    getDownloadURL(iconRef).then((url) => {
                        resolve(url);
                    })
                })
            })
        }
    }

    return (
        <div className='modal-container' >
            <div className='sg-backdrop' onClick={() => {props.closeModal()}} />
            <div className='create-account-form-2c-container border-radius-25 make-grid'>
                <div id='create-account-form-2c-header' className='form-header'>Create a Study Group</div>
                <div id='create-account-form-main-body' >
                    <div id='create-account-form-lc' >
                        <div className='form-body-img-area border-radius-10' >
                            <img src={groupIconPreview} alt="Group Icon" />
                            <ConvertToBase64Simple textID="banner-1" setFile={setGroupIconImage} setImage={setGroupIconPreview} />
                        </div>
                    </div>
                    <div id='create-account-form-rc' >
                        <div id='create-account-2c-form-container' >
                            <div className='form-body form-body-override' >
                                <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupName' labelText='Group Name' date={studyGroupName} setData={setStudyGroupName} />
                                {/* <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupCategory' labelText='Group Category' helperText='Subject (e.g COSC)' date={groupCategory} setData={setGroupCategory} /> */}
                                <label htmlFor='studyGroupCategory'>* Group Subject</label>
                                <select className='form-select' id='studyGroupCategory' value={groupSubject} onChange={(e) => {setGroupSubject(e.target.value)}} >
                                    <option value='Choose A Subject' >Choose A Subject</option>
                                    <option value='ASTR'>ASTR</option>
                                    <option value='COSC'>COSC</option>
                                    <option value='ENGL'>ENGL</option>
                                    <option value='MATH'>MATH</option>
                                </select>
                                
                                <label htmlFor='studyGroupDescription'>Group Description</label>
                                <textarea id='studyGroupDescription' value={studyGroupDescription} onChange={(e) => {setStudyGroupDescription(e.target.value)}} />
                                
                                <label htmlFor='studyGroupRules'>Group Rules</label>
                                <textarea id='studyGroupRules' value={studyGroupRules} onChange={(e) => {setStudyGroupRules(e.target.value)}} />
                                
                                <div className='form-checkbox' >
                                    <label htmlFor='checkbox'>This is a private Study Group</label>
                                    <input type='checkbox' id='checkbox' checked={checked} onChange={(e) => {setChecked(e.target.checked)}} />
                                </div>
                                <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={createStudyGroup} submitText='Create Group' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='create-event-modal-content' >
                <div className='form-container' >
                    <div className='form-header' >Create a Study Group</div>
                    <div style={{background: 'white'}} id='manage-members-container' >
                        <div className='form-body' >
                            <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupName' labelText='Group Name' date={studyGroupName} setData={setStudyGroupName} />
                            <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupCategory' labelText='Group Category' helperText='Subject (e.g COSC, ASTR)' date={groupCategory} setData={setGroupCategory} />
                            <label htmlFor='studyGroupDescription'>Group Description</label>
                            <textarea id='studyGroupDescription' value={studyGroupDescription} onChange={(e) => {setStudyGroupDescription(e.target.value)}} />
                            <label htmlFor='studyGroupRules'>Group Rules</label>
                            <textarea id='studyGroupRules' value={studyGroupRules} onChange={(e) => {setStudyGroupRules(e.target.value)}} />
                            <div className='form-checkbox' >
                                <label htmlFor='checkbox'>This is a private Study Group</label>
                                <input type='checkbox' id='checkbox' checked={checked} onChange={(e) => {setChecked(e.target.checked)}} />
                            </div>
                            <div style={{marginBottom: '15px'}} className="form-body-img-area border-radius-10">
                                <img src={groupIconPreview} alt="Banner Image Container"/>
                                <ConvertToBase64Simple textID="banner-1" setFile={setGroupIconImage} setImage={setGroupIconPreview} />
                            </div>
                            <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={createStudyGroup} submitText='Create Group' />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default CreateStudyGroupModal;