import { React, useEffect, useState } from 'react'
import getGlobalURL from '../../../assets/scripts/Global'
import axios from "axios";

// Props coming from ClubsField.jsx: org
const GreekOrgSlide = (props) => {
    return (
        <div id="info-slide">
            <div id='business-info-container' className='make-grid'>
                <div id='business-info-area'>
                    {/* {props.department.departmentDescription && <h4>{props.department.departmentDescription}</h4>} */}
                    {(props.org.fraternity) ? <p><strong>Fraternity</strong></p> : <p><strong>Sorority</strong></p>}
                    {props.org.organization && <p><strong>Organization:</strong> {props.org.organization}</p>}
                    {props.org.chapter && <p><strong>Chapter:</strong> {props.org.chapter}</p>}
                    {(props.org.philanthropy) ? props.org.philanthropy && <p><strong>Philanthropy:</strong> {props.org.philanthropy}</p> : null}
                    {(props.org.instagramHandle) ? props.org.instagramHandle && <p><strong>Instagram:</strong> {props.org.instagramHandle}</p> : null}
                    {(props.org.suspension) ? <p style={{color: 'red'}}><strong>This organization has been suspended</strong></p> : null}

                </div>
            </div>
        </div>
    )
}

export default GreekOrgSlide;