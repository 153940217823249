import getGlobalURL from "../assets/scripts/Global";
import { Fragment, React, useEffect, useState, useContext } from "react";
import axios from "axios";
import Background from "../assets/images/cover_image.jpg";
import { FoundBadToken } from "../scripts/badToken";
import StatContainer from "./atoms/StatContainer";
import MemberSinceContainer from "./atoms/MemberSinceContainer";
import MessageIcon from "./icons/MessageIcon";
import UserUnfollowIcon from "./icons/UserUnfollowIcon";
import UserFollowIcon from "./icons/UserFollowIcon";
import { AblyContext } from "../assets/custom-hooks/AblyContext";
import VerifiedIcon from "./icons/VerifiedIcon";

const ProfileHeader = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################
   const [fUserFollowers, setFUserFollowers] = useState(props.user !== undefined ? props.user.numFollowers : null);
   const [cUserFollowing, setCUserFollowing] = useState(props.currentUser.numFollowing); // ? I do not thinkg this is needed
   const [following, setFollowing] = useState(props.user ? props.user.isFollowing : null);

   const [fUserFollowersList, setFUserFollowersList] = useState(props.user !== undefined ? props.user.followers : null);
   const [cUserFollowingList, setCUserFollowingList] = useState(props.currentUser.following);
   const [user, setUser] = useState(props.user === undefined ? props.currentUser : props.user);

   const { ably } = useContext(AblyContext);

   //const user = (props.user === undefined) ? props.currentUser : props.user;
   let joinDate = new Date(user.joinDate).toString().split(" ").splice(1, 3).join(" ");

   useEffect(() => {
      setUser(props.user === undefined ? props.currentUser : props.user);
   }, [fUserFollowersList, props.user]);

   // Follow User Feature Start
   const handleUnfollowClick = () => {
      // First update the foreign user's followers array
      let newFUserFollowersList = [...fUserFollowersList];
      newFUserFollowersList.splice(newFUserFollowersList.indexOf(props.currentUser.userID), 1);

      // Then update the current user's following array
      let newCUserFollowingList = [...cUserFollowingList];
      newCUserFollowingList.splice(newCUserFollowingList.indexOf(props.user.userID), 1);

      // Update database
      updateFollowers(newFUserFollowersList, true);
      updateFollowing(newCUserFollowingList);
   };

   const newHandleUnfollow = () => {
      axios.patch(`${url}users/updateFollowers`, {
         unfollowFlag: true,
         foreignUserID: props.user.userID,
         currentUserID: props.currentUser.userID,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         }
      }).then((response) => {
         // setFUserFollowers(fUserFollowers - 1);
         user.numFollowers -= 1
         setFollowing(false);
         user.isFollowing = false;
         props.currentUser.numFollowing -= 1
      }).catch((error) => {
         if(error.response.status === 401){
            FoundBadToken();
         }
      })
   }

   const handleFollowClick = () => {
      // First update the foreign user's followers array
      let newFUserFollowersList = [...fUserFollowersList];
      newFUserFollowersList.push(props.currentUser.userID);

      // Then update the current user's following array
      let newCUserFollowingList = [...cUserFollowingList];
      newCUserFollowingList.push(props.user.userID);

      // Update database
      // updateFollowers(newFUserFollowersList, false);
      // updateFollowing(newCUserFollowingList);
   };

   const newHandleFollow = () => {
      axios.patch(`${url}users/updateFollowers`, {
         unfollowFlag: false,
         foreignUserID: props.user.userID,
         currentUserID: props.currentUser.userID,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         }
      }).then((response) => {
         if(response.status === 200){
            // setFUserFollowers(fUserFollowers + 1);
            user.numFollowers += 1;
            setFollowing(true);
            user.isFollowing = true;
            props.currentUser.numFollowing += 1
            userUpdate(`user-updates-${props.user.userID}`, 'new-notification', '');
         }
      }).catch((error) => {
         if(error.response.status === 401){
            FoundBadToken();
         }
      })
   }

   function userUpdate(channel, publish, data){
      let ablyChannel = ably.channels.get(channel);
      ablyChannel.publish(publish, { data: data }, () => {
      })
   }

   const updateFollowers = (newFUserFollowersList, unfollowFlag) => {
      axios.patch(`${url}users/updateFollowers`, {
         unfollowFlag: unfollowFlag,
         foreignUserID: props.user.userID,
         currentUserID: props.currentUser.userID,
         followers: newFUserFollowersList,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         props.user.followers = newFUserFollowersList;
         setFUserFollowersList(newFUserFollowersList);
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   const updateFollowing = (newCUserFollowingList) => {
      axios.patch(`${url}users/updateFollowing`, {
         userID: props.currentUser.userID,
         following: newCUserFollowingList,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         props.currentUser.following = newCUserFollowingList;
         setCUserFollowingList(newCUserFollowingList);
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   function nameCityState() {
      var string = "@" + user.username;
      if (user.city !== "") {
         string += " | " + user.city;
      }
      if (user.state !== "") {
         string += ", " + user.state;
      }
      return string;
   }

   // Follow User Feature End
   return (
      <div id='header-container' className='margin-top-80 container-border'>
         <div id='header-top-container'>
            <div className="background-img-contain" id="profile-header-cover-img" style={{ backgroundImage: `url(${user.bannerImage})` }}></div>
            <div id="profile-header-avatar">
               <img id="profile-avatar" src={user.profileImage} />
            </div>
         </div>
            <div id='header-middle-container'>
               <div id='profile-header-title'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <h3>{`${user.fName} ${user.lName}`}</h3>
                     {user.username === 'TowsonUniversity' && <VerifiedIcon width="20px" height="20px" />}
                  </div>
                  {/* <h3>{`${user.fName} ${user.lName}`}</h3> */}
                  <p>{`${nameCityState()}`}</p>
               </div>
               <div id='header-stats-btns-container'>
                  <div id='header-stats'>
                     <StatContainer label="Following" stat={user.numFollowing} />
                     <StatContainer label="Followers" stat={user.numFollowers} />
                     <MemberSinceContainer label="Member Since" date={joinDate} />
                  </div>
                     { !(props.user === undefined) &&
                        <div id='header-btns'>
                           { following === true ? (
                              <UserUnfollowIcon className="clickable btn-ease" onClick={newHandleUnfollow} width="25px" height="25px" fill="var(--secondary-text-color)" strokeColor="none" />
                           ) : (
                              <UserFollowIcon className="clickable btn-ease" onClick={newHandleFollow} width="25px" height="25px" fill="var(--secondary-text-color)" strokeColor="none" />
                           ) }
                           <MessageIcon className="clickable btn-ease" onClick={props.handleShowMessageModal} width="30px" height="30px" fill="none" strokeColor="var(--secondary-text-color)"/>
                        </div>
                     }
               </div>
            </div>
         <div id='header-bottom-container'>
            <p>{user.bio}</p>
         </div>
      </div>
   );
};

export default ProfileHeader;