import { useNavigate } from "react-router-dom";

export const FoundBadToken = () => {
   // When the user clicks the 'Logout' link, route to the Login page with the user object cleared
   // let navigate = useNavigate();
   console.log("I Found a bad token");
   localStorage.clear();
   sessionStorage.clear();
   window.location.href = "/";
};
