import getGlobalURL from "../../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from "react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import BanPostList from "./clubModals/BanPostList";
import { FoundBadToken } from "../../scripts/badToken";

const BanAppealListing = (props) => {
   const url = getGlobalURL();
   const [user, setUser] = useState();
   const [fetching, setFetching] = useState(true);
   const [banPostListModal, setBanPostListModal] = useState(false);

   useEffect(() => {
      axios
         .post(
            `${url}users/getSingleUser`,
            {
               userID: props.appeal.bannedUserID,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         )
         .then((response) => {
            setUser(response.data[0]);
            setFetching(false);
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error.response.data.err);
            }
         });
   }, []);

   const handleApproveAppeal = () => {
      if (
         window.confirm(
            "Are you sure you want to approve this appeal? This will unban the user and they will be able to participate in the club again."
         )
      ) {
         axios
            .patch(
               `${url}clubs/overturnBan`,
               {
                  clubName: props.club.clubName,
                  userID: props.appeal.bannedUserID,
                  adminID: props.currentUser.userID,
               },
               {
                  headers: {
                     Authorization: sessionStorage.getItem("token"),
                  },
               }
            )
            .then((response) => {
               if (response.status === 200) {
                  alert(
                     "The ban has been overturned, the user is now unbanned."
                  );
               }
            })
            .catch((error) => {
               if (error.code === "ERR_BAD_REQUEST") {
                  FoundBadToken();
               } else {
                  alert(error.response.data.err);
               }
            });
      } else {
      }
   };

   const hanldeDenyAppeal = () => {
      if (
         window.confirm(
            "Are you sure you want to deny this appeal? This will keep the user banned from the club."
         )
      ) {
         axios
            .patch(
               `${url}clubs/upholdBan`,
               {
                  clubName: props.club.clubName,
                  userID: props.appeal.bannedUserID,
                  adminID: props.currentUser.userID,
               },
               {
                  headers: {
                     Authorization: sessionStorage.getItem("token"),
                  },
               }
            )
            .then((response) => {
               if (response.status === 200) {
                  alert("The ban has been upheld. The user is still banned.");
               }
            })
            .catch((error) => {
               if (error.code === "ERR_BAD_REQUEST") {
                  FoundBadToken();
               } else {
                  alert(error.response.data.err);
               }
            });
      } else {
      }
   };

   const handlePreviousPosts = () => {
      setBanPostListModal(true);
   };

   const closeModal = () => {
      setBanPostListModal(false);
   };

   return (
      <Fragment>
         {banPostListModal && (
            <BanPostList
               user={user}
               posts={props.appeal.clubMessages}
               closeModal={closeModal}
            />
         )}
         {fetching ? (
            <div
               style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  marginTop: "15px",
               }}
            >
               <PuffLoader color="#006994" />
            </div>
         ) : (
            user && (
               <div id="manage-members-listings-area" className="make-grid">
                  <img src={user.profileImage} />
                  <div id="manage-members-stats-title">
                     <h3>
                        {user.fName} {user.lName}
                     </h3>
                     <p>{props.appeal.appealReason}</p>
                  </div>
                  <div id="manage-members-btns">
                     <button
                        onClick={handleApproveAppeal}
                        className="admin-btns btn-ease"
                     >
                        Approve ✔️
                     </button>
                     <button
                        onClick={hanldeDenyAppeal}
                        className="admin-btns btn-ease"
                     >
                        Deny ❌
                     </button>
                     <button
                        onClick={handlePreviousPosts}
                        className="admin-btns btn-ease"
                     >
                        Previous Posts 🔍
                     </button>
                  </div>
               </div>
            )
         )}
      </Fragment>
   );
};

export default BanAppealListing;
