import React from 'react'

const DownArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={props.width}
      fill={props.color}
      stroke={props.color}
      viewBox="0 0 76.95 41.752"
      {...props}
    >
      <path
        d="M505.76 358.26 271.09 123.59c-8.341-8.341-21.824-8.341-30.165 0L6.255 358.26c-8.341 8.341-8.341 21.824 0 30.165 8.341 8.341 21.824 8.341 30.165 0L256 168.845l219.58 219.58a21.275 21.275 0 0 0 15.083 6.251 21.275 21.275 0 0 0 15.083-6.251c8.341-8.341 8.341-21.824 0-30.165z"
        style={{
          fill: props.color,
        }}
        transform="matrix(-.15 0 0 -.15 76.872 59.279)"
      />
    </svg>
  )
}

export default DownArrow