import { React, Fragment } from 'react'

/**
 * @description - This component renders a button area with two buttons one one line. The left button is intended to be used
 *  as a cancel button, and the right button is a button for submission of a form. 
 * 
 * @component
 * @param {function} handleCloseForm - A function that calls the close form handler.
 * @param {function} handleSubmit - A function that calls the submit form.
 * @param {string} closeText - A string label for the close/cancel button.
 * @param {string} submitText - A string label for the submit button.
 * @returns {JSX.Element} - A React element that renders a button area with two buttons on one line (cancel and submit).
 * 
 * @example
 * // Renders a button area with two buttons one one line.
 * <FormCancelSubmitBtns 
 *  handleCloseForm={handleCloseFormClick} 
 *  closeText="Cancel" 
 *  handleSubmit={createBusiness} 
 *  submitText="Send Request"
 * />
 */

const FormCancelSubmitBtns = (props) => {
    return (
        <Fragment>
            <div id="form-body-btn-area">
                <button className='form-body-btn form-body-cancel-btn btn-ease clickable' type="button" onClick={props.handleCloseForm}>{props.closeText}</button>
                <button className='form-body-btn form-body-create-btn btn-ease clickable' type="button" onClick={props.handleSubmit}>{props.submitText}</button>
            </div>
        </Fragment>
    )
}

export default FormCancelSubmitBtns