import React from "react";
import { useState } from "react";
import axios from "axios";
import getGlobalURL from "../../assets/scripts/Global";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import MoonLoader from "react-spinners/MoonLoader";
import { FoundBadToken } from "../../scripts/badToken";
import ConvertToBase64 from "../../components/helperComponents/ConvertToBase64";

const EditInfoModal = (props) => {
   const url = getGlobalURL();
   const [fName, setFName] = useState(props.currentUser.fName);
   const [lName, setLName] = useState(props.currentUser.lName);
   const [city, setCity] = useState(props.currentUser.city);
   const [state, setState] = useState(props.currentUser.state);
   const [username, setUsername] = useState(props.currentUser.username);
   const [email, setEmail] = useState(props.currentUser.email);
   const [bio, setBio] = useState(props.currentUser.bio);
   const [profileImageUrl, setProfileImageUrl] = useState(props.currentUser.profileImage);
   const [bannerImageUrl, setBannerImageUrl] = useState(props.currentUser.bannerImage);
   const [profilePreview, setProfilePreview] = useState(props.currentUser.profileImage);
   const [bannerPreview, setBannerPreview] = useState(props.currentUser.bannerImage);
   const [profileImageFile, setProfileImageFile] = useState(null);
   const [bannerFile, setBannerFile] = useState(null);
   const [updating, setUpdating] = useState(false);
   const handleBackdropClick = () => {props.closeModal()};

   const handleSubmit = async (e) => {
      let profileImgUrl = profileImageUrl;
      let bannerImgUrl = bannerImageUrl;

      setUpdating(true);

      if (profileImageFile != null) {
         profileImgUrl = await uploadProfileImage(props.currentUser.userID);
      }
      if (bannerFile != null) {
         bannerImgUrl = await uploadBannerImage(props.currentUser.userID);
      }
      axios.patch(url + "users/updateUser", {
         userID: props.currentUser.userID,
         fName: fName,
         lName: lName,
         city: city,
         state: state,
         username: username,
         bio: bio,
         profileImage: profileImgUrl,
         bannerImage: bannerImgUrl,
      }, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         setProfileImageUrl(profileImgUrl);
         setBannerImageUrl(bannerImgUrl);
         props.currentUser.profileImage = profileImgUrl;
         props.currentUser.bannerImage = bannerImgUrl;
         props.currentUser.fName = fName;
         props.currentUser.lName = lName;
         props.currentUser.city = city;
         props.currentUser.state = state;
         props.currentUser.username = username;
         props.currentUser.bio = bio;
         alert("account updated");
         setUpdating(false);
         props.closeModal();
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   async function uploadProfileImage(userID) {
      if (!profileImageFile) {
         console.log("RETURNING FROM PROFILE IMAGE");
         return;
      }
      const profileImageRef = ref(storage, `profileImages/${userID}`);
      return new Promise((resolve, reject) => {
         uploadBytes(profileImageRef, profileImageFile).then((response) => {
            getDownloadURL(profileImageRef).then((profileUrl) => {
               resolve(profileUrl);
            });
         });
      });
   }

   async function uploadBannerImage(userID) {
      if (!bannerFile) {
         console.log("RETURNING FROM BANNER IMAGE");
         return;
      }
      const bannerImageRef = ref(storage, `profileBannerImages/${userID}`);
      return new Promise((resolve, reject) => {
         uploadBytes(bannerImageRef, bannerFile).then((response) => {
            getDownloadURL(bannerImageRef).then((bannerUrl) => {
               setBannerImageUrl(bannerUrl);
               console.log("BANNER UPLOAD 2");
               console.log("BANNER URL: " + bannerUrl);
               resolve(bannerUrl);
            });
         });
      });
   }

   const handleClosePreviewClick = () => {
      // SetPostImage does not seem to be resetting the state variable back to "" in time.
      setProfileImageUrl(props.currentUser.profileImage);
   };

   return (
      <div id="edit-info-modal-container" className="show-modal">
         <div id="edit-info-modal-backdrop" onClick={handleBackdropClick} />
         {updating ? (
            <MoonLoader color="#006994" />
         ) : (
            <div id="edit-info-modal-content">
               <div id="edit-info-form-container">
                  <h2>Edit your data below</h2>
                  <label>First Name:</label>
                  <input type="text" value={fName} onChange={(event) => {setFName(event.target.value)}} />
                  
                  <label>Last Name:</label>
                  <input type="text" value={lName} onChange={(event) => {setLName(event.target.value)}} />
                  
                  <label>City:</label>
                  <input type="text" value={city} onChange={(event) => {setCity(event.target.value)}} />
                  
                  <label>State:</label>
                  <input type="text" value={state} onChange={(event) => {setState(event.target.value)}} />
                  
                  <label>Username:</label>
                  <input type="text" value={username} onChange={(event) => {setUsername(event.target.value)}} />
                  
                  <label>Email:</label>
                  <input type="text" value={email} onChange={(event) => {setEmail(event.target.value)}} />
                  
                  <label>Bio:</label>
                  <textarea type="text" value={bio} onChange={(event) => {setBio(event.target.value)}} />
                  
                  <h4>Upload Profile Image:</h4>                  
                  <ConvertToBase64 
                     type="image"
                     textID="profile" 
                     userImage={props.currentUser.profileImage} 
                     setFile={setProfileImageFile} 
                  />
                        
                  <h4>Upload Profile Banner:</h4>
                  <ConvertToBase64 
                     type="image"
                     textID="banner" 
                     userImage={props.currentUser.bannerImage} 
                     setFile={setBannerFile} 
                  />

                  <br></br>
                  <button className="btn-ease" onClick={handleSubmit}>
                     Submit
                  </button>
               </div>
            </div>
         )}
      </div>
   );
};

export default EditInfoModal;
