import React from 'react'
import ExitIcon from './toolbar/icons/ExitIcon'
import AiIcon from './toolbar/icons/AiIcon'
import PrivateChatsIcon from './toolbar/icons/PrivateChatsIcon'
import GroupChatsIcon from './toolbar/icons/GroupChatsIcons'
import SavedChatsIcon from './toolbar/icons/SavedChatsIcon'
import SettingsIcon from './toolbar/icons/SettingsIcon'
import ClassroomIcon from './toolbar/icons/ClassroomIcon'
import GroupUsersIcon from './toolbar/icons/GroupUsersIcon'

/**
 * @description - A toolbar component for the message application
 * 
 * @component
 * @param {string} roomSelected - The currently selected chat room
 * @param {function} handleRoomSelected - A function that handles the selection of a chat room
 * @param {function} handleOpenMessagesApp - A function that handles toggling the opening/closing of the messaging application
 * @returns {JSX.Element} - Rendered message application toolbar component
 * 
 * @example
 * // Renders a message application toolbar component
 * <MessageApplicationToolbar 
 *  roomSelected={roomSelected} 
 *  handleRoomSelected={handleRoomSelected} 
 *  handleOpenMessagesApp={props.handleOpenMessagesApp} 
 * />
 */

const MessageApplicationToolbar = (props) => {
  return (
    <div id='message-application-toolbar-container'>
        <div id='message-application-toolbar-btn-area'>
          <div id='toolbar-top'>
            <div className='message-application-toolbar-options'>
              <ul>
                <li className={(props.roomSelected === "Private Chats") ? "active-chat-room" : ""} onClick={() => props.handleRoomSelected("Private Chats")}><PrivateChatsIcon width={40} height={40}/></li>
                <label>Private Chats</label>
                <li className={(props.roomSelected === "Group Chats") ? "active-chat-room" : ""} onClick={() => props.handleRoomSelected("Group Chats")}><GroupChatsIcon width={40} height={40} /></li>
                <label>Group Chats</label>
                {/* <li className={(props.roomSelected === "Archived") ? "active-chat-room" : ""} onClick={() => props.handleRoomSelected("Archived")}><SavedChatsIcon /></li>
                <label>Archived</label> */}
              </ul>
            </div>
            <div id='message-application-toolbar-academic'>
              <hr />
              <ul>
                <li className={(props.roomSelected === "Classrooms") ? "active-chat-room" : ""} onClick={() => props.handleRoomSelected("Classrooms")}><ClassroomIcon width={40} height={40} /></li>
                <label>Classrooms</label>
                <li className={(props.roomSelected === "Study Groups") ? "active-chat-room" : ""} onClick={() => props.handleRoomSelected("Study Groups")}><GroupUsersIcon width="30px" height="30px" color="#ccc" /></li>
                <label>Study Groups</label>
                <li className={(props.roomSelected === "Chatbot") ? "active-chat-room" : ""} onClick={() => props.handleRoomSelected("Chatbot")}><AiIcon width="30px" color="#ccc"/></li>
                <label>Uni Chatbot</label>
              </ul>
            </div>
          </div>
          <div id='toolbar-bottom'>
            <div className='message-application-toolbar-settings'>
              <hr />
              <ul>
                {/* <li className={(props.roomSelected === "Settings") ? "active-chat-room" : ""} onClick={() => props.handleRoomSelected("Settings")}><SettingsIcon width="30px" height="30px" color="#ccc"/></li>
                <label>Settings</label> */}
                <li onClick={() => props.handleOpenMessagesApp()}><ExitIcon /></li>
                <label>Exit</label>
              </ul>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MessageApplicationToolbar