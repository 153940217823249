import { React, useState, Fragment } from "react";
import getGlobalURL from "../../assets/scripts/Global";
import axios from "axios";
import { FoundBadToken } from "../../scripts/badToken";

const CreatePersonalEventModal = (props) => {
   const selectedDate = props.slotInfo.start.toISOString().slice(0, 10);
   console.log(selectedDate);

   const url = getGlobalURL();

   const [eventTitle, setEventTitle] = useState("");
   const [eventDescription, setEventDescription] = useState("");
   const [startTime, setStartTime] = useState(selectedDate);
   const [endTime, setEndTime] = useState(selectedDate);
   const [priority, setPriority] = useState(0); // 3 = low, 2 = medium, 1 = high, 0 = no priority

   const handleCreateEvent = () => {
      console.log(startTime);
      console.log(endTime);
      const start = new Date(startTime);
      const end = new Date(endTime); // + 'T00:00:00.000'
      console.log(start.getTime() < end.getTime());
      if (start.getTime() > end.getTime()) {
         alert("Invalid start and end times for event");
         return;
      }
      console.log(start);
      console.log(end);
      let event = {
         title: eventTitle,
         description: eventDescription,
         start: start,
         end: end,
         eventID: Date.now(),
         academic: false,
         assignment: false,
         personal: true,
         priority: priority,
      };
      axios
         .post(
            `${url}users/addCalendarEvent`,
            {
               userID: props.currentUser.userID,
               event: event,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         )
         .then((response) => {
            if (response.status === 200) {
               props.eventMade();
               props.closeModal();
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error.response.data.message);
            }
         });
   };

   const handleSetPriority = (event) => {
      setPriority(event.target.value);
   };

   return (
      <div className="modal-container">
         <div className="modal-backdrop" onClick={props.closeModal} />
         <div className="create-post-modal-content">
            <div className="create-post-form-container">
               <div id="modal-title" className="make-grid">
                  <h1>Create Event</h1>
               </div>
               <div
                  style={{ background: "white" }}
                  id="manage-members-container"
               >
                  <div id="create-event-form" className="make-grid">
                     <input
                        onChange={(event) => {
                           setEventTitle(event.target.value);
                        }}
                        placeholder="Event Title"
                     />
                     <textarea
                        onChange={(event) => {
                           setEventDescription(event.target.value);
                        }}
                        placeholder="Event Description"
                     />
                     <input
                        onChange={(event) => {
                           setStartTime(event.target.value);
                        }}
                        type="datetime-local"
                        defaultValue={`${selectedDate}T00:00`}
                     />
                     <input
                        id="finishDate"
                        onChange={(event) => {
                           setEndTime(event.target.value);
                        }}
                        type="datetime-local"
                     />
                     <label htmlFor="finishDate">
                        Leave blank if this is meant to serve as a reminder
                     </label>
                     <select id="setPriority" onChange={handleSetPriority}>
                        <option value={0}>No Priority</option>
                        <option value={3}>Low</option>
                        <option value={2}>Medium</option>
                        <option value={1}>High</option>
                     </select>
                     <label htmlFor="setPriority">Set Priority</label>
                     <button onClick={handleCreateEvent}>Create Event</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CreatePersonalEventModal;
