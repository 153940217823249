import {React, Fragment} from 'react'
import ChatbotPastQueries from './ChatbotPastQueries'
import ChatbotChatField from './ChatbotChatField'

const ChatBot = () => {
    return (
        <Fragment>
            <ChatbotPastQueries />
            <ChatbotChatField />
        </Fragment>
    )
}

export default ChatBot