import getGlobalURL from "../../assets/scripts/Global";
import React, { useEffect, useState, Fragment } from "react";
import "../../assets/stylesheets/oceansStyles.css";
import axios from "axios";
import Post from "../../components/Post";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FoundBadToken } from "../../scripts/badToken";
import ConvertToBase64 from "../../components/helperComponents/ConvertToBase64";
import SendIcon from "../../components/messages/toolbar/icons/SendIcon";
import NewPostFormHeader from "../../components/organisms/posts/NewPostFormHeader";

const PostExpandModal = (props) => {
   // Incoming props from PostsField: 'foreignUser as currentUser', 'currentUser as foreignUser', 'post', 'handleLikeFlag', 'closeModal', 'handlePostTitleClick', 'modalStatus', 'setLikeFlag'

   var tempUser;
   props.foreignUser === undefined ? (tempUser = props.currentUser) : (tempUser = props.foreignUser);

   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [listOfComments, setListOfComments] = useState([]);
   const [commentContent, setCommentContent] = useState("");
   const [file, setFile] = useState(null);

   useEffect(() => {
      if (props.post.postID !== undefined || props.post.postID !== null) {
         axios.get(`${url}comments/getComments/${props.post.postID}`, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }).then((response) => {
            setListOfComments(response.data.reverse());
         }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } 
            else {
               alert(error.response.data.err);
            }
         });
      }
   }, []);

   /* Call to parent to close modal if user clicks the modal area behind the form container */
   const handleBackdropClick = () => {
      props.closeModal();
   };

   const createComment = () => {
      if (tempUser.userID === 0) {
         alert("USER ID CANNOT BE 0");
      }
      else {
         let commentID = Date.now();
         if(file != null){
            const imageRef = ref(storage, `commentImages/${commentID}`);
            uploadBytes(imageRef, file).then((response) => {
               getDownloadURL(imageRef).then((imageUrl) => {
                  axios.post(url + "comments/createComment", {
                     commentContent: commentContent,
                     commentID: commentID,
                     postID: props.post.postID,
                     userID: tempUser.userID,
                     timestamp: Date.now(),
                     commentImageUrl: imageUrl,
                     postOwnerID: props.post.userPosterID
                  }, {
                     headers: {
                        "Authorization": sessionStorage.getItem("token")
                     }
                  }).then((response) =>{
                     props.closeModal();
                  }).catch((error) => {
                     alert(error.response.data.err);
                  })
               })
            });
         }
         else{
            axios.post(url + "comments/createComment", {
               commentContent: commentContent,
               commentID: commentID,
               postID: props.post.postID,
               userID: tempUser.userID,
               timestamp: Date.now(),
               commentImageUrl: '',
               postOwnerID: props.post.userPosterID
            }, {
               headers: {
                  "Authorization": sessionStorage.getItem("token")
               }
            }).then((response) => {
               props.closeModal();
            }).catch((error) => {
               alert(error.response.data.err);
            })
         }
      }
   };

   return (
      <div className="modal-container">
         <div className="modal-backdrop" onClick={handleBackdropClick} />
         <div className="modal-thread-container container-border scrollbar-mch-thin">
            {/*If we figure out how to use Post component only for both modal and regular, just change ModalPost to Post*/}
            <Post
               post={props.post}
               handleLikeFlag={props.handleLikeFlag}
               handlePostTitleClick={props.handlePostTitleClick}
               expandedModalStatus={props.modalStatus}
               currentUser={props.currentUser}
               source={props.source}
               club={props.club}
               ocean={props.ocean}
               handlePostDelete={props.handlePostDelete}
               foreignUser={props.foreignUser}
            />
            <div id="modal-post-container" className="container-border make grid" >
               <div id="create-comment-container" className="comment-content">
                  <div id="comment-input-field-container" className="make-grid">
                     <NewPostFormHeader avatar={tempUser.profileImage} name={`${tempUser.fName} ${tempUser.lName}`}/>
                     {/* <img className="user-comment-img" src={tempUser.profileImage} /> */}
                     <textarea className="make-grid" placeholder="Enter your comment here" onChange={(event) => setCommentContent(event.target.value)}></textarea>
                     <hr className="hr-custom"/>
                     <div className="comment-icons-container">
                        <ConvertToBase64 type="image" textID="comment-1" setFile={setFile} width="25px" height="25px" color="var(--secondary-text-color)" />
                        <SendIcon onClick={createComment} className="clickable" width="30px" height="30px" strokeColor="var(--secondary-text-color)"/>
                     </div>
                  </div>
               </div>

               {listOfComments.map((comment) => {
                  console.log(`comment.commentImageUrl: ${comment.commentImageUrl}`)
                  return (
                     <div className="comment-container">
                        <div id="comment-title-container" className="make-grid">
                           <img className="comment-avatar" src={comment.profileImage} />
                           <h3>{comment.name}</h3>
                           <p>{new Date(props.post.timestamp).toString().split(" ").splice(0, 4).join(" ")}</p>
                        </div>
                        <div className="comment-content">
                           {comment.commentImageUrl ? (<img loading="lazy" className="post-thumbnail" src={comment.commentImageUrl} />) : null}
                           <p>{comment.commentContent}</p>
                        </div>
                     </div>
                  );
               })}
            </div>
         </div>
      </div>
   );
};

export default PostExpandModal;
