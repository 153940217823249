import React, { useState, useEffect, Fragment } from 'react'
import NavHeader from '../components/NavHeader'
import OceansList from '../components/OceansList'
import PostsField from '../components/PostsField'
import ProfileHeader from '../components/ProfileHeader'
import { useLocation } from 'react-router-dom'
import SuggestedOceans from '../components/suggested/SuggestedOceans'
import SuggestedFriends from '../components/suggested/SuggestedFriends'
import Footer from '../components/Footer'
import MessageModal from '../Modals/messageModals/MessageModal'
import MessageApplication from '../components/messages/MessageApplication'
import NewMyAccount from './NewMyAccount'
import NavDrawer from '../components/navigation/NavDrawer'
import FeedbackForm from '../Modals/forms/FeedbackForm'

const DisplayUser = () => {
  let location = useLocation();
  let foreignUser = location.state.foreignUser;
  let currentUser = location.state.currentUser;
  // [0] = foreignUser
  // [1] = currentUser

  const [oceanSelected, setOceanSelected] = useState(foreignUser.oceans[0]);
  const [activeButton, setActiveButton] = useState(foreignUser.oceans[0]);
  const [oceanCreated, setOceanCreated] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openNavDrawer, setOpenNavDrawer] = useState(true);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openMyAccount, setOpenMyAccount] = useState(false);
  const [openMessagesApp, setOpenMessagesApp] = useState(location.state.openMessagesApp !== null ? location.state.openMessagesApp : false);
  const [messageAppCurrentlyOpen, setMessageAppCurrentlyOpen] = useState(false);

  const [message, setMessage] = useState();

  const onSearchedOcean = (ocean) => {
    // console.log(ocean);
  }

  const buttonSelection = (buttonSelected) => {
    setOceanSelected(buttonSelected)
    setActiveButton(buttonSelected)
  }

  const handleOceanCreated = () => {
    setOceanCreated(!oceanCreated);
  }

  const handleShowMessageModal = () => {
    // alert('Message Modal')
    setMessage(true);
  }

  const handleCloseMessageModal = () => {
    // alert('Message Modal')
    setMessage(false);
  }

  const handleCloseMessage = () => {
		setMessageAppCurrentlyOpen(false);
	};

  const handleMessageChatOpen = () => {
		setMessageAppCurrentlyOpen(true);
	};
  
  const handleOpenNotifications = () => {
		setOpenNotifications(!openNotifications);
	}

  const handleOpenNavDrawer = () => {
		setOpenNavDrawer(!openNavDrawer);
	};

  const handleOpenMessagesApp = () => {
		if(openMessagesApp === false || openMessagesApp === undefined) {
			setOpenNavDrawer(false);
		} else {
			setOpenNavDrawer(true);
		}
		setOpenMessagesApp(!openMessagesApp);
	};

  return (
    <Fragment>
      <NavHeader 
        currentUser={currentUser} 
        onSearchedOcean={onSearchedOcean} 
        handleOpenNavDrawer={handleOpenNavDrawer}
        source="DisplayUser" 
        handleOpenNotifications={handleOpenNotifications}
        openNotifications={openNotifications}
        setOpenNotifications={setOpenNotifications}
        handleOpenMessagesApp={handleOpenMessagesApp}
      />
      {openNavDrawer && <NavDrawer 
                    currentUser={currentUser} 
                    handleOpenNavDrawer={handleOpenNavDrawer} 
                    handleButtonSelection={buttonSelection} 
                    activeButton={activeButton} 
                    setOpenMyAccount={setOpenMyAccount}
                    source="DisplayUser"
                  />
      }
      { openMyAccount ? (<NewMyAccount currentUser={currentUser} />) 
      : openMessagesApp ? (
        <MessageApplication
            handleCloseMessage={handleCloseMessage}
            handleMessageChatOpen={handleMessageChatOpen}
            currentUser={currentUser}
            handleOpenMessagesApp={handleOpenMessagesApp}
        />
      ) : (
        <Fragment>
          {message && <MessageModal foreignUser={foreignUser} currentUser={currentUser} handleCloseMessageModal={handleCloseMessageModal} />}
          <div id='main-panel' className='make-grid' onClick={() => setOpenNotifications(false)}>
            <div id='left-panel' className='padding-top-80'>
              { !openNavDrawer && (
                <Fragment>
                  <SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated}/>
                  <SuggestedFriends currentUser={currentUser}/>
                </Fragment>
              )}
            </div>
            <div id='center-panel' className='padding-top-80'>
              {openFeedback ? (
                <FeedbackForm userID={currentUser.userID} setOpenFeedback={setOpenFeedback}/>
              ) : (
                <Fragment>
                  <ProfileHeader handleShowMessageModal={handleShowMessageModal} currentUser={currentUser} user={foreignUser} />
                  <OceansList currentUser={foreignUser} buttonSelection={buttonSelection} activeButton={activeButton} oceanCreated={oceanCreated} source={"displayUser"}/>
                  <PostsField oceanSelected={oceanSelected} foreignUser={currentUser} userID={foreignUser.userID} currentUser={foreignUser} source={"displayUser"}/>
                </Fragment>
              )}
            </div>
            <div id='right-panel' className='padding-top-80'>
              { openNavDrawer && (
                <Fragment>
                  <SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated}/>
                  <SuggestedFriends currentUser={currentUser}/>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
      <Footer currentUser={currentUser}/>
    </Fragment>
  )
}

export default DisplayUser