import getGlobalURL from "../../assets/scripts/Global";
import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import Post from "../Post";
import PostExpandModal from "../../Modals/postModals/PostExpandModal";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../scripts/badToken";

const ClubPostField = (props) => {
   const url = getGlobalURL();

   let club = props.club.clubName;

   const [listOfPosts, setListOfPosts] = useState([]);
   const [likeFlag, setLikeFlag] = useState();
   const [postDelete, setPostDelete] = useState(false);

   const [modalStatus, setModalStatus] = useState(false);
   const [modalPostID, setModalPostID] = useState();
   const [hasMore, setHasMore] = useState(true);
   const [index, setIndex] = useState(1);

   useEffect(() => {
      axios
         .get(`${url}clubs/getClubPosts/${props.club.clubID}`, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         })
         .then((response) => {
            if (response.status == 200) {
               setListOfPosts(response.data);
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
   }, [club, likeFlag, postDelete, props.postMade]);

   let modalPost =
      modalPostID === undefined
         ? ""
         : listOfPosts.find((element) => element.postID === modalPostID);

   const handleLikeFlag = (countChange, postID) => {
      setLikeFlag(countChange);
      setModalPostID(postID);
   };

   function handlePostTitleClick(postID) {
      setModalStatus(true);
      setModalPostID(postID);
   }

   function handlePostDelete() {
      setPostDelete(!postDelete);
      setModalStatus(false);
   }

   const closeModal = () => {
      setModalStatus(false);
   };

   return (
      <Fragment>
         {modalStatus && <PostExpandModal foreignUser={props.foreignUser} currentUser={props.currentUser} post={modalPost} handleLikeFlag={handleLikeFlag} handlePostTitleClick={handlePostTitleClick} closeModal={closeModal} modalStatus={modalStatus} userID={props.currentUser.userID} setLikeFlag={likeFlag} handlePostDelete={handlePostDelete} source="clubsHome" club={props.club} />}
         {listOfPosts.map((post, index) => {
            return (
               <Post
                  key={index}
                  currentUser={props.currentUser}
                  post={post}
                  handleLikeFlag={handleLikeFlag}
                  closeModal={closeModal}
                  handlePostTitleClick={handlePostTitleClick}
                  modalStatus={modalStatus}
                  userID={props.currentUser.userID}
                  handlePostDelete={handlePostDelete}
                  source="clubsHome"
                  club={props.club}
                  foreignUser={props.currentUser}
               />
            );
         })}
      </Fragment>
   );
};

export default ClubPostField;
