import getGlobalURL from "../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import Club from "./cards/Club";
import PuffLoader from "react-spinners/PuffLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { FoundBadToken } from "../../scripts/badToken";
import SearchField from "../helperComponents/SearchField";
import DefaultBanner from "../../assets/images/directory_banner.jpg";
import GroupUsersIcon from "../icons/GroupUsersIcon";
import CreateClubModal from "./CreateClubModal";

const ClubsField = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [index, setIndex] = useState(1);
   const [dataSource, setDataSource] = useState([]);
   const [fetchingClubs, setFetchingClubs] = useState(true);
   const [hasMore, setHasMore] = useState(true);
   const [showCreateClubModal, setShowCreateClubModal] = useState(false);

   const [searchedClub, setSearchedClub] = useState("");
   const [category, setCategory] = useState("All");
   const tempDescription = "Welcome to the Clubs section! Here, you can explore the vibrant and diverse array of student clubs available at our university. Whether you’re interested in academic pursuits, cultural organizations, sports, or hobbies, there’s something for everyone. Browse through detailed descriptions of each club to learn about their missions, activities, and upcoming events. You can also follow clubs that catch your interest to receive updates and stay informed about their latest happenings. Joining a club is a fantastic way to meet new people, develop skills, and enrich your university experience. Dive in, find your passion, and get involved!"


   const fetchMoreData = async () => {
      try {
         const res = await axios.get(`${url}clubs/getMoreClubs/${index}`, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         });

         if (res.status === 200) {
            if (res.data.length === 0) {
               // No more elements to fetch
               setHasMore(false);
            } else {
               setDataSource([...dataSource, ...res.data]);
               setFetchingClubs(false);
               setIndex(index + 1);
            }
         }
      } catch (err) {
         if (err.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(err.response.data.err);
         }
      }
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const res = await axios.get(`${url}clubs/getMoreClubs/${index}`, {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            });
            if (res.status === 200) {
               setDataSource(res.data);
               setFetchingClubs(false);
               setIndex(index + 1);
            }
         } catch (err) {
            if (err.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(err.response.data.err);
            }
         }
      };

      fetchData();
   }, []);

   function filterClubs() {
      let array = dataSource.filter((club) => {
         return (
            club.clubName
               .toLowerCase()
               .startsWith(searchedClub.toLowerCase()) &&
            (club.clubCategory === category || category === "All")
         );
      });
      if (array.length === 0) {
         return <h1>No clubs found</h1>;
      } else {
         return array.map((club) => {
            return (
               <Fragment key={club.clubName}>
                  <Club
                     title={club.clubName}
                     description={club.description}
                     clubBanner={club.clubBanner}
                     currentUser={props.currentUser}
                     club={club}
                  />
               </Fragment>
            );
         });
      }
   }

   const handleShowCreateClubModal = () => {
      setShowCreateClubModal(true);
   }

   const addClub = (club) => {
      setDataSource([...dataSource, club]);
   }

   return (
      <Fragment>
         {showCreateClubModal && <CreateClubModal addClub={addClub} currentUser={props.currentUser} closeModal={() => { setShowCreateClubModal(false) }} />}
         <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src={DefaultBanner}/>
            <div className="content-placeholder-text-area">
                  <h2 className='highlight'>Explore Clubs</h2>
                  <p>{tempDescription}</p>
            </div>
            <div id="clubs-orgs-field-container">
               <div id="search-orgs-container">
                  <SearchField
                     title="clubs"
                     categories={["All", "Science", "Technology", "Gaming", "Adventure", "Music", "Religious", "Athletic"]}
                     setSearch={setSearchedClub}
                     setCategory={setCategory}
                  />
               </div>
               <div onClick={handleShowCreateClubModal} className="action-btn-container btn-ease clickable">
                  <GroupUsersIcon width="30px" height="30px" color="var(--towson-gold)" />
                  <span className="action-btn">Create A Club</span>
               </div>
               <InfiniteScroll
                  dataLength={dataSource.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<PuffLoader color="#006994" />}
                  scrollThreshold={1.0}
               >
                  {fetchingClubs ? (
                     <PuffLoader color="#006994" />
                  ) : searchedClub === "" && category === "All" ? (
                     dataSource.filter((club) => {
                        return (
                           club.clubCategory === category || category === "All"
                        );
                     }).map((club) => {
                        return (
                           <Fragment key={club.clubName}>
                              <Club
                                 title={club.clubName}
                                 description={club.description}
                                 clubBanner={club.clubBanner}
                                 currentUser={props.currentUser}
                                 club={club}
                              />
                           </Fragment>
                        );
                     })
                  ) : (
                     filterClubs()
                  )}
               </InfiniteScroll>
            </div>
         </div>
      </Fragment>
   );
};

export default ClubsField;
