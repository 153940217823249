import getGlobalURL from "../../../../assets/scripts/Global";
import { Fragment, React, useState, useEffect, useRef } from 'react'
import axios from 'axios';
import PuffLoader from "react-spinners/PuffLoader";
import ClubMemberListing from "../../ClubMemberListing";

const ManageMembersModal = (props) => {
    const url = getGlobalURL();

    // const [membersList, setMembersList] = useState(props.club.members);
    const [posting, setPosting] = useState(false); // Used for the spinner

    const [removedMember, setRemovedMember] = useState(false);

    const handleBackdropClick = () => {
        props.closeModal("manageMembers");
    }

    useEffect(() => {
    }, [removedMember, props.club])

    const handleRemoveMember = () => {
        setRemovedMember(!removedMember);
    }

    const updateMembersList = (list) => {
        // setMembersList(list)
    }

    return (
        <div className='modal-container' >
            <div className='sg-backdrop' onClick={() => { handleBackdropClick() }}  />
            <div className="new-form-container">
                <div className="new-form-header">Manage Club Members</div>
                <div className="new-form-body">
                    <p className="form-instruction-text">In this form, you as an admin, may edit the permissions of each club member.</p>
                    <hr className="hr-custom"/>
                    {posting ? (
                        <div style={{display: "flex", justifyContent: "center",alignItems: "center", height: "100%","margin-top": "15px"}}>
                            <PuffLoader color="#006994" />
                        </div>
                    ) : (
                        <div className='member-listing-container' >
                            {props.club.members.map((memberID) => {
                                if(memberID != props.currentUser.userID){
                                    return (
                                        <Fragment key={memberID}>
                                            <ClubMemberListing updateMembersList={updateMembersList} handleClubEdit={props.handleClubEdit} currentUserID={props.currentUser.userID} handleRemoveMember={handleRemoveMember} club={props.club} memberID={memberID} />
                                        </Fragment>
                                    )
                                }
                            })}
                        </div>
                    )}
                </div>
            </div>



{/* 
            <div className='create-account-form-2c-container border-radius-25 make-grid'>
                <div id='create-account-form-2c-header' className='form-header'>
                    {`Manage Members for the ${props.club.clubName}`}
                </div>
                <div className='member-listing-container' >
                    {props.club.members.map((memberID) => {
                        if(memberID != props.currentUser.userID){
                            return (
                                <Fragment key={memberID}>
                                    <ClubMemberListing updateMembersList={updateMembersList} handleClubEdit={props.handleClubEdit} currentUserID={props.currentUser.userID} handleRemoveMember={handleRemoveMember} club={props.club} memberID={memberID} />
                                </Fragment>
                            )
                        }
                    })}
                </div>
            </div> */}
        </div>
    )
}

export default ManageMembersModal