import React, { useState, Fragment, useEffect } from "react";
import CreatePostInactive from "../organisms/posts/CreatePostInactive";
import CreateClubPostActive from "../organisms/posts/CreateClubPostActive";

const ClubNewPost = (props) => {
   const [isCreatingPost, setCreatingPost] = useState(false);

   return (
      <div>
         {isCreatingPost ? (
            <CreateClubPostActive currentUser={props.currentUser} club={props.club} clubSelected={props.clubSelected} source={props.source} handlePostMade={props.handlePostMade} setCreatingPost={setCreatingPost}/>
         ) : (
            <CreatePostInactive userAvatar={props.currentUser.profileImage} setCreatePost={setCreatingPost} hubSelected={props.source === "ClubHome" ? props.club.clubName : props.clubSelected} />
         )}
      </div>
   );
};

export default ClubNewPost;
