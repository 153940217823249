import getGlobalURL from "../../assets/scripts/Global";
import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Post from "../Post";
import PostExpandModal from "../../Modals/postModals/PostExpandModal";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../scripts/badToken";

const ClubDashPostsField = (props) => {
   const url = getGlobalURL();

   const [listOfPosts, setListOfPosts] = useState([]);
   const [likeFlag, setLikeFlag] = useState();
   const [postDelete, setPostDelete] = useState(false);

   const [modalStatus, setModalStatus] = useState(false);
   const [modalPostID, setModalPostID] = useState();
   const [hasMore, setHasMore] = useState(true);

   useEffect(() => {
      if (props.currentUser.clubs.length > 0) {
         axios
            .get(`${url}clubs/getClubPostsByName/${props.clubSelected}`, {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            })
            .then((response) => {
               if (response.status == 200) {
                  setListOfPosts(response.data);
               }
            })
            .catch((error) => {
               if (error.code === "ERR_BAD_REQUEST") {
                  FoundBadToken();
               } else {
                  alert(error);
               }
            });
      }
   }, [props.clubSelected, props.postMade, postDelete, likeFlag]);

   let modalPost =
      modalPostID === undefined
         ? ""
         : listOfPosts.find((element) => element.postID === modalPostID);

   const handleLikeFlag = (countChange, postID) => {
      // This function runs when the 'like' button is pressed. The like flag is set with the new number of likes
      // only to ensure that useEffect sees a change. The modalPostID state variable is also updated in order to
      // pull out the post where the like change originated from the new array of posts returned by the axios call
      setLikeFlag(countChange);
      setModalPostID(postID);
   };

   function handlePostTitleClick(postID) {
      // This function runs when the user clicks on the 'post title field' from any of the posts. The modalStatus
      // state variable is changed to prevent the short circuiting in the return below, and the postID is grabbed
      // from the post where the click originated from to be used in pulling out the post from the new array
      setModalStatus(true);
      setModalPostID(postID);
   }

   function handlePostDelete() {
      // This function runs when.....
      setPostDelete(!postDelete);
      setModalStatus(false);
   }

   const closeModal = () => {
      // This function is called from within <PostExpandModal /> component when the user clicks on the modal backdrop.
      // By setting the modalStatus to false, short circuiting takes place in the logic within the return, which prevents
      // the <PostExpandModal /> component from being rendered.
      setModalStatus(false);
   };

   return (
      <Fragment>
         {/* if modalStatus == true then modal pops up, else short circuiting takes place*/}
         {modalStatus && (
            <PostExpandModal
               foreignUser={props.foreignUser}
               currentUser={props.currentUser}
               post={modalPost}
               handleLikeFlag={handleLikeFlag}
               handlePostTitleClick={handlePostTitleClick}
               closeModal={closeModal}
               modalStatus={modalStatus}
               userID={props.userID}
               setLikeFlag={likeFlag}
               handlePostDelete={handlePostDelete}
               source={props.source}
            />
         )}
         {listOfPosts.map((post) => {
            return (
               <Fragment key={post.postID}>
                  {/* passing in foreign user when its used in DisplayUser. DisplayUser needs to check if the foreignUser.userID === post.userPosterID */}
                  {/* OCEAN IN THIS CASE IS REALLY THE CLUB THAT THE USER IS CURRENTLY IN */}
                  <Post
                     ocean={props.clubSelected}
                     currentUser={props.currentUser}
                     post={post}
                     handleLikeFlag={handleLikeFlag}
                     closeModal={closeModal}
                     handlePostTitleClick={handlePostTitleClick}
                     modalStatus={modalStatus}
                     userID={props.userID}
                     handlePostDelete={handlePostDelete}
                     source={props.source}
                     foreignUser={props.foreignUser}
                  />
               </Fragment>
            );
         })}
      </Fragment>
   );
};

export default ClubDashPostsField;
