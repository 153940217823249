import { React, useState, useEffect } from 'react'
import { useCollapse } from 'react-collapsed'
import DefaultStudyGroupIcon from '../../assets/images/DefaultStudyGroupIcon.png'
import axios from 'axios'
import getGlobalURL from '../../assets/scripts/Global'
import LockIcon from '../../components/icons/LockIcon'
import RemoveIcon from '../icons/RemoveIcon'
import UserFollowIcon from '../icons/UserFollowIcon'
import ModTrue from '../icons/ModTrue'
import ModFalse from '../icons/ModFalse'

// ! PROPS COMING FROM StudyGroupField.jsx: currentUser, group, studyGroupSelected
const StudyGroupListing = (props) => {
    const url = getGlobalURL();
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
    }, [clicked])

    const handleJoinGroup = () => {
        axios.post(`${url}studyGroups/joinStudyGroup`, {
            studyGroupID: props.group.studyGroupID,
            userID: props.currentUser.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then(async (response) => {
            if(props.group.visibility === 'public'){
                props.currentUser.groups.push(props.group.studyGroupID);
                let data = {
                    studyGroupID: props.group.studyGroupID,
                    message: {
                        userID: props.currentUser.userID,
                        profileImage: props.currentUser.profileImage,
                        userName: `${props.currentUser.fName} ${props.currentUser.lName}`,
                        messageContent: 'joined the group',
                        date: Date.now(),
                        messageType: 'system'
                    }
                }
                await sendRealTimeMessage(props.group.studyGroupID, data);
            }
            if(props.group.visibility === 'private'){
                props.group.isPending = true;
            }
            await sendRealTimeMemberUpdate(props.group.studyGroupID, props.currentUser.userID, 'join')
            setClicked(!clicked);
        }).catch((error) => {
            alert('Error joining group')
        })
    }

    const handleLeaveGroup = () => {
        axios.post(`${url}studyGroups/leaveStudyGroup`, {
            studyGroupID: props.group.studyGroupID,
            userID: props.currentUser.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then(async (response) => {
            props.currentUser.groups = props.currentUser.groups.filter((group) => {
                return group !== props.group.studyGroupID
            })
            let data = {
                studyGroupID: props.group.studyGroupID,
                message: {
                    userID: props.currentUser.userID,
                    profileImage: props.currentUser.profileImage,
                    userName: `${props.currentUser.fName} ${props.currentUser.lName}`,
                    messageContent: 'left the group',
                    date: Date.now(),
                    messageType: 'system'
                }
            }
            await sendRealTimeMessage(props.group.studyGroupID, data);
            await sendRealTimeMemberUpdate(props.group.studyGroupID, props.currentUser.userID, 'leave')
            setClicked(!clicked);
        }).catch((error) => {
            alert('Error leaving group')
        })
    }

    const hanldeDeleteGroup = () => {
        axios.patch(`${url}studyGroups/deleteStudyGroup`, {
            studyGroupID: props.group.studyGroupID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.handleStudyGroupDeleted(props.group.studyGroupID)
        }).catch((error) => [
            alert('Error deleting group')
        ])
    }

    const hanldeCancelJoinRequest = () => {
        axios.patch(`${url}studyGroups/cancelJoinRequest`, {
            studyGroupID: props.group.studyGroupID,
            userID: props.currentUser.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.group.isPending = false;
            setClicked(!clicked);
        }).catch((error) => {

        })
    }

    const displayActionButton = () => {
        return props.group.studyGroupOwner === props.currentUser.userID ? (
            <div className='sg-action-btn-area clickable' onClick={hanldeDeleteGroup}>
                <RemoveIcon className="clickable" width='20px' height='20px' color='var(--primary-color)' />
                <span>Delete Group</span>
            </div>
        ) : props.currentUser.groups.includes(props.group.studyGroupID) ? (
            <div className='sg-action-btn-area clickable' onClick={handleLeaveGroup}>
                <ModTrue className="clickable" width='20px' height='20px' color='var(--primary-color)' />
                <span>Leave Group</span>
            </div>
        ) : props.group.isPending ? (
            <div className='sg-action-btn-area clickable' onClick={hanldeCancelJoinRequest}>
                <ModTrue className="clickable" width='20px' height='20px' color='var(--primary-color)' />
                <span>Cancel Join Request</span>
            </div>
        ) :
            <div className='sg-action-btn-area clickable' onClick={handleJoinGroup}>
                <ModFalse width='20px' height='20px' color='var(--primary-color)' />
                <span>Join Group</span>
            </div>
    }

    async function sendRealTimeMessage(groupID, data){
        if(props.ably){
            let channel = props.ably.channels.get(`study-group-${groupID}`);
            channel.publish('new-message', { data }, () => { console.log(`message sent to channel study-group-${groupID}`) })
        }
    }

    async function sendRealTimeMemberUpdate(studyGroupID, userID, action){
        if(props.ably){
            let channel = props.ably.channels.get(`study-group-${studyGroupID}`);
            channel.publish('member-update', { studyGroupID, member: {
                userInfo: {
                    userID: userID,
                    profileImage: props.currentUser.profileImage,
                    name: `${props.currentUser.fName} ${props.currentUser.lName}`
                }
            }, action }, () => { console.log(`member update sent to channel study-group-${studyGroupID}`) })
        }
    }

    return (
        <div className='sg-listing-container'>
            <img src={props.group.studyGroupPicture ? props.group.studyGroupPicture : DefaultStudyGroupIcon} alt={props.group.studyGroupName} />
            <div className='sg-listing-text-area'>
                <h3 style={{color: 'black'}} >{props.group.visibility === 'public' ? `${props.group.studyGroupName}` : <>{props.group.studyGroupName} <LockIcon width='22' height='22' color='gold' /></>}</h3>
                <p >{props.group.studyGroupDescription}</p>
                <div className='sg-button-container'>
                    {displayActionButton()}
                </div>
            </div>
        </div>
    )
}

export default StudyGroupListing

// return (
//     <div id='study-group-container' className='border-radius-25' >
//         <div id='sg-image-container' className='border-radius-25' >
//             <img src={props.group.studyGroupPicture ? props.group.studyGroupPicture : DefaultStudyGroupIcon} />
//         </div>
//         <div id='sg-text-field' >
//             <h1 style={{color: 'black'}} >{props.group.visibility === 'public' ? `${props.group.studyGroupName}` : <>{props.group.studyGroupName} <LockIcon width='22' height='22' color='gold' /></>}</h1>
//             <p style={{color:'black'}} >{props.group.studyGroupDescription}</p>
//         </div>
//         <div id='sg-button' >
//             {displayActionButton()}
//         </div>
//     </div>
// )