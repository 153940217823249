import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import LeftAlignedMessage from "../LeftAlignedMessage";
import RightAlignedMessage from "../RightAlignedMessage";
import ChatTextArea from "../../atoms/ChatTextArea";
import { FoundBadToken } from "../../../scripts/badToken";
import UserInfo from "./UserInfo";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const MessageApplicationChatField = (props) => {
	const url = getGlobalURL();

	const [foreignUser, setForeignUser] = useState({});

	const [foreignUserImage, setForeignUserImage] = useState("");
	const [foreignUserName, setForeignUserName] = useState("");

	const [listOfMessages, setListOfMessages] = useState();

	const [messages, setMessages] = useState(0);
	const [message, setMessage] = useState("");

	const lastMessageRef = useRef(null);

	const scrollToBottom = () => {
		/*
			Called within useEffect when a new message is sent. Automatically scrolls the message-application-chat-field
			own to the last message without scrolling the entire window down.
		*/
		let lastMessageDiv = lastMessageRef.current;
		if (lastMessageDiv) {
			let lastChild = lastMessageDiv.lastChild;
			if (lastChild) {
				lastMessageDiv.scrollTop = lastChild.offsetTop + lastChild.scrollHeight;
			}
		}
	};

	useEffect(() => {
		let tempObj = {
			messagesByDate: [{
				date: '',
				messages: []
			}]
		}
		props.conversation.messages.forEach((message) => {
			let date = new Date(message.date).toString().split(" ").splice(0, 4).join(" ");
			let obj = tempObj.messagesByDate.find((m) => {
				return m.date === date;
			})
			if(obj){
				obj.messages.push(message);
			}
			else{
				tempObj.messagesByDate.push({
					date: date,
					messages: [message]
				})
			}
		})
		setListOfMessages(tempObj);
	}, [props.conversation.messages.length, props.conversation])

	useEffect(() => {
		let otherUser = props.conversation.participants.find((participant) => {
			return participant.userID !== props.currentUser.userID;
		});

		let currentUser = props.conversation.participants.find((participant) => {
			return participant.userID === props.currentUser.userID;
		});
		setMessages(currentUser.missedMessages);
		setForeignUserImage(otherUser.userImage);
		setForeignUserName(otherUser.name);
		setForeignUser(otherUser);
	}, [props.conversation])

	useEffect(() => {
		scrollToBottom();
	}, [props.conversation.messages.length, lastMessageRef, listOfMessages]);

	const newSendMessage = async () => {
		if (!message) {
			alert("Message cannot be empty");
			return;
		}
		// * get the ID of the other user
		let otherParticipant = props.conversation.participants.find((participant) => {
			return participant.userID !== props.currentUser.userID;
		});

		if (props.ably) {
			if (await userOffline(otherParticipant.userID)) {
				console.log('user is offline')
				// * pass in true if the user missed this message
				await sendMessage(true);
				return;
			}
			// * pass in false since the user is online
			await sendMessage(false);
			sendRealTimeMessage(otherParticipant.userID);
		}
	};

	function sendRealTimeMessage(otherUserID) {
		console.log(`sending real time message to the channe: conversation-${otherUserID}`)
		let channel = props.ably.channels.get(`conversation-${otherUserID}`);
		channel.publish("new-message", {
				data: {
					conversationID: props.conversation.conversationID,
					message: {
						userID: props.currentUser.userID,
						messageContent: message,
						contentUrl: "",
						date: Date.now(),
					},
				},
			}, () => {
				
			}
		);
		channel = props.ably.channels.get(`user-updates-${otherUserID}`);
		channel.publish("new-message", {/* nothing to send since we just want to trigger this event */}, () => {
				console.log("temp new message sent");
			}
		);
	}

	async function userOffline(userID) {
		let tempChannel = props.ably.channels.get(`user-updates-${userID}`);
		return new Promise((resolve, reject) => {
			tempChannel.presence.get((err, members) => {
				if(err){
					reject(err);
				}
				if(members.length > 0){
					resolve(false);
				}
				else {
					resolve(true);
				}
			});
		});
	}

	async function sendMessage(missedMessage) {
		let time = Date.now();
		axios.post(`${url}conversations/sendMessage`,{
			conversationID: props.conversation.conversationID,
			userID: props.currentUser.userID,
			messageContent: message,
			contentUrl: "",
			date: time
		}, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			},
		}).then((response) => {
			props.addMessageToConvo({
				userID: props.currentUser.userID,
				messageContent: message,
				contentUrl: "",
				date: time
			})
			setMessage("");
		}).catch((error) => {
			if (error.code === "ERR_BAD_REQUEST") {
				FoundBadToken();
			} else {
				alert(error);
			}
		});
	}

	function setImageFlag(dateIndex, index) {
		if(listOfMessages.messagesByDate[dateIndex].messages[index + 1] !== undefined){
			if(listOfMessages.messagesByDate[dateIndex].messages[index + 1].userID !== listOfMessages.messagesByDate[dateIndex].messages[index].userID){
				return true;
			}
			else{
				return false;
			}
		}
		return true;
	}

	return (
		<Fragment>
			<div id="message-application-chat-field-container" className="make-grid" >
				<div className="message-application-chat-field scrollbar-mch-thin" ref={lastMessageRef} >
					{listOfMessages && listOfMessages.messagesByDate.map((date, dateIndex) => {
						if(date.date != '' && date.messages.length){
							return (
								<Fragment>
									<div className="date-separator">{date.date}</div>
									{date.messages.map((message, index) => {
										const flag = setImageFlag(dateIndex, index);
										return message.userID === props.currentUser.userID ? (
											<RightAlignedMessage userImage={props.currentUser.profileImage} key={index} message={message} imageFlag={flag} />
										) : (
											<LeftAlignedMessage foreignUserImage={foreignUser.userImage} foreignUserName={foreignUser.name} key={index} message={message} imageFlag={flag} />
										);
									})}
								</Fragment>
							)
						}
					})}
				</div>
				<div className="message-app-input-field">
					<ChatTextArea handleSendMessage={newSendMessage} setMessage={setMessage} message={message} />
				</div>
			</div>
			<UserInfo foreignUser={foreignUser} />
		</Fragment>
	);
};

export default MessageApplicationChatField;
