import Post from '../../Post'

const BanPostList = (props) => {

  return (
    <div className='modal-container' >
      <div className='modal-backdrop' onClick={props.closeModal} />
      <div className='ban-appeal-modal-content' >
        <div id='create-post-form-container' >
          <div id="modal-title" className='make-grid' >
            <h1>These are posts made by the user</h1>
          </div>
          <div id='banned-members-container' >
            {props.posts.map((post) => {
              return (
                <Post user={props.user} post={post} source='clubBanAppeal' />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BanPostList;