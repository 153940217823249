import { React, Fragment } from 'react'
import DepartmentField from '../departments/CollegeField'
import UniversityStoreField from '../campusModeComponents/UniversityStore/UniversityStoreField'
import OnCampusJobs from '../jobs/OnCampusJobs'
import DirectoryField from '../directory/DirectoryField'
import LibraryField from '../library/LibraryField'

/**
 * @description - A component that renders a specific field based on the active button selected. The fields that can be rendered are: 
 *  DepartmentField, DirectoryField, LibraryField, OnCampusJobs, Search, and UniversityStoreField.
 * 
 * @component
 * @param {String} activeButton - The active button selected
 * @param {Object} currentUser - The current user object
 * @returns {JSX.Element} - A specific field based on the active button selected
 * 
 * @example
 * // Renders a specific field based on the active button selected
 * <AdministrationTab
 *  activeButton={activeButton}
 *  currentUser={currentUser}
 * />
 */

const AdministrationTab = (props) => {
    return (
        <Fragment>
            {(props.activeButton === "Departments") ? <DepartmentField currentUser={props.currentUser} />
                : (props.activeButton === "Directory") ? <DirectoryField />
                : (props.activeButton === "Library") ? <LibraryField />
                : (props.activeButton === "On-Campus Jobs") ? <OnCampusJobs currentUser={props.currentUser} />
                : (props.activeButton === "Search") ? <p>SEARCH COMPONENT HERE</p>
                : (props.activeButton === "TU Store") ? <UniversityStoreField />
                : null
            }
        </Fragment>
    )
}

export default AdministrationTab

// Old Navigation: Note that we'd need to pass the following props to the AdministrationBtnList component
// import AdministrationBtnList from '../campusModeComponents/AdministrationBtnList'
// <AdministrationBtnList currentUser={props.currentUser} campusButtonSelection={props.campusButtonSelection} activeButton={props.activeButton} handleTabClick={props.handleTabClick} tabSelected={props.tabSelected} source={props.source}/>
