import React from 'react'

/**
 * @description - This component renders a left-aligned message component in a private chat. Used in the message application interface.
 * 
 * @component
 * @param {string} props.foreignUserImage - A url string used to fetch the foreign user's avatar.
 * @param {string} props.foreignUserName - The name of the foreign user to be displayed above the message content.
 * @param {boolean} props.imageFlag - A boolean flag that determines whether the foreign user's avatar is displayed or not.
 * @param {object} props.message - A message object that contains the content of the message - the only field used currently.
 * @returns {JSX.Element} - A react element that renders a left-aligned message container in a private chat.
 * 
 * @example
 * // Render a left-aligned message in a private chat.
 * <LeftAlignedMessage foreignUserImage={foreignUserImage} foreignUserName={foreignUserName} key={index} message={message} imageFlag={flag} />
 */

const LeftAlignedMessage = (props) => {
    return (
        <div className='message-left-container'>
            <div className='message-container-foreign-user make-grid'>
                <div className='message-foreign-user-avatar-container'>
                    {(props.imageFlag) ? <img className='message-user-avatar' src={props.foreignUserImage} /> : <div className='message-foreign-user-avatar-blank' />}
                </div>
                <div className='message-content-foreign-user make-grid'>
                    <h3>{props.messageType !== 'system' ? props.foreignUserName : props.foreignUserName + ' ' + props.message.messageContent}</h3>
                    {props.messageType !== 'system' && <p>{props.message.messageContent}</p>}
                </div>
            </div>
        </div>
    )
}

export default LeftAlignedMessage