// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getPerformance } from "firebase/performance";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-RQQVfkxac9Tep630_S4QqE3Ri3n26-I",
  authDomain: "oceans-file-upload.firebaseapp.com",
  projectId: "oceans-file-upload",
  storageBucket: "oceans-file-upload.appspot.com",
  messagingSenderId: "114832910793",
  appId: "1:114832910793:web:23d42786c6776c4c26e018"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Google Analytics for Firebase 
const analytics = getAnalytics(app);

// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);

export const storage = getStorage(app);