import { React, useState } from 'react';

const ClubEventListing = (props) => {
    const [toggleClass, setToggleClass] = useState(false);

    let startDate = new Date(props.event.start);
    let formattedStartDate = startDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    let formattedStartTime = startDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).split(" ").join("");

    let endDate = new Date(props.event.end);
    let formattedEndDate = endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    let formattedEndTime = endDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).split(" ").join("");

    return (
        <div id='club-events-listings-area'>
                <h3 onClick={() => props.handleSetEvent(props.event)} >{props.event.title}</h3>
                <p id='club-events-date' >{formattedStartDate}, {formattedStartTime} - {formattedEndTime}</p>
                <p id='club-events-description' onClick={() => setToggleClass(!toggleClass)} className={(toggleClass) ? "show" : ""}>{props.event.description} {(toggleClass) ? " ...click for less" : null}</p>
                {(!toggleClass) ? <h5 onClick={() => setToggleClass(!toggleClass)}>...click for more</h5> : null}
        </div>
    )
}

export default ClubEventListing