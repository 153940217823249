import getGlobalURL from "../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import ChildOceansListing from "./ChildOceansListing";
import PuffLoader from "react-spinners/PuffLoader";
import CreateChildOceanModal from "../../Modals/forms/CreateChildOceanModal";
import { FoundBadToken } from "../../scripts/badToken";

const ChildOceansList = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [childOceansList, setChildOceansList] = useState([]);
   const [fetching, setFetching] = useState(true);
   const [modalStatus, setModalStatus] = useState(false);

   // Get 5 suggested oceans that the user is not already a member of. Store in state variable

   useEffect(() => {
      axios.get(`${url}oceans/getChildOceans/${props.parentOcean}`, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         setChildOceansList(response.data);
         setFetching(false);
      }).catch((error) => {
         if (error.response.status === 401) {
            alert("Session expired. Please login again.")
            FoundBadToken();
         } else {
            setFetching(false);
            // alert(error.response.data.err);
         }
      });
   }, [props.parentOcean, props.parentOceanChildren.length]);

   const handleCreateChildOceanClick = () => {
      setModalStatus(true);
   };

   const closeModal = () => {
      setModalStatus(false);
   };

   return (
      <Fragment>
         {modalStatus && <CreateChildOceanModal currentUser={props.currentUser} closeModal={closeModal} parentOcean={props.parentOcean} />}
         {fetching ? (
            <PuffLoader color="#006994" />
         ) : (
            <div id="child-oceans-container">
               <h2>Sub Hubs</h2>
               {/* map through suggested oceans list here */}
               {childOceansList.sort().map((ocean) => {
                  if (ocean != null) {
                     return (
                        <Fragment key={ocean.oceanName}>
                           <ChildOceansListing currentUser={props.currentUser} ocean={ocean} />
                        </Fragment>
                     );
                  }
               })}
               <p>Create and request to be a child topic of this ocean!</p>
               <button id="create-ocean-btn" className="btn-ease" onClick={handleCreateChildOceanClick} >Create A Sub Hub</button>
               <hr />
            </div>
         )}
      </Fragment>
   );
};

export default ChildOceansList;
