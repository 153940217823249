import { React, useState, useEffect, Fragment } from "react";
import axios from "axios";
import getGlobalURL from "../../../../assets/scripts/Global";
import FormCancelSubmitBtns from "../../../../Modals/forms/FormCancelSubmitBtns";
import InputWithObject from "../../../atoms/InputWithObject";
import ConvertToBase64Simple from "../../../helperComponents/ConvertToBase64Simple";
import {storage} from "../../../../firebase";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import DefaultStudyGroupIcon from "../../../../assets/images/DefaultStudyGroupIcon.png"
import { FoundBadToken } from "../../../../scripts/badToken";

const EditStudyGroupModal = (props) => {
    const url = getGlobalURL();
    const [studyGroupName, setStudyGroupName] = useState(props.group.studyGroupName);
    const [studyGroupDescription, setStudyGroupDescription] = useState(props.group.studyGroupDescription);
    const [studyGroupRules, setStudyGroupRules] = useState(props.group.studyGroupRules)
    const [checked, setChecked] = useState(props.group.visibility === 'private' ? true : false)
    const [groupIconPreview, setGroupIconPreview] = useState(props.group.studyGroupPicture ? props.group.studyGroupPicture : DefaultStudyGroupIcon)
    const [groupIconImage, setGroupIconImage] = useState(null)
    const [groupSubject, setGroupSubject] = useState(props.group.subject)

    const possibleSubjects =  ['ASTR', 'COSC', 'ENGL', 'MATH'];

    const saveStudyGroup = async () => {
        let studyGroupPicture;
        if(groupIconImage){
            studyGroupPicture = await updateStudyGroupIcon(props.group.studyGroupID);
        }
        axios.patch(`${url}studyGroups/updateStudyGroup`, {
            studyGroupID: props.group.studyGroupID,
            studyGroupName: studyGroupName,
            subject: groupSubject,
            studyGroupDescription: studyGroupDescription,
            studyGroupPicture: groupIconImage === null ? props.group.studyGroupPicture : studyGroupPicture,
            studyGroupRules: studyGroupRules,
            private: checked ? 'private' : 'public'
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.group.studyGroupName = studyGroupName;
            props.group.subject = groupSubject;
            props.group.studyGroupDescription = studyGroupDescription;
            props.group.studyGroupPicture = groupIconImage === null ? props.group.studyGroupPicture : studyGroupPicture;
            props.group.studyGroupRules = studyGroupRules;
            props.group.visibility = checked ? 'private' : 'public';
            props.updateGroup(props.group);
            props.closeModal();
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    const updateStudyGroupIcon = async (studyGroupID) => {
        if(!groupIconImage){
            return;
        }
        else{
            const iconRef = ref(storage, `studyGroupIcons/${studyGroupID}`);
            return new Promise((resolve, reject) => {
                uploadBytes(iconRef, groupIconImage).then((response) => {
                    getDownloadURL(iconRef).then((url) => {
                        resolve(url);
                    })
                })
            })
        }
    }

    return (
        <div className='modal-container' >
            <div className='sg-backdrop' onClick={() =>  { props.closeModal() }}  />
            <div className='create-account-form-2c-container border-radius-25 make-grid'>
                <div id='create-account-form-2c-header' className='form-header'>Update study Group</div>
                <div id='create-account-form-main-body' >
                    <div id='create-account-form-lc' >
                        <div className='form-body-img-area border-radius-10' >
                            <img src={groupIconPreview} alt="Group Icon" />
                            <ConvertToBase64Simple textID="banner-1" setFile={setGroupIconImage} setImage={setGroupIconPreview} />
                        </div>
                    </div>
                    <div id='create-account-form-rc' >
                        <div id='create-account-2c-form-container' >
                            <div className='form-body form-body-override' >
                                {/* <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupName' labelText='Group Name' date={studyGroupName} setData={setStudyGroupName} /> */}
                                {/* <InputWithObject handleFocus={() => {}} type='text' required={true} forText='studyGroupCategory' labelText='Group Category' helperText='Subject (e.g COSC)' date={groupCategory} setData={setGroupCategory} /> */}
                                <label htmlFor='studyGroupName'>* Group Name</label>
                                <input type='text' id='studyGroupName' value={studyGroupName} onChange={(e) => {setStudyGroupName(e.target.value)}} />
                                <label htmlFor='studyGroupCategory'>* Group Subject</label>
                                <select className='form-select' id='studyGroupCategory' value={groupSubject} onChange={(e) => {setGroupSubject(e.target.value)}} >
                                    {/* <option value='currentSubject' disabled >Current: {props.group.subject}</option> */}
                                    {possibleSubjects.map((subject, index) => {
                                        return (
                                            <option key={index} value={subject} >{subject}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor='studyGroupDescription'>Group Description</label>
                                <textarea id='studyGroupDescription' value={studyGroupDescription} onChange={(e) => {setStudyGroupDescription(e.target.value)}} />
                                <label htmlFor='studyGroupRules'>Group Rules</label>
                                <textarea id='studyGroupRules' value={studyGroupRules} onChange={(e) => {setStudyGroupRules(e.target.value)}} />
                                <div className='form-checkbox' >
                                    <label htmlFor='checkbox'>This is a private Study Group</label>
                                    <input type='checkbox' id='checkbox' checked={checked} onChange={(e) => {setChecked(e.target.checked)}} />
                                </div>
                                <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText='Cancel' handleSubmit={saveStudyGroup} submitText='Update Group' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditStudyGroupModal;