import React, { createContext, useState, useEffect, useRef } from 'react';
import { Realtime } from 'ably'
import axios from 'axios';
import getGlobalURL from '../scripts/Global';
import { FoundBadToken } from '../../scripts/badToken';

export const AblyContext = createContext();

export const AblyProvider = ({ children }) => {
    const url = getGlobalURL();
    const ablyRef = useRef()
    let currentUser = useRef()
    let userChannel = useRef()
    let onlineChannel = useRef()
    const [numMessages, setNumMessages] = useState(0);
    const [numNotifications, setNumNotifications] = useState(0);

    const [flag, setFlag] = useState(false); // * flag to force re-render

    useEffect(() => {
        if(sessionStorage.getItem('userID') !== null || sessionStorage.getItem('userID') !== undefined){
            initializeAbly(sessionStorage.getItem('userID'))
            if(sessionStorage.getItem('token')){
                fetchNumMessages(sessionStorage.getItem('userID'), sessionStorage.getItem('token'))
            }
        }
        if(sessionStorage.getItem('currentUser')){
            currentUser.current = JSON.parse(sessionStorage.getItem('currentUser'))
        }
    }, [])

    const initializeAbly = (userID, user) => {
        if(userID){
            ablyRef.current = new Realtime({key: process.env.REACT_APP_ABLY, clientId: userID.toString()});
            sessionStorage.setItem('userID', userID)
            
            initUserChannel(userID);
            
            // * join all online users channel
            onlineChannel.current = ablyRef.current.channels.get('all-online-users');
            onlineChannel.current.presence.enter(userID.toString(), (err) => {
                if(err){
                    console.log(err);
                }
                else{
                    console.log('user entered all-online-users channel');
                }
            })
            setFlag(!flag)
        }
        if(user){
            console.log('setting current user')
            currentUser.current = user;
            sessionStorage.setItem('currentUser', JSON.stringify(user))
            console.log('current user set in session storage')
        }
    }

    const initUserChannel = (userID) => {
        userChannel.current = ablyRef.current.channels.get(`user-updates-${userID}`);
        userChannel.current.presence.enter("", (err) => {});
        userChannel.current.subscribe('new-message', (message) => {
            setNumMessages((prevNumMessages) => prevNumMessages + 1)
        })

        userChannel.current.subscribe('new-notification', (message) => {
            setNumNotifications((prevNumNotifications) => prevNumNotifications + 1)
        })

        userChannel.current.subscribe('remove-notification', (message) => {
            if(numNotifications > 0){
                setNumNotifications((prevNumNotifications) => prevNumNotifications - 1)
            }
        })

        userChannel.current.subscribe('club-update', (message) => {
            currentUser.current.clubs = message.data.data
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser.current))
        })

        userChannel.current.subscribe('ocean-update', (message) => {
            currentUser.current.ocean = message.data.data
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser.current))
        
        })

        userChannel.current.subscribe('study-group-update', (message) => {
            if(message.data.action === 'add'){
                currentUser.current.groups.push(message.data.studyGroupID)
                sessionStorage.setItem('currentUser', JSON.stringify(currentUser.current))
            }
            else if(message.data.action === 'remove'){
                currentUser.current.groups = currentUser.current.groups.filter((studyGroup) => {
                    return studyGroup !== message.data.studyGroupID;
                })
                sessionStorage.setItem('currentUser', JSON.stringify(currentUser.current))
            }
        })

        userChannel.current.subscribe('friend-group-invite', (message) => {
            currentUser.current.friendGroupRequests.push(message.data.friendGroupID)
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser.current))
        })
    }

    const closeAbly = () => {
        if(ablyRef.current){
            console.log('closing Ably connection')
            userChannel.current.presence.leave((err) => {
                if (!err) {
                    console.log("user has left the channel with presence.leave()");
                }
                else {
                    console.log(err);
                }
            });
            userChannel.current.unsubscribe();
            ablyRef.current.close()
            setFlag(!flag)
        }
        else{
            console.log('Ably connection already closed or is undefined')
        }
    }

    const fetchNumMessages = (userID, token) => {
        axios.all([
			axios.get(`${url}conversations/getNumMissedMessages/${userID}`, {
				headers: {
					Authorization: token,
				}
			}),
			axios.get(`${url}studyGroups/getNumMissedMessages/${userID}`, {
				headers: {
					Authorization: token,
				}
			}),
			axios.get(`${url}friendGroups/getNumMissedMessages/${userID}`, {
				headers: {
					Authorization: token,
				}
			}),
            axios.get(`${url}users/getNumNotifications/${userID}`, {
                headers: {
                    Authorization: token,
                }
            })
		]).then((responses) => {
			let convoMissedMessages = responses[0].data.convoMissedMessages;
			let groupMissedMessages = responses[1].data.sgMissedMessages;
			let friendGroupMissedMessages = responses[2].data.fgMissedMessages;
            let missedNotifications = responses[3].data.missedNotifications;
			setNumMessages(convoMissedMessages + groupMissedMessages + friendGroupMissedMessages);
            setNumNotifications(missedNotifications)
		})
    }

    const reduceNumMessages = (num) => {
        setNumMessages(numMessages - num);
    }

    const setNotificationsRead = () => {
        setNumNotifications(0);
    }

    const markAsRead = (userID, chatID, token, type) => {
        axios.patch(`${url}${type}/markMessagesAsRead`, {
            userID: userID,
            chatID: chatID
        }, {
            headers: {
                Authorization: token
            }
        }).then((response) => {

        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session Expired. Please login again.')
                FoundBadToken()
            }
        })
    }

    return (
        <AblyContext.Provider value={{ ably: ablyRef.current, initializeAbly, closeAbly, fetchNumMessages, numMessages, reduceNumMessages, numNotifications, setNotificationsRead, markAsRead, currentUser: currentUser.current }}>
            {children}
        </AblyContext.Provider>
    )
}