import React from 'react'

const ConstructionIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        aria-hidden="true"
        className="iconify iconify--noto"
        viewBox="0 0 128 128"
        {...props}
    >
        <path
            fill="#9e9e9e"
            d="M28.19 121.77s-1.75 2.23-6.3 2.23-6.3-2.23-6.3-2.23V43.71h12.6v78.06z"
        />
        <path
            fill="#bdbdbd"
            d="M28.19 121.77s-1.75 2.23-6.3 2.23-6.3-2.23-6.3-2.23V91.7s1.28-2.44 5.96-2.44 6.64 2.44 6.64 2.44v30.07z"
        />
        <path
            fill="#9e9e9e"
            d="M112.4 121.77s-1.75 2.23-6.3 2.23-6.3-2.23-6.3-2.23V43.71h12.6v78.06z"
        />
        <path
            fill="#bdbdbd"
            d="M112.4 121.77s-1.75 2.23-6.3 2.23-6.3-2.23-6.3-2.23V91.7s1.28-2.44 5.96-2.44 6.64 2.44 6.64 2.44v30.07z"
        />
        <path
            fill="#9e9e9e"
            d="M121.27 81.86H6.73C5.22 81.86 4 80.64 4 79.13V44.17c0-1.51 1.22-2.73 2.73-2.73h114.54c1.51 0 2.73 1.22 2.73 2.73v34.96c0 1.51-1.22 2.73-2.73 2.73z"
        />
        <path
            fill="#ffd600"
            d="M121.27 43.44H6.73C5.22 43.44 4 44.66 4 46.17v34.96c0 1.51 1.22 2.73 2.73 2.73h114.54c1.51 0 2.73-1.22 2.73-2.73V46.17c0-1.51-1.22-2.73-2.73-2.73z"
        />
        <path
            fill="#212121"
            d="M17.43 43.44 4 56.87v19.8l33.23-33.23zM55.1 43.44 14.69 83.86h19.8L74.9 43.44zM92.78 43.44 52.36 83.86h19.8l40.41-40.42zM124 49.89 90.03 83.86h19.8L124 69.69z"
        />
        <path
            fill="#e2a610"
            d="M113.8 45.19H98.41c-1.44 0-2.5-.94-2.16-1.9l1.07-6.71h17.55l1.07 6.71c.36.97-.7 1.9-2.14 1.9z"
        />
        <circle cx={106.1} cy={23.71} r={12.71} fill="#ffca28" />
        <circle cx={106.1} cy={23.71} r={9.39} fill="#ff5722" />
        <path
            fill="#ffca28"
            d="M114.88 36.68H97.33s.06-2.8 5.08-2.8h7.44c5.31 0 5.03 2.8 5.03 2.8z"
        />
        <path
            fill="#ffd5ca"
            d="m107.21 19.21 2.4-3.1c.11-.14.33-.03.29.14l-.93 3.81c-.22.89.46 1.75 1.38 1.73l3.92-.06c.18 0 .23.24.07.32l-3.56 1.65c-.83.39-1.07 1.45-.49 2.16l2.49 3.03c.11.14-.04.33-.2.25l-3.51-1.75c-.82-.41-1.8.06-2 .96l-.82 3.84c-.04.18-.29.18-.32 0l-.82-3.84c-.19-.9-1.18-1.37-2-.96l-3.51 1.75c-.16.08-.32-.12-.2-.25l2.49-3.03c.58-.71.34-1.77-.49-2.16l-3.56-1.65c-.16-.08-.11-.32.07-.32l3.92.06c.92.01 1.6-.84 1.38-1.73l-.93-3.81c-.04-.17.18-.28.29-.14l2.4 3.1c.59.72 1.68.72 2.24 0z"
        />
        <path
            fill="#e2a610"
            d="M29.59 45.19H14.2c-1.44 0-2.5-.94-2.16-1.9l1.07-6.71h17.55l1.07 6.71c.36.97-.7 1.9-2.14 1.9z"
        />
        <circle cx={21.9} cy={23.71} r={12.71} fill="#ffca28" />
        <circle cx={21.9} cy={23.71} r={9.39} fill="#ff5722" />
        <path
            fill="#ffca28"
            d="M30.67 36.68H13.12s.06-2.8 5.08-2.8h7.44c5.31 0 5.03 2.8 5.03 2.8z"
        />
        <path
            fill="#ffd5ca"
            d="m23 19.21 2.4-3.1c.11-.14.33-.03.29.14l-.93 3.81c-.22.89.46 1.75 1.38 1.73l3.92-.06c.18 0 .23.24.07.32l-3.56 1.65c-.83.39-1.07 1.45-.49 2.16l2.49 3.03c.11.14-.04.33-.2.25l-3.51-1.75c-.82-.41-1.8.06-2 .96l-.82 3.84c-.04.18-.29.18-.32 0l-.82-3.84c-.19-.9-1.18-1.37-2-.96l-3.51 1.75c-.16.08-.32-.12-.2-.25l2.49-3.03c.58-.71.34-1.77-.49-2.16l-3.56-1.65c-.16-.08-.11-.32.07-.32l3.92.06c.92.01 1.6-.84 1.38-1.73l-.93-3.81c-.04-.17.18-.28.29-.14l2.4 3.1c.59.72 1.68.72 2.24 0z"
        />
    </svg>
)

export default ConstructionIcon;