import { React, Fragment, useEffect } from 'react'
import ClubDashPostsField from '../Clubs/ClubDashPostField'
import ClubNewPost from '../Clubs/ClubNewPost'

/**
 * @description - A component that renders a ClubNewPost component and a ClubDashPostsField component. The ClubDashPostsField 
 *  component is where the club posts are displayed. The ClubNewPost component is where the user can create a new post.
 * 
 * @component
 * @param {String} activeButton - The active button selected
 * @param {String} clubSelection - The club selected
 * @param {Object} currentUser - The current user object
 * @param {Boolean} postMade - The state variable that indicates if a post was made
 * @param {String} source - The source component where the tab is being rendered
 * @param {String} tabSelected - The tab that is currently selected
 * @param {Boolean} userChange - The state variable that indicates if the user has changed
 * @param {Function} handlePostMade - The function that handles the post made state
 * @returns {JSX.Element} - A ClubNewPost component and a ClubDashPostsField component
 * 
 * @example
 * // Renders a ClubNewPost component and a ClubDashPostsField component
 * <ClubsTab
 *  activeButton={activeButton}
 *  clubSelection={clubSelection}
 *  currentUser={currentUser}
 *  handlePostMade={handlePostMade}
 *  postMade={postMade}
 *  source="dashboard"
 *  tabSelected={tabSelected}
 *  userChange={userChange}
 * />
 */

const ClubsTab = (props) => {

    useEffect(() => {
        
    }, [props.userChange])

    return (
        <Fragment>
            {/* checks to make sure the user is in a club */}
            {props.currentUser.clubs.length > 0 && <ClubNewPost clubSelected={props.activeButton} currentUser={props.currentUser} handlePostMade={props.handlePostMade} source="Dashboard"/>}
            {props.currentUser.clubs.length > 0 && <ClubDashPostsField clubSelected={props.activeButton} currentUser={props.currentUser} postMade={props.postMade} source={props.source} tabSelected={props.tabSelected} userID={props.currentUser.userID} />}
        </Fragment>
    )
}

export default ClubsTab


// Old Navigation: Note that we'd need to pass the following props to the ClubsList component
// import ClubsList from '../Clubs/ClubsList'
// <ClubsList handleClubClicked={props.handleClubClicked} currentUser={props.currentUser} clubSelection={props.clubSelection} activeButton={props.activeButton} oceanCreated={props.oceanCreated} handleTabClick={props.handleTabClick} tabSelected={props.tabSelected} source={props.source} />