import React from 'react'

const ThumbsDown = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <g fill={props.color}>
            <path d="M12.366 23.112c.247.535.782.888 1.384.888 1.242 0 2.222-.359 2.74-1.292.226-.407.314-.843.354-1.225.039-.37.039-.764.039-1.111V17h3.133c1.838 0 3.225-1.648 2.95-3.455l-1.374-9A2.988 2.988 0 0 0 18.643 2H8v13h.377l3.989 8.112ZM6 15H3.983A2.992 2.992 0 0 1 1 12V5c0-1.649 1.328-3 2.983-3H6v13Z" />
        </g>
    </svg>
)

export default ThumbsDown;