import { React, useState, useEffect } from 'react'
import getGlobalURL from "../../../../assets/scripts/Global";
import axios from 'axios';
import BanAppealListing from '../../BanAppealListing';

const ManageBanAppeals = (props) => {

  const url = getGlobalURL();

  const [banAppeals, setBanAppeals] = useState(props.club.banAppeals);

  const handleBackdropClick = () => {
    props.closeModal('banAppeals')
  }

  return (
    <div className='modal-container' >
      <div className='sg-backdrop' onClick={handleBackdropClick} />
      <div className='create-account-form-2c-container border-radius-25 make-grid' >
          <div id='create-account-form-2c-header' className='form-header'>Ban Appeals</div>
          <div className='member-listing-container' >
            {banAppeals.map((appeal) => {
              return (
                <BanAppealListing club={props.club} currentUser={props.currentUser} appeal={appeal} />
              )
            })}
          </div>
        </div>
    </div>
  )
}

export default ManageBanAppeals;