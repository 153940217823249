import React from 'react'
import AddDocumentIcon from '../messages/toolbar/icons/AddDocumentIcon'
import AddImageIcon from '../messages/toolbar/icons/AddImageIcon'

/**
 * @description - This component renders a pop-up that allows the user to add files
 * 
 * @component
 * @param {function} handleAddFilesPopUp - A function that toggles the pop-up
 * @param {function} setFile - A function that updates the file state variable
 * @param {function} setImage - A function that updates the image state variable
 * @param {function} setImagePreview - A function that updates the image preview state variable
 * @returns {JSX.Element} - A react element that renders a pop-up that allows the user to add files
 * 
 * @example
 * // Render a pop-up that allows the user to add files
 * <AddFilesPopUp 
 *  handleAddFilesPopUp={handleAddFilesPopUp}
 *  setFile={props.setFile} 
 *  setImage={props.setImage} 
 *  setImagePreview={props.setImagePreview}
 * />
 */

const AddFilesPopUp = (props) => {

    const handleAddFile = (e) => {
        props.setFile(e.target.files[0]);
        props.handleAddFilesPopUp((prevState) => !prevState);
    };

    const handleAddImage = (e) => {
        const targetFiles = e.target.files[0];
        props.setImage(targetFiles);
        var reader = new FileReader();
        if (targetFiles) {
            reader.readAsDataURL(targetFiles);
            reader.onload = function () {
            props.setImagePreview(reader.result);
            props.handleAddFilesPopUp((prevState) => !prevState);
            };
        }
    };

    return (
        <div id='message-app-add-files-pop-up' className='border-radius-10'>
            <ul>
                <li>
                    <div className='add-files-pop-up-icons border-radius-5'>
                        <input type='file' accept='*' name='file-selection' onChange={handleAddFile} style={{ display: 'none' }}/>
                        <AddDocumentIcon onClick={() => {document.querySelector('input[name="file-selection"]').click()}} width="30px" height="30px" color="#ccc" />
                        <label>Add Document</label>
                    </div>
                </li>
                <li>
                    <div className='add-files-pop-up-icons border-radius-5'>
                        <input type='file' accept='image/*' name='img-selection' onChange={handleAddImage} style={{ display: 'none' }}/>
                        <AddImageIcon onClick={() => {document.querySelector('input[name="img-selection"]').click()}} width="30px" height="30px" color="#ccc" />
                        <label>Add Image</label>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default AddFilesPopUp