import React from 'react'

/**
 * @description - This component renders a 3-column testimonial card like the one located on the login page. 
 * 
 * @component
 * @param {object} data - The data object with the details about the testimonial (name, year, testimonial, major, and avatar url).
 * @returns {JSX.Element} - A React element that renders a testimonial card.
 * 
 * @example
 * // Renders a a testimonial card.
 * <Testimonial currentTestimonial={currentTestimonial}/>
 */

const Testimonial = (props) => {
    return (
        <div id='testimonial-container' className='make-grid'>
            <img className='container-border' src={props.currentTestimonial.avatar}/>
            <div className='quote-mark'>"</div>
            <div id='testimonial-text-area'>
                <p className='quote'>{props.currentTestimonial.testimonial}</p>
                <p className='testimonial-student'>- {props.currentTestimonial.name}, <em>{props.currentTestimonial.year}</em> | {props.currentTestimonial.major}</p>
            </div>
        </div>
    )
}

export default Testimonial