import { React, Fragment, useEffect, useState } from 'react'
import { adjustTextAreaHeight } from '../../assets/scripts/mchScripts';
import SendIcon from '../messages/toolbar/icons/SendIcon'
import AddFileIcon from '../messages/toolbar/icons/AddFileIcon';
import AddFilesPopUp from './AddFilesPopUp';

/**
 * @description - This component renders a textarea whose height adjusts. Includes a button to open a pop-up to add files. Handles checking
 *  an 'Enter' key press to send the message. Use this component within any chat that is handled by ably. There is a call to handleSetMessage
 *  which is a function that updates the message state variable as the user types. There is a call to sendMessage which is a function that sends
 *  the message.
 * 
 * @component
 * @param {string} message - A state variable that holds the message the user types
 * @param {function} handleSetMessage - A function that triggers as the user types that updates the message state variable
 * @param {function} sendMessage - A function that triggers when the send icon is clicked
 * @param {string} placeholder - A string that holds the placeholder text for the textarea
 * @param {function} setFile - A function that updates the file state variable
 * @param {function} setImage - A function that updates the image state variable
 * @returns {JSX.Element} - A react element that renders a textarea whose height adjusts with a button to open a pop-up to add files
 *  and a button to send the message
 * 
 * @example
 * // Render a textarea whose height adjusts with a button to open a pop-up to add files and a button to send the message
 * <ClassroomChatTextArea 
 *  message={message} 
 *  handleSetMessage={handleSetMessage} 
 *  sendMessage={sendMessage} 
 *  placeholder={`Message ${props.course.courseSubject}-${props.course.courseCode}-${props.channelSelected}`} 
 *  setFile={setFile} 
 *  setImage={setImage} 
 * />
 */

const ClassroomChatTextArea = (props) => {
    const [addFilesPopUp, setAddFilesPopUp] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);

    const handleAddFilesPopUp = () => {
        setAddFilesPopUp(!addFilesPopUp);
    }

    const handleClosePreviewClick = () => {
        props.setImage(null);
        setImagePreview(null);
    };

    const handleRemoveFile = () => {
        props.setFile(null);
    };

    const handleSendClick = () => {
        setImagePreview(null);
        props.sendMessage();
    }

    const handleMonitorTyping = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setImagePreview(null);
            props.sendMessage();
        } else {
            props.handleSetMessage(e);
        }
    }

    return (
        <Fragment>
            <div id="message-app-classroom-message-field-file-preview-area" style={(imagePreview) ? {"padding": "5px"} : null}>            
                {(imagePreview !== null) && (
                <Fragment>
                <div id='message-app-file-preview-container'>
                    <div onClick={handleClosePreviewClick} className='close-preview-btn btn-ease'>x</div>
                    <img src={imagePreview} />
                </div>
                </Fragment>
                )}
                {props.file && <p>{props.file.name}</p>}
                {props.file && <button className='ocean-header-btns btn-ease' onClick={handleRemoveFile}>Remove File</button>}
            </div>

            <div id='message-app-input-container' className='make-grid'>
                {addFilesPopUp && <AddFilesPopUp handleAddFilesPopUp={handleAddFilesPopUp} setFile={props.setFile} setImage={props.setImage} setImagePreview={setImagePreview}/>}
                <div className='message-app-input-icons'>
                    <ul>
                        <li className='btn-ease'><AddFileIcon color="#333" onClick={handleAddFilesPopUp}/></li>
                    </ul>
                </div>
                <textarea id='message-input' onKeyUp={(e) => handleMonitorTyping(e)} onKeyDown={adjustTextAreaHeight} rows={1} placeholder={props.placeholder} />          
                <div id='message-app-send-btn-container' className='message-app-input-icons'>
                    <ul>
                        <li className='btn-ease'> <SendIcon onClick={handleSendClick} width="30px" height="30px" strokeColor="var(--primary-color)" /></li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default ClassroomChatTextArea