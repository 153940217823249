import getGlobalURL from "../../assets/scripts/Global";
import React, { useState, Fragment } from 'react'
import "../../assets/stylesheets/oceansStyles.css"
import axios from "axios";
import {storage} from "../../firebase";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import ConvertToBase64Simple from "../../components/helperComponents/ConvertToBase64Simple";
import FormCancelSubmitBtns from "./FormCancelSubmitBtns";
import DefaultImage from "../../assets/images/tu_graphite_card_background_banner.png"
import InputWithObject from "../../components/atoms/InputWithObject";
import FormMessageField from "./FormMessageField";
import PasswordPolicy from "./PasswordPolicy";
import TextAreaWithObject from "../../components/atoms/TextAreaWithObject";

const CreateBusinessAccountForm = (props) => {
    const url = getGlobalURL();
    const [verifyPassword, setVerifyPassword] = useState("");
    const [bannerImage, setBannerImage] = useState(DefaultImage);
    const [file, setFile] = useState(null);
    const [validPassword, setValidPassword] = useState(false);
    const [formMessage, setFormMessage] = useState("Hello there! We're excited to have you join us! Please fill out the form to create your business account.");
    const [focussedField, setFocussedField] = useState(null);
    const [businessData, setBusinessData] = useState({
        fName: "",
        lName: "",
        username: "",
        password: "",
        email: "",
        businessName: "",
        businessAddress: "",
        businessCity: "",
        businessState: "",
        businessZip: "",
        businessPhone: "",
        businessUrl: "",
        category: [],
        tags: [],
        businessDescription: "",
        businessBanner: "",
    });

    /* The createUser function is called when the user clicks 'Create Account' button */
    const createBusiness = () => {
        if (businessData.password !== verifyPassword) {
            alert("PASSWORDS DO NOT MATCH");
        } else if (validPassword === false) {
            setFormMessage("Password does not meet our password policy!")
        } else {
            let businessID = Date.now();
            const imageRef = ref(storage, `businessBanners/${businessID}`);
            /* Route call to create the user with the state variables populated from the form */
            if (file === null) {
                axios.post(url + "businesses/createBusiness", {
                    fName: businessData.fName,
                    lName: businessData.lName,
                    businessName: businessData.businessName,
                    username: businessData.username,
                    password: businessData.password,
                    email: businessData.email,
                    businessAddress: businessData.businessAddress,
                    businessCity: businessData.businessCity,
                    businessState: businessData.businessState,
                    businessZip: businessData.businessZip,
                    businessPhone: businessData.businessPhone,
                    businessDescription: businessData.businessDescription,
                    businessUrl: businessData.businessUrl,
                    category: businessData.category,
                    tags: businessData.tags,
                    businessID: businessID,
                    createdDate: Date.now(),
                }).then((response) => {
                    alert("BUSINESS MADE");
                    console.log(response);
                }).catch((error) => {
                    alert("there was an error creating the account");
                    console.log(error);
                });
            } else {
                uploadBytes(imageRef, file).then((response) => {
                    getDownloadURL(imageRef).then((imageUrl) => {
                        axios.post(url + "businesses/createBusiness", {
                            fName: businessData.fName,
                            lName: businessData.lName,
                            businessName: businessData.businessName,
                            username: businessData.username,
                            password: businessData.password,
                            email: businessData.email,
                            businessAddress: businessData.businessAddress,
                            businessCity: businessData.businessCity,
                            businessState: businessData.businessState,
                            businessZip: businessData.businessZip,
                            businessPhone: businessData.businessPhone,
                            businessDescription: businessData.businessDescription,
                            businessUrl: businessData.businessUrl,
                            category: businessData.category,
                            tags: businessData.tags,
                            businessID: businessID,
                            createdDate: Date.now(),
                            businessBanner: imageUrl,
                        }).then((response) => {
                            alert("BUSINESS MADE");
                            console.log(response);
                            /* Once account is successfully created, close the modal back to login */
                            props.closeForm();
                        }).catch((error) => {
                            alert("there was an error creating the account");
                            console.log(error);
                        });
                    })
                })
            }
        }
    };
    
    /* Call to parent to close modal if user clicks the modal area behind the form container */
    const handleCloseFormClick = () => {
        props.closeForm();
    }

    const handleClearImages = () => {
        setBannerImage(DefaultImage);
    }

    const handleFocus = (focussedOn) => {
        setFocussedField(focussedOn);
    }

    return (
        <div className='create-account-form-2c-container border-radius-25 make-grid'>
            <div id='create-account-form-2c-header' className='form-header'>Create Your Business Account</div>
            <div id='create-account-form-main-body'>
                <div id='create-account-form-lc'>
                    <div className="form-body-img-area border-radius-10">
                        <img src={bannerImage} alt="Banner Image Container"/>
                        <ConvertToBase64Simple textID="business-banner" setFile={setFile} setImage={setBannerImage} />
                    </div>
                    <button onClick={handleClearImages}>clear images</button>
                    <FormMessageField formMessage={formMessage} focussedField={focussedField}/>
                </div>
                <div id='create-account-form-rc' className="scrollbar-mch-thin">
                    <div id='form-container'>
                        <div className='form-body form-body-override'>
                            <h3>Personal Info:</h3>
                            <InputWithObject handleFocus={handleFocus} type="text" forText="fName" labelText="First Name:" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="lName" labelText="Last Name:" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="email" labelText="Email" helperText="test@domain.com" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="username" labelText="Username:" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="password" forText="password" labelText="Password:" data={businessData} setData={setBusinessData} />
                            {(focussedField === "password") && <PasswordPolicy password={businessData.password} setValidPassword={setValidPassword}/>}

                            <label htmlFor="vpassword">Verify Password:</label>
                            <input onFocus={() => handleFocus("vpassword")} type="password" value={verifyPassword} onChange={(e) => setVerifyPassword(e.target.value)} id="vpassword" />
                        
                            <h3>Business Info:</h3>
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessName" labelText="Business Name:" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessPhone" labelText="Phone Number:" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessAddress" labelText="Address:" helperText="Street No. & Name" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessCity" labelText="City:" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessState" labelText="State:" helperText="Abbreviated (e.g. MD)" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessAddress" labelText="Address:" helperText="Street No. & Name" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessZip" labelText="Zip:" data={businessData} setData={setBusinessData} />
                            <InputWithObject handleFocus={handleFocus} type="text" forText="businessUrl" labelText="URL:" helperText="https://www.YourSite.com" data={businessData} setData={setBusinessData} />
                            
                            <TextAreaWithObject handleFocus={handleFocus} forText="businessDescription" labelText="About Your Business:" data={businessData} setData={setBusinessData} />
                            <FormCancelSubmitBtns handleCloseForm={handleCloseFormClick} closeText="Cancel" handleSubmit={createBusiness} submitText="Send Request"/>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default CreateBusinessAccountForm