import React, { useState, Fragment } from "react";
import "../../assets/stylesheets/oceansStyles.css";
import axios from "axios";
import getGlobalURL from "../../assets/scripts/Global";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../scripts/badToken";
import DefaultBanner from "../../assets/images/tu_graphite_card_background_banner.png"
import ConvertToBase64Simple from "../../components/helperComponents/ConvertToBase64Simple";
import InputWithObject from "../../components/atoms/InputWithObject";
import FormCancelSubmitBtns from "../forms/FormCancelSubmitBtns";
import TextAreaWithObject from "../../components/atoms/TextAreaWithObject";


const CreateOceanModal = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [ocean, setOcean] = useState("");
   const [oceanDescription, setOceanDescription] = useState("");
   const [userOceansList, setUserOceansList] = useState(props.currentUser.oceans);
   const [oceanBannerImage, setOceanBannerImage] = useState(DefaultBanner);
   const [focussedField, setFocussedField] = useState(null);
   // const [selectedImage, setSelectedImage] = useState(false);
   const [posting, setPosting] = useState(false); // Used for the spinner
   const [file, setFile] = useState(null);

   /* The createUser function is called when the user clicks 'Create Account' button */

   const handleSubmitClick = () => {
      let newOceansList = [...userOceansList];
      newOceansList.push(ocean.oceanName);
      createOcean(newOceansList);
   };

   const createOcean = (newOceansList) => {
      setPosting(true);
      let oceanID = Date.now();
      const imageRef = ref(storage, `oceanBanners/${ocean.oceanName}`);
      /* Route call to create the ocean with the state variables populated from the form */
      uploadBytes(imageRef, file).then((response) => {
         getDownloadURL(imageRef).then((imageUrl) => {
            axios.post(`${url}oceans/createOcean/${props.currentUser.userID}`, {
                  oceanName: ocean.oceanName,
                  description: oceanDescription.oceanDescription,
                  oceanID: oceanID,
                  createdDate: Date.now(),
                  oceanBanner: imageUrl,
                  owner: props.currentUser.userID,
               }, {
                  headers: {
                     Authorization: sessionStorage.getItem("token"),
                  },
               }
            ).then((response) => {
               props.currentUser.oceans.push(ocean.oceanName);
               axios.patch(`${url}users/updateUserOceans`, {
                     userID: props.currentUser.userID,
                     oceans: newOceansList,
                  }, {
                     headers: {
                        Authorization: sessionStorage.getItem("token"),
                     },
                  }
               ).then((response) => {
                  props.handleOceanCreated();
                  props.closeModal();
                  setPosting(false);
               }).catch((error) => {
                  if (error.code === "ERR_BAD_REQUEST") {
                     FoundBadToken();
                  } else {
                     alert(
                        "there was an error updating the user oceans list!"
                     );
                     props.closeModal();
                     console.log(error);
                  }
               });
            }).catch((error) => {
               if (error.code === "ERR_BAD_REQUEST") {
                  FoundBadToken();
               } else {
                  alert(`there was an error creating the ocean! ${error.response.data.err}`);
                  props.closeModal();
                  console.log(error);
               }
            });
         });
      });
   };

   const handleFocus = (focussedOn) => {
      setFocussedField(focussedOn);
   }

   return (
      <div className="modal-container">
         <div className='modal-backdrop' onClick={() => props.closeModal()} />
         <div className="new-form-container">
            <div className="new-form-header">Create A New Hub</div>
            <div className="new-form-body-2c">
               <div className="new-form-col">
                  <div className="form-body-img-area border-radius-10">
                     <img src={oceanBannerImage} alt="Banner Image Container"/>
                     <ConvertToBase64Simple textID="banner-1" setFile={setFile} setImage={setOceanBannerImage} />
                  </div>
                  <p className="form-instruction-text">Not finding a hub for a specific topic? Use this form to create a new one! Once a hub is created, users may discover it, join it, and start posting in it! PLEASE first search for the topic to ensure that it does not already exist.</p>
               </div>
               <div className="new-form-col">
                  {posting ? (
                     <div style={{display: "flex", justifyContent: "center",alignItems: "center", height: "100%","margin-top": "15px"}}>
                           <PuffLoader color="#006994" />
                     </div>
                  ) : (
                     <Fragment>
                        <InputWithObject handleFocus={handleFocus} required={true} type="text" forText="oceanName" labelText="Hub Name:" data={ocean} setData={setOcean}/>
                        <TextAreaWithObject handleFocus={handleFocus} forText="oceanDescription" labelText="Hub Description:" data={oceanDescription} setData={setOceanDescription} />
                        <FormCancelSubmitBtns handleCloseForm={props.closeModal} closeText="Cancel" handleSubmit={handleSubmitClick} submitText="Create Hub" />
                     </Fragment>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default CreateOceanModal;
