import getGlobalURL from "../../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import Major from "./Major";
import PuffLoader from "react-spinners/PuffLoader";
import $ from "jquery";
import MajorSlide from "./MajorSlide";
import { FoundBadToken } from "../../../scripts/badToken";
import SearchField from "../../helperComponents/SearchField";
import DefaultBanner from "../../../assets/images/tu_gold_card_background_169.png";
// import InfiniteScroll from "react-infinite-scroll-component";

const MajorsField = () => {
   const url = getGlobalURL();
   const [index, setIndex] = useState(1);
   const [dataSource, setDataSource] = useState([]);
   const [fetchingMajors, setFetchingMajors] = useState(true);
   const [hasMore, setHasMore] = useState(true);
   const [majorClicked, setMajorClicked] = useState("");

   const [searchedMajor, setSearchedMajor] = useState("");
   const [category, setCategory] = useState("All");

   const tempDescription = "Welcome to the Majors section! Here, you can explore detailed information about every major offered by our university, helping you make informed decisions about your academic journey. This section provides comprehensive descriptions of each major, including key areas of study, career opportunities, and the unique aspects of each program. You can learn about the required courses, elective options, and any special requirements or opportunities such as internships or research projects. Whether you’re deciding on a major, considering a switch, or simply curious about the diverse academic paths available, this section is your go-to resource for all the information you need to chart your course to success.";

   // const fetchMoreData = async () => {
   //   try {
   //     const res = await axios.get(`${url}majors/getMajors`, {
   //       headers: {
   //         Authorization: sessionStorage.getItem("token"),
   //       },
   //     });

   //     if (res.status === 200) {
   //       if (res.data.length === 0) {
   //         // No more elements to fetch
   //         setHasMore(false);
   //       } else {
   //         setDataSource([...dataSource, ...res.data]);
   //         setFetchingMajors(false);
   //         setIndex(index + 1);
   //       }
   //     }
   //   } catch (err) {
   //     alert(err.response.data.err);
   //   }
   // };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const res = await axios.get(`${url}majors/getMajors`, {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            });

            if (res.status === 200) {
               setDataSource(res.data);
               setFetchingMajors(false);
               setIndex(index + 1);
            }
         } catch (err) {
            if (err.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(err.response.data.err);
            }
         }
      };

      fetchData();
   }, []);

   useEffect(() => {
      $("#info-slide").slideToggle("fast", "swing");
   }, [majorClicked]);

   const handleMajorClick = (majorClicked) => {
      setMajorClicked(majorClicked);
      $("#info-slide").slideToggle("fast", "swing");
   };

   return (
      <Fragment>
         <div className="content-placeholder-container container-border">
            <img className="header-img background-img-contain" src={DefaultBanner}/>
            <div className="content-placeholder-text-area">
               <h2 className='highlight'>Explore Majors</h2>
               <p>{tempDescription}</p>
            </div>
            <div id="cards-field-container">
               <div id="search-orgs-container">
                  <SearchField 
                     title="majors"
                     categories={['All', 'College of Business & Economics', 'College of Education', 'College of Fine Arts & Communication', 'College of Health Professions', 'College of Liberal Arts', 'Jess & Mildred Fisher College of Science & Mathematics', 'Honors College']}
                     setSearch={setSearchedMajor}
                     setCategory={setCategory}
                  />
               </div>

               {/* <InfiniteScroll
               dataLength={dataSource.length}
               next={fetchMoreData}
               hasMore={hasMore}
               loader={<PuffLoader color="#006994" />}
               scrollThreshold={1.0}
         > */}
               {fetchingMajors ? (
                  <PuffLoader color="#006994" />
               ) : searchedMajor === "" ? (
                  dataSource.filter((major) => {
                     return (
                        major.majorCollege === category || category === "All"
                     );
                  }).sort().map((major, index) => {
                     return (
                        <div key={index} id="club-org-listing-plus-info-container">
                           <Fragment key={major.majorName}>
                              <Major
                                 major={major}
                                 handleMajorClick={handleMajorClick}
                                 majorClicked={majorClicked}
                                 index={index}
                              />
                              {majorClicked == major.majorName ? (
                                 <MajorSlide id="info-slide" major={major} />
                              ) : null}
                           </Fragment>
                        </div>
                     );
                  })
               ) : (
                  dataSource.filter((major) => {
                     return major.majorName
                        .toLowerCase()
                        .startsWith(searchedMajor.toLowerCase());
                  })
                  .filter((major) => {
                     return (
                        major.majorCollege.toLowerCase() ===
                           category.toLowerCase() || category === "All"
                     );
                  }).sort().map((major, index) => {
                     return (
                        <div id="club-org-listing-plus-info-container">
                           <Fragment key={major.majorName}>
                              <Major
                                 major={major}
                                 handleMajorClick={handleMajorClick}
                                 majorClicked={majorClicked}
                                 index={index}
                              />
                              {majorClicked == major.majorName ? (
                                 <MajorSlide id="info-slide" major={major} />
                              ) : null}
                           </Fragment>
                        </div>
                     );
                  })
               )}
               {/* </InfiniteScroll> */}
            </div>
         </div>
      </Fragment>
   );
};

export default MajorsField;
