import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import { FoundBadToken } from "../../../scripts/badToken";

// ! PROPS COMING FROM CalendarModal.jsx: event, handleCloseModal, course, currentUser
const EventModal = (props) => {
   const url = getGlobalURL();

   const handleDeleteEvent = () => {
      axios
         .post(
            `${url}courses/deleteCalendarEvent`,
            {
               courseCode: props.course.courseCode,
               eventID: props.event.eventID,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         )
         .then((response) => {
            if (response.status === 200) {
               props.handleEventMade();
               props.handleCloseEventModal();
            }
         })
         .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
   };

   return (
      <div className="modal-container">
         <div
            className="modal-backdrop"
            onClick={props.handleCloseEventModal}
         />
         <div className="create-post-modal-content">
            <div className="create-post-form-container">
               <div id="modal-title" className="make-grid">
                  <h1>{props.event.title}</h1>
               </div>
               <div
                  style={{ background: "white" }}
                  id="manage-members-container"
               >
                  <p>{props.event.description}</p>
                  {props.currentUser.userID ===
                     props.course.courseInstructor && (
                     <button onClick={handleDeleteEvent}>Delete Event</button>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default EventModal;
