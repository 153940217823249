import React from 'react'

const UserFollowIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        fill={props.fill}
        stroke={props.stroke}
        viewBox="0 0 32 32"
        {...props}
    >
        <path d="M2.002 27.959c0-.795.597-1.044.835-1.154l8.783-4.145a2 2 0 0 0 .416-3.376C9.958 17.616 8.99 13.95 8.99 11.997V7c0-2.09 3.638-4.995 7.004-4.995 3.396 0 6.997 2.861 6.997 4.995v4.998c0 1.924-.8 5.604-2.945 7.292a2 2 0 0 0 .402 3.389l2.997 1.422.494-1.996-2.657-1.243c2.771-2.18 3.708-6.463 3.708-8.864V7.001c0-3.31-4.582-6.995-8.998-6.995S6.988 3.692 6.988 7.001v4.997c0 2.184.997 6.602 3.793 8.846l-8.783 4.145S0 25.879 0 26.988v3.001c0 1.105.895 1.999 1.998 1.999h21.997v-2l-21.996.001V27.96zm28.996-1.963h-3v-3a1 1 0 0 0-2 0v3h-3a1 1 0 0 0 0 2h3v3a1 1 0 0 0 2 0v-3h3a1 1 0 0 0 0-2z" />
    </svg>
)

export default UserFollowIcon