import React, { useState, Fragment, useEffect, useRef, useContext } from "react";
import NavHeader from "../components/NavHeader";
import { useLocation } from "react-router-dom";
import axios from "axios";
import getGlobalURL from "../assets/scripts/Global";
import SuggestedOceans from "../components/suggested/SuggestedOceans";
import SuggestedFriends from "../components/suggested/SuggestedFriends";
import Footer from "../components/Footer";
import ClubsTab from "../components/tabComponents/ClubsTab";
import AdministrationTab from "../components/tabComponents/AdministrationTab";
import AcademicsTab from "../components/tabComponents/AcademicsTab";
import StudentLifeTab from "../components/tabComponents/StudentLifeTab";
import OffCampusTab from "../components/tabComponents/OffCampusTab";
import { isMobile } from "react-device-detect";
import MessageApplication from "../components/messages/MessageApplication";
import { FoundBadToken } from "../scripts/badToken";
import CalendarHeader from "../components/CalendarHeader";
import NavDrawer from "../components/navigation/NavDrawer";
import NewMyAccount from "./NewMyAccount";
import { AblyContext } from "../assets/custom-hooks/AblyContext";
import DeveloperWelcome from "../components/Mobile/DeveloperWelcome";
import DeveloperMobileWelcome from "../components/Mobile/DeveloperMobileWelcome";
import FeedbackForm from "../Modals/forms/FeedbackForm";

const Dashboard = () => {
	// ....
	const { ably, currentUser } = useContext(AblyContext);
	let location = useLocation();
	let incomingTab = location.state.activeTab;
	//This hook is used once the user logs in. They will be directed to the Dashboard upon successful login

	// State variable holds the ocean selected from within the <OceanList /> component.
	// Initial state is the first ocean in the user's oceans list
	const [oceanObj, setOceanObject] = useState(null);
	
	const [oceanSelected, setOceanSelected] = useState((location.state.buttonSelected) ? location.state.buttonSelected : currentUser.oceans[0]);
	const [ocean, setOcean] = useState(null);
	const [postMade, setPostMade] = useState(false);
	const [activeButton, setActiveButton] = useState((location.state.buttonSelected) ? location.state.buttonSelected : currentUser.oceans[0]);
	const [oceanCreated, setOceanCreated] = useState(false);
	const [tabSelected, setTabSelected] = useState(incomingTab ? incomingTab : "My Hubs");
	// const [tabSelected, setTabSelected] = useState((location.state.activeTab) ? location.state.activeTab : "My Hubs");
	const [numMessages, setNumMessages] = useState(0);
	const [numNotifications, setNumNotifications] = useState(currentUser.newNotifications);
	const [openNotifications, setOpenNotifications] = useState(false);
	const [openNavDrawer, setOpenNavDrawer] = useState(true);

	const [isClub, setIsClub] = useState(false);
	const [clubSelected, setClubSelected] = useState(null);

	const [academicTab, setAcademicTab] = useState(false);	

	const [userChange, setUserChange] = useState(false);

	const [openMyAccount, setOpenMyAccount] = useState(false);
	const [openFeedback, setOpenFeedback] = useState(false);
	const [openMessagesApp, setOpenMessagesApp] = useState(location.state.openMessagesApp !== null ? location.state.openMessagesApp : false);
	const [messageAppCurrentlyOpen, setMessageAppCurrentlyOpen] = useState(false);

	let url = getGlobalURL();
	let userChannel = useRef(null);

	// * this should only ever run on the initial login and never again
	
	useEffect(() => {
		axios.get(`${url}oceans/getOceanByName`, {
			params:{
				oceanName: currentUser.oceans[0],
				userID: currentUser.userID
			},
			headers: {
				"Authorization": sessionStorage.getItem("token")
			}
		}).then((response) => {
			if(response.status === 200){
				setOcean(response.data);
			}
			else{
				alert("Error getting ocean")
			}
		}).catch((error) => {
			alert(error.response.data.message);
		})
	}, []);

	useEffect(() => {}, [postMade]);

	const handleOpenNavDrawer = () => {
		setOpenNavDrawer(!openNavDrawer);
	}

	const buttonSelection = (buttonSelected, tabSelection) => {
		// This function updates the oceanSelected state variable with the ocean selected within the <OceansList /> component
		
		console.log(`In Dashboard: buttonSelected: ${buttonSelected}, tabSelected: ${tabSelected}`)
		if(tabSelection === "My Clubs") {
			setIsClub(true);
			setClubSelected(buttonSelected);
		} else {
			setIsClub(false);
			setOceanSelected(buttonSelected);
		}

		setActiveButton(buttonSelected);

		// This if statement is only here while the old navigation component is still in use. We can 
		if(tabSelection !== null) {
			setTabSelected(tabSelection);
		}
	};

	const clubSelection = (clubSelected) => {
		setIsClub(true);
		setClubSelected(clubSelected);
		setActiveButton(clubSelected);
	};

	const campusButtonSelection = (buttonSelected) => {
		setActiveButton(buttonSelected);
	};

	const handlePostMade = () => {
		// This function runs when.....
		setPostMade(!postMade);
	};

	const handleOceanCreated = () => {
		setOceanCreated(!oceanCreated);
	};

	const handleTabClick = (tabSelection) => {
		setTabSelected(tabSelection);
		if (tabSelection === "My Hubs") {
			setIsClub(false);
			setOceanSelected(currentUser.oceans[0]);
			setActiveButton(currentUser.oceans[0]);
		} else if (tabSelection === "My Clubs") {
			setIsClub(true);
			// setAcademicTab(false);
			setClubSelected(currentUser.clubs[0]);
			setActiveButton(currentUser.clubs[0]);
		}
	};

	const handleOpenMessagesApp = () => {
		if(openMessagesApp === false || openMessagesApp === undefined) {
			setOpenNavDrawer(false);
		} else {
			setOpenNavDrawer(true);
		}
		setOpenMessagesApp(!openMessagesApp);
	};

	const handleMessageChatOpen = () => {

		setMessageAppCurrentlyOpen(true);
	};

	const handleCloseMessage = () => {
		setMessageAppCurrentlyOpen(false);
	};

	const reduceNumMessages = (num) => {
		setNumMessages(Math.abs(numMessages - num));
	};

	const handleOpenNotifications = () => {
		if(openNotifications === false){
			setOpenNotifications(true);
			markSeenNotifications();
		}
		else{
			setNumNotifications(0);
			currentUser.newNotifications = 0;
			setOpenNotifications(false);
		}
		// setOpenNotifications(!openNotifications);
	}

	const markSeenNotifications = async () => {
		await axios.post(`${url}users/markSeenNotifications`, {
			userID: currentUser.userID
		}, {
			headers: {
				Authorization: sessionStorage.getItem("token"),
			}
		}).then((response) => {
			// nothing to do here since we are just marking them as seen in the db
		}).catch((error) => {
			alert(error);
		})
	}

	// const getHeader = () => {
	// 	if(tabSelected === 'My Clubs'){
	// 		return <ClubTopicHeader currentUser={currentUser} clubSelected={clubSelected} club={clubSelected} />
	// 	}
	// 	else if(tabSelected === 'Academics'){
	// 		return <CalendarHeader currentUser={currentUser} source='academics'/>
	// 	}
	// 	else if(tabSelected === 'Student Life'){
	// 		return <CalendarHeader currentUser={currentUser} source='student-life'/>
	// 	}
	// 	else if(tabSelected === 'Off Campus'){
	// 		return <CalendarHeader currentUser={currentUser} source='off-campus'/>
	// 	}
	// 	else{
	// 		return <TopicHeader currentUser={currentUser} oceanSelected={oceanSelected} />
	// 	}
	// }

	if (isMobile) {
		return (
			<Fragment>
				<DeveloperWelcome currentUser={currentUser}/>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<NavHeader
				numMessages={numMessages}
				numNotifications={numNotifications}
				messageAppCurrentlyOpen={messageAppCurrentlyOpen}
				currentUser={currentUser}
				oceanCreated={oceanCreated}
				source="Dashboard"
				handleOpenMessagesApp={handleOpenMessagesApp}
				handleOpenNotifications={handleOpenNotifications}
				openNotifications={openNotifications}
				setOpenNotifications={setOpenNotifications}
				handleOpenNavDrawer={handleOpenNavDrawer}
			/>
			{openNavDrawer && <NavDrawer 
								currentUser={currentUser} 
								handleOpenNavDrawer={handleOpenNavDrawer} 
								handleButtonSelection={buttonSelection} 
								activeButton={activeButton} 
								activeTab={tabSelected}
								setOpenMyAccount={setOpenMyAccount}
								setOpenFeedback={setOpenFeedback}
								source="Dashboard"
							/>
			}
			<div id="main-body" onClick={() => setOpenNotifications(false)}>
				{ openMyAccount ? (
					<NewMyAccount currentUser={currentUser} />
				) : openMessagesApp ? (
					<MessageApplication
						reduceNumMessages={reduceNumMessages}
						handleCloseMessage={handleCloseMessage}
						handleMessageChatOpen={handleMessageChatOpen}
						currentUser={currentUser}
						handleOpenMessagesApp={handleOpenMessagesApp}
					/>
				) : (
					<div id="main-panel" className="make-grid">
						<div id="left-panel" className="padding-top-80">
							{ !openNavDrawer && (
								<Fragment>
									<SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated} />
									<SuggestedFriends currentUser={currentUser} />
								</Fragment>
							)}
						</div>
						<div id="center-panel" className="padding-top-80">
							{/* {!isClub && <MiniProfile currentUser={currentUser} oceanSelected={oceanSelected} ocean={ocean}/>}
                {isClub && <ClubMiniProfile currentUser={currentUser} clubSelected={clubSelected} club={clubSelected}/>} */}

							{/* {openMessagesApp ? <MessageApplication handleCloseMessage={handleCloseMessage} handleMessageChatOpen={handleMessageChatOpen} ably={ably.current} currentUser={currentUser} handleOpenMessagesApp={handleOpenMessagesApp}/>
                  : ( */}
							{
							// tabSelected === "My Hubs" ? (
							// 	<OceansTab 
							// 		currentUser={currentUser} 
							// 		oceanSelected={oceanSelected} 
							// 		oceanCreated={oceanCreated} 
							// 		postMade={postMade} 
							// 		tabSelected={tabSelected} 
							// 		source="dashboard" 
							// 		handlePostMade={handlePostMade} 
							// 	/>
							// ) : 
							openFeedback ? (
								<FeedbackForm userID={currentUser.userID} setOpenFeedback={setOpenFeedback}/>
							) : tabSelected === "Dashboard" ? (
								// <DeveloperMobileWelcome />
								<DeveloperWelcome currentUser={currentUser}/>
							) : tabSelected === "My Clubs" ? (
								<ClubsTab
									activeButton={activeButton}
									clubSelection={clubSelection}
									currentUser={currentUser}
									handlePostMade={handlePostMade}
									postMade={postMade}
									source="dashboard"
									tabSelected={tabSelected}
									userChange={userChange}
								/>
							) : tabSelected === "Administration" ? (
								<AdministrationTab
									activeButton={activeButton}
									currentUser={currentUser}
								/>
							) : tabSelected === "Academics" ? (
								<AcademicsTab
									ably={ably}
									activeButton={activeButton}
									currentUser={currentUser}
								/>
							) : tabSelected === "Student Life" ? (
								<StudentLifeTab
									activeButton={activeButton}
									currentUser={currentUser}
								/>
							) : tabSelected === "Off Campus" ? (
								<OffCampusTab
									activeButton={activeButton}
									currentUser={currentUser}
								/>
							) : null}
							{/* )
                } */}
						</div>
						<div id="right-panel" className="padding-top-80">
							{ openNavDrawer && (
								<Fragment>
									<SuggestedOceans currentUser={currentUser} handleOceanCreated={handleOceanCreated} />
									<SuggestedFriends currentUser={currentUser} />
								</Fragment>
							)}
						</div>
					</div>
				)}
			</div>
			<Footer currentUser={currentUser} />
		</Fragment>
	);
};

export default Dashboard;


/*
if (isMobile) {
	return (
		<Fragment>
			<NavHeader
				numMessages={numMessages}
				numNotifications={numNotifications}
				messageAppCurrentlyOpen={messageAppCurrentlyOpen}
				currentUser={currentUser}
				oceanCreated={oceanCreated}
				source="Dashboard"
				handleOpenMessagesApp={handleOpenMessagesApp}
				handleOpenNotifications={handleOpenNotifications}
				openNotifications={openNotifications}
				setOpenNotifications={setOpenNotifications}
				handleOpenNavDrawer={handleOpenNavDrawer}
			/>
			<div id="main-panel" className="make-grid">
				{openNavDrawer && <NavDrawer 
					currentUser={currentUser} 
					handleOpenNavDrawer={handleOpenNavDrawer} 
					handleButtonSelection={buttonSelection} 
					activeButton={activeButton}
					activeTab={tabSelected}
					setOpenMyAccount={setOpenMyAccount}
					source="ClubHome"
				/>}
				
				
				
				<div id="center-panel">
					<div id="mini-profile-topic-container">
						<TopicHeader oceanSelected={oceanSelected} ocean={ocean} />
					</div>

					{
					// tabSelected === "My Hubs" ? (
					// 	<OceansTab
					// 		currentUser={currentUser}
					// 		handlePostMade={handlePostMade}
					// 		oceanSelected={oceanSelected}
					// 		postMade={postMade}
					// 		source="dashboard"
					// 		tabSelected={tabSelected}
					// 	/>
					// ) : 
					tabSelected === "My Clubs" ? (
						<ClubsTab
							activeButton={activeButton}
							clubSelection={clubSelection}
							currentUser={currentUser}
							handlePostMade={handlePostMade}
							postMade={postMade}
							source="dashboard"
							tabSelected={tabSelected}
							userChange={userChange}
						/>
					) : tabSelected === "Administration" ? (
						<AdministrationTab
							activeButton={activeButton}
							currentUser={currentUser}
						/>
					) : tabSelected === "Academics" ? (
						<AcademicsTab
							ably={ably.current}
							activeButton={activeButton}
							currentUser={currentUser}
						/>
					) : tabSelected === "Student Life" ? (
						<StudentLifeTab
							activeButton={activeButton}
							currentUser={currentUser}
						/>
					) : tabSelected === "Off Campus" ? (
						<OffCampusTab
							activeButton={activeButton}
							currentUser={currentUser}
						/>
					) : null}
				</div>
				{/* <div id='right-panel' className='padding-top-80'>
		
		</div>
			</div>
			<Footer currentUser={currentUser} />
		</Fragment>
	);
}*/