import React from 'react'

/**
 * @description - A component that displays a list of categories and handles the selection of a category. Called by SearchField.jsx.
 * 
 * @component
 * @param {array} categories - An array of categories
 * @param {function} setCategory - A function that sets the category state
 * @returns {JSX.Element} - A component that displays a list of categories and handles the selection of a category
 * 
 * @example
 * // Renders a component that displays a list of categories
 * <CategoryFilter 
 *  categories={props.categories} 
 *  setCategory={props.setCategory} 
 * />             
 */

const CategoryFilter = (props) => {
    
    return (
        <div className='orgs-search-field border-radius-15'>
            <select id='club-category' onChange={(e) => props.setCategory(e.target.value)} >
                {props.categories.map((category, index) => {
                    return(
                        <option key={index} value={category}>{category}</option>
                    )
                    })
                }
            </select>
        </div>
    )
}

export default CategoryFilter