import {React, useState, useEffect} from 'react'
import GroupUsersIcon from '../../icons/GroupUsersIcon'
import ThumbsUp from '../../icons/ThumbsUp'
import ThumbsDown from '../../icons/ThumbsDown'

/**
 * @description - A container that displays helpful tool's information within a card.
 * 
 * @component
 * @param {object} tool - An object that contains the tool object
 * @param {object} userData - An object that contains the userID and profileUrl (not used at this time).
 * @param {function} closeModal - A function that closes the modal
 * @param {function} handleAddRecommendation - A function that adds a recommendation to the tool
 * @param {function} handleRemoveRecommendation - A function that removes a recommendation from the tool
 * @returns {JSX.Element} - A container that displays helpful tool's information within a card
 * 
 * @example
 * // Renders a container that displays helpful tool's information within a card
 * <ExpandedToolCard 
 *  closeModal={closeModal} 
 *  handleAddRecommendation={handleAddRecommendation} 
 *  handleRemoveRecommendation={handleRemoveRecommendation}
 *  tool={helpfulToolsList[index]} 
 *  closeModal={closeModal} 
 *  userData={props.userData}
 * />
 */

const ExpandedToolCard = (props) => {
    const [userRecommends, setUserRecommends] = useState(props.tool.recommendations.some((recommendation) => Number(recommendation.userID) === props.userData.userID));

    useEffect(() => {
        setUserRecommends((props.tool.recommendations.some((recommendation) => Number(recommendation.userID) === props.userData.userID)));
    }, [props.tool.recommendations.length])

    return (
        <div className='modal-container'>
            <div className='sg-backdrop' onClick={() =>  { props.closeModal() }}  />
            <div className='info-card-container scrollbar-mch-thin'>
                <h2>{props.tool.title}   <span>
                    {userRecommends
                        ? (<ThumbsDown className="clickable" onClick={() => props.handleRemoveRecommendation(props.tool.title)} width="20px" height="20px" color="var(--secondary-text-color)" />)
                        : (<ThumbsUp className="clickable" onClick={() => props.handleAddRecommendation(props.tool.title)} width="20px" height="20px" color="var(--secondary-text-color)"/>)
                    }
                </span></h2>
                <div className='helpful-tool-recommendations-list'>
                    <GroupUsersIcon width="30px" height="30px" color="var(--secondary-text-color)"/>
                    {(userRecommends && props.tool.recommendations.length > 0)  ? `You and ${props.tool.recommendations.length - 1}` : `${props.tool.recommendations.length}`} others recommend this tool.
                </div>
                <hr className='hr-custom'/>
                <p><span className='paragraph-lead'><strong>What is it?</strong></span> {props.tool.description}</p>
                <p><span className='paragraph-lead'><strong>Usage:</strong></span> {props.tool.usage}</p>
                <img src={props.tool.logo} />
            </div>
        </div>
    )
}

export default ExpandedToolCard