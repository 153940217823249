import { React, useState, Fragment } from "react";
import getGlobalURL from "../../../assets/scripts/Global";
import axios from "axios";
import { FoundBadToken } from "../../../scripts/badToken";
import InputWithObject from "../../atoms/InputWithObject";
import FormCancelSubmitBtns from "../../../Modals/forms/FormCancelSubmitBtns";
import TextAreaWithObject from "../../atoms/TextAreaWithObject";

const CreateClubEvent = (props) => {
   const url = getGlobalURL();

   const [eventTitle, setEventTitle] = useState("");
   const [eventDescription, setEventDescription] = useState("");
   const [startTime, setStartTime] = useState("");
   const [endTime, setEndTime] = useState("");
   const [focussedField, setFocussedField] = useState(null);

   const submitEvent = () => {
      if (!eventTitle || !eventDescription || !startTime || !endTime) {
         alert("Please fill out all fields");
         return;
      } else if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
         alert("Invalid start and end times for event");
         return;
      } else {
         handleCreateEvent();
      }
   };

   const handleCreateEvent = () => {
      alert(`title: ${eventTitle.title}, description: ${eventDescription.eventDescription}, start: ${startTime.start}, end: ${endTime.end}`)

      let event = {
         title: eventTitle.title,
         description: eventDescription.eventDescription,
         start: startTime.start,
         end: endTime.end,
         eventID: Date.now(),
         academic: false,
         assignment: false,
         personal: false,
         business: false,
      };
      axios.post(`${url}clubs/addCalendarEvent`, {
            clubName: props.club,
            event: event,
            userID: props.currentUser.userID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         if (response.status === 200) {
            props.handleEventMade();
            props.closeModal("createEvent");
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   };

   const handleFocus = (focussedOn) => {
      setFocussedField(focussedOn);
   }

   return (
      <div className="modal-container">
         <div className="modal-backdrop" onClick={() => { props.closeModal("createEvent")}}/>
         <div className="new-form-container">
            <div className="new-form-header">Create Club Event</div>
            <div className="new-form-body">
               <InputWithObject handleFocus={handleFocus} type="text" forText="title" labelText="Event Title:" data={eventTitle} setData={setEventTitle} />
               <TextAreaWithObject handleFocus={handleFocus} forText="eventDescription" labelText="Event Description:" data={eventDescription} setData={setEventDescription} />
               <InputWithObject handleFocus={handleFocus} type="datetime-local" forText="start" labelText="Start Time:" data={startTime} setData={setStartTime} />
               <InputWithObject handleFocus={handleFocus} type="datetime-local" forText="end" labelText="End Time:" data={endTime} setData={setEndTime} />
               <FormCancelSubmitBtns handleCloseForm={() => props.closeModal("createEvent")} closeText="Cancel" handleSubmit={handleCreateEvent} submitText="Create Event" />
            </div>
         </div>
      </div>
   );
};

export default CreateClubEvent;
