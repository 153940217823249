import { React } from 'react'

/**
 * @description - This component renders a label and an textarea field. The label includes optional helper text that appears
 *  right-justified. The component receives a state data object with its setData function and loads the input data
 *  into the object. 
 * 
 * @component
 * @param {string} forText - A string used to set the htmlFor attribute.
 * @param {string} labelText - A string for the label text displayed to the user.
 * @param {string} helperText - A string for the helper area to give the user a hint about what to enter.
 * @param {boolean} required - A boolean to set the required attribute on the input field.
 * @param {string} value - An optional string to set the value attribute on the textarea field.
 * @param {object} data - The data object to populate.
 * @param {function} handleFocus - The function to handle the focus event.
 * @param {function} setData - The set function to populate the data object.
 * @returns {JSX.Element} - A React element that renders a label with an input field.
 * 
 * @example
 * // Renders a label and a textarea field. The label includes optional helper text that appears right-justified
 * <TextAreaWithObject
 *  handleFocus={handleFocus}
 *  forText="eventDescription" 
 *  labelText="Event Description:" 
 *  helperText="Up to 300 characters"
 *  value={someDescription}
 *  data={eventDescription} 
 *  setData={setEventDescription}
 * />
 */

const TextAreaWithObject = (props) => {
    return (
        <div className='form-label-input-container'>
            <div className="form-body-label-area">
                <label htmlFor={props.forText}>{props.required && `* `}{props.labelText}</label>
                {props.helperText && <label className="helper">{props.helperText}</label>}
            </div>
            <textarea value={(props.value) ? props.value : null} onFocus={() => props.handleFocus(props.forText)} onChange={(e) => props.setData({...props.data, [props.forText]: e.target.value})} id="eventDescription"/>
        </div>
    )
}

export default TextAreaWithObject;