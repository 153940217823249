import { React, useEffect, useState } from 'react';
import DisplayMessage from './DisplayMessage';

const FriendsGroupList = (props) => {

    const [requests, setRequests] = useState(props.currentUser.friendGroupRequests);

    useEffect(() => {
        setRequests(props.currentUser.friendGroupRequests);
    }, [props.currentUser.friendGroupRequests.length])

    return (
        <div id='message-application-left-panel-container' className='make-grid'>
            <div id='message-application-user-search-field' className='make-grid'>
                <input type='text' placeholder='🔍 Search Groups'/>
            </div>
            
            <div id='message-application-user-field' className='scrollbar-mch-thin'>   
                <button onClick={() => { props.handleShowGroup() }} style={{cursor: 'pointer'}} id='create-ocean-btn' >Create a Group Chat</button>
                {requests.length > 0 && <p onClick={() => { props.handleShowInvitesModal() }} style={{fontSize: '.8em', cursor: 'pointer'}} >Your Group Requests ({props.currentUser.friendGroupRequests.length})</p>}
                {props.groups.map((group, index) => {
                    return (
                        <div className={props.groupSelected === index ? 'activeChat border-radius-15' : ''} >
                            <DisplayMessage handleSelectedGroupChat={props.handleSelectedGroupChat} index={index} key={index * 2} currentUser={props.currentUser} group={group} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FriendsGroupList;