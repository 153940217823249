import { React, useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import { FoundBadToken } from "../../../scripts/badToken";

const FG_Members = (props) => {
    const url = getGlobalURL();

    const [members, setMembers] = useState([]);
    const [pendingMembers, setPendingMembers] = useState([]);
    const [onlineMembers, setOnlineMembers] = useState([]);

    const onlineChannel = useRef(props.ably.channels.get('all-online-users'));

    // * fetch the users in the friend group chat
    useEffect(() => {
        axios.get(`${url}friendGroups/getFriendGroupMembers/${props.group.groupID}`, {
            headers: {
                Authorization: sessionStorage.getItem("token")
            }
        }).then((response) => {
            if(response.status === 200){
                setMembers(response.data.currentMembers);
                setPendingMembers(response.data.pending);
            }
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })

    }, [props.group.groupID, props.group, props.group.pendingMembers, props.group.members])

    // * ably useEffect to show who is online
    useEffect(() => {
        onlineChannel.current.presence.get((err, member) => {
            if(!err){
                let temp = [];
                member.forEach((m) => {
                    temp.push(m.clientId);
                })
                setOnlineMembers(temp);
            }
        })

        onlineChannel.current.presence.subscribe('enter', (member) => {
            let temp = onlineMembers;
            temp.push(member.clientId);
            setOnlineMembers(temp);
        })

        onlineChannel.current.presence.subscribe('leave', (member) => {
            let temp = [];
            onlineMembers.forEach((m) => {
                if(m.toString() !== member.clientId.toString()){
                    temp.push(m);
                }
            })
            setOnlineMembers(temp);
            // let temp = onlineMembers;
            // temp = temp.filter((m) => {
            //     return m !== member.clientId;
            // })
            // setOnlineMembers(temp);
        })

        return () => {
            onlineChannel.current.presence.unsubscribe();
        }
    }, [])

    const displayOwner = () => {
        if(members.length > 0){
            const owner = members.find((member) => member.userInfo.userID === props.group.groupOwner);
            if(owner){
                const online = onlineMembers.includes(owner.userInfo.userID.toString());
                return (
                    <Fragment>
                        <div className='date-separator-study' >Owner</div>
                        <div id='study-group-members-listings-area' className='make-grid' >
                            <img style={{border: online ? '3px solid limegreen' : ''}} src={owner.userInfo.profileImage} />
                            <div id='study-group-members-stats-title' >
                                <h3>{owner.userInfo.name}</h3>
                                {displayButtons(owner)}
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    }

    const displayInvitedMembers = () => {
        if(pendingMembers.length > 0){
            return (
                <Fragment>
                    <div className='date-separator-study' >Invited Members</div>
                    {pendingMembers.map((member, index) => {
                        return (
                            <div key={index} id="study-group-members-listings-area" className='make-grid' >
                                <img src={member.userInfo.profileImage} />
                                <div id='study-group-members-stats-title' >
                                    <h3>{member.userInfo.name}</h3>
                                    {props.group.groupOwner === props.currentUser.userID && (
                                        <button onClick={() => { cancelInvite(member.userInfo.userID) }} className='admin-btns btn-ease'>Cancel Invite</button>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </Fragment>
            )
        }
    }

    const cancelInvite = (userID) => {
        axios.post(`${url}friendGroups/cancelInvite`, {
            groupID: props.group.groupID,
            userID: userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            let newPendingMembers = pendingMembers.filter((member) => member.userInfo.userID !== userID);
            setPendingMembers(newPendingMembers);
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    const deleteFriendGroup = () => {
        axios.delete(`${url}friendGroups/deleteFriendGroup`, {
            params: {
                groupID: props.group.groupID,
            },
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            alert('Group has been deleted')
            sendRemoveGroup(props.group.groupID)
            // props.removeGroup(props.group.groupID)
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    function sendRemoveGroup(groupID){
        let channel = props.ably.channels.get(`friend-group-${groupID}`);
        channel.publish('delete-group', {
            groupID: groupID
        })
    }

    const handleRemoveMember = (member) => {
        axios.patch(`${url}friendGroups/removeMemberFromGroup`, {
            groupID: props.group.groupID,
            userID: member.userInfo.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            let newMembers = members.filter((m) => m.userInfo.userID !== member.userInfo.userID);
            setMembers(newMembers);
            sendRemoveMember(props.group.groupID, member);
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    function sendRemoveMember(groupID, member, content){
        let channel = props.ably.channels.get(`friend-group-${groupID}`);
        channel.publish('remove-member', {
            groupID: groupID,
            userID: member.userInfo.userID,
            message: {
                userID: member.userInfo.userID,
                profileImage: member.userInfo.profileImage,
                userName: member.userInfo.name,
                date: Date.now(),
                messageContent: content,
                contentURL: '',
                messageType: 'system'
            }
        })
    }

    const handleLeaveGroup = (member) => {
        axios.patch(`${url}friendGroups/leaveGroup`, {
            groupID: props.group.groupID,
            userID: member.userInfo.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            props.currentUser.friendGroupChats = props.currentUser.friendGroupChats.filter((group) => group !== props.group.groupID);
            let newMembers = members.filter((m) => m.userInfo.userID !== member.userInfo.userID);
            setMembers(newMembers);
            sendRemoveMember(props.group.groupID, member, 'left the group');
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    const displayButtons = (member) => {
        return props.group.groupOwner === props.currentUser.userID ? (
            member.userInfo.userID === props.currentUser.userID ? (
                <><button onClick={props.handleShowModal} className='admin-btns btn-ease'>Edit Group</button><button onClick={() => { deleteFriendGroup() }} className='admin-btns btn-ease' >Delete Group</button></>
            ) : <button onClick={() => { handleRemoveMember(member) }} className='admin-btns btn-ease'>Remove</button>
        ) : member.userInfo.userID === props.currentUser.userID ? (
            <button onClick={() => { handleLeaveGroup(member) }} className='admin-btns btn-ease'>Leave Group</button>
        ) : <button className='admin-btns btn-ease'>Message</button>
    }

    return  (
        <div id='message-app-study-members' className='border-radius-20'>
            <h3>{props.group.groupName}</h3>
            {displayOwner()}
            <div className='date-separator-study' >Members</div>
            {members.length > 1 ? members.map((member, index) => {
                if(member.userInfo.userID !== props.group.groupOwner){
                    const online = onlineMembers.includes(member.userInfo.userID.toString());
                    return (
                        <div key={index} id='study-group-members-listings-area' className='make-grid' >
                            <img style={{border: online ? '3px solid limegreen' : ''}} src={member.userInfo.profileImage} />
                            <div id='study-group-members-stats-title' >
                                <h3>{member.userInfo.name}</h3>
                                {displayButtons(member)}
                            </div>
                        </div>
                    )
                }
            }) : <p style={{textAlign: 'center'}} >No Members</p>}
            {displayInvitedMembers()}
        </div>
    )
}

export default FG_Members;