import { React, useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import getGlobalURL from "../../../../assets/scripts/Global";
import { FoundBadToken } from "../../../../scripts/badToken";

const MembersPanel = (props) => {
    const url = getGlobalURL();

    const [members, setMembers] = useState([]);
    const [pendingMembers, setPendingMembers] = useState([]);
    const [onlineMembers, setOnlineMembers] = useState([]);

    const onlineChannel = useRef(props.ably.channels.get('all-online-users'));

    useEffect(() => {
        axios.get(`${url}studyGroups/getStudyGroupMembers`, {
            params: {
                studyGroupID: props.group.studyGroupID
            },
            headers: {
                Authorization: sessionStorage.getItem("token"),
            }
        }).then((response) => {
            if (response.status === 200) {
                console.log('members: ', response.data.currentMembers)
                setMembers(response.data.currentMembers);
                setPendingMembers(response.data.pendingMembers);
            }
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }, [props.group.studyGroupID]);

    //* ably useEffect to show who is online
    useEffect(() => {
        onlineChannel.current.presence.get((err, member) => {
            if(!err){
                let temp = [];
                member.forEach((m) => {
                    temp.push(m.clientId);
                })
                console.log('members online from members panel: ', temp);
                setOnlineMembers(temp);
            }
        })

        onlineChannel.current.presence.subscribe('enter', (member) => {
            let temp = onlineMembers;
            temp.push(member.clientId);
            setOnlineMembers(temp);
        })

        onlineChannel.current.presence.subscribe('leave', (member) => {
            console.log('member left: ', member.clientId)
            console.log('members before filter: ', onlineMembers);
            let temp = [];
            onlineMembers.forEach((m) => {
                if(m.toString() !== member.clientId.toString()){
                    temp.push(m);
                }
            })
            console.log('members after filter: ', temp);
            setOnlineMembers(temp);
            // let temp = onlineMembers;
            // temp = temp.filter((m) => {
            //     return m !== member.clientId;
            // })
            // setOnlineMembers(temp);
        })

        return () => {
            onlineChannel.current.presence.unsubscribe();
        }
    }, [])

    // * ably useEffect to update members panel when people join and leave
    useEffect(() => {
        const channel = props.ably.channels.get(`study-group-${props.group.studyGroupID}`);
        channel.subscribe('member-update', (message) => {
            if(message.data.action === 'join'){
                if(props.group.visibility === 'public'){
                    setMembers(prevMembers => [...prevMembers, message.data.member]);
                }
                else{
                    setPendingMembers(prevMembers => [...prevMembers, message.data.member]);
                }
            }
            else if(message.data.action === 'add'){
                setMembers(prevMembers => [...prevMembers, message.data.member]);
            }
            else if(message.data.action === 'remove'){
                setMembers(prevMembers => prevMembers.filter((m) => m.userInfo.userID !== message.data.member.userInfo.userID));
            }
        })
    
        return () => {
            channel.unsubscribe();
        }
    }, []);

    const handleRejectMember = (memberID) => {
        axios.patch(`${url}studyGroups/rejectMember`, {
            studyGroupID: props.group.studyGroupID,
            userID: memberID
        }, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            }
        }).then((response) => {
            setPendingMembers(prevMembers => prevMembers.filter((m) => m.userInfo.userID !== memberID));
            sendUserUpdate(memberID, props.group.studyGroupID, 'remove');
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    const handleRemoveMember = (member) => {
        axios.patch(`${url}studyGroups/removeMemberFromGroup`, {
            studyGroupID: props.group.studyGroupID,
            userID: member.userInfo.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            }
        }).then(async (response) => {
            let group = props.group;
            group.members = group.members.filter((m) => m.userID !== member.userInfo.userID);
            props.updateGroup(group);
            console.log('setting members from within handleRemoveMember.then()')
            setMembers(prevMembers => prevMembers.filter((m) => m.userInfo.userID !== member.userInfo.userID));

            // * send update to alert the other members online to remove this member
            await sendMemberUpdate(member, 'remove');

            // * send update to user to remove this studyGroupID from their groups array
            await sendUserUpdate(member.userInfo.userID, props.group.studyGroupID, 'remove');

            // * send a system message to the group chat
            await sendRealTimeMessage(member, 'has been removed from the group');
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    const hanldeAcceptMember = (member) => {
        axios.patch(`${url}studyGroups/acceptMember`, {
            studyGroupID: props.group.studyGroupID,
            userID: member.userInfo.userID
        }, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            }
        }).then(async (response) => {
            let group = props.group;
            group.members.push({
                userID: member.userInfo.userID,
                missedMessages: 0
            })
            props.updateGroup(group);
            setPendingMembers(prevMembers => prevMembers.filter((m) => m.userInfo.userID !== member.userInfo.userID));
            // setMembers(prevMembers => [...prevMembers, member]);

            // * send update to alert the other members online to add this member
            await sendMemberUpdate(member, 'add');

            // * send update to user to add this studyGroupID to their groups array
            await sendUserUpdate(member.userInfo.userID, props.group.studyGroupID, 'add');

            // * send system message to the group chat
            await sendRealTimeMessage(member, 'has been added to the group');
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }

    const handleLeaveGroup = (member) => {
        if(window.confirm('Are you sure you want to leave this group?')){
            axios.post(`${url}studyGroups/leaveStudyGroup`, {
                studyGroupID: props.group.studyGroupID,
                userID: props.currentUser.userID
            }, {
                headers: {
                    Authorization: sessionStorage.getItem('token')
                }
            }).then(async (response) => {

                props.currentUser.groups = props.currentUser.groups.filter((group) => {
                    return group !== props.group.studyGroupID
                })
                props.leaveGroup(props.group.studyGroupID)

                // * send update to alert the other members online to remove this member
                await sendMemberUpdate(member, 'remove')

                // * send system message to the group chat
                await sendRealTimeMessage(member, 'has left the group')
            }).catch((error) => {
                if(error.response.status === 401){
                    alert('Session expired. Please log in again.')
                    FoundBadToken();
                }
            })
        }
    }

    async function sendMemberUpdate(member, action){
        console.log('sending member update')
        let channel = props.ably.channels.get(`study-group-${props.group.studyGroupID}`);
        channel.publish('member-update', {
                studyGroupID: props.group.studyGroupID,
                userID: member.userInfo.userID,
                action: action,
                member: member
        }, () => {
            console.log(`sent message to study-group-${props.group.studyGroupID}`)
        })
        console.log('sent member update')
    }

    async function sendRealTimeMessage(member, messageContent){
        console.log('sending real time message')
        let channel = props.ably.channels.get(`study-group-${props.group.studyGroupID}`);
        channel.publish('new-message', {
            data: {
                studyGroupID: props.group.studyGroupID,
                message: {
                    userID: member.userInfo.userID,
                    profileImage: member.userInfo.profileImage,
                    userName: member.userInfo.name,
                    messageContent: messageContent,
                    date: Date.now(),
                    messageType: 'system'
                }
            }
        }, () => {
            console.log(`sent message to study-group-${props.group.studyGroupID}`)
        })
        console.log('sent real time message')
    }

    async function sendUserUpdate(userID, studyGroupID, action){
        console.log('sending user update')
        let channel = props.ably.channels.get(`user-updates-${userID}`);
        channel.publish('study-group-update', {
                studyGroupID: studyGroupID,
                action: action
        }, () => {
            console.log(`sent message to user-updates-${userID}`)
        })
        console.log('sent user update')
    }

    const displayButtons = (member) => {
        return props.group.studyGroupOwner === props.currentUser.userID ? (
            member.userInfo.userID === props.currentUser.userID ? (
                <button onClick={() => { props.handleEditGroup() }} className='admin-btns btn-ease' >Edit Group</button>
            ) : <button onClick={() => { handleRemoveMember(member) }} className='admin-btns btn-ease' >Remove</button>
        ) : member.userInfo.userID === props.currentUser.userID ? (
            <button onClick={() => { handleLeaveGroup(member) }} className='admin-btns btn-ease' >Leave</button>
        ) : <button className='admin-btns btn-ease' >Message</button>
    }

    const displayPendingMembers = () => {
        if(props.group.visibility === 'private'){
            return (
                <div>
                    <div className='date-separator-study' >Pending Members</div>
                    {pendingMembers.length > 0 ? (
                        pendingMembers.map((member, index) => {
                            const online = onlineMembers.includes(member.userInfo.userID.toString());
                            return (
                                <div key={index} id='study-group-members-listings-area' className='make-grid' >
                                    <img style={{border: online ? '3px solid limegreen' : ''}} src={member.userInfo.profileImage} />
                                    <div id='study-group-members-stats-title' >
                                        <h3>{member.userInfo.name}</h3>
                                        <button onClick={() => { hanldeAcceptMember(member) }} className='admin-btns btn-ease' >Accept</button>
                                        <button onClick={() => { handleRejectMember(member.userInfo.userID) }} className='admin-btns btn-ease' >Reject</button>
                                    </div>
                                </div>
                            )
                        })
                    ) : <p>No pending members at this time</p>}
                </div>
            )
        }
    }

    const displayOwner = () => {
        if(members.length > 0){
            const owner = members.find((member) => member.userInfo.userID === props.group.studyGroupOwner);
            const online = onlineMembers.includes(owner.userInfo.userID.toString());
            return (
                <Fragment>
                    <div className='date-separator-study' >Owner</div>
                    <div id='study-group-members-listings-area' className='make-grid' >
                        <img style={{border: online ? '3px solid limegreen' : ''}} src={owner.userInfo.profileImage} />
                        <div id='study-group-members-stats-title' >
                            <h3>{owner.userInfo.name}</h3>
                            {displayButtons(owner)}
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    return (
        <div id='message-app-study-members' className='border-radius-20' >
            <h3 style={{textAlign: 'center', fontSize: '1em'}} >{props.group.studyGroupName}</h3>
            {displayOwner()}
            <div className='date-separator-study' >Members</div>
            {members.map((member, index) => {
                if(member.userInfo.userID !== props.group.studyGroupOwner){
                    const online = onlineMembers.includes(member.userInfo.userID.toString());
                    return (
                        <div key={index} id='study-group-members-listings-area' className='make-grid' >
                            <img style={{border: online ? '3px solid limegreen' : ''}} src={member.userInfo.profileImage} />
                            <div id='study-group-members-stats-title' >
                                <h3>{member.userInfo.name}</h3>
                                {displayButtons(member)}
                            </div>
                        </div>
                    )
                }
            })}
            {props.group.studyGroupOwner === props.currentUser.userID && (
                displayPendingMembers()
            )}
        </div>
    )
}

export default MembersPanel;