import getGlobalURL from "../../assets/scripts/Global";
import { React, useState, useEffect, Fragment } from "react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { FoundBadToken } from "../../scripts/badToken";

const ChildRequestListing = (props) => {
   // Incoming props from ChildRequestModal: 'currentUser', 'ocean'

   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [ocean, setOcean] = useState();
   const [fetching, setFetching] = useState(true);

   useEffect(() => {
      axios.get(`${url}oceans/getOceanByID/${props.childOceanID}`, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         setOcean(response.data);
         setFetching(false);
      }).catch((error) => {
         if (error.response.status === 401) {
            alert("Session expired. Please login again.");
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   }, []);

   const handleAcceptChild = () => {
      axios.patch(`${url}oceans/acceptChildOcean`, {
            currentOceanID: props.parentOcean.oceanID,
            childOceanID: props.childOceanID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         alert("Child Accepted");
         props.addSubHub(ocean, props.childOceanID)
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   };

   const handleRejectChild = () => {
      axios.patch(`${url}oceans/rejectChildOcean`, {
            currentOceanID: props.parentOcean.oceanID,
            childOceanID: props.childOceanID,
         }, {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         alert("Child Rejected");
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error.response.data.err);
         }
      });
   };

   return (
      <Fragment>
         {fetching ? (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "15px"}}>
               <PuffLoader color="#006994" />
            </div>
         ) : ocean != null ? (
            <div id="child-request-listings-area" className="make-grid">
               <img src={ocean.oceanBanner} />
               <div id="child-request-stats-title">
                  <h3>{ocean.oceanName}</h3>
               </div>
               <p>{ocean.members} members | {ocean.posts} posts</p>
               <div id="child-request-btns">
                  <button onClick={handleAcceptChild} className="admin-btns btn-ease">Accept</button>
                  <button onClick={handleRejectChild} className="admin-btns btn-ease">Reject</button>
               </div>
            </div>
         ) : null}
      </Fragment>
   );
};

export default ChildRequestListing;
