import { React, useState, useEffect } from 'react';
import GoldBanner from '../../../assets/images/tu_gold_card_background_banner.png'

const UserInfo = (props) => {
    return (
        <div id='message-app-private-chat-user-info' className='border-radius-20' >
            <div className='private-chat-user-info-img-area border-radius-10' >
                <img src={props.foreignUser.bannerImage ? props.foreignUser.bannerImage : GoldBanner} alt='profile' />
                <div className='private-chat-user-info-profile-img-area' >
                    <img src={props.foreignUser.profileImage} alt='profile' />
                </div>
            </div>
            <div style={{marginTop: '12%'}} className='date-separator-study' >{props.foreignUser.name}</div>
        </div>
    )
}

export default UserInfo;