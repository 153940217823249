import { React, useState, useEffect, Fragment } from 'react'
import { DefaultAvatar } from '../../assets/scripts/mchScripts'
import Testimonial from './Testimonial';

const TestimonialsParent = () => {
    const testimonials = [
        {
            name: "Jal Irani",
            year: "Lecturer",
            testimonial: "This platform has greatly improved my ability to stay connected with students. It makes organizing study groups, sharing resources, and providing feedback much easier. An invaluable tool for student engagement.",
            major: "Computer Science",
            avatar: "https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/profileImages%2F1698701933048?alt=media&token=9b7a14b2-4fad-4e48-92b1-ce9d9f4661ca"

        },
        {
            name: "Andres Londono",
            year: "Senior",
            testimonial: "I wish I had this platform earlier in my academic journey. It would have made navigating through courses, finding study groups, and connecting with faculty so much easier. Nevertheless, I'm grateful for the support it provided during my final year.",
            major: "Computer Science",
            avatar: "https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/profileImages%2F1698698992242?alt=media&token=4c959727-0d7c-4fd6-b38d-de5e34672806"
        },
        {
            name: "Alex Johnson",
            year: "Freshman",
            testimonial: "This platform made it so easy to meet new friends during my first semester! I felt so much more connected to the campus community.",
            major: "Computer Science",
            avatar: DefaultAvatar
        },
        {
            name: "Megan Smith",
            year: "Sophomore",
            testimonial: "I love how I can find study groups for my classes and get in touch with professors easily. It's been a game-changer for my academic life.",
            major: "Biology",
            avatar: DefaultAvatar
        },
        {
            name: "Jake Miller",
            year: "Junior",
            testimonial: "The events calendar keeps me updated on all the cool happenings around campus and the city. I've discovered so many new places and activities!",
            major: "Marketing",
            avatar: DefaultAvatar
        },
        {
            name: "Emily Davis",
            year: "Senior",
            testimonial: "Networking with alumni and attending career workshops has been incredibly helpful. I'm feeling much more prepared for graduation and job hunting.",
            major: "Finance",
            avatar: DefaultAvatar
        },
        {
            name: "Daniel Lee",
            year: "Freshman",
            testimonial: "As a new student, this platform was my go-to for everything from finding campus resources to making friends. Highly recommend!",
            major: "Engineering",
            avatar: DefaultAvatar
        },
        {
            name: "Sophia Martinez",
            year: "Sophomore",
            testimonial: "It's amazing how I can keep track of both my academic schedule and social events in one place. Balancing everything has never been easier.",
            major: "Psychology",
            avatar: DefaultAvatar
        },
        {
            name: "Ryan Brown",
            year: "Junior",
            testimonial: "The platform's integration with local businesses and events has made exploring the city so much fun. I feel like a local now!",
            major: "History",
            avatar: DefaultAvatar
        },
        {
            name: "Isabella Wilson",
            year: "Senior",
            testimonial: "I’ve been able to join several student organizations and even started my own club. The support and connections I've made are invaluable.",
            major: "Political Science",
            avatar: DefaultAvatar
        },
        {
            name: "Chris Thompson",
            year: "Freshman",
            testimonial: "Being able to chat with other students in my major has helped me settle into my classes and find study partners quickly.",
            major: "Mathematics",
            avatar: DefaultAvatar
        },
        {
            name: "Olivia Garcia",
            year: "Junior",
            testimonial: "I appreciate how easy it is to connect with faculty for mentorship and research opportunities. It’s been a huge boost for my academic career.",
            major: "Chemistry",
            avatar: DefaultAvatar
        },
        {
            name: "Ryan Kraft",
            year: "Alumni",
            testimonial: "I love how I am able to stay connected to Towson by helping current students work through the process of finding solutions to assignments that have them stuck. I could have used this back when I was an undergrad!",
            major: "Computer Science",
            avatar: "https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/profileImages%2F1698788870113?alt=media&token=0c0d9f42-d9e1-4378-872d-f736be412c9e"
        },
        {
            name: "Matt Dibbern",
            year: "First-Year Graduate Student",
            testimonial: "I'm happy to have a platform like this at my disposal. It makes finding academic resources, connecting with classmates, and seeing what there is to do around town a breeze. I'm not sure how I made it through undergrad without it!",
            major: "Computer Science",
            avatar: DefaultAvatar
        }
    ]

    const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[Math.floor(Math.random() * testimonials.length)]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((prevIndex) => {
                const nextIndex = (prevIndex + 1) % testimonials.length;
                setCurrentTestimonial(testimonials[nextIndex]);
                return nextIndex;
            });
        }, 13000); // 13 seconds

    return () => clearInterval(intervalId);
    }, [testimonials]);

        return (
            <Testimonial currentTestimonial={currentTestimonial}/>
        )
}

export default TestimonialsParent