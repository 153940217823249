import { React, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
// * moment is a library for parsing, validating, manipulating, and formatting dates
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const BusinessCalendarModal = (props) => {
    const [showEventModal, setShowEventModal] = useState(false);
    const [event, setEvent] = useState();

    // * localizer is for formatting dates and times for specific locale
    const localizer = momentLocalizer(moment)

    const handleSelectEvent = (event) => {
        setShowEventModal(true)
        setEvent(event)
    }

    return (
        <div className='modal-container' >
            <div className='modal-backdrop' onClick={() => {props.handleCloseCalendarModal()}} >
                <div onClick={(event) => event.stopPropagation()} >
                    <Calendar 
                        localizer={localizer}
                        events={props.events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500, background: 'white'}}
                        onSelectEvent={(event) => {handleSelectEvent(event)}}
                    />
                </div>
            </div>
        </div>
    )
}

export default BusinessCalendarModal;