import React from 'react'

const UserUnfollowIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        fill={props.fill}
        stroke={props.strokeColor}
        viewBox="0 0 1024 1024"
        {...props}
    >
        <path d="m799.12 383.856.001-159.92C799.121 118.016 652.513.081 511.217.081c-141.312 0-288.128 117.936-288.128 223.855v159.92c0 69.872 31.888 211.232 121.392 283.072l-281.04 132.64S-.511 828.064-.511 863.536v96.032c0 35.344 28.64 63.968 63.951 63.968h607.936v-64l-607.888.032v-64.944c0-25.44 19.104-33.425 26.72-36.945l281.04-132.624c20.143-9.248 34.048-28.335 36.752-50.335 2.72-22-6.16-43.825-23.456-57.697-66.48-53.376-97.456-170.688-97.456-233.199v-159.92c0-66.864 116.4-159.856 224.128-159.856 108.688 0 223.904 91.536 223.904 159.856v159.92c0 61.552-25.6 179.328-94.224 233.36a63.793 63.793 0 0 0-23.968 57.792c2.592 22.16 16.56 41.313 36.848 50.624l18.112 8.352 28.065-51.792-19.489-14.72c88.657-69.727 118.656-206.768 118.656-283.584zm125.505 494.945 90.496-90.512c12.496-12.464 12.496-32.752 0-45.248-12.48-12.48-32.753-12.48-45.233 0l-90.512 90.528-90.496-90.528c-12.496-12.48-32.769-12.48-45.25 0-12.495 12.496-12.495 32.784 0 45.248l90.497 90.512-90.496 90.496c-12.496 12.48-12.496 32.768 0 45.264 12.48 12.464 32.753 12.464 45.249 0l90.496-90.511 90.512 90.511c12.48 12.464 32.753 12.464 45.233 0 12.496-12.496 12.496-32.784 0-45.264z" />
    </svg>
)

export default UserUnfollowIcon