import { React, Fragment, useState, useEffect } from "react";
import GraphiteCardbackground from '../../../assets/images/tu_graphite_card_background_169.png';
import GoldCardBackground from '../../../assets/images/tu_gold_card_background_169.png'

// Props coming from GreekLifeField.jsx: greekOrg, handleOrgClicked
const GreekOrg = (props) => {

    const handleClick = () => {
        props.handleOrgClicked(props.greekOrg.chapter)
    }


    return (
        <Fragment>
                <div id='card-container' onClick={handleClick} className='clickable border-radius-25'>
                    <div id='club-org-cover-img-container-inset-shadow'></div>
                        <div id='club-org-cover-img-container'>
                            <img src={props.greekOrg.fraternity ? GraphiteCardbackground : GoldCardBackground}/>
                        </div>
                    <div id='card-text-field'>
                    <p>{props.greekOrg.organization} | {props.greekOrg.chapter}</p>
                </div>
            </div>
        </Fragment>
    )

}

export default GreekOrg