import {React, useState} from 'react'

const ChatbotPastQueries = (props) => {
    const [querySelected, setQuerySelected] = useState(0)
    const dummyList = [
        {
            queryID: 1,
            content: "What meal plans are available?"
        },
        {
            queryID: 2,
            content: "Where is Jal Irani's office?"
        }, 
        {
            queryID: 3,
            content: "What are the core requirements for a Computer Science degree?"
        },
        {
            queryID: 4,
            content: "What food is available at the student union?"
        },
        {
            queryID: 5,
            content: "Where is a post office on campus?"
        }, 
        {
            queryID: 6,
            content: "Who can I contact about financial aid?"
        },
        {
            queryID: 7,
            content: "Are there research opportunities for undergraduates?"
        },
    ]

    const handleQuerySelected = (index) => {
        setQuerySelected(index);
    }

    return (
        <div id='message-app-chatbot-past-queries-field-container' className='scrollbar-mch-thin'> 
            <h3>Past Queries:</h3>
            {dummyList.map((query, index) => {
                return (
                    <div key={index} className={(querySelected === index) ? "past-query activeChat btn-ease" : "past-query btn-ease"} >
                        <p onClick={() => handleQuerySelected(index)}>{query.content}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default ChatbotPastQueries