import React from 'react'
import { formatNumber } from '../../assets/scripts/mchScripts'

/**
 * @description - A component that displays a stat with a label over it. Imports and utilizes the formatNumber 
 *  function from mchScripts.js to format the stat number with commas.
 * 
 * @component
 * @param {string} label - The label for the stat
 * @param {number} stat - The stat to display
 * @returns {JSX.Element} - A component that displays a stat with a label over it
 * 
 * @example
 * // Renders a component that displays a stat with a label over it
 * <StatContainer 
 *  label="Following" 
 *  stat={user.following.length} 
 * />
 */

const StatContainer = (props) => {
    return (
        <div className="header-stat-container">
            <p>{props.label}</p>
            <h1>{formatNumber(props.stat)}</h1>
        </div>
    )
}

export default StatContainer