import { React, Fragment } from 'react'
import ClassroomField from '../classrooms/ClassroomField'
import StudyGroupField from '../study-groups/StudyGroupField'
import MajorsField from '../campusModeComponents/majors/MajorsField'
import DepartmentField from '../departments/CollegeField'
import LibraryField from '../library/LibraryField'
import TutoringFiled from '../Tutoring/TutoringField'
import HelpfulToolsField from '../organisms/helpful-tools/HelpfulToolsField'

/**
 * @description - A component that renders a specific Academics field based on the active button selected. The fields are: 
 *  DepartmentField, MajorsField, ClassroomField, StudyGroupField, TutoringFiled, LibraryField, and HelpfulToolsField. 
 * 
 * @component
 * @param {Object} ably - The ably object
 * @param {String} activeButton - The active button selected
 * @param {Object} currentUser - The current user object
 * @returns {JSX.Element} - A specific Academics field based on the active button selected
 * 
 * @example
 * // Renders a specific Academics field based on the active button selected
 * <AcademicsTab
 *  ably={ably.current}
 *  activeButton={activeButton}
 *  currentUser={currentUser}
 * />
 */

const AcademicsTab = (props) => {
    return (
        <Fragment>
            {(props.activeButton === "Departments") ? <DepartmentField currentUser={props.currentUser} />
                : (props.activeButton === "Majors") ? <MajorsField />
                : (props.activeButton === "Classes") ? <ClassroomField currentUser={props.currentUser} />
                : (props.activeButton === "Study Groups") ? <StudyGroupField ably={props.ably} currentUser={props.currentUser} />
                : (props.activeButton === "Tutoring Services") ? <TutoringFiled currentUser={props.currentUser} />
                : (props.activeButton === "Library") ? <LibraryField />
                : (props.activeButton === "Helpful Tools") ? <HelpfulToolsField userData={{userID: props.currentUser.userID, profileUrl: props.currentUser.profileImage}}/>
                : null
            }
        </Fragment>
    )
}

export default AcademicsTab


// Old Navigation: Note that we'd need to pass the following props to the AcademicsBtnList component
// import AcademicsBtnList from '../campusModeComponents/AcademicsBtnList'
// <AcademicsBtnList currentUser={props.currentUser} campusButtonSelection={props.campusButtonSelection} activeButton={props.activeButton} handleTabClick={props.handleTabClick} tabSelected={props.tabSelected} source={props.source}/>
