import { React, Fragment, useState, useEffect, useRef } from "react";
import DefaultStudyGroupIcon from '../../../assets/images/DefaultStudyGroupIcon.png'
import GroupUserList from "./GroupUserList";
import axios from 'axios'
import getGlobalURL from '../../../assets/scripts/Global'
import StudyGroupChatField from "./StudyGroupChatField";
import { FoundBadToken } from "../../../scripts/badToken";

const StudyGroups = (props) => {
    const url = getGlobalURL();

    const [groupSelected, setGroupSelected] = useState(0);
    const [studyGroups, setStudyGroups] = useState([]);
    const groupRef = useRef(studyGroups);

    useEffect(() => {
        axios.get(`${url}studyGroups/getUserStudyGroups`, {
            params: {
                userID: props.currentUser.userID
            },
            headers: {
                Authorization: sessionStorage.getItem("token"),
            }
        }).then((response) => {
            if (response.status === 200) {
                setStudyGroups(response.data);
            }
        }).catch((error) => {
            if(error.response.status === 401){
                alert('Session expired. Please log in again.')
                FoundBadToken();
            }
        })
    }, [props.currentUser.groups])

    useEffect(() => {
        groupRef.current = studyGroups;
    }, [studyGroups]);

    // * ably useEffect
    useEffect(() => {
        const channels = {};
        props.currentUser.groups.forEach((group) => {
            const channel = props.ably.channels.get(`study-group-${group}`);
            channels[group] = channel;

            channel.subscribe('member-update', (message) => {
                if(message.data.action === 'remove'){
                    if(message.data.userID === props.currentUser.userID){
                        leaveGroup(message.data.studyGroupID);
                    }
                    else{
                        let groupID = message.data.studyGroupID;
                        let group;
                        let groupIndex;
                        groupRef.current.forEach((g, index) => {
                            if(g.studyGroupID === groupID){
                                group = g;
                                groupIndex = index;
                            }
                        })
                        group.members = group.members.filter((member) => {
                            return member.userID !== message.data.userID;
                        })
                        groupRef.current[groupIndex] = group;
                        setStudyGroups([...groupRef.current])
                    }
                }
                else if(message.data.action === 'add'){
                    let groupID = message.data.studyGroupID;
                    let group;
                    let groupIndex;
                    groupRef.current.forEach((g, index) => {
                        if(g.studyGroupID === groupID){
                            group = g;
                            groupIndex = index;
                        }
                    })
                    group.members.push({
                        userID: message.data.userID,
                        missedMessages: 0,
                    });
                    groupRef.current[groupIndex] = group;
                    setStudyGroups([...groupRef.current])
                }
            })

            channel.subscribe('new-message', (message) => {
                updateMessages(message);
            })
        })

        return () => {
            Object.values(channels).forEach((channel) => {
                channel.unsubscribe();
            })
        }
    }, [])

    const updateMessages = (message) => {
        let groupID = message.data.data.studyGroupID;
        let group;
        let groupIndex;
        groupRef.current.forEach((g, index) => {
            if(g.studyGroupID === groupID){
                group = g;
                groupIndex = index;
            }
        })
        group.messages.push(message.data.data.message);
        groupRef.current[groupIndex] = group;
        setStudyGroups([...groupRef.current])
    }

    const updateGroup = (group) => {
        let index = studyGroups.findIndex((g) => {
            return g.studyGroupID === group.studyGroupID;
        })
        studyGroups[index] = group;
        setStudyGroups([...studyGroups]);
    }

    const leaveGroup = (groupID) => {
        let newGroups = studyGroups.filter((group) => {
            return group.studyGroupID !== groupID;
        });
        setStudyGroups(newGroups);
        setGroupSelected(0);
    }

    const handleSelectedGroupChat = (index) => {
        setGroupSelected(index);
    }

    const addMessageToGroup = (message) => {
        let group = studyGroups[groupSelected];
        group.messages.push(message);
        setStudyGroups([...studyGroups]);
    }

    const addFetchedMessages = (messages) => {
        let group = studyGroups[groupSelected];
        messages.forEach((message) => {
            group.messages.push(message.messages);
        })
        setStudyGroups([...studyGroups]);
    }
    
    return (
        <Fragment>
            <GroupUserList currentUser={props.currentUser} groups={studyGroups} groupSelected={groupSelected} handleSelectedGroupChat={handleSelectedGroupChat} />
            {studyGroups.length !== 0 && studyGroups[groupSelected].studyGroupID !== 1709259029266 && <StudyGroupChatField addFetchedMessages={addFetchedMessages} leaveGroup={leaveGroup} updateGroup={updateGroup} ably={props.ably} addMessageToGroup={addMessageToGroup} currentUser={props.currentUser} group={studyGroups[groupSelected]} />}
        </Fragment>
    )
}

export default StudyGroups;