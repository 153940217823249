import { React, useEffect, useState } from 'react'

const ScheduleSlide = (props) => {
    return (
        <div id="info-slide">
            <div id='tutoring-info-container'>
                <div id='business-info-area'>
                    <img src={props.scheduleImage}/>
                    <p id="tutoring-schedule-anchor">click <a href={props.schedulePDF} target="_blank">here</a> to see full semester schedule pdf</p>
                </div>
            </div>
        </div>
    )
}

export default ScheduleSlide;