const DepartmentSlide = (props) => {
  return (
    <div id="info-slide">
      <div id='department-info-container'>
        <div id='business-info-area'>
          {props.department.departmentDescription && <h4>{props.department.departmentDescription}</h4>}
          {props.department.departmentCollege && <p><strong>College:</strong> {props.department.departmentCollege}</p>}
          {props.department.departmentAddress && <p><strong>Address:</strong> {props.department.departmentAddress}</p>}
          {props.department.departmentPhone && <p><strong>Phone:</strong> {props.department.departmentPhone}</p>}
          {props.department.departmentEmail && <p><strong>Email:</strong> {props.department.departmentEmail}</p>}
          {props.department.departmentHours && <p><strong>Hours:</strong> {props.department.departmentHours}</p>}
        </div>
      </div>
    </div>
  )
}

export default DepartmentSlide