import { React, Fragment, useState } from "react";
import CreatePostInactive from "./organisms/posts/CreatePostInactive";
import CreatePostActive from "./organisms/posts/CreatePostActive";

const NewPost = (props) => {
   const [createPost, setCreatePost] = useState(false);

   return (
      <Fragment>
         { createPost ? (
               <CreatePostActive 
                  handlePostMade={props.handlePostMade} 
                  hubSelected={props.oceanSelected} 
                  setCreatePost={setCreatePost} 
                  userObject={{
                     userID: props.currentUser.userID, 
                     profileImage: props.currentUser.profileImage, 
                     fName: props.currentUser.fName, 
                     lName: props.currentUser.lName, 
                  }}
               />
         ) : (
               <CreatePostInactive 
                  userAvatar={props.currentUser.profileImage} 
                  setCreatePost={setCreatePost} 
                  hubSelected={props.oceanSelected} 
               />
         )}
      </Fragment>
   );
};

export default NewPost;
