import {React} from 'react'
import { useNavigate } from 'react-router-dom';
import Background from '../../assets/images/tu_graphite_card_background_169.png'

const SuggestedOceansListing = (props) => {
    let navigate = useNavigate()

    const handleOceanClick = () => {
        navigate("/OceansHome", {state: {currentUser: props.currentUser, oceanSelected: props.suggestedOcean.oceanName, ocean: props.suggestedOcean}});
    }

    return (
        <div onClick={handleOceanClick} id='suggested-oceans-listings-area' className='clickable make-grid'>
            <img src={(props.suggestedOcean.oceanBanner) ? props.suggestedOcean.oceanBanner : Background} className='background-img-contain container-border'/>
            <div id='suggested-oceans-stats-title'>
                <h3>{props.suggestedOcean.oceanName}</h3>
            </div>
            <p>{props.suggestedOcean.members} members | {props.suggestedOcean.posts} posts</p>
        </div>
    )
}

export default SuggestedOceansListing