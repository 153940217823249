import React, { Fragment } from 'react'
import UniversityStoreButton from './UniversityStoreButton'


// ! PROPS COMING FROM AcademicsTab.jsx: currentUser
const UniversityStoreField = () => {

const universityStoreButtons = [
    {
      btnTitle : "TU Store",
      btnLink : "https://towsonustore.com/home.aspx"
    },
    {
        btnTitle : "Course Materials",
        btnLink : "https://towsonustore.com/SiteText?id=20416"
    },
    {
        btnTitle : "Rentals",
        btnLink : "https://towsonustore.com/SiteText?id=30525"
    },
    {
        btnTitle : "Direct Access",
        btnLink : "https://towsonustore.com/directaccess"
    },
    {
        btnTitle : "Tiger Rewards",
        btnLink : "https://towsonustore.com/tigerrewards"
    },
    {
        btnTitle : "Clothing",
        btnLink : "https://towsonustore.com/SiteText?id=42572"
    },
    {
        btnTitle : "Gifts",
        btnLink : "https://towsonustore.com/SiteText?id=42574"
    }];


  return (
    <Fragment>
      <div id="store-orgs-field-container" className="container-border">
          {universityStoreButtons.map((button, index) => {
            return (
              <div id='club-org-listing-plus-info-container'>
                <Fragment key={index} >
                    <UniversityStoreButton title = {button.btnTitle} link = {button.btnLink} />
                </Fragment>
              </div>
            )
          })}
      </div>
    </Fragment>
  )
}

export default UniversityStoreField