import {React} from 'react'
import { useNavigate } from 'react-router-dom';

const ChildOceansListing = (props) => {
// Incoming props from ChildOceansList: 'currentUser', 'ocean'

    let navigate = useNavigate()

    const handleOceanClick = () => {
        navigate("/OceansHome", {state: {currentUser: props.currentUser, oceanSelected: props.ocean.oceanName, ocean: props.ocean}});
    }

    return (
        <div id='child-oceans-listings-area' className='make-grid'>
            <img onClick={handleOceanClick} src={props.ocean.oceanBanner} className='container-border'/>
            <div id='child-oceans-stats-title'>
                <h3>{props.ocean.oceanName}</h3>
            </div>
            <p>{props.ocean.members} members | {props.ocean.posts} posts</p>
        </div>
    )
}

export default ChildOceansListing