import React, { useState, useEffect, useContext } from 'react'
import { getElapsedTime } from '../../../assets/scripts/mchScripts'
import { AblyContext } from '../../../assets/custom-hooks/AblyContext'

const Message = (props) => {
    // Receiving a single user object

    const [userPic, setUserPic] = useState('')
    const [userName, setUserName] = useState('')
    const [numMessages, setNumMessages] = useState()

    const { reduceNumMessages } = useContext(AblyContext)

    useEffect(() => {
        let otherUser = props.conversation.participants.find((participant) => {
            return participant.userID !== props.currentUser.userID
        })
        setUserPic(otherUser.userImage)
        setUserName(otherUser.name)
        let currentUser = props.conversation.participants.find((participant) => {
            return participant.userID === props.currentUser.userID
        })
        setNumMessages(currentUser.missedMessages)
    }, [props.conversation.messages.length, props.conversation])

    const handleSetMessagesToZero = () => {
        props.conversation.participants.forEach((participant) => {
            if (participant.userID === props.currentUser.userID) {
                participant.missedMessages = 0
            }
        })
    }

    const getPreviewMessage = () => {
        return props.conversation.messages[props.conversation.messages.length - 1].userID === props.currentUser.userID ? (
            <><strong>You: </strong> {props.conversation.messages[props.conversation.messages.length - 1].messageContent} </>
        ) : (
            props.conversation.messages[props.conversation.messages.length - 1].messageContent
        )
    }

    return (
        <div className={numMessages > 0 || props.conversation.hasSeen == false ? 'message-area-unseen' : 'message-area'}>
            <div onClick={() => {
                reduceNumMessages(numMessages)
                props.handleSelectedChat(props.index);
                setNumMessages(0);
                props.conversation.hasSeen = true
                handleSetMessagesToZero()
            }} className="message-container make-grid">
                <img src={userPic} />
                <div className='message-text-area make-grid'>
                    <h2>{userName}</h2>
                    <span id='message-content'>{getPreviewMessage()}</span>
                </div>
                <div className='message-details make-grid'>
                    <p>{getElapsedTime(props.conversation.messages[props.conversation.messages.length - 1].date)}</p>
                    <div className='message-counter-container'>
                        { (numMessages > 0) ? (
                            <div className='message-counter'>
                                <p>{numMessages}</p>
                            </div> ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Message