import getGlobalURL from "../assets/scripts/Global";
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import axios from "axios";
import { storage } from "../firebase";
import { ref, deleteObject } from "firebase/storage"
import { AblyContext } from "../assets/custom-hooks/AblyContext";

const Post = (props) => {
  // This component renders a single post with a border around it.
  // Incoming props from PostsField: 'post', 'handleLikeFlag', 'handlePostTitleClick',
  // 'handlePostDelete', 'userID', 'closeModal', 'modalStatus', 'setLikeFlag'

  // ##################### URL change for Vercel##############################
  const url = getGlobalURL();
  if (url === "https://oceans-server-prod-v1.vercel.app") { axios.defaults.withCredentials = true; }
  // ##################### END OF URL change for Vercel#######################

  // If modalStatus === true, this post is rendering in a modal so the 'post title area'
  // onClick is bypassed to prevent a second modal from rendering.

  let modalStatus = props.modalStatus;
  let expandedModalStatus = props.expandedModalStatus;

  // let postContent = props.post.postContent.replace(/↵/g, "\n");

  let navigate = useNavigate()

  let newDate = new Date(props.post.timestamp).toString().split(" ").splice(0, 4).join(" ");

  //...
  const [likes, setLikes] = useState(props.post.likes);
  const [wasLiked, setWasLiked] = useState(props.post.hasLiked);
  const [commentModalStatus, setCommentModalStatus] = useState(false);

  const { ably } = useContext(AblyContext);

  useEffect(() => {
    setWasLiked(props.post.hasLiked);
    setLikes(props.post.likes);
  }, [props.post.hasLiked])

  const handleLiked = async () => {
    // ! this needs to be an await becuase other the axios call relies on the 'old' value of props.post.hasLiked
    await updateLikes(props.post.postID, props.source === 'displayUser' ? props.foreignUser.userID : props.currentUser.userID);
  }

  const updateLikes = async (postID, userID) => {
    // This function runs when the 'like' button is clicked. The updateLike route is triggered, which
    // updates the like count in the Database. Grandparent function handleLikeFlag is called to trigger
    // a new route call to pull an updated list of posts.

    axios.patch(`${url}posts/updateLike`, {
      userID: userID,
      ownerID: props.post.userPosterID,
      postID: postID,
      liking: props.post.hasLiked ? false : true
    }, {
      headers: {
        "Authorization": sessionStorage.getItem("token"),
      }
    }).then((response) => {
      if(props.post.hasLiked === false){
        userUpdate(`user-updates-${props.post.userPosterID}`, 'new-notification', '')
      }
      props.handleLikeFlag(likes, props.post.postID, props.post.hasLiked ? false : true);
    }).catch((error) => {
      alert(error)
    })
  }

  function userUpdate(channel, publish, data){
    let ablyChannel = ably.channels.get(channel);
    ablyChannel.publish(publish, { data: data }, () => {
    })
  }

  const updateClubLikes = (postID, userID) => {
    axios.patch(`${url}posts/updateLike/${postID}/${userID}`, {}, {
      headers: {
        "Authorization": sessionStorage.getItem("token"),
      }
    }).then((response) => {
      setLikes(likes + 1);
      props.handleLikeFlag(likes, postID, props.post.hasLiked ? false : true);
    }).catch((error) => {
      alert(error);
    })
  }

  const deletePost = (postID) => {
    if(props.post.ocean && !props.post.club){
      if (!props.post.postContentUrl) {
        /* delete requests dont need/have a data object */
        axios.delete(`${url}posts/delete/${postID}/${props.ocean}`, {
          data: {
            isOcean: true
          },
          headers: {
            "Authorization": sessionStorage.getItem("token"),
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          props.handlePostDelete();
        }).catch((error) => {
          alert(error.response.data.err);
        })
      } else {
        let deleteRef;
        if (props.post.contentIsVideo) {
          deleteRef = ref(storage, `postVideos/${postID}`);
        }
        else {
          deleteRef = ref(storage, `images/${postID}`);
        }
        deleteObject(deleteRef).then((response) => {
          axios.delete(`${url}posts/delete/${postID}/${props.ocean}`, {
            data: {
              isOcean: true
            },
            headers: {
              "Authorization": sessionStorage.getItem("token"),
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            props.handlePostDelete();
          }).catch((error) => {
            alert(error.response.data.err);
          })
        })
      }
    } else{
      if (!props.post.postContentUrl) {
        /* delete requests dont need/have a data object */
        axios.delete(`${url}posts/delete/${postID}/${JSON.stringify(props.club.clubName)}`, {
          data: {
            isOcean: false
          },
          headers: {
            "Authorization": sessionStorage.getItem("token"),
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          props.handlePostDelete();
        }).catch((error) => {
          alert(error.response.data.err);
        })
      }
      else {
        let deleteRef;
        if (props.post.contentIsVideo) {
          deleteRef = ref(storage, `postVideos/${postID}`);
        }
        else {
          deleteRef = ref(storage, `images/${postID}`);
        }
        deleteObject(deleteRef).then((response) => {
          axios.delete(`${url}posts/delete/${postID}/${props.ocean}`, {
            data: {
              isOcean: false
            },
            headers: {
              "Authorization": sessionStorage.getItem("token"),
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            props.handlePostDelete();
          }).catch((error) => {
            alert(error.response.data.err);
          })
        })
      }
    }
  }

  const handleAvatarClick = () => {
    // This function....
    var foreignUser = {};

    if (props.currentUser.userID === props.post.userPosterID) {
      navigate("/Profile", { state: { currentUser: props.currentUser } })
    } else {
      axios.post(`${url}users/getSingleUser`, {
        userID: props.post.userPosterID
      }, {
        headers: {
          "Authorization": sessionStorage.getItem("token"),
        }
      }).then((response) => {
        foreignUser = response.data[0];

        navigate("/DisplayUser", { state: { foreignUser: foreignUser, currentUser: props.currentUser } })
      })
    }
  }

  const handleCommentBtnClick = () => {
    setCommentModalStatus(true);
  }

  const profilePost = () => {
    return (
      props.post.userPosterID === props.currentUser.userID ? (
        <div id="post-container" className="container-border scroll-hide">
          <div id="post-title-container" className="make-grid">
            <img onClick={handleAvatarClick} className="post-image" src={props.post.profileImage} />
            <h3>{props.post.name}</h3>
            <p>{newDate}</p>
          </div>
          <div className="post-content clickable scrollbar-mch-thin" onClick={() => { !modalStatus && props.handlePostTitleClick(props.post.postID) }}>
            {(modalStatus !== undefined) &&
              (
                <Fragment>
                  <h3>{props.post.postSubject}</h3>
                  {props.post.postContentUrl != null ? (
                    props.post.contentIsVideo == false ? (
                      <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                    ) : (<ReactPlayer width='100%' height='35%' url={props.post.postContentUrl} onClickPreview />)
                  ) : null}
                </Fragment>
              )}
            {(expandedModalStatus !== undefined) &&
              (
                <Fragment>
                  {props.post.postContentUrl != null ? (
                    props.post.contentIsVideo == false ? (
                      <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                    ) : (<video src={props.post.postContentUrl} controls />)
                  ) : ""}
                  <p style={{ 'whiteSpace': 'pre-wrap' }}>{props.post.postContent}</p>
                </Fragment>
              )}
          </div>
          <div className="insights-container">
            <button className={wasLiked ? 'insights-btn-liked btn-ease' : 'insights-btn btn-ease'} onClick={() => { handleLiked() }}>Like {likes}</button>
            {/* start of ternary operator */}
            {props.currentUser.userID === props.post.userPosterID ? (
              <button className="insights-btn btn-ease" onClick={() => {
                deletePost(props.post.postID);
              }}>Delete</button>
            ) : (null)}
            { /* end of ternary operator */}
          </div>
        </div>
      ) : null
    )
  }

  const dashboardPost = () => {
    return (
      (props.source == "dashboard") ? (
        <div id="post-container" className="container-border scroll-hide">
          <div id="post-title-container" className="make-grid">
            <img onClick={handleAvatarClick} className="post-image" src={props.post.profileImage} />
            <h3>{props.post.name}</h3>
            <p>{newDate}</p>
          </div>
          <div className="post-content clickable scrollbar-mch-thin" onClick={() => { !modalStatus && props.handlePostTitleClick(props.post.postID) }}>
            {(modalStatus !== undefined) &&
              (
                <Fragment>
                  <h3>{props.post.postSubject}</h3>
                  {props.post.postContentUrl ? (
                    props.post.contentIsVideo == false ? (
                      <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                    ) : (<ReactPlayer width='100%' height='35%' playIcon={<button>Play</button>} light="" url={props.post.postContentUrl} onClickPreview />)
                  ) : null}
                </Fragment>
              )}
            {(expandedModalStatus !== undefined) &&
              (
                <Fragment>
                  {props.post.postContentUrl ? (
                    props.post.contentIsVideo == false ? (
                      <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                    ) : (<video src={props.post.postContentUrl} controls />)
                  ) : ""}
                  <p style={{ 'whiteSpace': 'pre-wrap' }}>{props.post.postContent}</p>
                </Fragment>
              )}
          </div>
          <div className="insights-container">
            <button className={wasLiked ? 'insights-btn-liked btn-ease' : 'insights-btn btn-ease'} onClick={() => { handleLiked() }}>Like {likes}</button>

            {/* start of ternary operator */}
            {props.currentUser.userID === props.post.userPosterID ? (
              <button className="insights-btn btn-ease" onClick={() => {
                deletePost(props.post.postID);
              }}>Delete</button>
            ) : (null)}
            { /* end of ternary operator */}
          </div>
        </div>
      ) : null
    )
  }

  const displayUserPost = () => {
    return (
      props.post.userPosterID === props.currentUser.userID ? (
        <div id="post-container" className="container-border scroll-hide">
          <div id="post-title-container" className="make-grid">
            <img className="post-image" src={props.post.profileImage} />
            <h3>{props.post.name}</h3>
            <p>{newDate}</p>
          </div>
          <div className="post-content clickable scrollbar-mch-thin" onClick={() => { !modalStatus && props.handlePostTitleClick(props.post.postID) }}>
            {(modalStatus !== undefined) &&
              (
                <Fragment>
                  <h3>{props.post.postSubject}</h3>
                  {props.post.postContentUrl != null ? (
                    props.post.contentIsVideo == false ? (
                      <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                    ) : (<ReactPlayer width='100%' height='35%' url={props.post.postContentUrl} onClickPreview />)
                  ) : null}
                </Fragment>
              )}
            {(expandedModalStatus !== undefined) &&
              (
                <Fragment>
                  {props.post.postContentUrl != null ? (
                    props.post.contentIsVideo == false ? (
                      <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                    ) : (<video src={props.post.postContentUrl} controls />)
                  ) : ""}
                  <p style={{ 'whiteSpace': 'pre-wrap' }}>{props.post.postContent}</p>
                </Fragment>
              )}
          </div>
          <div className="insights-container">
            <button className={wasLiked ? 'insights-btn-liked btn-ease' : 'insights-btn btn-ease'} onClick={() => { handleLiked() }}>Like {likes}</button>
          </div>
        </div>
      ) : (null)
    )
  }

  function displayClubName(userPosterID, name){
    if(props.club.admins.includes(userPosterID)){
      return (
        <h3>🛡️ {name}</h3>
      )
    }
    else if(props.club.officers.includes(userPosterID)){
      return (
        <h3>👮 {name}</h3>
      )
    }
    else{
      return (
        <h3>{name}</h3>
      )
    }
  }

  const clubHomePost = () => {
    return (
      <div id="post-container" className="container-border scroll-hide">
        <div id="post-title-container" className="make-grid">
          <img onClick={handleAvatarClick} className="post-image" src={props.post.profileImage} />
          {displayClubName(props.post.userPosterID, props.post.name)}
          <p>{newDate}</p>
        </div>
        <div className="post-content clickable scrollbar-mch-thin" onClick={() => { !modalStatus && props.handlePostTitleClick(props.post.postID) }}>
          {(modalStatus !== undefined) &&
            (
              <Fragment>
                <h3>{props.post.postSubject}</h3>
                {props.post.postContentUrl ? (
                  props.post.contentIsVideo == false ? (
                    <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                  ) : (<ReactPlayer width='100%' height='35%' playIcon={<button>Play</button>} light="" url={props.post.postContentUrl} onClickPreview />)
                ) : null}
              </Fragment>
            )}
          {(expandedModalStatus !== undefined) &&
            (
              <Fragment>
                {props.post.postContentUrl ? (
                  props.post.contentIsVideo == false ? (
                    <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                  ) : (<video src={props.post.postContentUrl} controls />)
                ) : ""}
                <p style={{ 'whiteSpace': 'pre-wrap' }}>{props.post.postContent}</p>
              </Fragment>
            )}
        </div>
        <div className="insights-container">
          <button className={wasLiked ? 'insights-btn-liked btn-ease' : 'insights-btn btn-ease'} onClick={() => { handleLiked() }}>Like {likes}</button>
          {/* start of ternary operator */}
          {props.currentUser.userID === props.post.userPosterID ? (
            <button className="insights-btn btn-ease" onClick={() => {
              deletePost(props.post.postID);
            }}>Delete</button>
          ) : (null)}
          { /* end of ternary operator */}
        </div>
      </div>
    )
  }

  const clubBanAppeal = () => {
    return (
      <div id="post-container" className="container-border scroll-hide">
        <div id="post-title-container" className="make-grid">
          <img className="post-image" src={props.user.profileImage} />
          <h3>{props.user.fName} {props.user.lName}</h3>
          <p>{newDate}</p>
        </div>
        <div className="post-content scrollbar-mch-thin" >
            <Fragment>
              <h3>{props.post.postSubject}</h3>
              <p>{props.post.postContent}</p>
              {props.post.postContentUrl && <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />}
            </Fragment>
          </div>
      </div>
    )
  }

  const expandNotificationModal = () => {
    return (
      <div id='post-container' className='container-border scroll-hide' >
        <div id='post-title-container' className='make-grid' >
          <img className='post-image' src={props.currentUser.profileImage} />
          <h3>{props.currentUser.fName + ' ' + props.currentUser.lName}</h3>
          <p>{newDate}</p>
        </div>
        <div className='post-content clickable scrollbar-mch-thin' onClick={() => { !modalStatus && props.handlePostTitleClick(props.post.postID) }} >
          <Fragment>
            {props.post.postContentUrl ? (
              props.post.contentIsVideo === false ? (
                <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
              ) : <video src={props.post.postContentUrl} controls />
            ) : null}
            <p style={{ 'whiteSpace': 'pre-wrap' }}>{props.post.postContent}</p>
          </Fragment>
        </div>
        <div className='insights-container' >
          <button className='insights-btn btn-ease' onClick={() => { deletePost(props.post.postID) }} >Delete</button>
        </div>
      </div>
    )
  }

  const otherSourcePost = () => {
    // Needed in props to move to its own component: props.post, props.handlePostTitleClick, modalStatus, expandedModalStatus, updateLikes, deletePost
    return (
      <div id="post-container" className="container-border scroll-hide">
        <div id="post-title-container" className="make-grid">
          <img onClick={handleAvatarClick} className="post-image" src={props.post.profileImage} />
          <h3>{props.post.name}</h3>
          <p>{newDate}</p>
        </div>
        <div className="post-content clickable scrollbar-mch-thin" onClick={() => { !modalStatus && props.handlePostTitleClick(props.post.postID) }}>
          {(modalStatus !== undefined) &&
            (
              <Fragment>
                <h3>{props.post.postSubject}</h3>
                {props.post.postContentUrl != null ? (
                  props.post.contentIsVideo == false ? (
                    <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                  ) : (<ReactPlayer width='100%' height='35%' url={props.post.postContentUrl} onClickPreview />)
                ) : null}
              </Fragment>
            )}
          {(expandedModalStatus !== undefined) &&
            (
              <Fragment>
                {props.post.postContentUrl != null ? (
                  props.post.contentIsVideo == false ? (
                    <img loading="lazy" className="post-thumbnail" src={props.post.postContentUrl} />
                  ) : (<video src={props.post.postContentUrl} controls />)
                ) : ""}
                <p style={{ 'whiteSpace': 'pre-wrap' }}>{props.post.postContent}</p>
              </Fragment>
            )}
        </div>
        <div className="insights-container">
          <button className={wasLiked ? 'insights-btn-liked btn-ease' : 'insights-btn btn-ease'} onClick={() => { handleLiked() }}>Like {likes}</button>
          
          {props.currentUser.userID === props.post.userPosterID ? (
            <button className="insights-btn btn-ease" onClick={() => {
              deletePost(props.post.postID);
            }}>Delete</button>
          ) : (null)}
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {
        props.source === "profile" ? (profilePost()) :
        props.source === "dashboard" ? (dashboardPost()) :
        props.source === "displayUser" ? (displayUserPost()) :
        props.source === "clubsHome" ? (clubHomePost()) :
        props.source === "clubBanAppeal" ? (clubBanAppeal()) :
        props.source === 'expandNotificationModal' ? expandNotificationModal() :
        otherSourcePost()
      }
    </Fragment>
  )
}

export default Post