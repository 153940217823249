import React, { useState, useEffect, Fragment, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../assets/stylesheets/oceansStyles.css";
import getGlobalURL from "../assets/scripts/Global";
import CreateAccountModal from "../Modals/accountModals/CreateAccountModal";
import ResetPasswordModal from "../Modals/accountModals/ResetPasswordModal";
import Tile from "../components/Tile";
import PuffLoader from "react-spinners/PuffLoader";
import MyCampusHubsLogo from "../components/logo/MyCampusHubsLogo";
import Footer from "../components/Footer";
import {isMobile} from "react-device-detect"
import CreateUserAccount from "../Modals/forms/CreateUserAccount";
import TestimonialsParent from "../components/testimonials/TestimonialsParent";
import { AblyContext } from "../assets/custom-hooks/AblyContext";
import DeveloperMobileWelcome from "../components/Mobile/DeveloperMobileWelcome";

const Login = () => {

  // ##################### URL change for Vercel##############################
  const url = getGlobalURL();

  const { initializeAbly, fetchNumMessages } = useContext(AblyContext);

  if(url === "https://syncedup-backend.vercel.app/") {
    axios.defaults.withCredentials = true;
  }
  // ##################### END OF URL change for Vercel#######################

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Initial call for available oceans list stores the full list in this state variable.
  const [oceansList, setOceansList] = useState([]);

  // When user clicks 'Create Account' button, this state variable will be used to call the modal
  const [modalStatus, setModalStatus] = useState(false);

  const [setPasswordModalStatus, setResetPasswordModalStatus] = useState(false);

  //This hook is used once the user logs in. They will be directed to the Dashboard upon successful login
  let navigate = useNavigate();

  // Let's grab the list of all Oceans at the very beginning. The can be used for new users to search through oceans without creating account
  // The list will also be passed to the CreateAccountModal for the user to be able to select from available oceans when creating account
  useEffect(() => {
    axios.get(`${url}oceans`).then((response) => {
      setOceansList(response.data);
    }).catch((error) => {
      console.log('error getting oceans')
    })
  }, [url]);

  // The userLogin function is called when the user clicks 'Log In' button
  const userLogin = () => {
    axios.post(`${url}users/login`, {
      username,
      password,
    }).then(async (response) => {
      if(response.status === 200){
        if(response.data.user.username !== undefined){
          let currentUser = response.data.user;
          sessionStorage.setItem('userID', currentUser.userID);
          await initializeAbly(currentUser.userID, currentUser)
          await fetchNumMessages(currentUser.userID, response.data.token)
          sessionStorage.setItem('token', response.data.token);
          navigate("/Dashboard", { state: { currentUser: currentUser, activeTab: "Dashboard" } })
        }
        else{
          alert("Invalid username or password");
        }
      }
      else{
        alert('Error logging in')
      }
    }).catch((error) => {
      // alert('error logging in')
      alert(error)
      if(error){
        if(error.response.status === 401){
          alert('Invalid username or password')
        }
        else if(error.response.status === 404){
          alert('User not found')
        }
      }
      else{
        alert('Error logging in')
      }
    })
  }

  const handleCreateUserClick = () => {
    // Setting modalStatus to true will prevent the short circuiting in the return and allows CreateAccountModal component to render
    setModalStatus(true);
  }

  const handleForgotPasswordClick = () => {
    setResetPasswordModalStatus(true);
  }

  const handleClosePasswordModal = () => {
    setResetPasswordModalStatus(false);
  }

  // This function is called from within CreateAccountModal component
  const closeModal = () => {
    setModalStatus(false);
  }

    if (isMobile) {
    return(
        <Fragment>
          <DeveloperMobileWelcome />
        </Fragment>
    )
  }

  return (
    <Fragment>
      
      { (modalStatus) ? <CreateUserAccount oceansList={oceansList} closeModal={closeModal}/> : (
        //{/* {modalStatus && <CreateAccountModal oceansList={oceansList} closeModal={closeModal}/>} */}
        // {setPasswordModalStatus && <ResetPasswordModal closeModal={handleClosePasswordModal}/>}
        <div id="main-login-container">
          <div id="tiles-area"  className=''>
            <TestimonialsParent />
          </div>
          <div id="login-section">
            <div id="login-container" className="container-border">
                <div id="login-area">
                    <MyCampusHubsLogo />
                    <h3>Where Campus Life Comes Alive</h3>
                    <input id="username" type="text" required="required" placeholder="Username" onChange={(event) => {
                        setUsername(event.target.value)
                    }} />
                    <input id="password" type="password" required="required" placeholder="Password" onChange={(event) => {
                        setPassword(event.target.value)
                    }} onKeyDown={
                        (event) => {
                            if (event.key === "Enter") {
                                userLogin();
                            }
                        }
                    }/>
                    <div className="buttonCenter">
                        <button className="signInButtons" onClick={() => { userLogin(); }}>Log In</button>
                    </div>
                    <a id="forgotPassword" onClick={handleForgotPasswordClick} >Forgot Password?</a>
                </div>
                <hr id="line" />
                <div className="buttonCenter">
                    <button className="signInButtons" onClick={handleCreateUserClick}>Create Account</button>
                </div>
            </div>
          </div>
        </div>
      )}
      <Footer currentUser={null}/>
    </Fragment>
    );

};

export default Login;

{/* <Fragment>
        {modalStatus && <CreateUserAccount oceansList={oceansList} closeModal={closeModal}/>}
        {setPasswordModalStatus && <ResetPasswordModal closeModal={handleClosePasswordModal}/>}
        <div id="main-login-container">
          <div id="login-section" style={{width:"24rem", paddingRight:0}}>
            <div id="login-container" className="container-border">
                <div id="login-area">
                    <MyCampusHubsLogo />
                    <h3>Where Campus Life Comes Alive</h3>
                    <input id="username" type="text" required="required" placeholder="Username" onChange={(event) => {
                        setUsername(event.target.value)
                    }} />
                    <input id="password" type="password" required="required" placeholder="Password" onChange={(event) => {
                        setPassword(event.target.value)
                    }} onKeyDown={
                        (event) => {
                            if (event.key === "Enter") {
                                userLogin();
                            }
                        }
                    }/>
                    <div className="buttonCenter">
                        <button className="signInButtons" onClick={() => { userLogin(); }}>Log In</button>
                    </div>
                    <a id="forgotPassword" onClick={handleForgotPasswordClick} >Forgot Password?</a>
                </div>
                <hr id="line" />
                <div className="buttonCenter">
                    <button className="signInButtons" onClick={handleCreateUserClick}>Create Account</button>
                </div>
            </div>
          </div>
        </div>
        <Footer currentUser={null}/>
      </Fragment> */}