import React from 'react'

/**
 * @description - This component renders an icon with a label below it. The icon component is passed in as a prop.
 * 
 * @component
 * @param {string} props.label - A url string used to fetch the foreign user's avatar.
 * @param {function} props.handleClick - A function that is called when the icon is clicked.
 * @param {JSX.Element} props.icon - The icon component to be displayed.
 * @returns {JSX.Element} - A react element that renders a clickable icon with a label below it.
 * 
 * @example
 * // Render a left-aligned message in a private chat.
 * <IconWithLabel 
 *  handleClick={handleRemoveMod} 
 *  icon={<ModTrue width="25" height="25" color="var(--primary-color)" />} 
 *  label="-Mod" 
 * />
 */

const IconWithLabel = (props) => {
    return (
        <div onClick={props.handleClick} className='icon-with-label clickable'>
            {props.icon}
            <p>{props.label}</p>
        </div>
    )
}

export default IconWithLabel