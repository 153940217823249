import { React } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FoundBadToken } from '../../scripts/badToken';
import getGlobalURL from '../../assets/scripts/Global';

const SuggestedFriendsListing = (props) => {
    let navigate = useNavigate()
    const url = getGlobalURL();

    const handleUserClick = () => {
        axios.get(`${url}users/getUserByUsername/${props.friend.username}/${props.currentUser.userID}`, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            },
        }).then((response) => {
            navigate("/DisplayUser", {state: {currentUser: props.currentUser, foreignUser: response.data}});
        }).catch((error) => {
            if(error.response.status === 401){
                FoundBadToken();
            }
            else{
                alert(error)
            }
        })
    }

    return (
        <div onClick={handleUserClick} id='suggested-friends-listings-area' className='clickable make-grid'>
            <img src={props.friend.profileImage}/>
            <div id='suggested-friends-stats-title'>
                <h3>{props.friend.fName} {props.friend.lName}</h3>
            </div>
            <p>{props.friend.followers} Followers</p>
        </div>
    )
}

export default SuggestedFriendsListing