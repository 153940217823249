const OffCampusJobs = (props) => {

  const description = "As a student you have access to various on and off-campus opportunities. It is the Career Center’s hope that a student job will be more than the path to a paycheck but rather a learning experience to help you become more organized, accept greater job responsibilities and develop transferable job skills that can apply to future career positions."

  const offCampus = "Learn more about how to locate and apply for part-time, temporary and seasonal off-campus positions."

  const tempDescription = "Welcome to our future Off-Campus Jobs section! We're excited to develop a resource that will connect you to valuable information about where to find jobs, internships, and career opportunities off-campus. In the meantime...."
  const moreInfo = "If you need an off-campus part-time or full-time job to help pay the bills while you’re in school, the Career Center can help with that. In addition to earning income, an off-campus job can help you build your resume, develop and strengthen your skills, and provide you with opportunities to gain valuable professional experience."

  const bulletPoints = [
    {
      title: "Browse Hundres of positions in Handshake",
      link: "https://www.towson.edu/careercenter/hire/studentfaq.html"
    },
    {
      title: "Resources to prepare your job search",
      link: "https://www.towson.edu/careercenter/students/searchskills/"
    },
    {
      title: "Resources for connecting with employers",
      link: "https://www.towson.edu/careercenter/students/jobs/"
    },
    {
      title: "Learn about work permits",
      link: "https://www.towson.edu/careercenter/students/employment/oncampus/requirements.html"
    }
  ]

  return (
    <div className="content-placeholder-container container-border">
        <img className="header-img background-img-contain" src="https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/oceanBanners%2FJobs?alt=media&token=911d96a2-b107-4884-acae-6d2d90cbe47d"/>
        <div className="content-placeholder-text-area">
            <h2 className='highlight'>Off Campus Jobs</h2>
            <p>{tempDescription}</p>
            <p>{moreInfo} Click <a href="https://www.towson.edu/careercenter/students/employment/offcampus.html" target="_blank" rel="noopener noreferrer" >here</a> to learn more</p>
          <ul>
            {bulletPoints.map((point, index) => {
              return (
                <li key={index}>
                  <a href={point.link} target="_blank" rel="noreferrer">{point.title}</a>
                </li>
              )
            })}
          </ul>
        </div>
    </div>
  )
}

export default OffCampusJobs

//https://firebasestorage.googleapis.com/v0/b/oceans-file-upload.appspot.com/o/oceanBanners%2FJobs?alt=media&token=911d96a2-b107-4884-acae-6d2d90cbe47d