import React from 'react'

const AdministrationIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        fill="none"
        viewBox="0 0 15 15"
        {...props}
    >
        <path fill={props.color} d="M7.5 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z" />
        <path
        fill={props.color}
        fillRule="evenodd"
        d="m12 6.191-4-2V3h3V0H7v4.191l-4 2V8H1v6H0v1h6v-4h3v4h6v-1h-1V8h-2V6.191ZM13 14V9h-1v5h1ZM3 14H2V9h1v5Zm3-5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
        clipRule="evenodd"
        />
        <path fill={props.color} d="M8 15v-3H7v3h1Z" />
    </svg>
)

export default AdministrationIcon