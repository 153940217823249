const creatorsData = [
    {
        id: 1,
        fName: "Caleb",
        lName: "Blomquist",
        username: "caleb",
        imgName: "caleb.jpg",
        gitHub: "https://github.com/c-blomquist",
        linkedIn: "https://www.linkedin.com/in/caleb-blomquist",
        resume: "caleb_blomquist_resume.pdf",
        email: "cblomq1@students.towson.edu"
    },
    {
        id: 2,
        fName: "Matthew",
        lName: "Dibbern",
        username: "matt",
        imgName: "matthew.jpg",
        gitHub: "https://github.com/mdibbern1",
        linkedIn: "https://www.linkedin.com/in/matt-dibbern-ab9045299/",
        resume: "matthew_dibbern_resume.pdf",
        email: "mdibbern14@gmail.com"
    },
    {
        id: 3,
        fName: "William",
        lName: "Duckworth III",
        username: "",
        imgName: "william.jpg",
        gitHub: "[link here]",
        linkedIn: "[link here]",
        resume: "william_duckworth_resume.pdf",
        email: ""
    },
    {
        id: 4,
        fName: "Jake",
        lName: "Furtaw",
        username: "",
        imgName: "jake.jpg",
        gitHub: "[link here]",
        linkedIn: "[link here]",
        resume: "jake_furtaw_resume.pdf",
        email: ""
    },
    {
        id: 5,
        fName: "Ryan",
        lName: "Kraft",
        username: "",
        imgName: "ryan.jpg",
        gitHub: "[link here]",
        linkedIn: "[link here]",
        resume: "ryan_kraft_resume.pdf",
        email: ""
    },
    {
        id: 6,
        fName: "Andres",
        lName: "Londoño",
        username: "alondo1",
        imgName: "andres.jpg",
        gitHub: "https://github.com/Packetouille",
        linkedIn: "https://www.linkedin.com/in/aflondono/",
        resume: "andres_londono_resume.pdf",
        email: "alondo1@students.towson.edu"
    }
];

export default creatorsData;