import { React, Fragment, useState, useEffect } from 'react'
import ManageMembersModal from './Modals/ManageClubMembers';
import CreateClubEvent from "../ClubCalendars/CreateClubEvent";
import EditClubModal from './Modals/EditClubModal';
import ManageBanAppeals from './Modals/ManageBanAppeals';

const ClubAdminControls = (props) => {
    const [editClubModalStatus, setEditClubModalStatus] = useState(false);
    const [manageMembersModalStatus, setManageMembersModalStatus] = useState(false);
    const [createEventModalStatus, setCreateEventModalStatus] = useState(false);
    const [banAppealModalStatus, setBanAppealModalStatus] = useState(false);
    
    const handleModalRequestClick = (source) => {
        source === "manageMembers" ? setManageMembersModalStatus(true)
            : source === "createEvent" ? setCreateEventModalStatus(true)
            : source === 'editClub' ? setEditClubModalStatus(true)
            : source === 'banAppeals' ? setBanAppealModalStatus(true) : console.log('')
    }

    const closeModal = (source) => {
        source === "manageMembers" ? setManageMembersModalStatus(false)
            : source === "createEvent" ? setCreateEventModalStatus(false)
            : source === 'editClub' ? setEditClubModalStatus(false)
            : source === 'banAppeals' ? setBanAppealModalStatus(false) : console.log('');
    }

    return (
        <Fragment>
            {manageMembersModalStatus && <ManageMembersModal ably={props.ably} handleClubEdit={props.handleClubEdit} currentUser={props.currentUser} club={props.club} closeModal={closeModal} />}
            {createEventModalStatus && <CreateClubEvent currentUser={props.currentUser} handleEventMade={props.handleEventMade} club={props.club.clubName} closeModal={closeModal} />}
            {editClubModalStatus && <EditClubModal currentUser={props.currentUser} handleClubEdit={props.handleClubEdit} club={props.club} closeModal={closeModal} />}
            {banAppealModalStatus && <ManageBanAppeals currentUser={props.currentUser} club={props.club} closeModal={closeModal} />}
            <div id="oceans-list-banner-container">
                <div id='oceans-list-banner-title-container'>
                    <div className="oceans-list-banner-title-tab tab-active">Admin</div>
                </div>
                <div id="oceans-list-banner-links-container" className="container-border">
                    <div id='controls-container'>
                        <button onClick={() => { handleModalRequestClick("manageMembers") }} className='oceans-btn btn-ease'>Manage Members</button>
                        <button onClick={() => { handleModalRequestClick("createEvent") }} className='oceans-btn btn-ease'>Create Event</button>
                        <button onClick={() => { handleModalRequestClick("editClub") }} className='oceans-btn btn-ease'>Edit Club</button>
                        <button onClick={() => { handleModalRequestClick("banAppeals") }} className='oceans-btn btn-ease' >Ban Appeals</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ClubAdminControls