import React from 'react';
import GeneralButton from '../atoms/buttons/GeneralButton';
import DefaultBanner from '../../assets/images/library_banner.jpg';

const LibraryField = (props) => {

  const buttons = [
    {
      title: 'Databases',
      link: 'https://towson.libguides.com/az.php'
    },
    {
      title: 'TU Library Catalog',
      link: 'https://catalog.umd.edu/F/?func=file&file%5Fname=find-b&local%5Fbase=tu&pds_handle=GUEST'
    },
    {
      title: 'Research Guides',
      link: 'https://towson.libguides.com/'
    },
    {
      title: 'Citing Sources',
      link: 'https://libraries.towson.edu/get-help/citing-sources'
    }
  ]

  const desc = 'Access scholarly articles, books, journals, and more with ease, empowering your research endeavors. Streamline your quest for knowledge within our comprehensive digital repository.';

  return (
    <div className="content-placeholder-container container-border">
      <img className="header-img background-img-contain" src={DefaultBanner}/>
      <div className="content-placeholder-text-area">
          <h2 className='highlight'>Albert S. Cook Library</h2>
          <div id='library-text-field' >
            <p>{desc}</p>
          </div>
          <div id='library-buttons-container' >
            {buttons.map((button, index) => {
            return (
              <GeneralButton key={index} title={button.title} link={button.link} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default LibraryField;