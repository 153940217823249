import {React, useState, Fragment} from 'react'
import EditInfoModal from '../Modals/accountModals/EditInfoModal';

const PersonalInfo = (props) => {
    const [modalStatus, setModalStatus] = useState(false);

    const handleEditIconClick = () => {
        modalStatus ? setModalStatus(false) : setModalStatus(true)
    }

    const closeModal = () => {
        setModalStatus(false);
    }

    return (
        <Fragment>
            {modalStatus && <EditInfoModal currentUser={props.currentUser} closeModal={closeModal} />}
            <fieldset>
                <table id='basic-info-table'>
                        <caption>
                                Basic Info
                                <img onClick={handleEditIconClick} className='edit-icon btn-ease' src={require('../assets/images/icons/edit.png')} alt='Edit icon'/>   
                        </caption>
                    <thead>
                        <tr>
                            <td className='row row-key'>First Name</td>
                            <td className='row row-value'>{`${props.currentUser.fName}`}</td>
                        </tr>
                        <tr>
                            <td className='row row-key'>Last Name</td>
                            <td className='row row-value'>{`${props.currentUser.lName}`}</td>
                        </tr>
                        <tr>
                            <td className='row row-key'>City</td>
                            <td className='row row-value'>{`${props.currentUser.city}`}</td>
                        </tr>
                        <tr>
                            <td className='row row-key'>State</td>
                            <td className='row row-value'>{`${props.currentUser.state}`}</td>
                        </tr>
                        <tr>
                            <td className='row row-key'>Username</td>
                            <td className='row row-value'>{`${props.currentUser.username}`}</td>
                        </tr>
                        <tr>
                            <td className='row row-key'>Email</td>
                            <td className='row row-value'>{`${props.currentUser.email}`}</td>
                        </tr>
                        <tr>
                            <td className='row row-key'>Bio</td>
                            <td className='row row-value'>{`${props.currentUser.bio}`}</td>
                        </tr>
                        <tr>
                            <td className='row row-key'>Profile Pic</td>
                            <td className='row row-value'><img src={`${props.currentUser.profileImage}`} width='50' height='50'/></td>
                        </tr>
                        <tr>
                            <td className='row row-key'>Profile Banner</td>
                            <td className='row row-value'><img src={`${props.currentUser.bannerImage}`} width='150' height='50'/></td>
                        </tr>
                    </thead>
                </table>                        
            </fieldset>
        </Fragment>
    )
}

export default PersonalInfo