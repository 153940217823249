import { React, useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import getGlobalURL from "../../../assets/scripts/Global";
import ChannelMessage from "../classroomComponents/ChannelMessage";
import { FoundBadToken } from "../../../scripts/badToken";

// ! PROPS FROM Dashboard.jsx: currentUser, course, ably, channelSelected, message
const MessageExpandModal = (props) => {
   const url = getGlobalURL();

   const [replies, setReplies] = useState([]);
   const [reply, setReply] = useState("");

   const channel = useRef(null);

   useEffect(() => {
      axios.post(`${url}channels/getMessageReplies`, {
            courseCode: props.course.courseCode,
            channelName: props.channelSelected,
            messageID: props.message.messageID,
         },
         {
            headers: {
               Authorization: sessionStorage.getItem("token"),
            },
         }
      ).then((response) => {
         if (response.status === 200) {
            console.log(response.data.replies);
            setReplies(response.data.replies);
         }
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(error);
         }
      });
   }, []);

   useEffect(() => {
      channel.current = props.ably.current.channels.get(
         `${props.course.courseCode}-${props.channelSelected}-${props.message.messageID}`
      );
      channel.current.subscribe("receive-reply", (reply) => {
         setReplies([...replies, reply.data]);
      });

      return () => {
         channel.current.unsubscribe();
         channel.current = null;
      };
   }, []);

   const handleBackdropClick = () => {
      props.handleHideMessageModal();
   };

   const sendReply = () => {
      if (reply === "") {
         alert("Please enter a reply");
         return;
      } else {
         let newReply = {
            reply: reply,
            senderName: props.currentUser.fName + " " + props.currentUser.lName,
            senderPicture: props.currentUser.profileImage,
            timestamp: Date.now(),
            replyID: Date.now(),
            senderID: props.currentUser.userID,
         };
         
         axios.post(`${url}channels/makeReply`, {
               courseCode: props.course.courseCode,
               channelName: props.channelSelected,
               messageID: props.message.messageID,
               reply: newReply,
            },
            {
               headers: {
                  Authorization: sessionStorage.getItem("token"),
               },
            }
         ).then((response) => {
            if (response.status === 200) {
               setReply("");
               alert("Reply sent");
            }
         }).catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
               FoundBadToken();
            } else {
               alert(error);
            }
         });
      }
   };

   return (
      <div className="modal-container">
         <div className="modal-backdrop" onClick={handleBackdropClick} />
         <div className="modal-thread-container container-border">
            <ChannelMessage
               channelSelected={props.channelSelected}
               course={props.course}
               currentUser={props.currentUser}
               message={props.message}
            />
            <div id="modal-post-container" className="container-border make grid">
               <div id="create-comment-container" className="comment-content">
                  <div id="comment-input-field-container" className="make-grid">
                     <img className="user-comment-img" src={props.currentUser.profileImage}/>
                     <textarea className="make-grid" placeholder="Comment here" onChange={(event) => setReply(event.target.value)}></textarea>
                     <div className="comment-icons-container make-grid">
                        <img onClick={sendReply} id="comment-send-icon" className="comment-icons" src={require("../../../assets/images/icons/send.png")} />
                     </div>
                  </div>
               </div>

               {replies && replies.map((reply) => {
                  return (
                     <Fragment>
                        <div id="comment-title-container" className="make-grid">
                           <img className="comment-avatar" src={reply.senderPicture} />
                           <h3>{reply.senderName}</h3>
                        </div>
                        <div className="comment-content">
                           <p>{reply.reply}</p>
                           {reply.image && (
                              <img loading="lazy" className="post-thumbnail" src={reply.image} />
                           )}
                        </div>
                     </Fragment>
                  );
               })}
            </div>
         </div>
      </div>
   );
};

export default MessageExpandModal;
