import React from 'react'

/**
 * @description - This component renders a right-aligned message component in a private chat. Used in the message application interface.
 * 
 * @component
 * @param {string} props.userImage - A url string used to fetch the user's avatar.
 * @param {boolean} props.imageFlag - A boolean flag that determines whether the user's avatar is displayed or not.
 * @param {object} props.message - A message object that contains the content of the message - the only field used currently.
 * @returns {JSX.Element} - A react element that renders a right-aligned message container in a private chat.
 * 
 * @example
 * // Render a right-aligned message in a private chat.
 * <RightAlignedMessage userImage={props.currentUser.profileImage} key={index} message={message} imageFlag={flag} />
 */

const RightAlignedMessage = (props) => {
    return (
        <div className='message-right-container'>
            <div className='message-container-current-user make-grid'>
                <div className='message-content-current-user make-grid'>
                    <p>{props.message.messageType !== 'system' ? props.message.messageContent : `You ${props.message.messageContent}`}</p>
                </div>
                <div className='message-current-user-avatar-container'>
                    {(props.imageFlag) ? <img className='message-user-avatar' src={props.userImage} /> : <div className='message-current-user-avatar-blank' />}
                </div>
            </div>
        </div>
    )
}

export default RightAlignedMessage