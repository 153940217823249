import getGlobalURL from "../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import OceansButton from "./OceansButton";
import SocialBackground from "../assets/images/tu_graphite_card_background_169.png";
import CampusBackground from "../assets/images/tu_gold_card_background_169.png";
import { FoundBadToken } from "../scripts/badToken";

const OceansList = (props) => {
   // Incoming Props: 'currentUser', 'buttonSelection' from Dashboard or Profile page

   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [userOceansAdminList, setUserOceansAdminList] = useState(["Web Development", "AI"]);

   useEffect(() => {
      // OCEANS SCROLL
      $("#right-btn").on("click", function () {
         $("#oceans-list-container ul").animate({ scrollLeft: "+=291px" }, "slow");
      });

      $("#left-btn").on("click", function () {
         $("#oceans-list-container ul").animate({ scrollLeft: "-=291px" }, "slow");
      });
      // END OF OCEANS SCROLL
   }, []);

   useEffect(() => {
      axios.get(`${url}oceans/getUserOceansAdminList/${props.currentUser.userID}`, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         }
      }).then((response) => {
         setUserOceansAdminList(response.data);
      }).catch((error) => {
         if (error.code === "ERR_BAD_REQUEST") {
            FoundBadToken();
         } else {
            alert(
               error.response.data.err + " error in getUserOceansAdminList"
            );
         }
      });
   }, [props.oceanCreated]);

   const handleButtonClick = (buttonClicked) => {
      props.buttonSelection(buttonClicked, "My Hubs");
   };

   return (
      <div id="oceans-list-banner-container">
         <div id="oceans-list-banner-title-container">
            {props.source === "dashboard" ? (
               <Fragment>
                  <div onClick={() => {props.handleTabClick("My Hubs")}} className={`oceans-list-banner-title-tab ${props.tabSelected === "My Hubs" ? "tab-active" : "tab-inactive"}`}>👥 My Hubs</div>
                  <div onClick={() => {props.handleTabClick("My Clubs")}} className={`oceans-list-banner-title-tab ${props.tabSelected === "My Clubs" ? "tab-active" : "tab-inactive"}`}>👥 My Clubs</div>
                  <div onClick={() => {props.handleTabClick("Administration")}} className={`oceans-list-banner-title-tab ${props.tabSelected === "Administration" ? "tab-active" : "tab-inactive"}`}>🏛️ Admin</div>
                  <div onClick={() => {props.handleTabClick("Academics")}} className={`oceans-list-banner-title-tab ${props.tabSelected === "Academics" ? "tab-active" : "tab-inactive"}`}>📚 Academics</div>
                  <div onClick={() => {props.handleTabClick("Student Life")}} className={`oceans-list-banner-title-tab ${props.tabSelected === "Student Life" ? "tab-active" : "tab-inactive"}`}>☀️ Student Life</div>
                  <div onClick={() => {props.handleTabClick("Off Campus")}} className={`oceans-list-banner-title-tab ${props.tabSelected === "Off Campus" ? "tab-active" : "tab-inactive"}`}>🏙️ Off Campus</div>
               </Fragment>
            ) : (
               <div className={`oceans-list-banner-title-tab tab-active`}>My Hubs</div>
            )}
         </div>
         <div
            id="oceans-list-banner-links-container"
            className="container-border"
         >
            <div id="oceans-list-buttons-container" className="make-grid">
               <div id="left-btn" className="directional-btn btn-ease">
                  {"<"}
               </div>
               <div id="oceans-list-container">
                  {/* The oceans (buttons) will dynamically load here using map() of as many interests user has. 
                ensure that the format is <li><OceansButton /></li> */}
                  <ul>
                     <li>
                        {props.tabSelected === "My Clubs" ? (
                           <OceansButton
                              title={"All"}
                              handleButtonClick={handleButtonClick}
                              activeButton={props.activeButton}
                              userOceansAdminList={userOceansAdminList}
                           />
                        ) : null}
                     </li>
                     {props.currentUser.oceans.sort().map((oceanTitle) => {
                        return (
                           <Fragment key={oceanTitle}>
                              <li>
                                 <OceansButton
                                    title={oceanTitle}
                                    handleButtonClick={handleButtonClick}
                                    activeButton={props.activeButton}
                                    userOceansAdminList={userOceansAdminList}
                                 />
                              </li>
                           </Fragment>
                        );
                     })}
                  </ul>
               </div>
               <div id="right-btn" className="directional-btn btn-ease">
                  {">"}
               </div>
            </div>
         </div>
      </div>
   );
};

export default OceansList;

// style={{'background-image': `url(${SocialBackground})`, 'background-size': 'contain'}}
