import getGlobalURL from "../../assets/scripts/Global";
import { React, Fragment, useState, useEffect } from "react";
import axios from "axios";
import SuggestedOceansListing from "./SuggestedOceansListing";
import CreateOceanModal from "../../Modals/oceanModals/CreateOceanModal";
import { FoundBadToken } from "../../scripts/badToken";

const SuggestedOceans = (props) => {
   // ##################### URL change for Vercel##############################
   const url = getGlobalURL();
   if (url === "https://oceans-server-prod-v1.vercel.app") {
      axios.defaults.withCredentials = true;
   }
   // ##################### END OF URL change for Vercel#######################

   const [suggestedOceansList, setSuggestedOceansList] = useState([]);
   const [modalStatus, setModalStatus] = useState(false);

   // Get 5 suggested oceans that the user is not already a member of. Store in state variable

   useEffect(() => {
      axios.post(`${url}oceans/suggestedOceans/${props.currentUser.userID}`, {}, {
         headers: {
            Authorization: sessionStorage.getItem("token"),
         },
      }).then((response) => {
         setSuggestedOceansList(response.data);
      }).catch((error) => {
         if(error.response.status === 401){
            FoundBadToken();
         } 
         else{
            alert(error.response.data.err + " inside Suggested Ocean");
         }
      });
   }, []);

   const handleCreateOceanClick = () => {
      setModalStatus(true);
   };

   const closeModal = () => {
      setModalStatus(false);
   };

   return (
      <Fragment>
         {modalStatus && (
            <CreateOceanModal currentUser={props.currentUser} closeModal={closeModal} handleOceanCreated={props.handleOceanCreated} />
         )}
         <div id="suggested-oceans-container">
            <h2>Suggested Hubs</h2>
            {suggestedOceansList.sort().map((ocean) => {
               return (
                  <Fragment key={ocean.oceanName}>
                     <SuggestedOceansListing currentUser={props.currentUser} suggestedOcean={ocean} />
                  </Fragment>
               );
            })}
            <p>Can't find a topic that you're interested in? Create your own by clicking below!</p>
            <button id="create-ocean-btn" className="btn-ease" onClick={handleCreateOceanClick} >Create Hub</button>
            <hr />
         </div>
      </Fragment>
   );
};

export default SuggestedOceans;
